import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import "./PlantTable.css";
function Paginationcontrol({
    rowsCount,
    tableProps,
    tablePropsChange,
    alwaysShown = true
}) {

    const [pagesCount, setPagesCount] = useState(Math.ceil(rowsCount / tableProps.rowsPerPage));
    const [perPage, setPerPage] = useState(5);
  
    useEffect(() => {
      
        // setPagesCount(Math.ceil(rowsCount / tableProps.rowsPerPage)); 
              
      
    }, [tableProps.rowsPerPage])

    if(tableProps.pageLast>pagesCount){
        tableProps.pageLast=pagesCount;
    }

    const isPaginationShown = alwaysShown ? true : pagesCount > 1;
    const isCurrentPageFirst = tableProps.currentPage <= tableProps.pageFirst;
    const isCurrentPageLast = tableProps.currentPage >= tableProps.pageLast;
    const isNextForward = tableProps.pageLast === pagesCount;
    const isPrevForward = tableProps.pageFirst < perPage;


    const onPageNumberClick = pageNumber => {
        if (tableProps.currentPage === pageNumber) return;
        tablePropsChange({ ...tableProps, currentPage: pageNumber })
    };

    const onPreviousPageClick = () => {
       if(tableProps.currentPage !== tableProps.pageFirst){
            tablePropsChange({ ...tableProps, currentPage: (tableProps.currentPage - 1) })
        }
    };

    const onNextPageClick = () => {
         if(tableProps.currentPage !== tableProps.pageLast) {
            tablePropsChange({ ...tableProps, currentPage: tableProps.currentPage + 1 })
        }
    };

    const onDoubleLeftArrow = () => {
        if(tableProps.pageLast <= pagesCount ){
        tablePropsChange({ ...tableProps,currentPage:(tableProps.pageFirst-perPage), pageFirst:((tableProps.pageFirst - perPage)), pageLast:tableProps.pageFirst-1 })
        }
    }

    const onDoubleRightArrow = () => {
        if(tableProps.pageLast < pagesCount){
        tablePropsChange({ ...tableProps,currentPage:tableProps.pageFirst+perPage, pageFirst:(tableProps.pageLast + 1) , pageLast:(tableProps.pageLast + (perPage<pagesCount?(pagesCount-perPage):pagesCount) )})
        }
    }

    const handleRowsPerPage = (v) => {
        const ct=Math.ceil(rowsCount/parseInt(v));
       const lastPage=ct<perPage?ct:perPage;
       
       tablePropsChange({ ...tableProps, currentPage: 1,pageFirst:1,pageLast:lastPage, rowsPerPage: parseInt(v) })
      
    }

    const pageArray = useMemo(() => {
        const pArray = new Map();
        for (let i = 1; i <= pagesCount; i++) {
            pArray.set(i - 1, i - 1)
        }
        return pArray;
    }, [pagesCount])

    return (
        <>
            {isPaginationShown && (
                <div className="pagination-container">
                    <Pagination className="table-pagination gap-1" >
                        <Pagination.Item >Rows Per Page</Pagination.Item>
                        <Pagination.Item >
                            <select className="page-input" onChange={(e) => handleRowsPerPage(e.currentTarget.value)} value={tableProps.rowsPerPage} size="sm" aria-label="">
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                            </select >
                        </Pagination.Item>
                        <Button className={isPrevForward ? 'disable p-0 m-0' : 'p-0 m-0'}
                            onClick={() => onDoubleLeftArrow()}
                            disabled={isPrevForward}><KeyboardDoubleArrowLeftOutlinedIcon sx={{ color: 'black' }} /></Button>
                        <Button className={isCurrentPageFirst ? 'disable p-0 m-0' : 'p-0 m-0'}
                            onClick={() => onPreviousPageClick()}
                            disabled={isCurrentPageFirst}><KeyboardArrowLeftOutlinedIcon sx={{ color: 'black' }} /></Button>
                        {[...pageArray.keys()].slice(tableProps.pageFirst-1, tableProps.pageLast).map((page, index) => {
                           return (
                                <Pagination.Item
                                    activeLabel=""
                                    key={page+1}
                                    onClick={() => onPageNumberClick(page+1)}
                                    active={(page+1) === tableProps.currentPage}
                                >
                                    {page+1}
                                </Pagination.Item>
                            );
                        })}
                        <Button onClick={() => onNextPageClick()}
                            disabled={isCurrentPageLast}
                            className={isCurrentPageLast ? 'disable p-0 m-0' : 'p-0 m-0'}><KeyboardArrowRightOutlinedIcon sx={{ color: 'black' }} /></Button>
                        <Button onClick={() => onDoubleRightArrow()}
                            disabled={isNextForward}
                            className={isNextForward ? 'disable p-0 m-0' : 'p-0 m-0'}><KeyboardDoubleArrowRightOutlinedIcon sx={{ color: 'black' }} /></Button>
                    </Pagination>
                </div>
            )}
        </>
    );
};
export default Paginationcontrol
