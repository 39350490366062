import React, { useState, useEffect } from "react";
import "./ProductionOverview.css";
import GaugeChart from "./GaugeChart"; // Use your custom GaugeChart
import dragHandleDots from "../../assets/drag_handle_dots.svg";
import expandAltIcon from "../../assets/expand_alt_icon.svg";
import informationIcon from "../../assets/information_icon.svg";
import sampleData from "./SampleData";
import { Dialog, DialogContent  } from '@mui/material';
import CloseBlue from "../../assets/closeBtnBlue.svg";

const ProductionOverview = () => {
  const [selectedOption, setSelectedOption] = useState("Month");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [modalData, setModalData] = useState({}); // Data for the modal

  const [efficiencyData, setEfficiencyData] = useState({
    gauge: {
      percent: 60,
      colors: ["#00FF00"],
      needleColor: "#FF0000",
    },
    totalProductionPower: "15",
  });

  const [detailsData, setDetailsData] = useState({
    installedCapacity: "5",
    details: {
      daily: "0",
      monthly: "0",
      yearly: "0",
      total: "0",
    },
  });

  useEffect(() => {
    calculateDetailsData();
  }, []);

  // const handleExpandClick = () => {
  //   const data = {
  //     gauge: efficiencyData.gauge,
  //     totalProductionPower: efficiencyData.totalProductionPower,
  //     installedCapacity: detailsData.installedCapacity,
  //     details: detailsData.details,
  //   };
  //   setModalData(data);
  //   setIsModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };
  
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const calculateDetailsData = () => {
    const today = new Date();
    const entryDate = new Date(today);
    const dailyData = sampleData.filter(
      (entry) =>
        new Date(entry.DATE).toDateString() === entryDate.toDateString()
    );

    const monthlyData = sampleData.filter(
      (entry) =>
        new Date(entry.DATE).getFullYear() === entryDate.getFullYear() &&
        new Date(entry.DATE).getMonth() === entryDate.getMonth()
    );

    const yearlyData = sampleData.filter(
      (entry) => new Date(entry.DATE).getFullYear() === entryDate.getFullYear()
    );

    const totalProduction = sampleData.reduce((acc, curr) => {
      const production = parseFloat(curr.TOTAL_PRODUCTION);
      return acc + (isNaN(production) ? 0 : production);
    }, 0);

    const details = {
      daily: `${dailyData
        .reduce((acc, curr) => {
          const production = parseFloat(curr.TOTAL_PRODUCTION);
          return acc + (isNaN(production) ? 0 : production);
        }, 0)
        .toFixed(2)}`,
      monthly: `${(
        monthlyData.reduce((acc, curr) => {
          const production = parseFloat(curr.TOTAL_PRODUCTION);
          return acc + (isNaN(production) ? 0 : production);
        }, 0) / 1000
      ).toFixed(2)}`,
      yearly: `${(
        yearlyData.reduce((acc, curr) => {
          const production = parseFloat(curr.TOTAL_PRODUCTION);
          return acc + (isNaN(production) ? 0 : production);
        }, 0) / 1000
      ).toFixed(2)}`,
      total: `${(totalProduction / 1000).toFixed(2)}`,
    };

    setDetailsData((prevData) => ({
      ...prevData,
      details,
    }));
  };

  return (
    <div className="PO-production-overview-card">
      <div className="PO-card-header">
        <div className="PO-header-left">
          <img
            src={dragHandleDots}
            alt="Drag Handle"
            className="PO-drag-handle"
          />
          <h2 className="PO-card-title">Production Overview</h2>
          <img
            src={informationIcon}
            alt="Information Icon"
            className="PO-info-icon"
          />
        </div>
        <div className="PO-header-right">
          <div className="PO-icon" onClick={handleOpenModal}>
            <img
              src={expandAltIcon}
              alt="Expand Icon"
              className="PO-expand-icon"
            />
          </div>
        </div>
      </div>
      <div className="PO-card-content">
        <div className="PO-gauge-container">
          {efficiencyData && (
            <GaugeChart value={efficiencyData.gauge.percent} />
          )}
        </div>
        <div className="PO-center-section">
          <div className="PO-detail-row-left">
            <div className="PO-detail-item-left">
              <span className="PO-label-left">Current Power Production</span>
              <span className="PO-value-left">
                {efficiencyData?.totalProductionPower}<span className="PO-value-units"> KW</span>
              </span>
            </div>
          </div>
          <div className="PO-dotted-line-horizontal"></div>
          <div className="PO-detail-row-left">
            <div className="PO-detail-item-left">
              <span className="PO-label-left">Installed Capacity</span>
              <span className="PO-value-left">
                {detailsData?.installedCapacity}<span className="PO-value-units"> kWp</span>
              </span>
            </div>
          </div>
        </div>
        <div className="PO-right-section">
          <div className="PO-detail-row-with-divider">
            <div className="PO-detail-item">
              <span className="PO-label">Daily</span>
              <span className="PO-value">{detailsData?.details.daily}<span className="PO-value-units"> kWh</span></span>
            </div>
            <div className="PO-detail-item">
              <span className="PO-label">Monthly</span>
              <span className="PO-value">{detailsData?.details.monthly}<span className="PO-value-units"> MWh</span></span>
            </div>
          </div>
          <div className="PO-dotted-line-horizontal-small"></div>
          <div className="PO-detail-row-with-divider">
            <div className="PO-detail-item">
              <span className="PO-label">Yearly</span>
              <span className="PO-value">{detailsData?.details.yearly}<span className="PO-value-units"> MWh</span></span>
            </div>
            <div className="PO-detail-item">
              <span className="PO-label">Total</span>
              <span className="PO-value">{detailsData?.details.total}<span className="PO-value-units"> MWh</span></span>
            </div>
          </div>
        </div>
      </div>
      {/* {isModalOpen && (
        <ProductionModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          data={modalData}
          width={1200} // Pass desired width for modal
          min-height={300} // Pass desired height for modal
        />
      )} */}

      {/* Modal Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth={'xl'} 
          PaperProps={{
                        sx: {
                          width: "50%",
                          minHeight: "45%"
                        }
                      }}
      fullWidth>
        <DialogContent>
          <div>
          <div className="PO-card-header">
        <div className="PO-header-left">
          <img
            src={dragHandleDots}
            alt="Drag Handle"
            className="PO-drag-handle"
          />
          <h2 className="PO-card-title">Production Overview</h2>
          <img
            src={informationIcon}
            alt="Information Icon"
            className="PO-info-icon"
          />
        </div>
        <div className="PO-header-right">
          <div className="PO-icon">
            <img
              src={CloseBlue}
              alt="Close Icon"
              className="PO-close-icon"
              onClick={handleCloseModal}
            />
          </div>
        </div>
      </div>
      <div className="PO-card-content">
        <div className="PO-gauge-container">
          {efficiencyData && (
            <GaugeChart value={efficiencyData.gauge.percent} />
          )}
        </div>
        <div className="PO-center-section">
          <div className="PO-detail-row-left">
            <div className="PO-detail-item-left">
              <span className="PO-label-left">Current Power Production</span>
              <span className="PO-value-left">
                {efficiencyData?.totalProductionPower}<span className="PO-value-units"> KW</span>
              </span>
            </div>
          </div>
          <div className="PO-dotted-line-horizontal"></div>
          <div className="PO-detail-row-left">
            <div className="PO-detail-item-left">
              <span className="PO-label-left">Installed Capacity</span>
              <span className="PO-value-left">
                {detailsData?.installedCapacity}<span className="PO-value-units"> kWp</span>
              </span>
            </div>
          </div>
        </div>
        <div className="PO-right-section">
          <div className="PO-detail-row-with-divider">
            <div className="PO-detail-item">
              <span className="PO-label">Daily</span>
              <span className="PO-value">{detailsData?.details.daily}<span className="PO-value-units"> kWh</span></span>
            </div>
            <div className="PO-detail-item">
              <span className="PO-label">Monthly</span>
              <span className="PO-value">{detailsData?.details.monthly}<span className="PO-value-units"> MWh</span></span>
            </div>
          </div>
          <div className="PO-dotted-line-horizontal-small"></div>
          <div className="PO-detail-row-with-divider">
            <div className="PO-detail-item">
              <span className="PO-label">Yearly</span>
              <span className="PO-value">{detailsData?.details.yearly}<span className="PO-value-units"> MWh</span></span>
            </div>
            <div className="PO-detail-item">
              <span className="PO-label">Total</span>
              <span className="PO-value">{detailsData?.details.total}<span className="PO-value-units"> MWh</span></span>
            </div>
          </div>
        </div>
      </div>
          
          </div>
        
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProductionOverview;

// import React, { useState, useEffect } from "react";
// import "./ProductionOverview.css";
// import GaugeChart from "./GaugeChart"; // Use your custom GaugeChart
// import dragHandleDots from "../../assets/drag_handle_dots.svg";
// import expandAltIcon from "../../assets/expand_alt_icon.svg";
// import informationIcon from "../../assets/information_icon.svg";
// import ProductionModal from "./ProductionModal"; // Import the modal component
// import sampleData from "./SampleData";

// const ProductionOverview = () => {
//   const [selectedOption, setSelectedOption] = useState("Month");
//   const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
//   const [modalData, setModalData] = useState({}); // Data for the modal

//   const [efficiencyData, setEfficiencyData] = useState({
//     gauge: {
//       percent: 0,
//       colors: ["#00FF00"],
//       needleColor: "#FF0000",
//     },
//     totalProductionPower: "0 kWp",
//   });

//   const [detailsData, setDetailsData] = useState({
//     installedCapacity: "5 kWp",
//     details: {
//       daily: "0 kWh",
//       monthly: "0 MWh",
//       yearly: "0 MWh",
//       total: "0 MWh",
//     },
//   });

//   const [lastUpdated, setLastUpdated] = useState(new Date());

//   useEffect(() => {
//     calculateDetailsData();
//     updateLastUpdatedTime();

//     const interval = setInterval(() => {
//       calculateDetailsData();
//       updateLastUpdatedTime();
//     }, 15 * 60 * 1000); // Update every 15 minutes

//     return () => clearInterval(interval); // Clean up interval on component unmount
//   }, []);

//   const updateLastUpdatedTime = () => {
//     setLastUpdated(new Date());
//   };

//   const handleExpandClick = () => {
//     const data = {
//       gauge: efficiencyData.gauge,
//       totalProductionPower: efficiencyData.totalProductionPower,
//       installedCapacity: detailsData.installedCapacity,
//       details: detailsData.details,
//     };
//     setModalData(data);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const calculateDetailsData = () => {
//     const today = new Date();
//     const entryDate = new Date(today);
//     const dailyData = sampleData.filter(
//       (entry) =>
//         new Date(entry.DATE).toDateString() === entryDate.toDateString()
//     );

//     const monthlyData = sampleData.filter(
//       (entry) =>
//         new Date(entry.DATE).getFullYear() === entryDate.getFullYear() &&
//         new Date(entry.DATE).getMonth() === entryDate.getMonth()
//     );

//     const yearlyData = sampleData.filter(
//       (entry) => new Date(entry.DATE).getFullYear() === entryDate.getFullYear()
//     );

//     const totalProduction = sampleData.reduce((acc, curr) => {
//       const production = parseFloat(curr.TOTAL_PRODUCTION);
//       return acc + (isNaN(production) ? 0 : production);
//     }, 0);

//     const details = {
//       daily: `${dailyData
//         .reduce((acc, curr) => {
//           const production = parseFloat(curr.TOTAL_PRODUCTION);
//           return acc + (isNaN(production) ? 0 : production);
//         }, 0)
//         .toFixed(2)} kWh`,
//       monthly: `${(
//         monthlyData.reduce((acc, curr) => {
//           const production = parseFloat(curr.TOTAL_PRODUCTION);
//           return acc + (isNaN(production) ? 0 : production);
//         }, 0) / 1000
//       ).toFixed(2)} MWh`,
//       yearly: `${(
//         yearlyData.reduce((acc, curr) => {
//           const production = parseFloat(curr.TOTAL_PRODUCTION);
//           return acc + (isNaN(production) ? 0 : production);
//         }, 0) / 1000
//       ).toFixed(2)} MWh`,
//       total: `${(totalProduction / 1000).toFixed(2)} MWh`,
//     };

//     setDetailsData((prevData) => ({
//       ...prevData,
//       details,
//     }));
//   };

//   const formatTime = (date) => {
//     return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
//   };

//   const formatDate = (date) => {
//     return date.toLocaleDateString([], {
//       month: "long",
//       day: "numeric",
//       year: "numeric",
//     });
//   };

//   return (
//     <div className="PO-production-overview-card">
//       <div className="PO-card-header">
//         <div className="PO-header-left">
//           <img
//             src={dragHandleDots}
//             alt="Drag Handle"
//             className="PO-drag-handle"
//           />
//           <div className="PO-title-container">
//             <h2 className="PO-card-title">Production Overview</h2>{" "}
//             <span className="PO-last-updated">
//               Last Updated {formatTime(lastUpdated)} on{" "}
//               {formatDate(lastUpdated)}
//             </span>
//           </div>
//           <img
//             src={informationIcon}
//             alt="Information Icon"
//             className="PO-info-icon"
//           />
//         </div>

//         <div className="PO-header-right">
//           <div className="PO-icon" onClick={handleExpandClick}>
//             <img
//               src={expandAltIcon}
//               alt="Expand Icon"
//               className="PO-expand-icon"
//             />
//           </div>
//         </div>
//       </div>

//       <div className="PO-card-content">
//         <div className="PO-gauge-container">
//           {efficiencyData && (
//             <GaugeChart value={efficiencyData.gauge.percent * 100} />
//           )}
//         </div>
//         <div className="PO-center-section">
//           <div className="PO-detail-row-left">
//             <div className="PO-detail-item-left">
//               <span className="PO-label-left">Total Production Power</span>
//               <span className="PO-value-left">
//                 {efficiencyData?.totalProductionPower}
//               </span>
//             </div>
//           </div>
//           <div className="PO-dotted-line-horizontal"></div>
//           <div className="PO-detail-row-left">
//             <div className="PO-detail-item-left">
//               <span className="PO-label-left">Installed Capacity</span>
//               <span className="PO-value-left">
//                 {detailsData?.installedCapacity}
//               </span>
//             </div>
//           </div>
//         </div>
//         <div className="PO-right-section">
//           <div className="PO-detail-row-with-divider">
//             <div className="PO-detail-item">
//               <span className="PO-label">Daily</span>
//               <span className="PO-value">{detailsData?.details.daily}</span>
//             </div>
//             <div className="PO-detail-item">
//               <span className="PO-label">Monthly</span>
//               <span className="PO-value">{detailsData?.details.monthly}</span>
//             </div>
//           </div>
//           <div className="PO-dotted-line-horizontal-small"></div>
//           <div className="PO-detail-row-with-divider">
//             <div className="PO-detail-item">
//               <span className="PO-label">Yearly</span>
//               <span className="PO-value">{detailsData?.details.yearly}</span>
//             </div>
//             <div className="PO-detail-item">
//               <span className="PO-label">Total</span>
//               <span className="PO-value">{detailsData?.details.total}</span>
//             </div>
//           </div>
//         </div>
//       </div>
//       {isModalOpen && (
//         <ProductionModal
//           isOpen={isModalOpen}
//           onClose={handleCloseModal}
//           data={modalData}
//           width={350} // Pass desired width for modal
//           height={240} // Pass desired height for modal
//         />
//       )}
//     </div>
//   );
// };

// export default ProductionOverview;
