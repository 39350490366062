import { DatePicker } from "@mui/x-date-pickers";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useState } from "react";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";

function MuiDatePickerComponent(props) {
    // const [open, setOpen] = useState(false);

    // ButtonField component
    function ButtonField(props) {
        const {
            setOpen,
            label,
            id,
            disabled,
            InputProps: { ref } = {},
            inputProps: { 'aria-label': ariaLabel } = {},
        } = props;

        return (
            <IconButton
                ref={ref}
                sx={{ padding: 0 }}
                onClick={() => !disabled && setOpen((prev) => !prev)}
                disabled={disabled}
            >
                <CalendarMonthOutlinedIcon style={{ fontSize: 27 }} />
            </IconButton>
        );
    }

    function ButtonDatePicker(props) {
        const [open, setOpen] = useState(false);
        const min = dayjs('01/01/2019');
        const max = dayjs('12/31/2024');
        return (
            <DatePicker
                slots={{ ...props.slots, field: ButtonField }}
                slotProps={{ ...props.slotProps, field: { setOpen } }}
                {...props}
                minDate={min}
                maxDate={max}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            />
        );
    }

    return (
        <ButtonDatePicker
            {...props}
        />
    );

}

export default MuiDatePickerComponent;