import { Col, Container, Row } from "react-bootstrap";

import PlantCard from "./PlantCard";
import ProductionState from "./ProductionState";
import HistoricalProduction from "./HistoricalProduction";
import EnvironmentalBenefits from "./EnvironmentBenifits";
import Devices from "./Devices";
import DailyEnergyOutput from "./DailyEnergyOutput";
import PerformanceRation from "./PerformanceRatio";
import PlantCuf from "./PlantCuf";
function PlantDetailsTab(props) {
    return (<>
        <Container as={"div"} fluid className="mw-100 px-2 m-0">


            <Row className="mt-2">
                <Col className="m-0 me-lg-2 p-0 mh-100"><PlantCard plant={props.plant} /></Col>
                <Col className="m-0 ms-lg-2 p-0 mh-100"><ProductionState plant={props.plant} /></Col>

            </Row>
            <Row className="mt-2">
                <Col className="m-0 me-lg-2 p-0 mh-100"><HistoricalProduction /></Col>
                <Col className="m-0 ms-lg-2 p-0 mh-100"><EnvironmentalBenefits /></Col>

            </Row>
            <Row className="mt-2">
                <Col className="m-0 me-lg-2 p-0 mh-100"><Devices updatekey={props.updatekey} /></Col>
                <Col className="m-0 ms-lg-2 p-0 mh-100"><DailyEnergyOutput /></Col>

            </Row>
            <Row className="mt-2">
                <Col className="m-0 me-lg-2 p-0 mh-100"><PerformanceRation /></Col>
                <Col className="m-0 ms-lg-2 p-0 mh-100"><PlantCuf /></Col>

            </Row>

        </Container>
    </>)
}

export default PlantDetailsTab;