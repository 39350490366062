import React, { useState, useMemo, useEffect, useContext } from "react";
import "./UserTable.css"; // Importing the styles
import axios from "axios"; // Importing axios for API requests

// Importing icons from src/assets
import updownIcon from "../../assets/updown_icon.png";
import deleteIcon from "../../assets/delete_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import editIcon from "../../assets/edit-icon.png";

import { useNavigate } from "react-router-dom";
import { Col, OverlayTrigger, Popover, Row, Stack, Modal, Button } from "react-bootstrap";
import Paginationcontrol from "./PaginationControl";
import { useTranslation } from "react-i18next";
import { MessageContext } from "../../App"; // Context for displaying messages
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';

const UserTable = ({ stateChanger }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    userId: false,
    firstName: true,
    lastName: true,
    emailId: true,
    mobileNumber: true,
    role: true,
  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableProps, setTableProps] = useState({ rowsPerPage: 5, currentPage: 1 });
  const [data, setData] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal visibility state
  const [selectedUser, setSelectedUser] = useState(null); // Selected user for confirmation
  const [update, setUpdate] = useState(false);
  const onMessageChange = useContext(MessageContext); // Using context for messages
  const { t } = useTranslation(["user"]);

     // Retrieve userData from session storage
     const userData = JSON.parse(sessionStorage.getItem("userData"));
     const userEmail = userData?.email || "";
   console.log("User email from session storage:", userEmail); // Log to check email from session

  // Fetch data from API
  const fetchData = async () => {
    try {
      const entityId = JSON.parse(sessionStorage.getItem("userData"))?.entityId || "";
      const authToken = sessionStorage.getItem("authToken");

      if (!entityId) {
        console.warn("No entity ID found. Ensure session storage contains 'userData' with 'entityId'.");
        return;
      }

      const response = await axios.get(`https://solar-api.antsai.in/api/user/getUsersByEntity?entityid=${entityId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((item) => ({
          userId: item.userId || item.id || item.user_id,
          firstName: item.firstName,
          lastName: item.lastName,
          emailId: item.emailId,
          mobileNumber: item.mobileNo,
          role: item.role,
        }));
        setData(formattedData);
      } else {
        console.error("Unexpected data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle disable action with the selected user's userId
  // const handleDisableUser = (e, user) => {
  //   e.stopPropagation();
  //   setSelectedUser(user);
  //   setShowConfirmModal(true); // Show confirmation modal
  // };

  // Inside UserTable component

  const handleEditUser = (user) => {
    const entityId = JSON.parse(sessionStorage.getItem("userData"))?.entityId;
    console.log("Editing user with userId:", user.userId, "and entityId:", entityId); // Log userId and entityId
    
    // Pass the user data and entityId to the edit state function
    stateChanger({ ...user, entityId });
};

  // const confirmDisable = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://solar-api.antsai.in/api/user/disable",
  //       { userId: selectedUser.userId }, // Pass the userId of the selected user
  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       onMessageChange({ type: "Success", info: "User disabled successfully." });
  //       setShowConfirmModal(false); // Close the modal on success
  //       fetchData(); // Fetch updated data to refresh the table
  //     } else {
  //       onMessageChange({ type: "Danger", info: `Failed to disable user: ${response.data.message || "Unknown error"}` });
  //       setShowConfirmModal(false); // Close the modal in case of error
  //     }
  //   } catch (error) {
  //     onMessageChange({ type: "Warning", info: "An error occurred while disabling the user. Please try again later." });
  //     setShowConfirmModal(false); // Close the modal in case of error
  //   }
  // };

  const disableRow = (e, user) => {
    e.stopPropagation();
    setSelectedUser(user);
    setShowConfirmModal(true); // Show confirmation modal
  };

  const confirmDisable = async () => {
    if (!selectedUser?.userId) {
      console.error("User ID is missing. Cannot disable user.");
      onMessageChange({ type: "Warning", info: "User ID is missing. Please select a valid user." });
      return;
    }
  
    try {
      const response = await fetch("https://solar-api.antsai.in/api/user/disable", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
        body: JSON.stringify({ userid: selectedUser.userId }),
      });
  
      if (response.ok) {
        onMessageChange({ type: "Success", info: "User disabled successfully." });
        setShowConfirmModal(false);
        fetchData(); // Refresh the table data
      } else {
        const errorData = await response.json();
        console.error("Failed to disable user:", errorData);
        onMessageChange({ type: "Danger", info: `Failed to disable user: ${errorData.message || "Unknown error"}` });
        setShowConfirmModal(false);
      }
    } catch (error) {
      console.error("Error disabling user:", error);
      onMessageChange({ type: "Warning", info: "An error occurred while disabling the user. Please try again later." });
      setShowConfirmModal(false);
    }
  };
  

  // Handle edit action with the selected user's data
  // const handleEditUser = (user) => {
  //   stateChanger(user); // Pass the user data (including userId) to the edit state function
  // };

  const downloadCSV = () => {
    const csvRows = [];
    const headers = Object.keys(data[0] || {});
    csvRows.push(headers.join(","));
    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "UserList.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === "ascending" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === "ascending" ? 1 : -1;
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig, searchQuery]);

  const filteredData = useMemo(() => {
    return sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true;
      return row[selectedFilter].toLowerCase().startsWith(searchQuery.toLowerCase());
    });
  }, [update, sortConfig, searchQuery, sortedData]);

  const currentRows = useMemo(() => {
    if (filteredData.length > 0) {
      return filteredData.slice(
        (tableProps.currentPage - 1) * tableProps.rowsPerPage,
        (tableProps.currentPage - 1) * tableProps.rowsPerPage + tableProps.rowsPerPage
      );
    }
    return [];
  }, [filteredData, tableProps]);

  const tablePropsChange = (newTableProps) => setTableProps(newTableProps);

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-container">
        <Row className="d-flex flex-row align-items-center justify-content-between my-4">
          <Col></Col>
          <Col className="gap-3 d-flex flex-row align-items-center justify-content-end">
            <span className="downloadLink" onClick={downloadCSV}>{t("user:download")}</span>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="left-start"
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <ul>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <li key={column} onClick={() => setSelectedFilter(column)}>
                            {column.charAt(0).toUpperCase() + column.slice(1)}
                          </li>
                        ))}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <img src={filterIcon} alt="filter" className="filter-icon" />
            </OverlayTrigger>
            <div style={{ position: "relative" }} className="w-auto">
              <input
                type="text"
                style={{
                  width: "341px",
                  height: "39px",
                  background: "#BBCCD9 0% 0% no-repeat padding-box",
                  borderRadius: "5px",
                  opacity: "0.51",
                }}
                className="search-input"
                placeholder={`Search by ${selectedFilter || "..."}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={!selectedFilter}
              />
              <img src={searchIcon} alt="search" className="search-icon" />
            </div>
          </Col>
        </Row>
        <div className="border rounded border-light-subtle">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  {Object.keys(selectedColumns)
                    .filter((key) => selectedColumns[key])
                    .map((column) => (
                      <th key={column} onClick={() => handleSort(column)}>
                        {column.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + column.slice(1)}
                        <img src={updownIcon} alt="sort" style={{ cursor: "pointer" }} />
                      </th>
                    ))}
                  <th>
                  <div className=" d-flex flex-row align-items-center justify-content-end">
                    <OverlayTrigger
                      rootClose
                      trigger="click"
                      placement="auto-start"
                      overlay={
                        <Popover>
                          <Popover.Body className="overlay-body">
                            {Object.keys(selectedColumns).map((column) => (
                              <label key={column}>
                                <input
                                  type="checkbox"
                                  checked={selectedColumns[column]}
                                  onChange={() => setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }))}
                                />{" "}
                                {column.charAt(0).toUpperCase() + column.slice(1)}
                              </label>
                            ))}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                        <div>
                      <img src={settingIcon} alt="settings" className="setting-icon" />
                      </div>
                    </OverlayTrigger>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRows.map((row) => (
                  <tr key={row.userId}>
                    {Object.keys(selectedColumns)
                      .filter((key) => selectedColumns[key])
                      .map((column) => (
                        <td key={`${row.userId}-${column}`}>{row[column]}</td>
                      ))}
                    <td className="delete-column">
                    <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
                        <img src={editIcon} alt="edit" className="edit-icon" onClick={() => handleEditUser(row)} />
                        {/* <NotInterestedOutlinedIcon
                          onClick={(e) => handleDisableUser(e, row)}
                          style={{ cursor: "pointer" }}
                        /> */}
                        {/* <NotInterestedOutlinedIcon
                        onClick={(e) => disableRow(e, row)}
                        style={{ cursor: "pointer" }}
                      /> */}
                      {row.emailId !== userEmail ? (
            <NotInterestedOutlinedIcon
              onClick={(e) => disableRow(e, row)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <span style={{ width: "24px" }}></span> // Placeholder for alignment
          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {filteredData.length > 0 ? (
              <Paginationcontrol
                rowsCount={filteredData.length}
                tableProps={tableProps}
                tablePropsChange={tablePropsChange}
                alwaysShown={true}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Disable User?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to disable the user "{selectedUser?.firstName} {selectedUser?.lastName}"?
        </Modal.Body>
        <Modal.Footer>
          <button className="modal-button" onClick={() => setShowConfirmModal(false)} autoFocus>
            No
          </button>
          <button className="modal-button" onClick={confirmDisable}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserTable;
