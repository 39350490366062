import React, { createContext, useState } from "react";
import SolarNavbar from "./dashboard/navbar/SolarNavbar";
export const UserContext = createContext();
function Layout({ component }) {
    const [admin, setAdmin] = useState(false);
  
    return (
        <UserContext.Provider value={{admin,setAdmin}} >
            <div className="d-flex flex-column ">
                <SolarNavbar />

                {component}

            </div>
        </UserContext.Provider>

    )
};
export default Layout;