import React from "react";
import "./Dashboard.css";
import Overview from "./Overview";
import OverviewSection2 from "./overviewsection2/OverviewSection2";
import OverviewSection3 from "./overviewsection3/OverviewSection3";
import OverviewSection4 from "./overviewsection4/OverviewSection4";

function Dashboard() {
  return (
    <div className="Dashboard">
      <div className="dashboard-container">
        <Overview />
        <div className="section2">
          <OverviewSection2 />
        </div>
        <div className="section3">
          {" "}
          <OverviewSection3 />
        </div>
        <div className="section4">
          {" "}
          <OverviewSection4 />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
