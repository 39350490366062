// import React from "react";
// import styled from "styled-components";
// import expandAltIcon from "../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
// import sampleData from "../../overview/SampleData"; // Adjust the path as necessary
// import dragHandleDots from "../../../assets/drag_handle_dots.svg"; // Adjust the path as necessary
// import informationIcon from "../../../assets/information_icon.svg";

// const Card = styled.div`
//   border: 1px solid #e0e0e0;
//   border-radius: 8px;
//   padding: 16px;
//   margin: 16px;
//   width: 30%;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   background: #fff;
//   display: flex;
//   flex-direction: column;
//   height: 250px;
// `;

// const Title = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 8px; /* Reduced margin */
// `;

// const TitleContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin-bottom: 8px; /* Space between the title and the divider */

//   .drag-handle {
//     width: 16px; /* Set the desired width for the drag handle icon */
//     height: 16px; /* Set the desired height if needed */
//   }

//   .info-icon {
//     width: 17px; /* Set the desired width for the information icon */
//     height: 17px; /* Set the desired height if needed */
//   }
// `;

// const TitleLeft = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 8px;
// `;

// const TitleRight = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 8px;
// `;

// const TitleDivider = styled.div`
//   border-bottom: 1px solid #e0e0e0;
//   width: 100%;
//   margin-top: 5px; /* Space above the divider */
// `;

// const StatusRow = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin: 10px 0;
//   padding: 8px;
//   background-color: #f9f9f9;
//   border-radius: 4px;

//   &:nth-child(odd) {
//     background-color: #f1f1f1;
//   }

//   span {
//     display: inline-block;
//     width: 16px;
//     height: 16px;
//     border-radius: 50%;
//     margin-left: 8px;
//   }

//   .normal {
//     background: linear-gradient(to right, #d3d3d3, #d3d3d3, #d3d3d3);
//   }

//   .critical {
//     background: linear-gradient(to right, #d3d3d3, #d3d3d3, #d3d3d3);
//   }

//   .warning {
//     background: linear-gradient(to right, #d3d3d3, #d3d3d3, #d3d3d3);
//   }
// `;

// const Label = styled.div`
//   flex: 2;
//   text-align: left;
//   font-size: 0.9em;
// `;

// const Value = styled.div`
//   flex: 1;
//   text-align: center;
//   font-size: 0.9em;
//   font-weight: bold;
// `;

// const StatusText = styled.div`
//   flex: 1;
//   text-align: right;
//   font-size: 0.9em;
//   color: #888;
// `;

// const PlantStatus = () => {
//   const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

//   // Filter data by the current date
//   const currentData = sampleData.filter((plant) => plant.DATE === currentDate);

//   // Group data by PLANT_ID and calculate counts for each status
//   const plantStatusCounts = currentData.reduce((acc, plant) => {
//     const { PLANT_ID, plant_status } = plant;

//     if (!acc[PLANT_ID]) {
//       acc[PLANT_ID] = { online: 0, offline: 0, partially_online: 0 };
//     }

//     if (plant_status === "online") {
//       acc[PLANT_ID].online += 1;
//     } else if (plant_status === "offline") {
//       acc[PLANT_ID].offline += 1;
//     } else if (plant_status === "partially online") {
//       acc[PLANT_ID].partially_online += 1;
//     }

//     return acc;
//   }, {});

//   return (
//     <>
//       {Object.keys(plantStatusCounts).map((plantId) => (
//         <Card key={plantId}>
//           <Title>
//             <TitleContainer>
//               <TitleLeft>
//                 <img
//                   src={dragHandleDots}
//                   alt="Drag Handle"
//                   className="drag-handle"
//                 />
//                 <span>Plant Status</span>
//                 <img
//                   src={informationIcon}
//                   alt="Information Icon"
//                   className="info-icon"
//                 />
//               </TitleLeft>
//               <TitleRight>
//                 <img src={expandAltIcon} alt="Expand icon" />
//               </TitleRight>
//             </TitleContainer>
//             <TitleDivider />
//           </Title>
//           <StatusRow>
//             <Label>Plants online</Label>
//             <Value>{plantStatusCounts[plantId].online}</Value>
//             <span className="normal"></span>
//             <StatusText>Normal</StatusText>
//           </StatusRow>
//           <StatusRow>
//             <Label>Plants offline</Label>
//             <Value>{plantStatusCounts[plantId].offline}</Value>
//             <span className="critical"></span>
//             <StatusText>Critical</StatusText>
//           </StatusRow>
//           <StatusRow>
//             <Label>Partially online</Label>
//             <Value>{plantStatusCounts[plantId].partially_online}</Value>
//             <span className="warning"></span>
//             <StatusText>Warning</StatusText>
//           </StatusRow>
//         </Card>
//       ))}
//     </>
//   );
// };

// export default PlantStatus;

import { React, useState } from "react";
import styled from "styled-components";
import expandAltIcon from "../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
import sampleData from "../../overview/SampleData"; // Adjust the path as necessary
import dragHandleDots from "../../../assets/drag_handle_dots.svg"; // Adjust the path as necessary
import informationIcon from "../../../assets/information_icon.svg";
import critical from "../../../assets/EllipseCritical.svg";
import normal from "../../../assets/EllipseNormal.svg";
import warning from "../../../assets/EllipseWarning.svg";
import { color } from "chart.js/helpers";
import { Dialog, DialogContent  } from '@mui/material';
import CloseBlue from "../../../assets/closeBtnBlue.svg";

const Card = styled.div`
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #888888;
  border-radius: 8px;
  padding: 12px;
  margin: 16px;
  width: 30%;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  position: relative; /* Added for positioning the "more" text */
  justify-content: space-between; /* Ensures the "more" text stays at the bottom */
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px; /* Reduced margin */
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px; /* Space between the title and the divider */

  .drag-handle {
    /*width: 16px; /* Set the desired width for the drag handle icon */
    /*height: 16px; /* Set the desired height if needed */
  }

  .PS-card-title{
    font-family: "Lato", Open Sans;
    font-weight: bold;
    font-size: 18px;
    color: #1C2E38;
  }

  .info-icon {
    width: 20px; /* Set the desired width for the information icon */
    height: 20px; /* Set the desired height if needed */
  }
`;

const TitleLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TitleDivider = styled.div`
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  margin-top: 4px; /* Space above the divider */
`;

const StatusRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 0;
  padding: 8px 34px;
  background-color: #ffffff;
  border-radius: 4px;

  &:nth-child(odd) {
    background-color: #F2F3F6;
  }

  span {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 8px;
  }

  .normal {
    // background: linear-gradient(to right, #b1e26f, #8ec740, #65a80a);
    background: transparent radial-gradient(closest-side at 50% 50%, #B1E26F 0%, #8EC740 48%, #65A80A 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px #00000029;
    border: 1px solid #FFFFFF;
    opacity: 1;
  }

  .critical {
    // background: linear-gradient(to right, #F89C9C, #F46C6C, #C61010);
    background: transparent radial-gradient(closest-side at 50% 50%, #F89C9C 0%, #F46C6C 48%, #C61010 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px #00000029;
    border: 1px solid #FFFFFF;
    opacity: 1;
  }

  .warning {
    // background: linear-gradient(to right, #FFBE84, #F28E36, #F0770C);
    background: transparent radial-gradient(closest-side at 50% 50%, #FFBE84 0%, #F28E36 48%, #F0770C 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px #00000029;
    border: 1px solid #FFFFFF;
    opacity: 1;
  }
`;

const Label = styled.div`
  flex: 2;
  text-align: left;
  font-size: 18px;
`;

const Value = styled.div`
  flex: 1;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`;

const StatusText = styled.div`
  flex: 1;
  text-align: left;
  font-size: 18px;
  color: #888;
  padding-left:3vh;
`;

const Totaldivider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 5px 34px;
  width:100%;
  border-bottom: 1px solid #DEDEDE; 
`;

const MoreText = styled.div`
  color: #f75a34; /* Set the color */
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  text-align: right;
  margin-top: auto; /* Pushes the "more" text to the bottom of the card */
`;

const PlantStatus = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

  // Filter data by the current date
  const currentData = sampleData.filter((plant) => plant.DATE === currentDate);

  // Group data by PLANT_ID and calculate counts for each status
  const plantStatusCounts = currentData.reduce((acc, plant) => {
    const { PLANT_ID, plant_status } = plant;

    if (!acc[PLANT_ID]) {
      acc[PLANT_ID] = { online: 0, offline: 0, partially_online: 0 };
    }

    if (plant_status === "online") {
      acc[PLANT_ID].online += 1;
    } else if (plant_status === "offline") {
      acc[PLANT_ID].offline += 1;
    } else if (plant_status === "partially online") {
      acc[PLANT_ID].partially_online += 1;
    }

    return acc;
  }, {});

  return (
    <>
      {Object.keys(plantStatusCounts).map((plantId) => (
        <Card key={plantId}>
          <Title>
            <TitleContainer>
              <TitleLeft>
                <img
                  src={dragHandleDots}
                  alt="Drag Handle"
                  className="drag-handle"
                />
                <span className="PS-card-title">Plant Status</span>
                <img
                  src={informationIcon}
                  alt="Information Icon"
                  className="info-icon"
                />
              </TitleLeft>
              <TitleRight>
                <img src={expandAltIcon} alt="Expand icon" onClick={handleOpenModal} style={{cursor:'pointer'}} />
              </TitleRight>
            </TitleContainer>
            <TitleDivider />
          </Title>
          <div style ={{marginTop:19}}>
            <StatusRow>
              <Label>Plants online</Label>
              <Value>{plantStatusCounts[plantId].online}</Value>
              <span className="normal"></span>
              <StatusText>Normal</StatusText>
            </StatusRow>
            <StatusRow>
              <Label>Plants offline</Label>
              <Value>{plantStatusCounts[plantId].offline}</Value>
              <span className="critical"></span>
              <StatusText>Critical</StatusText>
            </StatusRow>
            <StatusRow>
              <Label>Partially online</Label>
              <Value>{plantStatusCounts[plantId].partially_online}</Value>
              <span className="warning"></span>
              <StatusText>Warning</StatusText>
            </StatusRow>
          </div>
          <Totaldivider>&nbsp;</Totaldivider>
          <div>
            <StatusRow style={{backgroundColor:'#fff'}}>
              <Label>Total</Label>
              <Value>{plantStatusCounts[plantId].online+plantStatusCounts[plantId].offline+plantStatusCounts[plantId].partially_online}</Value>
              <span className=""></span>
              <StatusText></StatusText>
            </StatusRow>
          </div>
          
          <MoreText>more...</MoreText> {/* Positioned at the bottom right */}
                  {/* Modal Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth={'xl'} PaperProps={{
    sx: {
      width: "50%",
      minHeight: "45%"
    }
  }}
  fullWidth>
        <DialogContent>
          <div>
          <Title>
            <TitleContainer>
              <TitleLeft>
                <img
                  src={dragHandleDots}
                  alt="Drag Handle"
                  className="drag-handle"
                />
                <span className="PS-card-title">Plant Status</span>
                <img
                  src={informationIcon}
                  alt="Information Icon"
                  className="info-icon"
                />
              </TitleLeft>
              <TitleRight>
                <img src={CloseBlue} alt="Expand icon" onClick={handleCloseModal} style={{width:35, cursor:'pointer'}}/>
              </TitleRight>
            </TitleContainer>
            <TitleDivider />
          </Title>
          <div style ={{marginTop:19}}>
            <StatusRow>
              <Label>Plants online</Label>
              <Value>{plantStatusCounts[plantId].online}</Value>
              <span className="normal"></span>
              <StatusText>Normal</StatusText>
            </StatusRow>
            <StatusRow>
              <Label>Plants offline</Label>
              <Value>{plantStatusCounts[plantId].offline}</Value>
              <span className="critical"></span>
              <StatusText>Critical</StatusText>
            </StatusRow>
            <StatusRow>
              <Label>Partially online</Label>
              <Value>{plantStatusCounts[plantId].partially_online}</Value>
              <span className="warning"></span>
              <StatusText>Warning</StatusText>
            </StatusRow>
          </div>
          <Totaldivider>&nbsp;</Totaldivider>
          <div>
            <StatusRow style={{backgroundColor:'#fff'}}>
              <Label>Total</Label>
              <Value>{plantStatusCounts[plantId].online+plantStatusCounts[plantId].offline+plantStatusCounts[plantId].partially_online}</Value>
              <span className=""></span>
              <StatusText></StatusText>
            </StatusRow>
          </div>
          </div>
        
        </DialogContent>
      </Dialog>
        </Card>
      ))}
    </>
  );
};

export default PlantStatus;
