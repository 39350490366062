// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import downArrowIcon from "../../../assets/drop_down_arrow.svg"; // Adjust the path as necessary
// import expandAltIcon from "../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
// import dragHandleDots from "../../../assets/drag_handle_dots.svg"; // Adjust the path as necessary
// import informationIcon from "../../../assets/information_icon.svg"; // Adjust the path as necessary
// import sampleData from "../SampleData"; // Adjust the path as necessary

// const Card = styled.div`
//   border: 1px solid #e0e0e0;
//   border-radius: 8px;
//   padding: 16px;
//   margin: 16px;
//   width: 30%;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   background: #fff;
//   height: 250px;
// `;

// const Title = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 8px;
//   font-weight: bold;
//   font-size: 1rem;
// `;

// const Controls = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const CustomDropdown = styled.div`
//   display: flex;
//   align-items: center;
//   position: relative;
//   cursor: pointer;

//   select {
//     appearance: none;
//     border: none;
//     background: none;
//     font-weight: bold;
//     font-size: 0.9em;
//     cursor: pointer;
//     padding-right: 20px;
//   }

//   img {
//     position: absolute;
//     right: 0;
//     pointer-events: none;
//     width: 20px;
//   }
// `;

// const Icon = styled.img`
//   margin-left: 8px;
//   cursor: pointer;
// `;

// const Divider = styled.div`
//   width: 1px;
//   height: 24px;
//   background-color: #e0e0e0;
//   margin: 0 8px;
// `;

// const BarContainer = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 4px 0;
//   justify-content: space-between;
// `;

// const Bar = styled.div`
//   background: #e0e0e0;
//   border-radius: 4px;
//   height: 8px;
//   flex-grow: 1;
//   margin: 13px 10px;
//   position: relative;

//   span {
//     background: #66b345;
//     border-radius: 4px;
//     height: 8px;
//     display: block;
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
// `;

// const Label = styled.div`
//   width: 50px; /* Fixed width to align all labels */
//   text-align: right;
//   font-weight: bold;
//   font-size: 0.8em;
// `;

// const PlantLabel = styled.div`
//   width: 80px; /* Fixed width to align all plant labels */
//   font-size: 0.8em;
// `;

// const TitleContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 8px;

//   .drag-handle {
//     width: 16px; /* Set the desired width for the drag handle icon */
//     height: 16px; /* Set the desired height if needed */
//   }

//   .info-icon {
//     width: 17px; /* Set the desired width for the information icon */
//     height: 17px; /* Set the desired height if needed */
//   }
// `;

// const TitleDivider = styled.div`
//   border-bottom: 1px solid #e0e0e0;
//   width: 100%;
// `;

// const PowerNormalizationRanks = () => {
//   const [selectedOption, setSelectedOption] = useState("Last 5");
//   const [plantData, setPlantData] = useState([]);

//   useEffect(() => {
//     const today = new Date().toDateString();

//     const filteredData = sampleData
//       .filter((entry) => new Date(entry.DATE).toDateString() === today)
//       .sort((a, b) => b.EFFICIENCY - a.EFFICIENCY)
//       .slice(0, 5) // Take top 5 plants by efficiency
//       .map((entry) => ({
//         plantName: "V Shashidhar", // Use the specified plant name
//         efficiency: entry.EFFICIENCY,
//       }));

//     setPlantData(filteredData);
//   }, [selectedOption]);

//   const handleDropdownChange = (event) => {
//     setSelectedOption(event.target.value);
//   };

//   return (
//     <Card>
//       <Title>
//         <TitleContainer>
//           <img src={dragHandleDots} alt="Drag Handle" className="drag-handle" />
//           <span>Power Normalisation Ranks</span>
//           <img
//             src={informationIcon}
//             alt="Information Icon"
//             className="info-icon"
//           />
//         </TitleContainer>
//         <Controls>
//           <CustomDropdown>
//             <select value={selectedOption} onChange={handleDropdownChange}>
//               <option value="Top 5">Top 5</option>
//               <option value="Last 5">Last 5</option>
//             </select>
//             <img src={downArrowIcon} alt="Dropdown icon" />
//           </CustomDropdown>
//           <Divider />
//           <Icon src={expandAltIcon} alt="Expand icon" />
//         </Controls>
//       </Title>
//       <TitleDivider />
//       {plantData.map((plant, index) => (
//         <BarContainer key={index}>
//           <PlantLabel>{plant.plantName}</PlantLabel>
//           <Bar>
//             <span style={{ width: `${plant.efficiency}%` }}></span>
//           </Bar>
//           <Label>{plant.efficiency.toFixed(2)}%</Label>
//         </BarContainer>
//       ))}
//     </Card>
//   );
// };

// export default PowerNormalizationRanks;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import downArrowIcon from "../../../assets/drop_down_arrow.svg"; // Adjust the path as necessary
import expandAltIcon from "../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
import dragHandleDots from "../../../assets/drag_handle_dots.svg"; // Adjust the path as necessary
import informationIcon from "../../../assets/information_icon.svg"; // Adjust the path as necessary
import sampleData from "../SampleData"; // Adjust the path as necessary
import { Dialog, DialogContent  } from '@mui/material';
import CloseBlue from "../../../assets/closeBtnBlue.svg";

const Card = styled.div`
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #888888;
  border-radius: 8px;
  padding: 12px;
  margin: 16px;
  width: 30%;
  background: #fff;
  max-height: 350px;
  position: relative; /* Added for positioning the "more" text */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures the "more" text stays at the bottom */
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 1rem;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const CustomDropdown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  select {
    appearance: none;
    border: none;
    background: none;
    font-family: Open Sans, bold;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    padding-right: 25px;
    color: #818F96;
  }

  img {
    position: absolute;
    right: 0;
    pointer-events: none;
    width: 25px;
  }
`;

const Icon = styled.img`
  margin-left: 11px;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: #e0e0e0;
  margin: 0 8px;
`;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
  justify-content: space-between;
`;

const Bar = styled.div`
  background: #e0e0e0;
  border-radius: 4px;
  height: 8px;
  flex-grow: 1;
  margin: 13px 10px;
  position: relative;

  span {
    background: #66b345;
    border-radius: 4px;
    height: 8px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Label = styled.div`
  width: 100px; /* Fixed width to align all labels */
  text-align: right;
  font-family:"LATO", Open Sans;
  
  font-weight:900;
  font-size: 18px;
`;

const PlantLabel = styled.div`
  width: 120px; /* Fixed width to align all plant labels */
  font-family:"LATO", Open Sans;
  font-size: 18px;
  font-weight: regular;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  

  .drag-handle {
    /*width: 16px; /* Set the desired width for the drag handle icon */
    /*height: 16px; /* Set the desired height if needed */
  }

  .info-icon {
    margin-left: 13px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .card-title{
    font-family: "Lato", sans-serif;
    font-size: 18px;
    color: #1C2E38;
    margin-left: 13px;
  }
`;

const TitleDivider = styled.div`
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
`;

const MoreText = styled.div`
  color: #F75A34; /* Set the color */
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  text-align: right;
  margin-top: auto; /* Pushes the "more" text to the bottom of the card */
`;

const PowerNormalizationRanks = () => {
  const [selectedOption, setSelectedOption] = useState("Last 5");
  const [plantData, setPlantData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const today = new Date().toDateString();

    const filteredData = sampleData
      .filter((entry) => new Date(entry.DATE).toDateString() === today)
      .sort((a, b) => b.EFFICIENCY - a.EFFICIENCY)
      .slice(0, 5) // Take top 5 plants by efficiency
      .map((entry) => ({
        plantName: "Plant Name", // Use the specified plant name
        efficiency: entry.EFFICIENCY,
      }));

    setPlantData(filteredData);
  }, [selectedOption]);

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Card>
      <div>
        <Title>
          <TitleContainer>
            <img
              src={dragHandleDots}
              alt="Drag Handle"
              className="drag-handle"
            />
            <span className="card-title">Power Normalisation Ranks</span>
            <img
              src={informationIcon}
              alt="Information Icon"
              className="info-icon"
            />
          </TitleContainer>
          <Controls>
            <CustomDropdown>
              <select value={selectedOption} onChange={handleDropdownChange}>
                <option value="Top 5">Top Five</option>
                <option value="Last 5">Bottom Five</option>
              </select>
              <img src={downArrowIcon} alt="Dropdown icon"  />
            </CustomDropdown>
            
            <Icon src={expandAltIcon} alt="Expand icon" onClick={handleOpenModal} />
          </Controls>
        </Title>
        <TitleDivider />
        {plantData.map((plant, index) => (
          <BarContainer key={index}>
            <PlantLabel>{plant.plantName}</PlantLabel>
            <Bar>
              <span style={{ width: `${plant.efficiency}%` }}></span>
            </Bar>
            <Label>{plant.efficiency.toFixed(2)}%</Label>
          </BarContainer>
        ))}
        {/* Modal Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth={'xl'} PaperProps={{
    sx: {
      width: "50%",
      minHeight: "45%"
    }
  }}
  fullWidth>
        <DialogContent>
          <div>
          <Title>
          <TitleContainer>
            <img
              src={dragHandleDots}
              alt="Drag Handle"
              className="drag-handle"
            />
            <span className="card-title">Power Normalisation Ranks</span>
            <img
              src={informationIcon}
              alt="Information Icon"
              className="info-icon"
            />
          </TitleContainer>
          <Controls>
            <CustomDropdown>
              <select value={selectedOption} onChange={handleDropdownChange}>
                <option value="Top 5">Top Five</option>
                <option value="Last 5">Bottom Five</option>
              </select>
              <img src={downArrowIcon} alt="Dropdown icon"  />
            </CustomDropdown>
            
            <Icon src={CloseBlue} alt="Expand icon" onClick={handleCloseModal} style={{width:35}}/>
          </Controls>
        </Title>
        <TitleDivider />
        {plantData.map((plant, index) => (
          <BarContainer key={index}>
            <PlantLabel>{plant.plantName}</PlantLabel>
            <Bar>
              <span style={{ width: `${plant.efficiency}%` }}></span>
            </Bar>
            <Label>{plant.efficiency.toFixed(2)}%</Label>
          </BarContainer>
        ))}
          </div>
        
        </DialogContent>
      </Dialog>
      </div>
      <MoreText>more...</MoreText> {/* Positioned at the bottom right */}
      
    </Card>
  );
};

export default PowerNormalizationRanks;
