import React, { useState, useMemo } from "react";
import "./PlantsTable.css"; // Importing the styles

// Importing icons from src/assets
import updownIcon from "./../../assets/updown_icon.png";
import deleteIcon from "./../../assets/delete_icon.png";
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import filterIcon from "./../../assets/filter-icon1.svg";
import settingIcon from "./../../assets/Setting-icon.svg";
import searchIcon from "./../../assets/search-icon.svg";
import GroupSettingIcon from "./../../assets/group_setting_icon.png"
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import Paginationcontrol from "./PaginationControl";
import GroupTable from "./group/GroupTable";
import { CloseOutlined } from "@mui/icons-material";


const PlantsTable = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    plantName: true, // Always visible and uncheckable
    plantType: true,
    capacity: true,
    peakPower: true,
    district: true,
    alerts: true, // Added alerts column
    status: true,
    installationDate: false,
    country: false,
    state: false,
    pr: false,
    cuf: false,
    yield: false,
    recCount: false,
  });
  const [selectedFilter, setSelectedFilter] = useState(""); // No filter selected by default
  const [searchQuery, setSearchQuery] = useState(""); // Search input
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedRows, setSelectedRows] = useState([])
  const [update, setUpdate] = useState(false);
  const [tableProps, setTableProps] = useState({ rowsPerPage: 5, currentPage: 1, pageFirst: 1, pageLast: 5 })

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [group,setGroup] = useState([])

  const navigate = useNavigate();

  const plantDetailsPage = (e, row) => {
    e.preventDefault()

    navigate("/dashboard/plants/plantStatus", { state: { "plant": row } });
  }

  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleFilterToggle = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleRowsPerPage = (value) => {

    setRowsPerPage(value)
  }

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setIsFilterVisible(false); // Close the filter dropdown after selecting
  };

  const handleColumnSelect = (column) => {
    setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  const plantNames = ["Gayitri", "Sashidhar", "Vijaya", "Lakshmi", "Madan"];
  const plantTypes = [
    "Residential",
    "Commercial & Industry",
    "Large Power Producer",
  ];
  const districts = [
    "Hyderabad",
    "Karimnagar",
    "Warangal",
    "Nizamabad",
    "Nalgonda",
  ];
  const states = [
    "Telangana",
    "Andhra Pradesh",
    "Karnataka",
    "Tamil Nadu",
    "Kerala",
  ];
  const countries = ["India", "USA", "UK", "Germany", "Japan"];

  // Generate 20 random plant entries
  const [data, setData] = useState(() => {
    return Array.from({ length: 20 }, (_, index) => ({
      id: index + 1,
      plantName: plantNames[Math.floor(Math.random() * plantNames.length)],
      plantType: plantTypes[Math.floor(Math.random() * plantTypes.length)],
      capacity: `${Math.floor(Math.random() * 10) + 1} KWH`,
      peakPower: `${Math.floor(Math.random() * 10) + 5} KWH`,
      district: districts[Math.floor(Math.random() * districts.length)],
      state: states[Math.floor(Math.random() * states.length)],
      country: countries[Math.floor(Math.random() * countries.length)],
      installationDate: `${Math.floor(Math.random() * 30) + 1}-${Math.floor(Math.random() * 12) + 1
        }-202${Math.floor(Math.random() * 4)}`,
      pr: `${Math.floor(Math.random() * 100)} %`,
      cuf: `${Math.floor(Math.random() * 100)} %`,
      yield: `${Math.floor(Math.random() * 5000)} kWh`,
      recCount: `${Math.floor(Math.random() * 100)}`,
      alerts: index % 3 === 0 ? "Normal" : index % 3 === 1 ? "Major" : "Critical", // Updated key to alerts
      status:
        index % 3 === 0
          ? "Online"
          : index % 3 === 1
            ? "Offline"
            : "Partially Offline",
    }))
  });

  // Sorting logic based on the updown icon click
  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (["capacity", "peakPower", "yield"].includes(sortConfig.key)) {
          // Extract number part and ignore the "KWH" part
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        }

        if (sortConfig.key === "installationDate") {
          // Parse date (format: day-month-year)
          const parseDate = (date) => {
            const [day, month, year] = date.split("-");
            return new Date(year, month - 1, day);
          };
          aValue = parseDate(aValue);
          bValue = parseDate(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  // Filtering logic (only filters if a filter is selected and a search query is entered)
  const filteredData = useMemo(() => {
    return sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true; // No filter if no filter or search query is provided
      return row[selectedFilter]
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase()); // Filter matches only if it starts with the query
    })
  }, [update, sortConfig, searchQuery]);

  // Pagination logic
  const currentRows = useMemo(() => {
    if (filteredData.length > 0) {
      return filteredData.slice(((tableProps.currentPage - 1) * tableProps.rowsPerPage), (((tableProps.currentPage - 1) * tableProps.rowsPerPage) + tableProps.rowsPerPage))
    }
    return []
  }, [filteredData, selectedRows, tableProps]);

  const tablePropsChange = (tableProps) => {
    setTableProps(tableProps);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handling a maximum of 7 columns and hiding the rest in horizontal scrolling
  const visibleColumns = Object.keys(selectedColumns).filter(
    (key) => selectedColumns[key]
  );

  //handling filter and dropdown select toggle outside
  function handleAllToggleEvents() {
    isFilterVisible == true ? setIsFilterVisible(false) : "";
    isDropdownVisible == true ? setIsDropdownVisible(false) : ""
  }

  //disable row
  const disableRow = (e, rowData) => {
    e.stopPropagation();
    const index = filteredData.findIndex(dataItem => dataItem.id === rowData.id);
    rowData.status = "offline";
    filteredData.splice(index, 1, rowData)
    setUpdate(!update)
  }

  const selectRows = (e, rowData) => {
    e.stopPropagation();
    const index = selectedRows.findIndex(r => r.id === rowData.id);
    { index === -1 ? setSelectedRows([...selectedRows, rowData]) : setSelectedRows([...selectedRows.filter(r => r.id !== rowData.id)]) }
  }

  const emptyRows = currentRows.length !== 0 ? currentRows.length : 0;
  const handleGroup = () => {
    setIsModalOpen(true);
}

  return (

    <div className=" d-flex flex-column align-items-center justify-content-center" onClick={() => handleAllToggleEvents()}>
      <div className="main-container">
        {/* Search Bar Container */}
        <div className="search-bar-container gap-3" >
          <OverlayTrigger rootClose trigger="click" placement="left-start" overlay={
            <Popover>
              <Popover.Body className="overlay-body-menu">

                <ul>
                  {Object.keys(selectedColumns).filter((key) => selectedColumns[key] == true).map(
                    (column) => (
                      <li key={column} onClick={() => handleFilterSelect(column)}>
                        {column.charAt(0).toUpperCase() + column.slice(1)}
                      </li>
                    )
                  )}
                </ul>

              </Popover.Body>
            </Popover>
          }>
            <img
              src={filterIcon}
              alt="filter"
              className="filter-icon"
            />
          </OverlayTrigger>

          <div className="search-input-container">
            <input
              type="text"
              className="search-input"
              placeholder={`Search by ${selectedFilter || "..."}`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              disabled={!selectedFilter} // Disable search input until a filter is selected
            />
            <img src={searchIcon} alt="search" className="search-icon" />
          </div>
        </div>

        {/* Card Container */}
        <div className="mw-100"><img onClick={() => handleGroup()} src={GroupSettingIcon} alt="Upload" width={30} height={25} className="upload-icon" /></div>
        <div className="border rounded border-light-subtle">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  <th></th>
                  {visibleColumns.map((column) => (
                    <th key={column}>
                      {/* {column.charAt(0).toUpperCase() + column.slice(1)} */}
                      {column.replace(/([A-Z])/g, " $1").split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                      <img
                        src={updownIcon}
                        alt="sort"
                        onClick={() => handleSort(column)}
                        style={{ cursor: "pointer" }}
                      />
                    </th>
                  ))}
                  <th>
                    <OverlayTrigger rootClose trigger="click" placement="auto-start" overlay={
                      <Popover>
                        <Popover.Body className="overlay-body">

                          <div>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectedColumns.plantName}
                                disabled // Plant Name is uncheckable
                              />{" "}
                              Plant Name
                            </label>
                            {Object.keys(selectedColumns).map(
                              (column) =>
                                column !== "plantName" && (
                                  <label key={column}>
                                    <input
                                      type="checkbox"
                                      checked={selectedColumns[column]}
                                      onChange={() => handleColumnSelect(column)}
                                    />{" "}
                                    {column.charAt(0).toUpperCase() + column.slice(1)}
                                  </label>
                                )
                            )}
                          </div>

                        </Popover.Body>
                      </Popover>
                    }>
                      <img
                        src={settingIcon}
                        alt="settings"
                        className="setting-icon"
                      />
                    </OverlayTrigger>

                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Rendering filtered rows */}
                {currentRows.map((rowdata, index) => (
                  <tr className={`${selectedRows.findIndex(rd => rd.id === rowdata.id) !== -1 ? 'row-select' : ''}`} key={rowdata.id} onClick={(e) => plantDetailsPage(e, rowdata)}>
                    <td className="checkbox-column">
                      <Form>
                        <Form.Check // prettier-ignore
                          type='checkbox'
                          className="table-column-checkbox"
                          onClick={(e) => selectRows(e, rowdata)}
                          checked={selectedRows.findIndex(rd => rd.id === rowdata.id) !== -1 ? true : false}
                        />
                      </Form>
                    </td>
                    {visibleColumns.map((column) => (
                      <td key={`${rowdata.id}-${column}`}>
                        {column === "alerts" ? (
                          <span
                            style={{
                              color:
                                rowdata.alerts === "Normal"
                                  ? "blue"
                                  : rowdata.alerts === "Major"
                                    ? "orange"
                                    : "red",
                            }}
                          >
                            {rowdata[column]}
                          </span>
                        ) : (
                          rowdata[column]
                        )}
                      </td>
                    ))}
                    <td className="delete-column">
                      {/* <img
                      src={deleteIcon}
                      alt="delete"
                      className="delete-icon"
                      onClick={(e) => { disableRow(e, rowdata) }}
                    /> */}
                      <NotInterestedOutlinedIcon onClick={(e) => { disableRow(e, rowdata) }} />
                    </td>
                  </tr>
                ))}

                {/* Filling empty rows to maintain table height */}
                {emptyRows < 0 &&
                  Array.from({ length: emptyRows }).map((_, index) => (
                    <tr key={`empty-${index}`}>
                      <td colSpan="9" className="empty-row"></td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <>
              <Paginationcontrol
                rowsCount={filteredData.length}
                tableProps={tableProps}
                tablePropsChange={tablePropsChange}
                alwaysShown={true}
              />
            </>
          </div>
        </div>
      </div>
      {/* Modal Dialog */}
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        backdrop="static"
      >
        <Modal.Header closeVariant="black" className="d-flex flex-row justify-content-between" closeButton={false}>
          <Modal.Title className="">
            Group Management
          </Modal.Title>
          <div>
            <div className="bg-black text-white rounded-2 text-align-center" style={{ padding: "0px 3px 2px 3px" }} onClick={() => setIsModalOpen(false)}>
              <CloseOutlined fontSize="12" />
            </div>
          </div>

        </Modal.Header>
        <Modal.Body>
          <Container>
            <GroupTable group={group} setGroup={setGroup} selectedRows={selectedRows} />
          </Container>
        </Modal.Body>
      </Modal>
    </div >
  );
};

export default PlantsTable;
