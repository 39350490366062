import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import sunIcon from "../../../assets/sun.svg";
import cloudIcon from "../../../assets/cloud.svg"; // Example icon for cloudy weather
import expandAltIcon from "../../../assets/expand_alt_icon.svg";
import infoIcon from "../../../assets/information_icon.svg";
import dragHandleDots from "../../../assets/drag_handle_dots.svg"; // Adjust the path as necessary
import { Dialog, DialogContent  } from '@mui/material';
import CloseBlue from "../../../assets/closeBtnBlue.svg";

const Card = styled.div`
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #888888;
  border-radius: 8px;
  padding: 12px;
  margin: 16px;
  width: 50%;
  min-width: 48.6%;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-height: 350px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;

  .drag-handle {
    /*width: 16px; /* Set the desired width for the drag handle icon */
    /*height: 16px; /* Set the desired height if needed */
  }

  .info-icon {
    width: 20px; /* Set the desired width for the information icon */
    height: 20px; /* Set the desired height if needed */
  }

  .card-title{
    font-family: "Lato", Open Sans;
    font-weight: bold;
    font-size: 18px;
    color: #1C2E38;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const CustomDropdown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  select {
    appearance: none;
    border: none;
    background: none;
    font-family: Open Sans, bold;
    font-weight: bold;
    font-size: 18px;
    color: #818F96;
    cursor: pointer;
    padding-right: 25px;
  }

  img {
    position: absolute;
    right: 0;
    pointer-events: none;
    width: 25px;
  }
`;

const WeatherInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  margin-right: 5px;
`;

const WeatherInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 1em;
`;

const Insolation = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-left: auto;
  
  gap: 10px;
  font-size: 14px;
  img {
    width: 16px;
  }
`;

const SunIcon = styled.img`
  margin-right: 8px;
  width: 20px;
  margin: 16px;
`;

const WeatherDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 230px;
  padding-top: 10px;
  overflow-x: auto;
  /*white-space: nowrap;*/

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const WeatherDay = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 200px;
  min-width: 110px;
  padding: 10px;
  background-color: ${({ active }) => (active ? "#007BB2" : "#EFF1F4")};
  border-radius: 8px;
  color: ${({ active }) => (active ? "#fff" : "#000")};
  width: 90px;
  margin-right: 8px;
  text-align: center;
`;

const WeatherLabel = styled.div`
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 4px;
`;

const WeatherDate = styled.div`
  font-size: 1rem;
  margin-bottom: 4px;
  color: ${({ active }) => (active ? "#fff" : "#000")};
`;

const WeatherValue = styled.div`
  font-size: 0.675rem;
  margin: 10px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-bottom: 8px;
`;

const WeatherCard = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await axios.get(
          "https://api.open-meteo.com/v1/forecast?latitude=52.52&longitude=13.41&current=temperature_2m,wind_speed_10m&daily=temperature_2m_max,temperature_2m_min&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m"
        );
        setWeatherData(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching the weather data", error);
      }
    };
    fetchWeatherData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const currentWeather = weatherData.current;
  const dailyWeather = weatherData.daily;

  const getDayName = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", { weekday: "short" });
  };

  const getFormattedDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
    });
  };

  return (
    <Card>
      <div>
      <Title>
        <TitleContainer>
          <img src={dragHandleDots} alt="Drag Handle" className="drag-handle" />
          <span style={{ marginLeft: "13px" }} className="card-title">Weather</span>
          <img src={infoIcon} alt="Info icon" />
        </TitleContainer>
        <Insolation>
          {/* Temperature
          <img src={sunIcon} alt="Sun icon" /> {currentWeather.temperature_2m}°C */}
          <WeatherInfo>Updated at {currentWeather.time}</WeatherInfo>
          <img
            src={expandAltIcon}
            alt="Expand icon"
            style={{ marginLeft: "8px", cursor:'pointer' }}
            //onClick={handleOpenModal}
          />
        </Insolation>
      </Title>
      </div>
      

      <Divider />

      <WeatherDetails>
        <WeatherDay active>
          {/* <WeatherLabel>{getDayName(currentWeather.time)}</WeatherLabel>
          <WeatherDate>{getFormattedDate(currentWeather.time)}</WeatherDate> */}
          <WeatherLabel>Today</WeatherLabel>
          <SunIcon src={sunIcon} alt="Sun icon" />
          <WeatherValue>
            Temp: {currentWeather.temperature_2m}°C
            <br />
            Wind: {currentWeather.wind_speed_10m} m/s
          </WeatherValue>
        </WeatherDay>
        {dailyWeather.time.slice(1, 7).map((time, index) => (
          <WeatherDay key={index}>
            {/* <WeatherLabel>{getDayName(time)}</WeatherLabel>
            <WeatherDate>{getFormattedDate(time)}</WeatherDate> */}
            <WeatherLabel>{getFormattedDate(time)}</WeatherLabel>
            <SunIcon
              src={
                dailyWeather.condition &&
                dailyWeather.condition[index] === "cloudy"
                  ? cloudIcon
                  : sunIcon
              }
              alt={
                dailyWeather.condition &&
                dailyWeather.condition[index] === "cloudy"
                  ? "Cloud icon"
                  : "Sun icon"
              }
            />
            <WeatherValue>
              Max Temp: {dailyWeather.temperature_2m_max[index]}°C
              <br />
              Min Temp: {dailyWeather.temperature_2m_min[index]}°C
            </WeatherValue>
          </WeatherDay>
        ))}
      </WeatherDetails>
      {/* Modal Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth={'xl'} PaperProps={{
    sx: {
      width: "50%",
      minHeight: "45%"
    }
  }}
  fullWidth>
        <DialogContent>
          <div>
          <div>
          <Title>
        <TitleContainer>
          <img src={dragHandleDots} alt="Drag Handle" className="drag-handle" />
          <span style={{ marginLeft: "13px" }} className="card-title">Weather</span>
          <img src={infoIcon} alt="Info icon" />
        </TitleContainer>
        <Insolation>
          {/* Temperature
          <img src={sunIcon} alt="Sun icon" /> {currentWeather.temperature_2m}°C */}
          <WeatherInfo>Updated at {currentWeather.time}</WeatherInfo>
          <img
            src={CloseBlue}
            alt="Close Button"
            style={{ marginLeft: "8px", width:35, cursor:'pointer' }}
            onClick={handleCloseModal}
          />
        </Insolation>
      </Title>
      </div>
      

      <Divider />

      <WeatherDetails style={{paddingTop:30}}>
        <WeatherDay active style={{minWidth:115, minHeight:300}}>
          {/* <WeatherLabel>{getDayName(currentWeather.time)}</WeatherLabel>
          <WeatherDate>{getFormattedDate(currentWeather.time)}</WeatherDate> */}
          <WeatherLabel>Today</WeatherLabel>
          <SunIcon src={sunIcon} alt="Sun icon" />
          <WeatherValue>
            Temp: {currentWeather.temperature_2m}°C
            <br />
            Wind: {currentWeather.wind_speed_10m} m/s
          </WeatherValue>
        </WeatherDay>
        {dailyWeather.time.slice(1, 7).map((time, index) => (
          <WeatherDay key={index} style={{minWidth:115, minHeight:300}}>
            {/* <WeatherLabel>{getDayName(time)}</WeatherLabel>
            <WeatherDate>{getFormattedDate(time)}</WeatherDate> */}
            <WeatherLabel>{getFormattedDate(time)}</WeatherLabel>
            <SunIcon
              src={
                dailyWeather.condition &&
                dailyWeather.condition[index] === "cloudy"
                  ? cloudIcon
                  : sunIcon
              }
              alt={
                dailyWeather.condition &&
                dailyWeather.condition[index] === "cloudy"
                  ? "Cloud icon"
                  : "Sun icon"
              }
            />
            <WeatherValue>
              Max Temp: {dailyWeather.temperature_2m_max[index]}°C
              <br />
              Min Temp: {dailyWeather.temperature_2m_min[index]}°C
            </WeatherValue>
          </WeatherDay>
        ))}
      </WeatherDetails>
          </div>
        
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default WeatherCard;

//2
// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import axios from "axios";
// import sunIcon from "../../../assets/sun.svg";
// import cloudIcon from "../../../assets/cloud.svg";
// import expandAltIcon from "../../../assets/expand_alt_icon.svg";
// import infoIcon from "../../../assets/information_icon.svg";
// import dragHandleDots from "../../../assets/drag_handle_dots.svg";

// const Card = styled.div`
//   border: 1px solid #e0e0e0;
//   border-radius: 8px;
//   padding: 16px;
//   margin: 16px;
//   width: 100%;
//   max-width: 850px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   background: #fff;
//   display: flex;
//   flex-direction: column;
//   height: 250px;
// `;

// const Title = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   font-weight: bold;
//   font-size: 1rem;
//   margin-bottom: 4px;
//   margin-top: -5px;

//   .drag-handle {
//     width: 16px;
//     height: 16px;
//   }
// `;

// const InfoIcon = styled.img`
//   margin-left: 8px;
//   width: 16px;
//   height: 16px;
// `;

// const ExpandIcon = styled.img`
//   cursor: pointer;
//   width: 16px;
//   height: 16px;
// `;

// const WeatherInfoContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 3px;
//   margin-right: 5px;
// `;

// const WeatherInfo = styled.div`
//   display: flex;
//   align-items: center;
//   font-size: 0.7em;
// `;

// const Insolation = styled.div`
//   display: flex;
//   align-items: center;
//   font-weight: 500;
//   margin-left: auto;
//   margin-bottom: -20px;
//   gap: 10px;
//   font-size: 14px;
//   img {
//     width: 16px;
//   }
// `;

// const SunIcon = styled.img`
//   margin-right: 8px;
//   width: 20px;
//   margin: 16px;
// `;

// const WeatherDetails = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding-top: 10px;
//   overflow-x: auto;
//   white-space: nowrap;
//   width: 100%;

//   ::-webkit-scrollbar {
//     display: none;
//   }

//   -ms-overflow-style: none;
//   scrollbar-width: none;
// `;

// const WeatherDay = styled.div`
//   display: inline-flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 10px;
//   background-color: ${({ active }) => (active ? "#25A4DD" : "#EFF1F4")};
//   border-radius: 8px;
//   color: ${({ active }) => (active ? "#fff" : "#000")};
//   width: calc(100% / 7 - 8px);
//   margin-right: 8px;
//   text-align: center;
//   border: ${({ active }) => (active ? "none" : "0.5px solid #C2CAE6")};
//   box-shadow: 0 2px 4px rgba(212, 218, 228, 0.36);
// `;

// const WeatherLabel = styled.div`
//   font-weight: 500;
//   font-size: 0.675rem;
//   margin-bottom: 4px;
// `;

// const WeatherDate = styled.div`
//   font-size: 0.675rem;
//   margin-bottom: 4px;
//   color: ${({ active }) => (active ? "#fff" : "#000")};
// `;

// const WeatherValue = styled.div`
//   font-size: 0.675rem;
//   margin: 10px;
// `;

// const Divider = styled.div`
//   width: 100%;
//   height: 1px;
//   background-color: #e0e0e0;
//   margin: 8px 0;
// `;

// const WeatherCard = () => {
//   const [weatherData, setWeatherData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [location, setLocation] = useState({ lat: null, lon: null });

//   useEffect(() => {
//     const getLocation = () => {
//       if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(
//           (position) => {
//             setLocation({
//               lat: position.coords.latitude,
//               lon: position.coords.longitude,
//             });
//           },
//           (error) => {
//             console.error("Error obtaining location", error);
//           }
//         );
//       } else {
//         alert("Geolocation is not supported by this browser.");
//       }
//     };

//     getLocation();
//   }, []);

//   useEffect(() => {
//     const fetchWeatherData = async () => {
//       if (location.lat && location.lon) {
//         try {
//           const response = await axios.get(
//             `https://api.open-meteo.com/v1/forecast?latitude=${location.lat}&longitude=${location.lon}&current=temperature_2m,wind_speed_10m&daily=temperature_2m_max,temperature_2m_min&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m`
//           );
//           setWeatherData(response.data);
//           setLoading(false);
//         } catch (error) {
//           console.error("Error fetching the weather data", error);
//         }
//       }
//     };

//     fetchWeatherData();
//   }, [location]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   const currentWeather = weatherData.current;
//   const dailyWeather = weatherData.daily;

//   const getDayName = (dateStr) => {
//     const date = new Date(dateStr);
//     return date.toLocaleDateString("en-US", { weekday: "short" });
//   };

//   const getFormattedDate = (dateStr) => {
//     const date = new Date(dateStr);
//     return date.toLocaleDateString("en-US", {
//       day: "numeric",
//       month: "long",
//     });
//   };

//   return (
//     <Card>
//       <Title>
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <img src={dragHandleDots} alt="Drag Handle" className="drag-handle" />
//           <span style={{ marginLeft: "8px" }}>Weather</span>
//           <InfoIcon src={infoIcon} alt="Info icon" />
//         </div>
//         <Insolation>
//           Temperature
//           <img src={sunIcon} alt="Sun icon" /> {currentWeather.temperature_2m}°C
//           <ExpandIcon
//             src={expandAltIcon}
//             alt="Expand icon"
//             style={{ marginLeft: "8px" }}
//           />
//         </Insolation>
//       </Title>
//       <WeatherInfoContainer>
//         <WeatherInfo>Updated at {currentWeather.time}</WeatherInfo>
//       </WeatherInfoContainer>

//       <Divider />

//       <WeatherDetails>
//         <WeatherDay active>
//           <WeatherLabel>{getDayName(currentWeather.time)}</WeatherLabel>
//           <WeatherDate>{getFormattedDate(currentWeather.time)}</WeatherDate>
//           <SunIcon src={sunIcon} alt="Sun icon" />
//           <WeatherValue>
//             Temp: {currentWeather.temperature_2m}°C
//             <br />
//             Wind: {currentWeather.wind_speed_10m} m/s
//           </WeatherValue>
//         </WeatherDay>
//         {dailyWeather.time.slice(1, 7).map((time, index) => (
//           <WeatherDay key={index}>
//             <WeatherLabel>{getDayName(time)}</WeatherLabel>
//             <WeatherDate>{getFormattedDate(time)}</WeatherDate>
//             <SunIcon
//               src={
//                 dailyWeather.condition &&
//                 dailyWeather.condition[index] === "cloudy"
//                   ? cloudIcon
//                   : sunIcon
//               }
//               alt={
//                 dailyWeather.condition &&
//                 dailyWeather.condition[index] === "cloudy"
//                   ? "Cloud icon"
//                   : "Sun icon"
//               }
//             />
//             <WeatherValue>
//               Max Temp: {dailyWeather.temperature_2m_max[index]}°C
//               <br />
//               Min Temp: {dailyWeather.temperature_2m_min[index]}°C
//             </WeatherValue>
//           </WeatherDay>
//         ))}
//       </WeatherDetails>
//     </Card>
//   );
// };

// export default WeatherCard;

// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import axios from "axios";
// import sunIcon from "../../../assets/sun.svg";
// import cloudIcon from "../../../assets/cloud.svg";
// import expandAltIcon from "../../../assets/expand_alt_icon.svg";
// import infoIcon from "../../../assets/information_icon.svg";
// import dragHandleDots from "../../../assets/drag_handle_dots.svg";

// const Card = styled.div`
//   border: 1px solid #e0e0e0;
//   border-radius: 8px;
//   padding: 16px;
//   margin: 16px;
//   width: 100%;
//   max-width: 850px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   background: #fff;
//   display: flex;
//   flex-direction: column;
//   height: 250px;
// `;

// const Title = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   font-weight: bold;
//   font-size: 1rem;
//   margin-bottom: 4px;
//   margin-top: -5px;

//   .drag-handle {
//     width: 16px;
//     height: 16px;
//   }
// `;

// const InfoIcon = styled.img`
//   margin-left: 8px;
//   width: 16px;
//   height: 16px;
// `;

// const ExpandIcon = styled.img`
//   cursor: pointer;
//   width: 16px;
//   height: 16px;
// `;

// const WeatherInfoContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 3px;
//   margin-right: 5px;
// `;

// const WeatherInfo = styled.div`
//   display: flex;
//   align-items: center;
//   font-size: 0.7em;
// `;

// const Insolation = styled.div`
//   display: flex;
//   align-items: center;
//   font-weight: 500;
//   margin-left: auto;
//   margin-bottom: -20px;
//   gap: 10px;
//   font-size: 14px;
//   img {
//     width: 16px;
//   }
// `;

// const SunIcon = styled.img`
//   margin-right: 8px;
//   width: 20px;
//   margin: 16px;
// `;

// const WeatherDetails = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding-top: 15px;
//   overflow-x: auto;
//   white-space: nowrap;
//   width: 100%;

//   ::-webkit-scrollbar {
//     display: none;
//   }

//   -ms-overflow-style: none;
//   scrollbar-width: none;
// `;

// const WeatherDay = styled.div`
//   display: inline-flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
//   background-color: ${({ active }) => (active ? "#25A4DD" : "#EFF1F4")};
//   border-radius: 8px;
//   color: ${({ active }) => (active ? "#fff" : "#000")};
//   width: calc(100% / 7 - 8px);
//   margin-right: 8px;
//   text-align: center;
//   border: ${({ active }) => (active ? "none" : "0.5px solid #C2CAE6")};
//   box-shadow: 0 2px 4px rgba(212, 218, 228, 0.36);
// `;

// const WeatherLabel = styled.div`
//   font-weight: 500;
//   font-size: 0.675rem;
//   margin-bottom: 4px;
// `;

// const WeatherDate = styled.div`
//   font-size: 0.675rem;
//   margin-bottom: 4px;
//   color: ${({ active }) => (active ? "#fff" : "#000")};
// `;

// const WeatherIcon = styled.img`
//   margin: 8px 0;
//   width: 24px;
//   height: 24px;
// `;

// const Temperature = styled.div`
//   font-size: 0.8rem;
//   margin-top: 5px;
// `;

// const Divider = styled.div`
//   width: 100%;
//   height: 1px;
//   background-color: #e0e0e0;
//   margin: 8px 0;
// `;

// const WeatherCard = () => {
//   const [weatherData, setWeatherData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [location, setLocation] = useState({ lat: null, lon: null });

//   useEffect(() => {
//     const getLocation = () => {
//       if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(
//           (position) => {
//             setLocation({
//               lat: position.coords.latitude,
//               lon: position.coords.longitude,
//             });
//           },
//           (error) => {
//             console.error("Error obtaining location", error);
//           }
//         );
//       } else {
//         alert("Geolocation is not supported by this browser.");
//       }
//     };

//     getLocation();
//   }, []);

//   useEffect(() => {
//     const fetchWeatherData = async () => {
//       if (location.lat && location.lon) {
//         try {
//           const response = await axios.get(
//             `https://api.open-meteo.com/v1/forecast?latitude=${location.lat}&longitude=${location.lon}&current=temperature_2m,wind_speed_10m&daily=temperature_2m_max,temperature_2m_min&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m`
//           );
//           setWeatherData(response.data);
//           setLoading(false);
//         } catch (error) {
//           console.error("Error fetching the weather data", error);
//         }
//       }
//     };

//     fetchWeatherData();
//   }, [location]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   const currentWeather = weatherData.current;
//   const dailyWeather = weatherData.daily;

//   const getDayName = (dateStr) => {
//     const date = new Date(dateStr);
//     return date.toLocaleDateString("en-US", { weekday: "short" });
//   };

//   const getFormattedDate = (dateStr) => {
//     const date = new Date(dateStr);
//     return date.toLocaleDateString("en-US", {
//       day: "numeric",
//       month: "long",
//     });
//   };

//   const getWeatherIcon = (temperature) => {
//     return temperature > 20 ? sunIcon : cloudIcon; // Example: Adjust the threshold as needed
//   };

//   return (
//     <Card>
//       <Title>
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <img src={dragHandleDots} alt="Drag Handle" className="drag-handle" />
//           <span style={{ marginLeft: "8px" }}>Weather</span>
//           <InfoIcon src={infoIcon} alt="Info icon" />
//         </div>
//         <Insolation>
//           Temperature
//           <img src={sunIcon} alt="Sun icon" /> {currentWeather.temperature_2m}°C
//           <ExpandIcon
//             src={expandAltIcon}
//             alt="Expand icon"
//             style={{ marginLeft: "8px" }}
//           />
//         </Insolation>
//       </Title>
//       <WeatherInfoContainer>
//         <WeatherInfo>Updated at {currentWeather.time}</WeatherInfo>
//       </WeatherInfoContainer>

//       <Divider />

//       <WeatherDetails>
//         <WeatherDay active>
//           <WeatherLabel>{getDayName(currentWeather.time)}</WeatherLabel>
//           <WeatherDate>{getFormattedDate(currentWeather.time)}</WeatherDate>
//           <WeatherIcon
//             src={getWeatherIcon(currentWeather.temperature_2m)}
//             alt="Weather icon"
//           />
//           <Temperature>{currentWeather.temperature_2m}°C</Temperature>
//         </WeatherDay>
//         {dailyWeather.time.slice(1, 7).map((time, index) => (
//           <WeatherDay key={index}>
//             <WeatherLabel>{getDayName(time)}</WeatherLabel>
//             <WeatherDate>{getFormattedDate(time)}</WeatherDate>
//             <WeatherIcon
//               src={getWeatherIcon(dailyWeather.temperature_2m_max[index])}
//               alt="Weather icon"
//             />
//             <Temperature>
//               {dailyWeather.temperature_2m_max[index]}°C
//             </Temperature>
//           </WeatherDay>
//         ))}
//       </WeatherDetails>
//     </Card>
//   );
// };

// export default WeatherCard;
