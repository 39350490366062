// SampleData.js

const sampleData = [
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-01",
    TOTAL_PRODUCTION: "",
    EFFICIENCY: 0,
    peak_hour_production: "",
    carbon_neutral: 0,
    carbon_offset: 0,
    total_yield: 0,
    co2_reduction: 0,
    trees_planted: 0,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-03",
    TOTAL_PRODUCTION: 13.7,
    EFFICIENCY: 49.82,
    peak_hour_production: "",
    carbon_neutral: 0.685,
    carbon_offset: 10.96,
    total_yield: 75.35,
    co2_reduction: 10.28,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-04",
    TOTAL_PRODUCTION: 14.9,
    EFFICIENCY: 54.18,
    peak_hour_production: "",
    carbon_neutral: 0.745,
    carbon_offset: 11.92,
    total_yield: 81.95,
    co2_reduction: 11.18,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-05",
    TOTAL_PRODUCTION: 13.5,
    EFFICIENCY: 49.09,
    peak_hour_production: "",
    carbon_neutral: 0.675,
    carbon_offset: 10.8,
    total_yield: 74.25,
    co2_reduction: 10.13,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-06",
    TOTAL_PRODUCTION: 5.8,
    EFFICIENCY: 21.09,
    peak_hour_production: "",
    carbon_neutral: 0.29,
    carbon_offset: 4.64,
    total_yield: 31.9,
    co2_reduction: 4.35,
    trees_planted: 0.2,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-07",
    TOTAL_PRODUCTION: 15.4,
    EFFICIENCY: 56,
    peak_hour_production: "",
    carbon_neutral: 0.77,
    carbon_offset: 12.32,
    total_yield: 84.7,
    co2_reduction: 11.55,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-08",
    TOTAL_PRODUCTION: 19.7,
    EFFICIENCY: 71.64,
    peak_hour_production: "",
    carbon_neutral: 0.985,
    carbon_offset: 15.76,
    total_yield: 108.35,
    co2_reduction: 14.78,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-09",
    TOTAL_PRODUCTION: 19.2,
    EFFICIENCY: 69.82,
    peak_hour_production: "",
    carbon_neutral: 0.96,
    carbon_offset: 15.36,
    total_yield: 105.6,
    co2_reduction: 14.4,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-10",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-11",
    TOTAL_PRODUCTION: 16.4,
    EFFICIENCY: 59.64,
    peak_hour_production: "",
    carbon_neutral: 0.82,
    carbon_offset: 13.12,
    total_yield: 90.2,
    co2_reduction: 12.3,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-12",
    TOTAL_PRODUCTION: 16.4,
    EFFICIENCY: 59.64,
    peak_hour_production: "",
    carbon_neutral: 0.82,
    carbon_offset: 13.12,
    total_yield: 90.2,
    co2_reduction: 12.3,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-13",
    TOTAL_PRODUCTION: 16.5,
    EFFICIENCY: 60,
    peak_hour_production: "",
    carbon_neutral: 0.825,
    carbon_offset: 13.2,
    total_yield: 90.75,
    co2_reduction: 12.38,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-14",
    TOTAL_PRODUCTION: 17.2,
    EFFICIENCY: 62.55,
    peak_hour_production: "",
    carbon_neutral: 0.86,
    carbon_offset: 13.76,
    total_yield: 94.6,
    co2_reduction: 12.9,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-15",
    TOTAL_PRODUCTION: 16.3,
    EFFICIENCY: 59.27,
    peak_hour_production: "",
    carbon_neutral: 0.815,
    carbon_offset: 13.04,
    total_yield: 89.65,
    co2_reduction: 12.23,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-16",
    TOTAL_PRODUCTION: 17.8,
    EFFICIENCY: 64.73,
    peak_hour_production: "",
    carbon_neutral: 0.89,
    carbon_offset: 14.24,
    total_yield: 97.9,
    co2_reduction: 13.35,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-17",
    TOTAL_PRODUCTION: 18.2,
    EFFICIENCY: 66.18,
    peak_hour_production: "",
    carbon_neutral: 0.91,
    carbon_offset: 14.56,
    total_yield: 100.1,
    co2_reduction: 13.65,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-18",
    TOTAL_PRODUCTION: 17.8,
    EFFICIENCY: 64.73,
    peak_hour_production: "",
    carbon_neutral: 0.89,
    carbon_offset: 14.24,
    total_yield: 97.9,
    co2_reduction: 13.35,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-19",
    TOTAL_PRODUCTION: 17.4,
    EFFICIENCY: 63.27,
    peak_hour_production: "",
    carbon_neutral: 0.87,
    carbon_offset: 13.92,
    total_yield: 95.7,
    co2_reduction: 13.05,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-20",
    TOTAL_PRODUCTION: 16.7,
    EFFICIENCY: 60.73,
    peak_hour_production: "",
    carbon_neutral: 0.835,
    carbon_offset: 13.36,
    total_yield: 91.85,
    co2_reduction: 12.53,
    trees_planted: 0.59,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-21",
    TOTAL_PRODUCTION: 17,
    EFFICIENCY: 61.82,
    peak_hour_production: "",
    carbon_neutral: 0.85,
    carbon_offset: 13.6,
    total_yield: 93.5,
    co2_reduction: 12.75,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-22",
    TOTAL_PRODUCTION: 17.1,
    EFFICIENCY: 62.18,
    peak_hour_production: "",
    carbon_neutral: 0.855,
    carbon_offset: 13.68,
    total_yield: 94.05,
    co2_reduction: 12.83,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-23",
    TOTAL_PRODUCTION: 16.8,
    EFFICIENCY: 61.09,
    peak_hour_production: "",
    carbon_neutral: 0.84,
    carbon_offset: 13.44,
    total_yield: 92.4,
    co2_reduction: 12.6,
    trees_planted: 0.59,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-24",
    TOTAL_PRODUCTION: 18,
    EFFICIENCY: 65.45,
    peak_hour_production: "",
    carbon_neutral: 0.9,
    carbon_offset: 14.4,
    total_yield: 99,
    co2_reduction: 13.5,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-25",
    TOTAL_PRODUCTION: 19,
    EFFICIENCY: 69.09,
    peak_hour_production: "",
    carbon_neutral: 0.95,
    carbon_offset: 15.2,
    total_yield: 104.5,
    co2_reduction: 14.25,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-26",
    TOTAL_PRODUCTION: 19.4,
    EFFICIENCY: 70.55,
    peak_hour_production: "",
    carbon_neutral: 0.97,
    carbon_offset: 15.52,
    total_yield: 106.7,
    co2_reduction: 14.55,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-27",
    TOTAL_PRODUCTION: "",
    EFFICIENCY: 0,
    peak_hour_production: "",
    carbon_neutral: 0,
    carbon_offset: 0,
    total_yield: 0,
    co2_reduction: 0,
    trees_planted: 0,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-28",
    TOTAL_PRODUCTION: 17.7,
    EFFICIENCY: 64.36,
    peak_hour_production: "",
    carbon_neutral: 0.885,
    carbon_offset: 14.16,
    total_yield: 97.35,
    co2_reduction: 13.28,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-29",
    TOTAL_PRODUCTION: 16.8,
    EFFICIENCY: 61.09,
    peak_hour_production: "",
    carbon_neutral: 0.84,
    carbon_offset: 13.44,
    total_yield: 92.4,
    co2_reduction: 12.6,
    trees_planted: 0.59,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-30",
    TOTAL_PRODUCTION: 12.9,
    EFFICIENCY: 46.91,
    peak_hour_production: "",
    carbon_neutral: 0.645,
    carbon_offset: 10.32,
    total_yield: 70.95,
    co2_reduction: 9.68,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-01-31",
    TOTAL_PRODUCTION: 13.4,
    EFFICIENCY: 48.73,
    peak_hour_production: "",
    carbon_neutral: 0.67,
    carbon_offset: 10.72,
    total_yield: 73.7,
    co2_reduction: 10.05,
    trees_planted: 0.47,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-01",
    TOTAL_PRODUCTION: 13,
    EFFICIENCY: 47.27,
    peak_hour_production: "",
    carbon_neutral: 0.65,
    carbon_offset: 10.4,
    total_yield: 71.5,
    co2_reduction: 9.75,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-02",
    TOTAL_PRODUCTION: 18.7,
    EFFICIENCY: 68,
    peak_hour_production: "",
    carbon_neutral: 0.935,
    carbon_offset: 14.96,
    total_yield: 102.85,
    co2_reduction: 14.03,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-03",
    TOTAL_PRODUCTION: 19.6,
    EFFICIENCY: 71.27,
    peak_hour_production: "",
    carbon_neutral: 0.98,
    carbon_offset: 15.68,
    total_yield: 107.8,
    co2_reduction: 14.7,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-04",
    TOTAL_PRODUCTION: 0.3,
    EFFICIENCY: 1.09,
    peak_hour_production: "",
    carbon_neutral: 0.015,
    carbon_offset: 0.24,
    total_yield: 1.65,
    co2_reduction: 0.23,
    trees_planted: 0.01,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-05",
    TOTAL_PRODUCTION: 18.1,
    EFFICIENCY: 65.82,
    peak_hour_production: "",
    carbon_neutral: 0.905,
    carbon_offset: 14.48,
    total_yield: 99.55,
    co2_reduction: 13.58,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-06",
    TOTAL_PRODUCTION: 17.3,
    EFFICIENCY: 62.91,
    peak_hour_production: "",
    carbon_neutral: 0.865,
    carbon_offset: 13.84,
    total_yield: 95.15,
    co2_reduction: 12.98,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-07",
    TOTAL_PRODUCTION: 16.3,
    EFFICIENCY: 59.27,
    peak_hour_production: "",
    carbon_neutral: 0.815,
    carbon_offset: 13.04,
    total_yield: 89.65,
    co2_reduction: 12.23,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-08",
    TOTAL_PRODUCTION: 16.7,
    EFFICIENCY: 60.73,
    peak_hour_production: "",
    carbon_neutral: 0.835,
    carbon_offset: 13.36,
    total_yield: 91.85,
    co2_reduction: 12.53,
    trees_planted: 0.59,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-09",
    TOTAL_PRODUCTION: 19.7,
    EFFICIENCY: 71.64,
    peak_hour_production: "",
    carbon_neutral: 0.985,
    carbon_offset: 15.76,
    total_yield: 108.35,
    co2_reduction: 14.78,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-10",
    TOTAL_PRODUCTION: 20.1,
    EFFICIENCY: 73.09,
    peak_hour_production: "",
    carbon_neutral: 1.005,
    carbon_offset: 16.08,
    total_yield: 110.55,
    co2_reduction: 15.08,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-11",
    TOTAL_PRODUCTION: 20.2,
    EFFICIENCY: 73.45,
    peak_hour_production: "",
    carbon_neutral: 1.01,
    carbon_offset: 16.16,
    total_yield: 111.1,
    co2_reduction: 15.15,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-12",
    TOTAL_PRODUCTION: 19.6,
    EFFICIENCY: 71.27,
    peak_hour_production: "",
    carbon_neutral: 0.98,
    carbon_offset: 15.68,
    total_yield: 107.8,
    co2_reduction: 14.7,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-13",
    TOTAL_PRODUCTION: 21.7,
    EFFICIENCY: 78.91,
    peak_hour_production: "",
    carbon_neutral: 1.085,
    carbon_offset: 17.36,
    total_yield: 119.35,
    co2_reduction: 16.28,
    trees_planted: 0.76,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-14",
    TOTAL_PRODUCTION: 20.9,
    EFFICIENCY: 76,
    peak_hour_production: "",
    carbon_neutral: 1.045,
    carbon_offset: 16.72,
    total_yield: 114.95,
    co2_reduction: 15.68,
    trees_planted: 0.74,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-15",
    TOTAL_PRODUCTION: 21.3,
    EFFICIENCY: 77.45,
    peak_hour_production: "",
    carbon_neutral: 1.065,
    carbon_offset: 17.04,
    total_yield: 117.15,
    co2_reduction: 15.98,
    trees_planted: 0.75,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-16",
    TOTAL_PRODUCTION: 21.7,
    EFFICIENCY: 78.91,
    peak_hour_production: "",
    carbon_neutral: 1.085,
    carbon_offset: 17.36,
    total_yield: 119.35,
    co2_reduction: 16.28,
    trees_planted: 0.76,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-17",
    TOTAL_PRODUCTION: 20.4,
    EFFICIENCY: 74.18,
    peak_hour_production: "",
    carbon_neutral: 1.02,
    carbon_offset: 16.32,
    total_yield: 112.2,
    co2_reduction: 15.3,
    trees_planted: 0.72,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-18",
    TOTAL_PRODUCTION: 19.6,
    EFFICIENCY: 71.27,
    peak_hour_production: "",
    carbon_neutral: 0.98,
    carbon_offset: 15.68,
    total_yield: 107.8,
    co2_reduction: 14.7,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-19",
    TOTAL_PRODUCTION: 18.9,
    EFFICIENCY: 68.73,
    peak_hour_production: "",
    carbon_neutral: 0.945,
    carbon_offset: 15.12,
    total_yield: 103.95,
    co2_reduction: 14.18,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-20",
    TOTAL_PRODUCTION: 19.9,
    EFFICIENCY: 72.36,
    peak_hour_production: "",
    carbon_neutral: 0.995,
    carbon_offset: 15.92,
    total_yield: 109.45,
    co2_reduction: 14.93,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-21",
    TOTAL_PRODUCTION: 20.1,
    EFFICIENCY: 73.09,
    peak_hour_production: "",
    carbon_neutral: 1.005,
    carbon_offset: 16.08,
    total_yield: 110.55,
    co2_reduction: 15.08,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-22",
    TOTAL_PRODUCTION: 18.8,
    EFFICIENCY: 68.36,
    peak_hour_production: "",
    carbon_neutral: 0.94,
    carbon_offset: 15.04,
    total_yield: 103.4,
    co2_reduction: 14.1,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-23",
    TOTAL_PRODUCTION: 18.5,
    EFFICIENCY: 67.27,
    peak_hour_production: "",
    carbon_neutral: 0.925,
    carbon_offset: 14.8,
    total_yield: 101.75,
    co2_reduction: 13.88,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-24",
    TOTAL_PRODUCTION: 19.2,
    EFFICIENCY: 69.82,
    peak_hour_production: "",
    carbon_neutral: 0.96,
    carbon_offset: 15.36,
    total_yield: 105.6,
    co2_reduction: 14.4,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-25",
    TOTAL_PRODUCTION: 20.2,
    EFFICIENCY: 73.45,
    peak_hour_production: "",
    carbon_neutral: 1.01,
    carbon_offset: 16.16,
    total_yield: 111.1,
    co2_reduction: 15.15,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-26",
    TOTAL_PRODUCTION: 19.1,
    EFFICIENCY: 69.45,
    peak_hour_production: "",
    carbon_neutral: 0.955,
    carbon_offset: 15.28,
    total_yield: 105.05,
    co2_reduction: 14.33,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-27",
    TOTAL_PRODUCTION: 18.9,
    EFFICIENCY: 68.73,
    peak_hour_production: "",
    carbon_neutral: 0.945,
    carbon_offset: 15.12,
    total_yield: 103.95,
    co2_reduction: 14.18,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-02-28",
    TOTAL_PRODUCTION: 19.3,
    EFFICIENCY: 70.18,
    peak_hour_production: "",
    carbon_neutral: 0.965,
    carbon_offset: 15.44,
    total_yield: 106.15,
    co2_reduction: 14.48,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-01",
    TOTAL_PRODUCTION: 19,
    EFFICIENCY: 69.09,
    peak_hour_production: "",
    carbon_neutral: 0.95,
    carbon_offset: 15.2,
    total_yield: 104.5,
    co2_reduction: 14.25,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-02",
    TOTAL_PRODUCTION: 18.6,
    EFFICIENCY: 67.64,
    peak_hour_production: "",
    carbon_neutral: 0.93,
    carbon_offset: 14.88,
    total_yield: 102.3,
    co2_reduction: 13.95,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-03",
    TOTAL_PRODUCTION: 16.7,
    EFFICIENCY: 60.73,
    peak_hour_production: "",
    carbon_neutral: 0.835,
    carbon_offset: 13.36,
    total_yield: 91.85,
    co2_reduction: 12.53,
    trees_planted: 0.59,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-04",
    TOTAL_PRODUCTION: 17.5,
    EFFICIENCY: 63.64,
    peak_hour_production: "",
    carbon_neutral: 0.875,
    carbon_offset: 14,
    total_yield: 96.25,
    co2_reduction: 13.13,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-05",
    TOTAL_PRODUCTION: 17.1,
    EFFICIENCY: 62.18,
    peak_hour_production: "",
    carbon_neutral: 0.855,
    carbon_offset: 13.68,
    total_yield: 94.05,
    co2_reduction: 12.83,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-06",
    TOTAL_PRODUCTION: 16.5,
    EFFICIENCY: 60,
    peak_hour_production: "",
    carbon_neutral: 0.825,
    carbon_offset: 13.2,
    total_yield: 90.75,
    co2_reduction: 12.38,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-07",
    TOTAL_PRODUCTION: 18.4,
    EFFICIENCY: 66.91,
    peak_hour_production: "",
    carbon_neutral: 0.92,
    carbon_offset: 14.72,
    total_yield: 101.2,
    co2_reduction: 13.8,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-08",
    TOTAL_PRODUCTION: 18.1,
    EFFICIENCY: 65.82,
    peak_hour_production: "",
    carbon_neutral: 0.905,
    carbon_offset: 14.48,
    total_yield: 99.55,
    co2_reduction: 13.58,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-09",
    TOTAL_PRODUCTION: 18.6,
    EFFICIENCY: 67.64,
    peak_hour_production: "",
    carbon_neutral: 0.93,
    carbon_offset: 14.88,
    total_yield: 102.3,
    co2_reduction: 13.95,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-10",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-11",
    TOTAL_PRODUCTION: 18.4,
    EFFICIENCY: 66.91,
    peak_hour_production: "",
    carbon_neutral: 0.92,
    carbon_offset: 14.72,
    total_yield: 101.2,
    co2_reduction: 13.8,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-12",
    TOTAL_PRODUCTION: 18.9,
    EFFICIENCY: 68.73,
    peak_hour_production: "",
    carbon_neutral: 0.945,
    carbon_offset: 15.12,
    total_yield: 103.95,
    co2_reduction: 14.18,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-13",
    TOTAL_PRODUCTION: 18.1,
    EFFICIENCY: 65.82,
    peak_hour_production: "",
    carbon_neutral: 0.905,
    carbon_offset: 14.48,
    total_yield: 99.55,
    co2_reduction: 13.58,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-14",
    TOTAL_PRODUCTION: 17.9,
    EFFICIENCY: 65.09,
    peak_hour_production: "",
    carbon_neutral: 0.895,
    carbon_offset: 14.32,
    total_yield: 98.45,
    co2_reduction: 13.43,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-15",
    TOTAL_PRODUCTION: 17,
    EFFICIENCY: 61.82,
    peak_hour_production: "",
    carbon_neutral: 0.85,
    carbon_offset: 13.6,
    total_yield: 93.5,
    co2_reduction: 12.75,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-16",
    TOTAL_PRODUCTION: 6.3,
    EFFICIENCY: 22.91,
    peak_hour_production: "",
    carbon_neutral: 0.315,
    carbon_offset: 5.04,
    total_yield: 34.65,
    co2_reduction: 4.73,
    trees_planted: 0.22,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-17",
    TOTAL_PRODUCTION: 12.5,
    EFFICIENCY: 45.45,
    peak_hour_production: "",
    carbon_neutral: 0.625,
    carbon_offset: 10,
    total_yield: 68.75,
    co2_reduction: 9.38,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-18",
    TOTAL_PRODUCTION: 15.4,
    EFFICIENCY: 56,
    peak_hour_production: "",
    carbon_neutral: 0.77,
    carbon_offset: 12.32,
    total_yield: 84.7,
    co2_reduction: 11.55,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-19",
    TOTAL_PRODUCTION: 15.6,
    EFFICIENCY: 56.73,
    peak_hour_production: "",
    carbon_neutral: 0.78,
    carbon_offset: 12.48,
    total_yield: 85.8,
    co2_reduction: 11.7,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-20",
    TOTAL_PRODUCTION: 22.2,
    EFFICIENCY: 80.73,
    peak_hour_production: "",
    carbon_neutral: 1.11,
    carbon_offset: 17.76,
    total_yield: 122.1,
    co2_reduction: 16.65,
    trees_planted: 0.78,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-21",
    TOTAL_PRODUCTION: 20.4,
    EFFICIENCY: 74.18,
    peak_hour_production: "",
    carbon_neutral: 1.02,
    carbon_offset: 16.32,
    total_yield: 112.2,
    co2_reduction: 15.3,
    trees_planted: 0.72,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-22",
    TOTAL_PRODUCTION: 21.1,
    EFFICIENCY: 76.73,
    peak_hour_production: "",
    carbon_neutral: 1.055,
    carbon_offset: 16.88,
    total_yield: 116.05,
    co2_reduction: 15.83,
    trees_planted: 0.74,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-23",
    TOTAL_PRODUCTION: 22.1,
    EFFICIENCY: 80.36,
    peak_hour_production: "",
    carbon_neutral: 1.105,
    carbon_offset: 17.68,
    total_yield: 121.55,
    co2_reduction: 16.58,
    trees_planted: 0.78,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-24",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-25",
    TOTAL_PRODUCTION: 15.9,
    EFFICIENCY: 57.82,
    peak_hour_production: "",
    carbon_neutral: 0.795,
    carbon_offset: 12.72,
    total_yield: 87.45,
    co2_reduction: 11.93,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-26",
    TOTAL_PRODUCTION: 17.7,
    EFFICIENCY: 64.36,
    peak_hour_production: "",
    carbon_neutral: 0.885,
    carbon_offset: 14.16,
    total_yield: 97.35,
    co2_reduction: 13.28,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-27",
    TOTAL_PRODUCTION: 21.8,
    EFFICIENCY: 79.27,
    peak_hour_production: "",
    carbon_neutral: 1.09,
    carbon_offset: 17.44,
    total_yield: 119.9,
    co2_reduction: 16.35,
    trees_planted: 0.77,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-28",
    TOTAL_PRODUCTION: 20.9,
    EFFICIENCY: 76,
    peak_hour_production: "",
    carbon_neutral: 1.045,
    carbon_offset: 16.72,
    total_yield: 114.95,
    co2_reduction: 15.68,
    trees_planted: 0.74,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-29",
    TOTAL_PRODUCTION: 20.3,
    EFFICIENCY: 73.82,
    peak_hour_production: "",
    carbon_neutral: 1.015,
    carbon_offset: 16.24,
    total_yield: 111.65,
    co2_reduction: 15.23,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-30",
    TOTAL_PRODUCTION: 22.4,
    EFFICIENCY: 81.45,
    peak_hour_production: "",
    carbon_neutral: 1.12,
    carbon_offset: 17.92,
    total_yield: 123.2,
    co2_reduction: 16.8,
    trees_planted: 0.79,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-03-31",
    TOTAL_PRODUCTION: 19.4,
    EFFICIENCY: 70.55,
    peak_hour_production: "",
    carbon_neutral: 0.97,
    carbon_offset: 15.52,
    total_yield: 106.7,
    co2_reduction: 14.55,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/01",
    TOTAL_PRODUCTION: 20.9,
    EFFICIENCY: 76,
    peak_hour_production: "",
    carbon_neutral: 1.045,
    carbon_offset: 16.72,
    total_yield: 114.95,
    co2_reduction: 15.68,
    trees_planted: 0.74,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/02",
    TOTAL_PRODUCTION: 19,
    EFFICIENCY: 69.09,
    peak_hour_production: "",
    carbon_neutral: 0.95,
    carbon_offset: 15.2,
    total_yield: 104.5,
    co2_reduction: 14.25,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/03",
    TOTAL_PRODUCTION: 19,
    EFFICIENCY: 69.09,
    peak_hour_production: "",
    carbon_neutral: 0.95,
    carbon_offset: 15.2,
    total_yield: 104.5,
    co2_reduction: 14.25,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/04",
    TOTAL_PRODUCTION: 19.8,
    EFFICIENCY: 72,
    peak_hour_production: "",
    carbon_neutral: 0.99,
    carbon_offset: 15.84,
    total_yield: 108.9,
    co2_reduction: 14.85,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/05",
    TOTAL_PRODUCTION: 16,
    EFFICIENCY: 58.18,
    peak_hour_production: "",
    carbon_neutral: 0.8,
    carbon_offset: 12.8,
    total_yield: 88,
    co2_reduction: 12,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/06",
    TOTAL_PRODUCTION: 14.7,
    EFFICIENCY: 53.45,
    peak_hour_production: "",
    carbon_neutral: 0.735,
    carbon_offset: 11.76,
    total_yield: 80.85,
    co2_reduction: 11.03,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/07",
    TOTAL_PRODUCTION: 14.2,
    EFFICIENCY: 51.64,
    peak_hour_production: "",
    carbon_neutral: 0.71,
    carbon_offset: 11.36,
    total_yield: 78.1,
    co2_reduction: 10.65,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/08",
    TOTAL_PRODUCTION: 21.8,
    EFFICIENCY: 79.27,
    peak_hour_production: "",
    carbon_neutral: 1.09,
    carbon_offset: 17.44,
    total_yield: 119.9,
    co2_reduction: 16.35,
    trees_planted: 0.77,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/09",
    TOTAL_PRODUCTION: 23.5,
    EFFICIENCY: 85.45,
    peak_hour_production: "",
    carbon_neutral: 1.175,
    carbon_offset: 18.8,
    total_yield: 129.25,
    co2_reduction: 17.63,
    trees_planted: 0.83,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/10",
    TOTAL_PRODUCTION: 23.5,
    EFFICIENCY: 85.45,
    peak_hour_production: "",
    carbon_neutral: 1.175,
    carbon_offset: 18.8,
    total_yield: 129.25,
    co2_reduction: 17.63,
    trees_planted: 0.83,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/11",
    TOTAL_PRODUCTION: 22.6,
    EFFICIENCY: 82.18,
    peak_hour_production: "",
    carbon_neutral: 1.13,
    carbon_offset: 18.08,
    total_yield: 124.3,
    co2_reduction: 16.95,
    trees_planted: 0.8,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/12",
    TOTAL_PRODUCTION: 18.7,
    EFFICIENCY: 68,
    peak_hour_production: "",
    carbon_neutral: 0.935,
    carbon_offset: 14.96,
    total_yield: 102.85,
    co2_reduction: 14.03,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/13",
    TOTAL_PRODUCTION: 15,
    EFFICIENCY: 54.55,
    peak_hour_production: "",
    carbon_neutral: 0.75,
    carbon_offset: 12,
    total_yield: 82.5,
    co2_reduction: 11.25,
    trees_planted: 0.53,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/14",
    TOTAL_PRODUCTION: 13.2,
    EFFICIENCY: 48,
    peak_hour_production: "",
    carbon_neutral: 0.66,
    carbon_offset: 10.56,
    total_yield: 72.6,
    co2_reduction: 9.9,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/15",
    TOTAL_PRODUCTION: 17.5,
    EFFICIENCY: 63.64,
    peak_hour_production: "",
    carbon_neutral: 0.875,
    carbon_offset: 14,
    total_yield: 96.25,
    co2_reduction: 13.13,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/16",
    TOTAL_PRODUCTION: 17.5,
    EFFICIENCY: 63.64,
    peak_hour_production: "",
    carbon_neutral: 0.875,
    carbon_offset: 14,
    total_yield: 96.25,
    co2_reduction: 13.13,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/17",
    TOTAL_PRODUCTION: 16.5,
    EFFICIENCY: 60,
    peak_hour_production: "",
    carbon_neutral: 0.825,
    carbon_offset: 13.2,
    total_yield: 90.75,
    co2_reduction: 12.38,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/18",
    TOTAL_PRODUCTION: 17.7,
    EFFICIENCY: 64.36,
    peak_hour_production: "",
    carbon_neutral: 0.885,
    carbon_offset: 14.16,
    total_yield: 97.35,
    co2_reduction: 13.28,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/19",
    TOTAL_PRODUCTION: 17.4,
    EFFICIENCY: 63.27,
    peak_hour_production: "",
    carbon_neutral: 0.87,
    carbon_offset: 13.92,
    total_yield: 95.7,
    co2_reduction: 13.05,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/20",
    TOTAL_PRODUCTION: 16.9,
    EFFICIENCY: 61.45,
    peak_hour_production: "",
    carbon_neutral: 0.845,
    carbon_offset: 13.52,
    total_yield: 92.95,
    co2_reduction: 12.68,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/21",
    TOTAL_PRODUCTION: 17.9,
    EFFICIENCY: 65.09,
    peak_hour_production: "",
    carbon_neutral: 0.895,
    carbon_offset: 14.32,
    total_yield: 98.45,
    co2_reduction: 13.43,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/22",
    TOTAL_PRODUCTION: 18.2,
    EFFICIENCY: 66.18,
    peak_hour_production: "",
    carbon_neutral: 0.91,
    carbon_offset: 14.56,
    total_yield: 100.1,
    co2_reduction: 13.65,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/23",
    TOTAL_PRODUCTION: 18.8,
    EFFICIENCY: 68.36,
    peak_hour_production: "",
    carbon_neutral: 0.94,
    carbon_offset: 15.04,
    total_yield: 103.4,
    co2_reduction: 14.1,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/24",
    TOTAL_PRODUCTION: 17.5,
    EFFICIENCY: 63.64,
    peak_hour_production: "",
    carbon_neutral: 0.875,
    carbon_offset: 14,
    total_yield: 96.25,
    co2_reduction: 13.13,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/25",
    TOTAL_PRODUCTION: 17.5,
    EFFICIENCY: 63.64,
    peak_hour_production: "",
    carbon_neutral: 0.875,
    carbon_offset: 14,
    total_yield: 96.25,
    co2_reduction: 13.13,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/26",
    TOTAL_PRODUCTION: 12.9,
    EFFICIENCY: 46.91,
    peak_hour_production: "",
    carbon_neutral: 0.645,
    carbon_offset: 10.32,
    total_yield: 70.95,
    co2_reduction: 9.68,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/27",
    TOTAL_PRODUCTION: 21.2,
    EFFICIENCY: 77.09,
    peak_hour_production: "",
    carbon_neutral: 1.06,
    carbon_offset: 16.96,
    total_yield: 116.6,
    co2_reduction: 15.9,
    trees_planted: 0.75,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/28",
    TOTAL_PRODUCTION: 15.5,
    EFFICIENCY: 56.36,
    peak_hour_production: "",
    carbon_neutral: 0.775,
    carbon_offset: 12.4,
    total_yield: 85.25,
    co2_reduction: 11.63,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/29",
    TOTAL_PRODUCTION: 12.2,
    EFFICIENCY: 44.36,
    peak_hour_production: "",
    carbon_neutral: 0.61,
    carbon_offset: 9.76,
    total_yield: 67.1,
    co2_reduction: 9.15,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023/04/30",
    TOTAL_PRODUCTION: 16.3,
    EFFICIENCY: 59.27,
    peak_hour_production: "",
    carbon_neutral: 0.815,
    carbon_offset: 13.04,
    total_yield: 89.65,
    co2_reduction: 12.23,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-01",
    TOTAL_PRODUCTION: 14.6,
    EFFICIENCY: 53.09,
    peak_hour_production: "",
    carbon_neutral: 0.73,
    carbon_offset: 11.68,
    total_yield: 80.3,
    co2_reduction: 10.95,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-02",
    TOTAL_PRODUCTION: 9.8,
    EFFICIENCY: 35.64,
    peak_hour_production: "",
    carbon_neutral: 0.49,
    carbon_offset: 7.84,
    total_yield: 53.9,
    co2_reduction: 7.35,
    trees_planted: 0.35,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-03",
    TOTAL_PRODUCTION: 12.7,
    EFFICIENCY: 46.18,
    peak_hour_production: "",
    carbon_neutral: 0.635,
    carbon_offset: 10.16,
    total_yield: 69.85,
    co2_reduction: 9.53,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-04",
    TOTAL_PRODUCTION: 17.7,
    EFFICIENCY: 64.36,
    peak_hour_production: "",
    carbon_neutral: 0.885,
    carbon_offset: 14.16,
    total_yield: 97.35,
    co2_reduction: 13.28,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-05",
    TOTAL_PRODUCTION: 14.5,
    EFFICIENCY: 52.73,
    peak_hour_production: "",
    carbon_neutral: 0.725,
    carbon_offset: 11.6,
    total_yield: 79.75,
    co2_reduction: 10.88,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-06",
    TOTAL_PRODUCTION: 15.4,
    EFFICIENCY: 56,
    peak_hour_production: "",
    carbon_neutral: 0.77,
    carbon_offset: 12.32,
    total_yield: 84.7,
    co2_reduction: 11.55,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-07",
    TOTAL_PRODUCTION: 18.5,
    EFFICIENCY: 67.27,
    peak_hour_production: "",
    carbon_neutral: 0.925,
    carbon_offset: 14.8,
    total_yield: 101.75,
    co2_reduction: 13.88,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-08",
    TOTAL_PRODUCTION: 19.9,
    EFFICIENCY: 72.36,
    peak_hour_production: "",
    carbon_neutral: 0.995,
    carbon_offset: 15.92,
    total_yield: 109.45,
    co2_reduction: 14.93,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-09",
    TOTAL_PRODUCTION: 20.6,
    EFFICIENCY: 74.91,
    peak_hour_production: "",
    carbon_neutral: 1.03,
    carbon_offset: 16.48,
    total_yield: 113.3,
    co2_reduction: 15.45,
    trees_planted: 0.73,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-10",
    TOTAL_PRODUCTION: 17.7,
    EFFICIENCY: 64.36,
    peak_hour_production: "",
    carbon_neutral: 0.885,
    carbon_offset: 14.16,
    total_yield: 97.35,
    co2_reduction: 13.28,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-11",
    TOTAL_PRODUCTION: 16,
    EFFICIENCY: 58.18,
    peak_hour_production: "",
    carbon_neutral: 0.8,
    carbon_offset: 12.8,
    total_yield: 88,
    co2_reduction: 12,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-12",
    TOTAL_PRODUCTION: 18.8,
    EFFICIENCY: 68.36,
    peak_hour_production: "",
    carbon_neutral: 0.94,
    carbon_offset: 15.04,
    total_yield: 103.4,
    co2_reduction: 14.1,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-13",
    TOTAL_PRODUCTION: 19.2,
    EFFICIENCY: 69.82,
    peak_hour_production: "",
    carbon_neutral: 0.96,
    carbon_offset: 15.36,
    total_yield: 105.6,
    co2_reduction: 14.4,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-14",
    TOTAL_PRODUCTION: 20.5,
    EFFICIENCY: 74.55,
    peak_hour_production: "",
    carbon_neutral: 1.025,
    carbon_offset: 16.4,
    total_yield: 112.75,
    co2_reduction: 15.38,
    trees_planted: 0.72,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-15",
    TOTAL_PRODUCTION: 20.2,
    EFFICIENCY: 73.45,
    peak_hour_production: "",
    carbon_neutral: 1.01,
    carbon_offset: 16.16,
    total_yield: 111.1,
    co2_reduction: 15.15,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-16",
    TOTAL_PRODUCTION: 19.4,
    EFFICIENCY: 70.55,
    peak_hour_production: "",
    carbon_neutral: 0.97,
    carbon_offset: 15.52,
    total_yield: 106.7,
    co2_reduction: 14.55,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-17",
    TOTAL_PRODUCTION: 21.2,
    EFFICIENCY: 77.09,
    peak_hour_production: "",
    carbon_neutral: 1.06,
    carbon_offset: 16.96,
    total_yield: 116.6,
    co2_reduction: 15.9,
    trees_planted: 0.75,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-18",
    TOTAL_PRODUCTION: 15.8,
    EFFICIENCY: 57.45,
    peak_hour_production: "",
    carbon_neutral: 0.79,
    carbon_offset: 12.64,
    total_yield: 86.9,
    co2_reduction: 11.85,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-19",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-20",
    TOTAL_PRODUCTION: 16.5,
    EFFICIENCY: 60,
    peak_hour_production: "",
    carbon_neutral: 0.825,
    carbon_offset: 13.2,
    total_yield: 90.75,
    co2_reduction: 12.38,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-21",
    TOTAL_PRODUCTION: 17.3,
    EFFICIENCY: 62.91,
    peak_hour_production: "",
    carbon_neutral: 0.865,
    carbon_offset: 13.84,
    total_yield: 95.15,
    co2_reduction: 12.98,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-22",
    TOTAL_PRODUCTION: 9.1,
    EFFICIENCY: 33.09,
    peak_hour_production: "",
    carbon_neutral: 0.455,
    carbon_offset: 7.28,
    total_yield: 50.05,
    co2_reduction: 6.83,
    trees_planted: 0.32,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-23",
    TOTAL_PRODUCTION: 20,
    EFFICIENCY: 72.73,
    peak_hour_production: "",
    carbon_neutral: 1,
    carbon_offset: 16,
    total_yield: 110,
    co2_reduction: 15,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-24",
    TOTAL_PRODUCTION: 20,
    EFFICIENCY: 72.73,
    peak_hour_production: "",
    carbon_neutral: 1,
    carbon_offset: 16,
    total_yield: 110,
    co2_reduction: 15,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-25",
    TOTAL_PRODUCTION: 14.2,
    EFFICIENCY: 51.64,
    peak_hour_production: "",
    carbon_neutral: 0.71,
    carbon_offset: 11.36,
    total_yield: 78.1,
    co2_reduction: 10.65,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-26",
    TOTAL_PRODUCTION: 20.5,
    EFFICIENCY: 74.55,
    peak_hour_production: "",
    carbon_neutral: 1.025,
    carbon_offset: 16.4,
    total_yield: 112.75,
    co2_reduction: 15.38,
    trees_planted: 0.72,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-27",
    TOTAL_PRODUCTION: 19.7,
    EFFICIENCY: 71.64,
    peak_hour_production: "",
    carbon_neutral: 0.985,
    carbon_offset: 15.76,
    total_yield: 108.35,
    co2_reduction: 14.78,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-28",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-29",
    TOTAL_PRODUCTION: 18,
    EFFICIENCY: 65.45,
    peak_hour_production: "",
    carbon_neutral: 0.9,
    carbon_offset: 14.4,
    total_yield: 99,
    co2_reduction: 13.5,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-30",
    TOTAL_PRODUCTION: 12.3,
    EFFICIENCY: 44.73,
    peak_hour_production: "",
    carbon_neutral: 0.615,
    carbon_offset: 9.84,
    total_yield: 67.65,
    co2_reduction: 9.23,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-05-31",
    TOTAL_PRODUCTION: 18.9,
    EFFICIENCY: 68.73,
    peak_hour_production: "",
    carbon_neutral: 0.945,
    carbon_offset: 15.12,
    total_yield: 103.95,
    co2_reduction: 14.18,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-01",
    TOTAL_PRODUCTION: 18.5,
    EFFICIENCY: 67.27,
    peak_hour_production: "",
    carbon_neutral: 0.925,
    carbon_offset: 14.8,
    total_yield: 101.75,
    co2_reduction: 13.88,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-02",
    TOTAL_PRODUCTION: 19.5,
    EFFICIENCY: 70.91,
    peak_hour_production: "",
    carbon_neutral: 0.975,
    carbon_offset: 15.6,
    total_yield: 107.25,
    co2_reduction: 14.63,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-03",
    TOTAL_PRODUCTION: 18.2,
    EFFICIENCY: 66.18,
    peak_hour_production: "",
    carbon_neutral: 0.91,
    carbon_offset: 14.56,
    total_yield: 100.1,
    co2_reduction: 13.65,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-04",
    TOTAL_PRODUCTION: 13.2,
    EFFICIENCY: 48,
    peak_hour_production: "",
    carbon_neutral: 0.66,
    carbon_offset: 10.56,
    total_yield: 72.6,
    co2_reduction: 9.9,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-05",
    TOTAL_PRODUCTION: 21.2,
    EFFICIENCY: 77.09,
    peak_hour_production: "",
    carbon_neutral: 1.06,
    carbon_offset: 16.96,
    total_yield: 116.6,
    co2_reduction: 15.9,
    trees_planted: 0.75,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-06",
    TOTAL_PRODUCTION: 17.9,
    EFFICIENCY: 65.09,
    peak_hour_production: "",
    carbon_neutral: 0.895,
    carbon_offset: 14.32,
    total_yield: 98.45,
    co2_reduction: 13.43,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-07",
    TOTAL_PRODUCTION: 18.5,
    EFFICIENCY: 67.27,
    peak_hour_production: "",
    carbon_neutral: 0.925,
    carbon_offset: 14.8,
    total_yield: 101.75,
    co2_reduction: 13.88,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-08",
    TOTAL_PRODUCTION: 8.9,
    EFFICIENCY: 32.36,
    peak_hour_production: "",
    carbon_neutral: 0.445,
    carbon_offset: 7.12,
    total_yield: 48.95,
    co2_reduction: 6.68,
    trees_planted: 0.31,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-09",
    TOTAL_PRODUCTION: 19.6,
    EFFICIENCY: 71.27,
    peak_hour_production: "",
    carbon_neutral: 0.98,
    carbon_offset: 15.68,
    total_yield: 107.8,
    co2_reduction: 14.7,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-10",
    TOTAL_PRODUCTION: 19.3,
    EFFICIENCY: 70.18,
    peak_hour_production: "",
    carbon_neutral: 0.965,
    carbon_offset: 15.44,
    total_yield: 106.15,
    co2_reduction: 14.48,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-11",
    TOTAL_PRODUCTION: 8.8,
    EFFICIENCY: 32,
    peak_hour_production: "",
    carbon_neutral: 0.44,
    carbon_offset: 7.04,
    total_yield: 48.4,
    co2_reduction: 6.6,
    trees_planted: 0.31,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-12",
    TOTAL_PRODUCTION: 20.4,
    EFFICIENCY: 74.18,
    peak_hour_production: "",
    carbon_neutral: 1.02,
    carbon_offset: 16.32,
    total_yield: 112.2,
    co2_reduction: 15.3,
    trees_planted: 0.72,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-13",
    TOTAL_PRODUCTION: 19.5,
    EFFICIENCY: 70.91,
    peak_hour_production: "",
    carbon_neutral: 0.975,
    carbon_offset: 15.6,
    total_yield: 107.25,
    co2_reduction: 14.63,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-14",
    TOTAL_PRODUCTION: 21.2,
    EFFICIENCY: 77.09,
    peak_hour_production: "",
    carbon_neutral: 1.06,
    carbon_offset: 16.96,
    total_yield: 116.6,
    co2_reduction: 15.9,
    trees_planted: 0.75,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-15",
    TOTAL_PRODUCTION: 21.4,
    EFFICIENCY: 77.82,
    peak_hour_production: "",
    carbon_neutral: 1.07,
    carbon_offset: 17.12,
    total_yield: 117.7,
    co2_reduction: 16.05,
    trees_planted: 0.75,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-16",
    TOTAL_PRODUCTION: 21.5,
    EFFICIENCY: 78.18,
    peak_hour_production: "",
    carbon_neutral: 1.075,
    carbon_offset: 17.2,
    total_yield: 118.25,
    co2_reduction: 16.13,
    trees_planted: 0.76,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-17",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-18",
    TOTAL_PRODUCTION: 20.1,
    EFFICIENCY: 73.09,
    peak_hour_production: "",
    carbon_neutral: 1.005,
    carbon_offset: 16.08,
    total_yield: 110.55,
    co2_reduction: 15.08,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-19",
    TOTAL_PRODUCTION: 12.8,
    EFFICIENCY: 46.55,
    peak_hour_production: "",
    carbon_neutral: 0.64,
    carbon_offset: 10.24,
    total_yield: 70.4,
    co2_reduction: 9.6,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-20",
    TOTAL_PRODUCTION: 17.7,
    EFFICIENCY: 64.36,
    peak_hour_production: "",
    carbon_neutral: 0.885,
    carbon_offset: 14.16,
    total_yield: 97.35,
    co2_reduction: 13.28,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-21",
    TOTAL_PRODUCTION: 15.3,
    EFFICIENCY: 55.64,
    peak_hour_production: "",
    carbon_neutral: 0.765,
    carbon_offset: 12.24,
    total_yield: 84.15,
    co2_reduction: 11.48,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-22",
    TOTAL_PRODUCTION: 13.9,
    EFFICIENCY: 50.55,
    peak_hour_production: "",
    carbon_neutral: 0.695,
    carbon_offset: 11.12,
    total_yield: 76.45,
    co2_reduction: 10.43,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-23",
    TOTAL_PRODUCTION: 12.7,
    EFFICIENCY: 46.18,
    peak_hour_production: "",
    carbon_neutral: 0.635,
    carbon_offset: 10.16,
    total_yield: 69.85,
    co2_reduction: 9.53,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-24",
    TOTAL_PRODUCTION: "",
    EFFICIENCY: 0,
    peak_hour_production: "",
    carbon_neutral: 0,
    carbon_offset: 0,
    total_yield: 0,
    co2_reduction: 0,
    trees_planted: 0,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-25",
    TOTAL_PRODUCTION: 13.5,
    EFFICIENCY: 49.09,
    peak_hour_production: "",
    carbon_neutral: 0.675,
    carbon_offset: 10.8,
    total_yield: 74.25,
    co2_reduction: 10.13,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-26",
    TOTAL_PRODUCTION: 11.6,
    EFFICIENCY: 42.18,
    peak_hour_production: "",
    carbon_neutral: 0.58,
    carbon_offset: 9.28,
    total_yield: 63.8,
    co2_reduction: 8.7,
    trees_planted: 0.41,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-27",
    TOTAL_PRODUCTION: 14.9,
    EFFICIENCY: 54.18,
    peak_hour_production: "",
    carbon_neutral: 0.745,
    carbon_offset: 11.92,
    total_yield: 81.95,
    co2_reduction: 11.18,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-28",
    TOTAL_PRODUCTION: 14.3,
    EFFICIENCY: 52,
    peak_hour_production: "",
    carbon_neutral: 0.715,
    carbon_offset: 11.44,
    total_yield: 78.65,
    co2_reduction: 10.73,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-29",
    TOTAL_PRODUCTION: 15.8,
    EFFICIENCY: 57.45,
    peak_hour_production: "",
    carbon_neutral: 0.79,
    carbon_offset: 12.64,
    total_yield: 86.9,
    co2_reduction: 11.85,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-06-30",
    TOTAL_PRODUCTION: 19.4,
    EFFICIENCY: 70.55,
    peak_hour_production: "",
    carbon_neutral: 0.97,
    carbon_offset: 15.52,
    total_yield: 106.7,
    co2_reduction: 14.55,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-01",
    TOTAL_PRODUCTION: 22.4,
    EFFICIENCY: 81.45,
    peak_hour_production: "",
    carbon_neutral: 1.12,
    carbon_offset: 17.92,
    total_yield: 123.2,
    co2_reduction: 16.8,
    trees_planted: 0.79,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-02",
    TOTAL_PRODUCTION: 19.4,
    EFFICIENCY: 70.55,
    peak_hour_production: "",
    carbon_neutral: 0.97,
    carbon_offset: 15.52,
    total_yield: 106.7,
    co2_reduction: 14.55,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-03",
    TOTAL_PRODUCTION: 19.9,
    EFFICIENCY: 72.36,
    peak_hour_production: "",
    carbon_neutral: 0.995,
    carbon_offset: 15.92,
    total_yield: 109.45,
    co2_reduction: 14.93,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-04",
    TOTAL_PRODUCTION: 17.2,
    EFFICIENCY: 62.55,
    peak_hour_production: "",
    carbon_neutral: 0.86,
    carbon_offset: 13.76,
    total_yield: 94.6,
    co2_reduction: 12.9,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-05",
    TOTAL_PRODUCTION: 14.4,
    EFFICIENCY: 52.36,
    peak_hour_production: "",
    carbon_neutral: 0.72,
    carbon_offset: 11.52,
    total_yield: 79.2,
    co2_reduction: 10.8,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-06",
    TOTAL_PRODUCTION: 13.9,
    EFFICIENCY: 50.55,
    peak_hour_production: "",
    carbon_neutral: 0.695,
    carbon_offset: 11.12,
    total_yield: 76.45,
    co2_reduction: 10.43,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-07",
    TOTAL_PRODUCTION: 17.9,
    EFFICIENCY: 65.09,
    peak_hour_production: "",
    carbon_neutral: 0.895,
    carbon_offset: 14.32,
    total_yield: 98.45,
    co2_reduction: 13.43,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-08",
    TOTAL_PRODUCTION: 14.9,
    EFFICIENCY: 54.18,
    peak_hour_production: "",
    carbon_neutral: 0.745,
    carbon_offset: 11.92,
    total_yield: 81.95,
    co2_reduction: 11.18,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-09",
    TOTAL_PRODUCTION: 18.6,
    EFFICIENCY: 67.64,
    peak_hour_production: "",
    carbon_neutral: 0.93,
    carbon_offset: 14.88,
    total_yield: 102.3,
    co2_reduction: 13.95,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-10",
    TOTAL_PRODUCTION: 20.8,
    EFFICIENCY: 75.64,
    peak_hour_production: "",
    carbon_neutral: 1.04,
    carbon_offset: 16.64,
    total_yield: 114.4,
    co2_reduction: 15.6,
    trees_planted: 0.73,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-11",
    TOTAL_PRODUCTION: 7.6,
    EFFICIENCY: 27.64,
    peak_hour_production: "",
    carbon_neutral: 0.38,
    carbon_offset: 6.08,
    total_yield: 41.8,
    co2_reduction: 5.7,
    trees_planted: 0.27,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-12",
    TOTAL_PRODUCTION: 9.2,
    EFFICIENCY: 33.45,
    peak_hour_production: "",
    carbon_neutral: 0.46,
    carbon_offset: 7.36,
    total_yield: 50.6,
    co2_reduction: 6.9,
    trees_planted: 0.32,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-13",
    TOTAL_PRODUCTION: 15.7,
    EFFICIENCY: 57.09,
    peak_hour_production: "",
    carbon_neutral: 0.785,
    carbon_offset: 12.56,
    total_yield: 86.35,
    co2_reduction: 11.78,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-14",
    TOTAL_PRODUCTION: 17.6,
    EFFICIENCY: 64,
    peak_hour_production: "",
    carbon_neutral: 0.88,
    carbon_offset: 14.08,
    total_yield: 96.8,
    co2_reduction: 13.2,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-15",
    TOTAL_PRODUCTION: 13.4,
    EFFICIENCY: 48.73,
    peak_hour_production: "",
    carbon_neutral: 0.67,
    carbon_offset: 10.72,
    total_yield: 73.7,
    co2_reduction: 10.05,
    trees_planted: 0.47,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-16",
    TOTAL_PRODUCTION: 12.3,
    EFFICIENCY: 44.73,
    peak_hour_production: "",
    carbon_neutral: 0.615,
    carbon_offset: 9.84,
    total_yield: 67.65,
    co2_reduction: 9.23,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-17",
    TOTAL_PRODUCTION: 10.2,
    EFFICIENCY: 37.09,
    peak_hour_production: "",
    carbon_neutral: 0.51,
    carbon_offset: 8.16,
    total_yield: 56.1,
    co2_reduction: 7.65,
    trees_planted: 0.36,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-18",
    TOTAL_PRODUCTION: 3,
    EFFICIENCY: 10.91,
    peak_hour_production: "",
    carbon_neutral: 0.15,
    carbon_offset: 2.4,
    total_yield: 16.5,
    co2_reduction: 2.25,
    trees_planted: 0.11,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-19",
    TOTAL_PRODUCTION: 1.9,
    EFFICIENCY: 6.91,
    peak_hour_production: "",
    carbon_neutral: 0.095,
    carbon_offset: 1.52,
    total_yield: 10.45,
    co2_reduction: 1.43,
    trees_planted: 0.07,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-20",
    TOTAL_PRODUCTION: 1.8,
    EFFICIENCY: 6.55,
    peak_hour_production: "",
    carbon_neutral: 0.09,
    carbon_offset: 1.44,
    total_yield: 9.9,
    co2_reduction: 1.35,
    trees_planted: 0.06,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-21",
    TOTAL_PRODUCTION: 3.7,
    EFFICIENCY: 13.45,
    peak_hour_production: "",
    carbon_neutral: 0.185,
    carbon_offset: 2.96,
    total_yield: 20.35,
    co2_reduction: 2.78,
    trees_planted: 0.13,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-22",
    TOTAL_PRODUCTION: 6.8,
    EFFICIENCY: 24.73,
    peak_hour_production: "",
    carbon_neutral: 0.34,
    carbon_offset: 5.44,
    total_yield: 37.4,
    co2_reduction: 5.1,
    trees_planted: 0.24,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-23",
    TOTAL_PRODUCTION: 9.9,
    EFFICIENCY: 36,
    peak_hour_production: "",
    carbon_neutral: 0.495,
    carbon_offset: 7.92,
    total_yield: 54.45,
    co2_reduction: 7.43,
    trees_planted: 0.35,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-24",
    TOTAL_PRODUCTION: 10.5,
    EFFICIENCY: 38.18,
    peak_hour_production: "",
    carbon_neutral: 0.525,
    carbon_offset: 8.4,
    total_yield: 57.75,
    co2_reduction: 7.88,
    trees_planted: 0.37,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-25",
    TOTAL_PRODUCTION: 4.7,
    EFFICIENCY: 17.09,
    peak_hour_production: "",
    carbon_neutral: 0.235,
    carbon_offset: 3.76,
    total_yield: 25.85,
    co2_reduction: 3.53,
    trees_planted: 0.17,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-26",
    TOTAL_PRODUCTION: 3.3,
    EFFICIENCY: 12,
    peak_hour_production: "",
    carbon_neutral: 0.165,
    carbon_offset: 2.64,
    total_yield: 18.15,
    co2_reduction: 2.48,
    trees_planted: 0.12,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-27",
    TOTAL_PRODUCTION: 2.7,
    EFFICIENCY: 9.82,
    peak_hour_production: "",
    carbon_neutral: 0.135,
    carbon_offset: 2.16,
    total_yield: 14.85,
    co2_reduction: 2.03,
    trees_planted: 0.1,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-28",
    TOTAL_PRODUCTION: 10.1,
    EFFICIENCY: 36.73,
    peak_hour_production: "",
    carbon_neutral: 0.505,
    carbon_offset: 8.08,
    total_yield: 55.55,
    co2_reduction: 7.58,
    trees_planted: 0.36,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-29",
    TOTAL_PRODUCTION: 12.9,
    EFFICIENCY: 46.91,
    peak_hour_production: "",
    carbon_neutral: 0.645,
    carbon_offset: 10.32,
    total_yield: 70.95,
    co2_reduction: 9.68,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-30",
    TOTAL_PRODUCTION: 15.2,
    EFFICIENCY: 55.27,
    peak_hour_production: "",
    carbon_neutral: 0.76,
    carbon_offset: 12.16,
    total_yield: 83.6,
    co2_reduction: 11.4,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-07-31",
    TOTAL_PRODUCTION: 16,
    EFFICIENCY: 58.18,
    peak_hour_production: "",
    carbon_neutral: 0.8,
    carbon_offset: 12.8,
    total_yield: 88,
    co2_reduction: 12,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-01",
    TOTAL_PRODUCTION: 15.3,
    EFFICIENCY: 55.64,
    peak_hour_production: "",
    carbon_neutral: 0.765,
    carbon_offset: 12.24,
    total_yield: 84.15,
    co2_reduction: 11.48,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-02",
    TOTAL_PRODUCTION: 12.5,
    EFFICIENCY: 45.45,
    peak_hour_production: "",
    carbon_neutral: 0.625,
    carbon_offset: 10,
    total_yield: 68.75,
    co2_reduction: 9.38,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-03",
    TOTAL_PRODUCTION: 10,
    EFFICIENCY: 36.36,
    peak_hour_production: "",
    carbon_neutral: 0.5,
    carbon_offset: 8,
    total_yield: 55,
    co2_reduction: 7.5,
    trees_planted: 0.35,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-04",
    TOTAL_PRODUCTION: 18.8,
    EFFICIENCY: 68.36,
    peak_hour_production: "",
    carbon_neutral: 0.94,
    carbon_offset: 15.04,
    total_yield: 103.4,
    co2_reduction: 14.1,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-05",
    TOTAL_PRODUCTION: 18.9,
    EFFICIENCY: 68.73,
    peak_hour_production: "",
    carbon_neutral: 0.945,
    carbon_offset: 15.12,
    total_yield: 103.95,
    co2_reduction: 14.18,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-06",
    TOTAL_PRODUCTION: 17,
    EFFICIENCY: 61.82,
    peak_hour_production: "",
    carbon_neutral: 0.85,
    carbon_offset: 13.6,
    total_yield: 93.5,
    co2_reduction: 12.75,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-07",
    TOTAL_PRODUCTION: 15.9,
    EFFICIENCY: 57.82,
    peak_hour_production: "",
    carbon_neutral: 0.795,
    carbon_offset: 12.72,
    total_yield: 87.45,
    co2_reduction: 11.93,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-08",
    TOTAL_PRODUCTION: 14.6,
    EFFICIENCY: 53.09,
    peak_hour_production: "",
    carbon_neutral: 0.73,
    carbon_offset: 11.68,
    total_yield: 80.3,
    co2_reduction: 10.95,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-09",
    TOTAL_PRODUCTION: 16.7,
    EFFICIENCY: 60.73,
    peak_hour_production: "",
    carbon_neutral: 0.835,
    carbon_offset: 13.36,
    total_yield: 91.85,
    co2_reduction: 12.53,
    trees_planted: 0.59,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-10",
    TOTAL_PRODUCTION: 21,
    EFFICIENCY: 76.36,
    peak_hour_production: "",
    carbon_neutral: 1.05,
    carbon_offset: 16.8,
    total_yield: 115.5,
    co2_reduction: 15.75,
    trees_planted: 0.74,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-11",
    TOTAL_PRODUCTION: 18.4,
    EFFICIENCY: 66.91,
    peak_hour_production: "",
    carbon_neutral: 0.92,
    carbon_offset: 14.72,
    total_yield: 101.2,
    co2_reduction: 13.8,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-12",
    TOTAL_PRODUCTION: 14.5,
    EFFICIENCY: 52.73,
    peak_hour_production: "",
    carbon_neutral: 0.725,
    carbon_offset: 11.6,
    total_yield: 79.75,
    co2_reduction: 10.88,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-13",
    TOTAL_PRODUCTION: 19.1,
    EFFICIENCY: 69.45,
    peak_hour_production: "",
    carbon_neutral: 0.955,
    carbon_offset: 15.28,
    total_yield: 105.05,
    co2_reduction: 14.33,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-14",
    TOTAL_PRODUCTION: 17.2,
    EFFICIENCY: 62.55,
    peak_hour_production: "",
    carbon_neutral: 0.86,
    carbon_offset: 13.76,
    total_yield: 94.6,
    co2_reduction: 12.9,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-15",
    TOTAL_PRODUCTION: 18.1,
    EFFICIENCY: 65.82,
    peak_hour_production: "",
    carbon_neutral: 0.905,
    carbon_offset: 14.48,
    total_yield: 99.55,
    co2_reduction: 13.58,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-16",
    TOTAL_PRODUCTION: 17.3,
    EFFICIENCY: 62.91,
    peak_hour_production: "",
    carbon_neutral: 0.865,
    carbon_offset: 13.84,
    total_yield: 95.15,
    co2_reduction: 12.98,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-17",
    TOTAL_PRODUCTION: 15,
    EFFICIENCY: 54.55,
    peak_hour_production: "",
    carbon_neutral: 0.75,
    carbon_offset: 12,
    total_yield: 82.5,
    co2_reduction: 11.25,
    trees_planted: 0.53,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-18",
    TOTAL_PRODUCTION: 9.6,
    EFFICIENCY: 34.91,
    peak_hour_production: "",
    carbon_neutral: 0.48,
    carbon_offset: 7.68,
    total_yield: 52.8,
    co2_reduction: 7.2,
    trees_planted: 0.34,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-19",
    TOTAL_PRODUCTION: 3.6,
    EFFICIENCY: 13.09,
    peak_hour_production: "",
    carbon_neutral: 0.18,
    carbon_offset: 2.88,
    total_yield: 19.8,
    co2_reduction: 2.7,
    trees_planted: 0.13,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-20",
    TOTAL_PRODUCTION: 16.4,
    EFFICIENCY: 59.64,
    peak_hour_production: "",
    carbon_neutral: 0.82,
    carbon_offset: 13.12,
    total_yield: 90.2,
    co2_reduction: 12.3,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-21",
    TOTAL_PRODUCTION: 17.7,
    EFFICIENCY: 64.36,
    peak_hour_production: "",
    carbon_neutral: 0.885,
    carbon_offset: 14.16,
    total_yield: 97.35,
    co2_reduction: 13.28,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-22",
    TOTAL_PRODUCTION: 11.7,
    EFFICIENCY: 42.55,
    peak_hour_production: "",
    carbon_neutral: 0.585,
    carbon_offset: 9.36,
    total_yield: 64.35,
    co2_reduction: 8.78,
    trees_planted: 0.41,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-23",
    TOTAL_PRODUCTION: 16.5,
    EFFICIENCY: 60,
    peak_hour_production: "",
    carbon_neutral: 0.825,
    carbon_offset: 13.2,
    total_yield: 90.75,
    co2_reduction: 12.38,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-24",
    TOTAL_PRODUCTION: 12.4,
    EFFICIENCY: 45.09,
    peak_hour_production: "",
    carbon_neutral: 0.62,
    carbon_offset: 9.92,
    total_yield: 68.2,
    co2_reduction: 9.3,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-25",
    TOTAL_PRODUCTION: 16.6,
    EFFICIENCY: 60.36,
    peak_hour_production: "",
    carbon_neutral: 0.83,
    carbon_offset: 13.28,
    total_yield: 91.3,
    co2_reduction: 12.45,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-26",
    TOTAL_PRODUCTION: 15.7,
    EFFICIENCY: 57.09,
    peak_hour_production: "",
    carbon_neutral: 0.785,
    carbon_offset: 12.56,
    total_yield: 86.35,
    co2_reduction: 11.78,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-27",
    TOTAL_PRODUCTION: 19.6,
    EFFICIENCY: 71.27,
    peak_hour_production: "",
    carbon_neutral: 0.98,
    carbon_offset: 15.68,
    total_yield: 107.8,
    co2_reduction: 14.7,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-28",
    TOTAL_PRODUCTION: 19.9,
    EFFICIENCY: 72.36,
    peak_hour_production: "",
    carbon_neutral: 0.995,
    carbon_offset: 15.92,
    total_yield: 109.45,
    co2_reduction: 14.93,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-29",
    TOTAL_PRODUCTION: 18.7,
    EFFICIENCY: 68,
    peak_hour_production: "",
    carbon_neutral: 0.935,
    carbon_offset: 14.96,
    total_yield: 102.85,
    co2_reduction: 14.03,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-30",
    TOTAL_PRODUCTION: 19.5,
    EFFICIENCY: 70.91,
    peak_hour_production: "",
    carbon_neutral: 0.975,
    carbon_offset: 15.6,
    total_yield: 107.25,
    co2_reduction: 14.63,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-08-31",
    TOTAL_PRODUCTION: 19.1,
    EFFICIENCY: 69.45,
    peak_hour_production: "",
    carbon_neutral: 0.955,
    carbon_offset: 15.28,
    total_yield: 105.05,
    co2_reduction: 14.33,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-01",
    TOTAL_PRODUCTION: 12.7,
    EFFICIENCY: 46.18,
    peak_hour_production: "",
    carbon_neutral: 0.635,
    carbon_offset: 10.16,
    total_yield: 69.85,
    co2_reduction: 9.53,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-02",
    TOTAL_PRODUCTION: 13.8,
    EFFICIENCY: 50.18,
    peak_hour_production: "",
    carbon_neutral: 0.69,
    carbon_offset: 11.04,
    total_yield: 75.9,
    co2_reduction: 10.35,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-03",
    TOTAL_PRODUCTION: 8.6,
    EFFICIENCY: 31.27,
    peak_hour_production: "",
    carbon_neutral: 0.43,
    carbon_offset: 6.88,
    total_yield: 47.3,
    co2_reduction: 6.45,
    trees_planted: 0.3,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-04",
    TOTAL_PRODUCTION: 2.9,
    EFFICIENCY: 10.55,
    peak_hour_production: "",
    carbon_neutral: 0.145,
    carbon_offset: 2.32,
    total_yield: 15.95,
    co2_reduction: 2.18,
    trees_planted: 0.1,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-05",
    TOTAL_PRODUCTION: 2.9,
    EFFICIENCY: 10.55,
    peak_hour_production: "",
    carbon_neutral: 0.145,
    carbon_offset: 2.32,
    total_yield: 15.95,
    co2_reduction: 2.18,
    trees_planted: 0.1,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-06",
    TOTAL_PRODUCTION: 8.7,
    EFFICIENCY: 31.64,
    peak_hour_production: "",
    carbon_neutral: 0.435,
    carbon_offset: 6.96,
    total_yield: 47.85,
    co2_reduction: 6.53,
    trees_planted: 0.31,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-07",
    TOTAL_PRODUCTION: 14.6,
    EFFICIENCY: 53.09,
    peak_hour_production: "",
    carbon_neutral: 0.73,
    carbon_offset: 11.68,
    total_yield: 80.3,
    co2_reduction: 10.95,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-08",
    TOTAL_PRODUCTION: 8,
    EFFICIENCY: 29.09,
    peak_hour_production: "",
    carbon_neutral: 0.4,
    carbon_offset: 6.4,
    total_yield: 44,
    co2_reduction: 6,
    trees_planted: 0.28,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-09",
    TOTAL_PRODUCTION: 9,
    EFFICIENCY: 32.73,
    peak_hour_production: "",
    carbon_neutral: 0.45,
    carbon_offset: 7.2,
    total_yield: 49.5,
    co2_reduction: 6.75,
    trees_planted: 0.32,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-10",
    TOTAL_PRODUCTION: 8.2,
    EFFICIENCY: 29.82,
    peak_hour_production: "",
    carbon_neutral: 0.41,
    carbon_offset: 6.56,
    total_yield: 45.1,
    co2_reduction: 6.15,
    trees_planted: 0.29,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-11",
    TOTAL_PRODUCTION: 20.4,
    EFFICIENCY: 74.18,
    peak_hour_production: "",
    carbon_neutral: 1.02,
    carbon_offset: 16.32,
    total_yield: 112.2,
    co2_reduction: 15.3,
    trees_planted: 0.72,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-12",
    TOTAL_PRODUCTION: 14.4,
    EFFICIENCY: 52.36,
    peak_hour_production: "",
    carbon_neutral: 0.72,
    carbon_offset: 11.52,
    total_yield: 79.2,
    co2_reduction: 10.8,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-13",
    TOTAL_PRODUCTION: 19.1,
    EFFICIENCY: 69.45,
    peak_hour_production: "",
    carbon_neutral: 0.955,
    carbon_offset: 15.28,
    total_yield: 105.05,
    co2_reduction: 14.33,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-14",
    TOTAL_PRODUCTION: 8.7,
    EFFICIENCY: 31.64,
    peak_hour_production: "",
    carbon_neutral: 0.435,
    carbon_offset: 6.96,
    total_yield: 47.85,
    co2_reduction: 6.53,
    trees_planted: 0.31,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-15",
    TOTAL_PRODUCTION: 17.4,
    EFFICIENCY: 63.27,
    peak_hour_production: "",
    carbon_neutral: 0.87,
    carbon_offset: 13.92,
    total_yield: 95.7,
    co2_reduction: 13.05,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-16",
    TOTAL_PRODUCTION: 18.7,
    EFFICIENCY: 68,
    peak_hour_production: "",
    carbon_neutral: 0.935,
    carbon_offset: 14.96,
    total_yield: 102.85,
    co2_reduction: 14.03,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-17",
    TOTAL_PRODUCTION: 20.4,
    EFFICIENCY: 74.18,
    peak_hour_production: "",
    carbon_neutral: 1.02,
    carbon_offset: 16.32,
    total_yield: 112.2,
    co2_reduction: 15.3,
    trees_planted: 0.72,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-18",
    TOTAL_PRODUCTION: 21.6,
    EFFICIENCY: 78.55,
    peak_hour_production: "",
    carbon_neutral: 1.08,
    carbon_offset: 17.28,
    total_yield: 118.8,
    co2_reduction: 16.2,
    trees_planted: 0.76,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-19",
    TOTAL_PRODUCTION: 21.1,
    EFFICIENCY: 76.73,
    peak_hour_production: "",
    carbon_neutral: 1.055,
    carbon_offset: 16.88,
    total_yield: 116.05,
    co2_reduction: 15.83,
    trees_planted: 0.74,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-20",
    TOTAL_PRODUCTION: 11.1,
    EFFICIENCY: 40.36,
    peak_hour_production: "",
    carbon_neutral: 0.555,
    carbon_offset: 8.88,
    total_yield: 61.05,
    co2_reduction: 8.33,
    trees_planted: 0.39,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-21",
    TOTAL_PRODUCTION: 9.5,
    EFFICIENCY: 34.55,
    peak_hour_production: "",
    carbon_neutral: 0.475,
    carbon_offset: 7.6,
    total_yield: 52.25,
    co2_reduction: 7.13,
    trees_planted: 0.33,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-22",
    TOTAL_PRODUCTION: 8,
    EFFICIENCY: 29.09,
    peak_hour_production: "",
    carbon_neutral: 0.4,
    carbon_offset: 6.4,
    total_yield: 44,
    co2_reduction: 6,
    trees_planted: 0.28,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-23",
    TOTAL_PRODUCTION: 15.6,
    EFFICIENCY: 56.73,
    peak_hour_production: "",
    carbon_neutral: 0.78,
    carbon_offset: 12.48,
    total_yield: 85.8,
    co2_reduction: 11.7,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-24",
    TOTAL_PRODUCTION: 13.9,
    EFFICIENCY: 50.55,
    peak_hour_production: "",
    carbon_neutral: 0.695,
    carbon_offset: 11.12,
    total_yield: 76.45,
    co2_reduction: 10.43,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-25",
    TOTAL_PRODUCTION: 7.7,
    EFFICIENCY: 28,
    peak_hour_production: "",
    carbon_neutral: 0.385,
    carbon_offset: 6.16,
    total_yield: 42.35,
    co2_reduction: 5.78,
    trees_planted: 0.27,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-26",
    TOTAL_PRODUCTION: 17.3,
    EFFICIENCY: 62.91,
    peak_hour_production: "",
    carbon_neutral: 0.865,
    carbon_offset: 13.84,
    total_yield: 95.15,
    co2_reduction: 12.98,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-27",
    TOTAL_PRODUCTION: 15.5,
    EFFICIENCY: 56.36,
    peak_hour_production: "",
    carbon_neutral: 0.775,
    carbon_offset: 12.4,
    total_yield: 85.25,
    co2_reduction: 11.63,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-28",
    TOTAL_PRODUCTION: 17.6,
    EFFICIENCY: 64,
    peak_hour_production: "",
    carbon_neutral: 0.88,
    carbon_offset: 14.08,
    total_yield: 96.8,
    co2_reduction: 13.2,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-29",
    TOTAL_PRODUCTION: 15.9,
    EFFICIENCY: 57.82,
    peak_hour_production: "",
    carbon_neutral: 0.795,
    carbon_offset: 12.72,
    total_yield: 87.45,
    co2_reduction: 11.93,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-09-30",
    TOTAL_PRODUCTION: 18.5,
    EFFICIENCY: 67.27,
    peak_hour_production: "",
    carbon_neutral: 0.925,
    carbon_offset: 14.8,
    total_yield: 101.75,
    co2_reduction: 13.88,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-01",
    TOTAL_PRODUCTION: 16.1,
    EFFICIENCY: 58.55,
    peak_hour_production: "",
    carbon_neutral: 0.805,
    carbon_offset: 12.88,
    total_yield: 88.55,
    co2_reduction: 12.08,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-02",
    TOTAL_PRODUCTION: 11.3,
    EFFICIENCY: 41.09,
    peak_hour_production: "",
    carbon_neutral: 0.565,
    carbon_offset: 9.04,
    total_yield: 62.15,
    co2_reduction: 8.48,
    trees_planted: 0.4,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-03",
    TOTAL_PRODUCTION: 17.8,
    EFFICIENCY: 64.73,
    peak_hour_production: "",
    carbon_neutral: 0.89,
    carbon_offset: 14.24,
    total_yield: 97.9,
    co2_reduction: 13.35,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-04",
    TOTAL_PRODUCTION: 20.5,
    EFFICIENCY: 74.55,
    peak_hour_production: "",
    carbon_neutral: 1.025,
    carbon_offset: 16.4,
    total_yield: 112.75,
    co2_reduction: 15.38,
    trees_planted: 0.72,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-05",
    TOTAL_PRODUCTION: 20.6,
    EFFICIENCY: 74.91,
    peak_hour_production: "",
    carbon_neutral: 1.03,
    carbon_offset: 16.48,
    total_yield: 113.3,
    co2_reduction: 15.45,
    trees_planted: 0.73,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-06",
    TOTAL_PRODUCTION: 21.1,
    EFFICIENCY: 76.73,
    peak_hour_production: "",
    carbon_neutral: 1.055,
    carbon_offset: 16.88,
    total_yield: 116.05,
    co2_reduction: 15.83,
    trees_planted: 0.74,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-07",
    TOTAL_PRODUCTION: 20.7,
    EFFICIENCY: 75.27,
    peak_hour_production: "",
    carbon_neutral: 1.035,
    carbon_offset: 16.56,
    total_yield: 113.85,
    co2_reduction: 15.53,
    trees_planted: 0.73,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-08",
    TOTAL_PRODUCTION: 19.8,
    EFFICIENCY: 72,
    peak_hour_production: "",
    carbon_neutral: 0.99,
    carbon_offset: 15.84,
    total_yield: 108.9,
    co2_reduction: 14.85,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-09",
    TOTAL_PRODUCTION: 19.7,
    EFFICIENCY: 71.64,
    peak_hour_production: "",
    carbon_neutral: 0.985,
    carbon_offset: 15.76,
    total_yield: 108.35,
    co2_reduction: 14.78,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-10",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-11",
    TOTAL_PRODUCTION: 17.2,
    EFFICIENCY: 62.55,
    peak_hour_production: "",
    carbon_neutral: 0.86,
    carbon_offset: 13.76,
    total_yield: 94.6,
    co2_reduction: 12.9,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-12",
    TOTAL_PRODUCTION: 17.9,
    EFFICIENCY: 65.09,
    peak_hour_production: "",
    carbon_neutral: 0.895,
    carbon_offset: 14.32,
    total_yield: 98.45,
    co2_reduction: 13.43,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-13",
    TOTAL_PRODUCTION: 18.1,
    EFFICIENCY: 65.82,
    peak_hour_production: "",
    carbon_neutral: 0.905,
    carbon_offset: 14.48,
    total_yield: 99.55,
    co2_reduction: 13.58,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-14",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-15",
    TOTAL_PRODUCTION: 17.1,
    EFFICIENCY: 62.18,
    peak_hour_production: "",
    carbon_neutral: 0.855,
    carbon_offset: 13.68,
    total_yield: 94.05,
    co2_reduction: 12.83,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-16",
    TOTAL_PRODUCTION: 16.2,
    EFFICIENCY: 58.91,
    peak_hour_production: "",
    carbon_neutral: 0.81,
    carbon_offset: 12.96,
    total_yield: 89.1,
    co2_reduction: 12.15,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-17",
    TOTAL_PRODUCTION: 17.5,
    EFFICIENCY: 63.64,
    peak_hour_production: "",
    carbon_neutral: 0.875,
    carbon_offset: 14,
    total_yield: 96.25,
    co2_reduction: 13.13,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-18",
    TOTAL_PRODUCTION: 19.4,
    EFFICIENCY: 70.55,
    peak_hour_production: "",
    carbon_neutral: 0.97,
    carbon_offset: 15.52,
    total_yield: 106.7,
    co2_reduction: 14.55,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-19",
    TOTAL_PRODUCTION: 18.2,
    EFFICIENCY: 66.18,
    peak_hour_production: "",
    carbon_neutral: 0.91,
    carbon_offset: 14.56,
    total_yield: 100.1,
    co2_reduction: 13.65,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-20",
    TOTAL_PRODUCTION: 17.4,
    EFFICIENCY: 63.27,
    peak_hour_production: "",
    carbon_neutral: 0.87,
    carbon_offset: 13.92,
    total_yield: 95.7,
    co2_reduction: 13.05,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-21",
    TOTAL_PRODUCTION: 17.5,
    EFFICIENCY: 63.64,
    peak_hour_production: "",
    carbon_neutral: 0.875,
    carbon_offset: 14,
    total_yield: 96.25,
    co2_reduction: 13.13,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-22",
    TOTAL_PRODUCTION: 19.8,
    EFFICIENCY: 72,
    peak_hour_production: "",
    carbon_neutral: 0.99,
    carbon_offset: 15.84,
    total_yield: 108.9,
    co2_reduction: 14.85,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-23",
    TOTAL_PRODUCTION: 20,
    EFFICIENCY: 72.73,
    peak_hour_production: "",
    carbon_neutral: 1,
    carbon_offset: 16,
    total_yield: 110,
    co2_reduction: 15,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-24",
    TOTAL_PRODUCTION: 19.9,
    EFFICIENCY: 72.36,
    peak_hour_production: "",
    carbon_neutral: 0.995,
    carbon_offset: 15.92,
    total_yield: 109.45,
    co2_reduction: 14.93,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-25",
    TOTAL_PRODUCTION: 20.5,
    EFFICIENCY: 74.55,
    peak_hour_production: "",
    carbon_neutral: 1.025,
    carbon_offset: 16.4,
    total_yield: 112.75,
    co2_reduction: 15.38,
    trees_planted: 0.72,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-26",
    TOTAL_PRODUCTION: 20.2,
    EFFICIENCY: 73.45,
    peak_hour_production: "",
    carbon_neutral: 1.01,
    carbon_offset: 16.16,
    total_yield: 111.1,
    co2_reduction: 15.15,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-27",
    TOTAL_PRODUCTION: 19.6,
    EFFICIENCY: 71.27,
    peak_hour_production: "",
    carbon_neutral: 0.98,
    carbon_offset: 15.68,
    total_yield: 107.8,
    co2_reduction: 14.7,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-28",
    TOTAL_PRODUCTION: 18.6,
    EFFICIENCY: 67.64,
    peak_hour_production: "",
    carbon_neutral: 0.93,
    carbon_offset: 14.88,
    total_yield: 102.3,
    co2_reduction: 13.95,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-29",
    TOTAL_PRODUCTION: 13.1,
    EFFICIENCY: 47.64,
    peak_hour_production: "",
    carbon_neutral: 0.655,
    carbon_offset: 10.48,
    total_yield: 72.05,
    co2_reduction: 9.83,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-30",
    TOTAL_PRODUCTION: 13.8,
    EFFICIENCY: 50.18,
    peak_hour_production: "",
    carbon_neutral: 0.69,
    carbon_offset: 11.04,
    total_yield: 75.9,
    co2_reduction: 10.35,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-10-31",
    TOTAL_PRODUCTION: 13.5,
    EFFICIENCY: 49.09,
    peak_hour_production: "",
    carbon_neutral: 0.675,
    carbon_offset: 10.8,
    total_yield: 74.25,
    co2_reduction: 10.13,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-01",
    TOTAL_PRODUCTION: 13.2,
    EFFICIENCY: 48,
    peak_hour_production: "",
    carbon_neutral: 0.66,
    carbon_offset: 10.56,
    total_yield: 72.6,
    co2_reduction: 9.9,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-02",
    TOTAL_PRODUCTION: 11.6,
    EFFICIENCY: 42.18,
    peak_hour_production: "",
    carbon_neutral: 0.58,
    carbon_offset: 9.28,
    total_yield: 63.8,
    co2_reduction: 8.7,
    trees_planted: 0.41,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-03",
    TOTAL_PRODUCTION: 11.3,
    EFFICIENCY: 41.09,
    peak_hour_production: "",
    carbon_neutral: 0.565,
    carbon_offset: 9.04,
    total_yield: 62.15,
    co2_reduction: 8.48,
    trees_planted: 0.4,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-04",
    TOTAL_PRODUCTION: 11.2,
    EFFICIENCY: 40.73,
    peak_hour_production: "",
    carbon_neutral: 0.56,
    carbon_offset: 8.96,
    total_yield: 61.6,
    co2_reduction: 8.4,
    trees_planted: 0.39,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-05",
    TOTAL_PRODUCTION: 11.8,
    EFFICIENCY: 42.91,
    peak_hour_production: "",
    carbon_neutral: 0.59,
    carbon_offset: 9.44,
    total_yield: 64.9,
    co2_reduction: 8.85,
    trees_planted: 0.42,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-06",
    TOTAL_PRODUCTION: 10.3,
    EFFICIENCY: 37.45,
    peak_hour_production: "",
    carbon_neutral: 0.515,
    carbon_offset: 8.24,
    total_yield: 56.65,
    co2_reduction: 7.73,
    trees_planted: 0.36,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-07",
    TOTAL_PRODUCTION: 9.4,
    EFFICIENCY: 34.18,
    peak_hour_production: "",
    carbon_neutral: 0.47,
    carbon_offset: 7.52,
    total_yield: 51.7,
    co2_reduction: 7.05,
    trees_planted: 0.33,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-08",
    TOTAL_PRODUCTION: 13,
    EFFICIENCY: 47.27,
    peak_hour_production: "",
    carbon_neutral: 0.65,
    carbon_offset: 10.4,
    total_yield: 71.5,
    co2_reduction: 9.75,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-09",
    TOTAL_PRODUCTION: 14.1,
    EFFICIENCY: 51.27,
    peak_hour_production: "",
    carbon_neutral: 0.705,
    carbon_offset: 11.28,
    total_yield: 77.55,
    co2_reduction: 10.58,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-10",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-11",
    TOTAL_PRODUCTION: 18.6,
    EFFICIENCY: 67.64,
    peak_hour_production: "",
    carbon_neutral: 0.93,
    carbon_offset: 14.88,
    total_yield: 102.3,
    co2_reduction: 13.95,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-12",
    TOTAL_PRODUCTION: 18.1,
    EFFICIENCY: 65.82,
    peak_hour_production: "",
    carbon_neutral: 0.905,
    carbon_offset: 14.48,
    total_yield: 99.55,
    co2_reduction: 13.58,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-13",
    TOTAL_PRODUCTION: 14.4,
    EFFICIENCY: 52.36,
    peak_hour_production: "",
    carbon_neutral: 0.72,
    carbon_offset: 11.52,
    total_yield: 79.2,
    co2_reduction: 10.8,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-14",
    TOTAL_PRODUCTION: 14.6,
    EFFICIENCY: 53.09,
    peak_hour_production: "",
    carbon_neutral: 0.73,
    carbon_offset: 11.68,
    total_yield: 80.3,
    co2_reduction: 10.95,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-15",
    TOTAL_PRODUCTION: 17.5,
    EFFICIENCY: 63.64,
    peak_hour_production: "",
    carbon_neutral: 0.875,
    carbon_offset: 14,
    total_yield: 96.25,
    co2_reduction: 13.13,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-16",
    TOTAL_PRODUCTION: 18.4,
    EFFICIENCY: 66.91,
    peak_hour_production: "",
    carbon_neutral: 0.92,
    carbon_offset: 14.72,
    total_yield: 101.2,
    co2_reduction: 13.8,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-17",
    TOTAL_PRODUCTION: 17.6,
    EFFICIENCY: 64,
    peak_hour_production: "",
    carbon_neutral: 0.88,
    carbon_offset: 14.08,
    total_yield: 96.8,
    co2_reduction: 13.2,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-18",
    TOTAL_PRODUCTION: 17.5,
    EFFICIENCY: 63.64,
    peak_hour_production: "",
    carbon_neutral: 0.875,
    carbon_offset: 14,
    total_yield: 96.25,
    co2_reduction: 13.13,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-19",
    TOTAL_PRODUCTION: 14.7,
    EFFICIENCY: 53.45,
    peak_hour_production: "",
    carbon_neutral: 0.735,
    carbon_offset: 11.76,
    total_yield: 80.85,
    co2_reduction: 11.03,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-20",
    TOTAL_PRODUCTION: 16,
    EFFICIENCY: 58.18,
    peak_hour_production: "",
    carbon_neutral: 0.8,
    carbon_offset: 12.8,
    total_yield: 88,
    co2_reduction: 12,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-21",
    TOTAL_PRODUCTION: 8.2,
    EFFICIENCY: 29.82,
    peak_hour_production: "",
    carbon_neutral: 0.41,
    carbon_offset: 6.56,
    total_yield: 45.1,
    co2_reduction: 6.15,
    trees_planted: 0.29,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-22",
    TOTAL_PRODUCTION: 16.3,
    EFFICIENCY: 59.27,
    peak_hour_production: "",
    carbon_neutral: 0.815,
    carbon_offset: 13.04,
    total_yield: 89.65,
    co2_reduction: 12.23,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-23",
    TOTAL_PRODUCTION: 3.1,
    EFFICIENCY: 11.27,
    peak_hour_production: "",
    carbon_neutral: 0.155,
    carbon_offset: 2.48,
    total_yield: 17.05,
    co2_reduction: 2.33,
    trees_planted: 0.11,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-24",
    TOTAL_PRODUCTION: 4.7,
    EFFICIENCY: 17.09,
    peak_hour_production: "",
    carbon_neutral: 0.235,
    carbon_offset: 3.76,
    total_yield: 25.85,
    co2_reduction: 3.53,
    trees_planted: 0.17,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-25",
    TOTAL_PRODUCTION: 13,
    EFFICIENCY: 47.27,
    peak_hour_production: "",
    carbon_neutral: 0.65,
    carbon_offset: 10.4,
    total_yield: 71.5,
    co2_reduction: 9.75,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-26",
    TOTAL_PRODUCTION: 10.9,
    EFFICIENCY: 39.64,
    peak_hour_production: "",
    carbon_neutral: 0.545,
    carbon_offset: 8.72,
    total_yield: 59.95,
    co2_reduction: 8.18,
    trees_planted: 0.38,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-27",
    TOTAL_PRODUCTION: 10.4,
    EFFICIENCY: 37.82,
    peak_hour_production: "",
    carbon_neutral: 0.52,
    carbon_offset: 8.32,
    total_yield: 57.2,
    co2_reduction: 7.8,
    trees_planted: 0.37,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-28",
    TOTAL_PRODUCTION: 15.1,
    EFFICIENCY: 54.91,
    peak_hour_production: "",
    carbon_neutral: 0.755,
    carbon_offset: 12.08,
    total_yield: 83.05,
    co2_reduction: 11.33,
    trees_planted: 0.53,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-29",
    TOTAL_PRODUCTION: 14.9,
    EFFICIENCY: 54.18,
    peak_hour_production: "",
    carbon_neutral: 0.745,
    carbon_offset: 11.92,
    total_yield: 81.95,
    co2_reduction: 11.18,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-11-30",
    TOTAL_PRODUCTION: 13,
    EFFICIENCY: 47.27,
    peak_hour_production: "",
    carbon_neutral: 0.65,
    carbon_offset: 10.4,
    total_yield: 71.5,
    co2_reduction: 9.75,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-01",
    TOTAL_PRODUCTION: 18.9,
    EFFICIENCY: 68.73,
    peak_hour_production: "",
    carbon_neutral: 0.945,
    carbon_offset: 15.12,
    total_yield: 103.95,
    co2_reduction: 14.18,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-02",
    TOTAL_PRODUCTION: 17.8,
    EFFICIENCY: 64.73,
    peak_hour_production: "",
    carbon_neutral: 0.89,
    carbon_offset: 14.24,
    total_yield: 97.9,
    co2_reduction: 13.35,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-03",
    TOTAL_PRODUCTION: 12.7,
    EFFICIENCY: 46.18,
    peak_hour_production: "",
    carbon_neutral: 0.635,
    carbon_offset: 10.16,
    total_yield: 69.85,
    co2_reduction: 9.53,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-04",
    TOTAL_PRODUCTION: 3.8,
    EFFICIENCY: 13.82,
    peak_hour_production: "",
    carbon_neutral: 0.19,
    carbon_offset: 3.04,
    total_yield: 20.9,
    co2_reduction: 2.85,
    trees_planted: 0.13,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-05",
    TOTAL_PRODUCTION: 4.4,
    EFFICIENCY: 16,
    peak_hour_production: "",
    carbon_neutral: 0.22,
    carbon_offset: 3.52,
    total_yield: 24.2,
    co2_reduction: 3.3,
    trees_planted: 0.15,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-06",
    TOTAL_PRODUCTION: 8.7,
    EFFICIENCY: 31.64,
    peak_hour_production: "",
    carbon_neutral: 0.435,
    carbon_offset: 6.96,
    total_yield: 47.85,
    co2_reduction: 6.53,
    trees_planted: 0.31,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-07",
    TOTAL_PRODUCTION: 5.6,
    EFFICIENCY: 20.36,
    peak_hour_production: "",
    carbon_neutral: 0.28,
    carbon_offset: 4.48,
    total_yield: 30.8,
    co2_reduction: 4.2,
    trees_planted: 0.2,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-08",
    TOTAL_PRODUCTION: 16.3,
    EFFICIENCY: 59.27,
    peak_hour_production: "",
    carbon_neutral: 0.815,
    carbon_offset: 13.04,
    total_yield: 89.65,
    co2_reduction: 12.23,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-09",
    TOTAL_PRODUCTION: 17.2,
    EFFICIENCY: 62.55,
    peak_hour_production: "",
    carbon_neutral: 0.86,
    carbon_offset: 13.76,
    total_yield: 94.6,
    co2_reduction: 12.9,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-10",
    TOTAL_PRODUCTION: 16.3,
    EFFICIENCY: 59.27,
    peak_hour_production: "",
    carbon_neutral: 0.815,
    carbon_offset: 13.04,
    total_yield: 89.65,
    co2_reduction: 12.23,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-11",
    TOTAL_PRODUCTION: 17,
    EFFICIENCY: 61.82,
    peak_hour_production: "",
    carbon_neutral: 0.85,
    carbon_offset: 13.6,
    total_yield: 93.5,
    co2_reduction: 12.75,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-12",
    TOTAL_PRODUCTION: 17.7,
    EFFICIENCY: 64.36,
    peak_hour_production: "",
    carbon_neutral: 0.885,
    carbon_offset: 14.16,
    total_yield: 97.35,
    co2_reduction: 13.28,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-13",
    TOTAL_PRODUCTION: 13.9,
    EFFICIENCY: 50.55,
    peak_hour_production: "",
    carbon_neutral: 0.695,
    carbon_offset: 11.12,
    total_yield: 76.45,
    co2_reduction: 10.43,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-14",
    TOTAL_PRODUCTION: 16.2,
    EFFICIENCY: 58.91,
    peak_hour_production: "",
    carbon_neutral: 0.81,
    carbon_offset: 12.96,
    total_yield: 89.1,
    co2_reduction: 12.15,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-15",
    TOTAL_PRODUCTION: 15.8,
    EFFICIENCY: 57.45,
    peak_hour_production: "",
    carbon_neutral: 0.79,
    carbon_offset: 12.64,
    total_yield: 86.9,
    co2_reduction: 11.85,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-16",
    TOTAL_PRODUCTION: 8.8,
    EFFICIENCY: 32,
    peak_hour_production: "",
    carbon_neutral: 0.44,
    carbon_offset: 7.04,
    total_yield: 48.4,
    co2_reduction: 6.6,
    trees_planted: 0.31,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-17",
    TOTAL_PRODUCTION: 13.6,
    EFFICIENCY: 49.45,
    peak_hour_production: "",
    carbon_neutral: 0.68,
    carbon_offset: 10.88,
    total_yield: 74.8,
    co2_reduction: 10.2,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-18",
    TOTAL_PRODUCTION: 13.4,
    EFFICIENCY: 48.73,
    peak_hour_production: "",
    carbon_neutral: 0.67,
    carbon_offset: 10.72,
    total_yield: 73.7,
    co2_reduction: 10.05,
    trees_planted: 0.47,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-19",
    TOTAL_PRODUCTION: 14.5,
    EFFICIENCY: 52.73,
    peak_hour_production: "",
    carbon_neutral: 0.725,
    carbon_offset: 11.6,
    total_yield: 79.75,
    co2_reduction: 10.88,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-20",
    TOTAL_PRODUCTION: 15,
    EFFICIENCY: 54.55,
    peak_hour_production: "",
    carbon_neutral: 0.75,
    carbon_offset: 12,
    total_yield: 82.5,
    co2_reduction: 11.25,
    trees_planted: 0.53,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-21",
    TOTAL_PRODUCTION: 17.1,
    EFFICIENCY: 62.18,
    peak_hour_production: "",
    carbon_neutral: 0.855,
    carbon_offset: 13.68,
    total_yield: 94.05,
    co2_reduction: 12.83,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-22",
    TOTAL_PRODUCTION: 17.1,
    EFFICIENCY: 62.18,
    peak_hour_production: "",
    carbon_neutral: 0.855,
    carbon_offset: 13.68,
    total_yield: 94.05,
    co2_reduction: 12.83,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-23",
    TOTAL_PRODUCTION: 14.9,
    EFFICIENCY: 54.18,
    peak_hour_production: "",
    carbon_neutral: 0.745,
    carbon_offset: 11.92,
    total_yield: 81.95,
    co2_reduction: 11.18,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-24",
    TOTAL_PRODUCTION: 15.4,
    EFFICIENCY: 56,
    peak_hour_production: "",
    carbon_neutral: 0.77,
    carbon_offset: 12.32,
    total_yield: 84.7,
    co2_reduction: 11.55,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-25",
    TOTAL_PRODUCTION: 15.5,
    EFFICIENCY: 56.36,
    peak_hour_production: "",
    carbon_neutral: 0.775,
    carbon_offset: 12.4,
    total_yield: 85.25,
    co2_reduction: 11.63,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-26",
    TOTAL_PRODUCTION: 16.2,
    EFFICIENCY: 58.91,
    peak_hour_production: "",
    carbon_neutral: 0.81,
    carbon_offset: 12.96,
    total_yield: 89.1,
    co2_reduction: 12.15,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-27",
    TOTAL_PRODUCTION: 15.4,
    EFFICIENCY: 56,
    peak_hour_production: "",
    carbon_neutral: 0.77,
    carbon_offset: 12.32,
    total_yield: 84.7,
    co2_reduction: 11.55,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-28",
    TOTAL_PRODUCTION: 15.5,
    EFFICIENCY: 56.36,
    peak_hour_production: "",
    carbon_neutral: 0.775,
    carbon_offset: 12.4,
    total_yield: 85.25,
    co2_reduction: 11.63,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-29",
    TOTAL_PRODUCTION: 14.4,
    EFFICIENCY: 52.36,
    peak_hour_production: "",
    carbon_neutral: 0.72,
    carbon_offset: 11.52,
    total_yield: 79.2,
    co2_reduction: 10.8,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-30",
    TOTAL_PRODUCTION: 14.8,
    EFFICIENCY: 53.82,
    peak_hour_production: "",
    carbon_neutral: 0.74,
    carbon_offset: 11.84,
    total_yield: 81.4,
    co2_reduction: 11.1,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2023-12-31",
    TOTAL_PRODUCTION: 16,
    EFFICIENCY: 58.18,
    peak_hour_production: "",
    carbon_neutral: 0.8,
    carbon_offset: 12.8,
    total_yield: 88,
    co2_reduction: 12,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-01",
    TOTAL_PRODUCTION: 15.6,
    EFFICIENCY: 56.73,
    peak_hour_production: "",
    carbon_neutral: 0.78,
    carbon_offset: 12.48,
    total_yield: 85.8,
    co2_reduction: 11.7,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-02",
    TOTAL_PRODUCTION: 13.2,
    EFFICIENCY: 48,
    peak_hour_production: "",
    carbon_neutral: 0.66,
    carbon_offset: 10.56,
    total_yield: 72.6,
    co2_reduction: 9.9,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-03",
    TOTAL_PRODUCTION: 13.5,
    EFFICIENCY: 49.09,
    peak_hour_production: "",
    carbon_neutral: 0.675,
    carbon_offset: 10.8,
    total_yield: 74.25,
    co2_reduction: 10.13,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-04",
    TOTAL_PRODUCTION: 12.9,
    EFFICIENCY: 46.91,
    peak_hour_production: "",
    carbon_neutral: 0.645,
    carbon_offset: 10.32,
    total_yield: 70.95,
    co2_reduction: 9.68,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-05",
    TOTAL_PRODUCTION: 14.4,
    EFFICIENCY: 52.36,
    peak_hour_production: "",
    carbon_neutral: 0.72,
    carbon_offset: 11.52,
    total_yield: 79.2,
    co2_reduction: 10.8,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-06",
    TOTAL_PRODUCTION: 14.9,
    EFFICIENCY: 54.18,
    peak_hour_production: "",
    carbon_neutral: 0.745,
    carbon_offset: 11.92,
    total_yield: 81.95,
    co2_reduction: 11.18,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-07",
    TOTAL_PRODUCTION: 13.1,
    EFFICIENCY: 47.64,
    peak_hour_production: "",
    carbon_neutral: 0.655,
    carbon_offset: 10.48,
    total_yield: 72.05,
    co2_reduction: 9.83,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-08",
    TOTAL_PRODUCTION: 14.4,
    EFFICIENCY: 52.36,
    peak_hour_production: "",
    carbon_neutral: 0.72,
    carbon_offset: 11.52,
    total_yield: 79.2,
    co2_reduction: 10.8,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-09",
    TOTAL_PRODUCTION: 9.3,
    EFFICIENCY: 33.82,
    peak_hour_production: "",
    carbon_neutral: 0.465,
    carbon_offset: 7.44,
    total_yield: 51.15,
    co2_reduction: 6.98,
    trees_planted: 0.33,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-10",
    TOTAL_PRODUCTION: 7.3,
    EFFICIENCY: 26.55,
    peak_hour_production: "",
    carbon_neutral: 0.365,
    carbon_offset: 5.84,
    total_yield: 40.15,
    co2_reduction: 5.48,
    trees_planted: 0.26,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-11",
    TOTAL_PRODUCTION: 14.6,
    EFFICIENCY: 53.09,
    peak_hour_production: "",
    carbon_neutral: 0.73,
    carbon_offset: 11.68,
    total_yield: 80.3,
    co2_reduction: 10.95,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-12",
    TOTAL_PRODUCTION: 14.1,
    EFFICIENCY: 51.27,
    peak_hour_production: "",
    carbon_neutral: 0.705,
    carbon_offset: 11.28,
    total_yield: 77.55,
    co2_reduction: 10.58,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-13",
    TOTAL_PRODUCTION: 7.5,
    EFFICIENCY: 27.27,
    peak_hour_production: "",
    carbon_neutral: 0.375,
    carbon_offset: 6,
    total_yield: 41.25,
    co2_reduction: 5.63,
    trees_planted: 0.26,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-14",
    TOTAL_PRODUCTION: 14.7,
    EFFICIENCY: 53.45,
    peak_hour_production: "",
    carbon_neutral: 0.735,
    carbon_offset: 11.76,
    total_yield: 80.85,
    co2_reduction: 11.03,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-15",
    TOTAL_PRODUCTION: 13.7,
    EFFICIENCY: 49.82,
    peak_hour_production: "",
    carbon_neutral: 0.685,
    carbon_offset: 10.96,
    total_yield: 75.35,
    co2_reduction: 10.28,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-16",
    TOTAL_PRODUCTION: 13.2,
    EFFICIENCY: 48,
    peak_hour_production: "",
    carbon_neutral: 0.66,
    carbon_offset: 10.56,
    total_yield: 72.6,
    co2_reduction: 9.9,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-17",
    TOTAL_PRODUCTION: 12.4,
    EFFICIENCY: 45.09,
    peak_hour_production: "",
    carbon_neutral: 0.62,
    carbon_offset: 9.92,
    total_yield: 68.2,
    co2_reduction: 9.3,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-18",
    TOTAL_PRODUCTION: 11.3,
    EFFICIENCY: 41.09,
    peak_hour_production: "",
    carbon_neutral: 0.565,
    carbon_offset: 9.04,
    total_yield: 62.15,
    co2_reduction: 8.48,
    trees_planted: 0.4,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-19",
    TOTAL_PRODUCTION: 12.5,
    EFFICIENCY: 45.45,
    peak_hour_production: "",
    carbon_neutral: 0.625,
    carbon_offset: 10,
    total_yield: 68.75,
    co2_reduction: 9.38,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-20",
    TOTAL_PRODUCTION: 12.2,
    EFFICIENCY: 44.36,
    peak_hour_production: "",
    carbon_neutral: 0.61,
    carbon_offset: 9.76,
    total_yield: 67.1,
    co2_reduction: 9.15,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-21",
    TOTAL_PRODUCTION: 12.1,
    EFFICIENCY: 44,
    peak_hour_production: "",
    carbon_neutral: 0.605,
    carbon_offset: 9.68,
    total_yield: 66.55,
    co2_reduction: 9.08,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-22",
    TOTAL_PRODUCTION: 11.9,
    EFFICIENCY: 43.27,
    peak_hour_production: "",
    carbon_neutral: 0.595,
    carbon_offset: 9.52,
    total_yield: 65.45,
    co2_reduction: 8.93,
    trees_planted: 0.42,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-23",
    TOTAL_PRODUCTION: 12.9,
    EFFICIENCY: 46.91,
    peak_hour_production: "",
    carbon_neutral: 0.645,
    carbon_offset: 10.32,
    total_yield: 70.95,
    co2_reduction: 9.68,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-24",
    TOTAL_PRODUCTION: 11.2,
    EFFICIENCY: 40.73,
    peak_hour_production: "",
    carbon_neutral: 0.56,
    carbon_offset: 8.96,
    total_yield: 61.6,
    co2_reduction: 8.4,
    trees_planted: 0.39,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-25",
    TOTAL_PRODUCTION: 11.2,
    EFFICIENCY: 40.73,
    peak_hour_production: "",
    carbon_neutral: 0.56,
    carbon_offset: 8.96,
    total_yield: 61.6,
    co2_reduction: 8.4,
    trees_planted: 0.39,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-26",
    TOTAL_PRODUCTION: 12.2,
    EFFICIENCY: 44.36,
    peak_hour_production: "",
    carbon_neutral: 0.61,
    carbon_offset: 9.76,
    total_yield: 67.1,
    co2_reduction: 9.15,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-27",
    TOTAL_PRODUCTION: 10.5,
    EFFICIENCY: 38.18,
    peak_hour_production: "",
    carbon_neutral: 0.525,
    carbon_offset: 8.4,
    total_yield: 57.75,
    co2_reduction: 7.88,
    trees_planted: 0.37,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-28",
    TOTAL_PRODUCTION: 12.1,
    EFFICIENCY: 44,
    peak_hour_production: "",
    carbon_neutral: 0.605,
    carbon_offset: 9.68,
    total_yield: 66.55,
    co2_reduction: 9.08,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-29",
    TOTAL_PRODUCTION: 12.1,
    EFFICIENCY: 44,
    peak_hour_production: "",
    carbon_neutral: 0.605,
    carbon_offset: 9.68,
    total_yield: 66.55,
    co2_reduction: 9.08,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-30",
    TOTAL_PRODUCTION: 13.4,
    EFFICIENCY: 48.73,
    peak_hour_production: "",
    carbon_neutral: 0.67,
    carbon_offset: 10.72,
    total_yield: 73.7,
    co2_reduction: 10.05,
    trees_planted: 0.47,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-01-31",
    TOTAL_PRODUCTION: 11.5,
    EFFICIENCY: 41.82,
    peak_hour_production: "",
    carbon_neutral: 0.575,
    carbon_offset: 9.2,
    total_yield: 63.25,
    co2_reduction: 8.63,
    trees_planted: 0.41,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-01",
    TOTAL_PRODUCTION: 12.9,
    EFFICIENCY: 46.91,
    peak_hour_production: "",
    carbon_neutral: 0.645,
    carbon_offset: 10.32,
    total_yield: 70.95,
    co2_reduction: 9.68,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-02",
    TOTAL_PRODUCTION: 10.5,
    EFFICIENCY: 38.18,
    peak_hour_production: "",
    carbon_neutral: 0.525,
    carbon_offset: 8.4,
    total_yield: 57.75,
    co2_reduction: 7.88,
    trees_planted: 0.37,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-03",
    TOTAL_PRODUCTION: 12.1,
    EFFICIENCY: 44,
    peak_hour_production: "",
    carbon_neutral: 0.605,
    carbon_offset: 9.68,
    total_yield: 66.55,
    co2_reduction: 9.08,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-04",
    TOTAL_PRODUCTION: 12.8,
    EFFICIENCY: 46.55,
    peak_hour_production: "",
    carbon_neutral: 0.64,
    carbon_offset: 10.24,
    total_yield: 70.4,
    co2_reduction: 9.6,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-05",
    TOTAL_PRODUCTION: 11.4,
    EFFICIENCY: 41.45,
    peak_hour_production: "",
    carbon_neutral: 0.57,
    carbon_offset: 9.12,
    total_yield: 62.7,
    co2_reduction: 8.55,
    trees_planted: 0.4,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-06",
    TOTAL_PRODUCTION: 11.7,
    EFFICIENCY: 42.55,
    peak_hour_production: "",
    carbon_neutral: 0.585,
    carbon_offset: 9.36,
    total_yield: 64.35,
    co2_reduction: 8.78,
    trees_planted: 0.41,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-07",
    TOTAL_PRODUCTION: 11.8,
    EFFICIENCY: 42.91,
    peak_hour_production: "",
    carbon_neutral: 0.59,
    carbon_offset: 9.44,
    total_yield: 64.9,
    co2_reduction: 8.85,
    trees_planted: 0.42,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-08",
    TOTAL_PRODUCTION: 10.4,
    EFFICIENCY: 37.82,
    peak_hour_production: "",
    carbon_neutral: 0.52,
    carbon_offset: 8.32,
    total_yield: 57.2,
    co2_reduction: 7.8,
    trees_planted: 0.37,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-09",
    TOTAL_PRODUCTION: 12.6,
    EFFICIENCY: 45.82,
    peak_hour_production: "",
    carbon_neutral: 0.63,
    carbon_offset: 10.08,
    total_yield: 69.3,
    co2_reduction: 9.45,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-10",
    TOTAL_PRODUCTION: 10.3,
    EFFICIENCY: 37.45,
    peak_hour_production: "",
    carbon_neutral: 0.515,
    carbon_offset: 8.24,
    total_yield: 56.65,
    co2_reduction: 7.73,
    trees_planted: 0.36,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-11",
    TOTAL_PRODUCTION: 10.7,
    EFFICIENCY: 38.91,
    peak_hour_production: "",
    carbon_neutral: 0.535,
    carbon_offset: 8.56,
    total_yield: 58.85,
    co2_reduction: 8.03,
    trees_planted: 0.38,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-12",
    TOTAL_PRODUCTION: 12,
    EFFICIENCY: 43.64,
    peak_hour_production: "",
    carbon_neutral: 0.6,
    carbon_offset: 9.6,
    total_yield: 66,
    co2_reduction: 9,
    trees_planted: 0.42,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-13",
    TOTAL_PRODUCTION: 12.4,
    EFFICIENCY: 45.09,
    peak_hour_production: "",
    carbon_neutral: 0.62,
    carbon_offset: 9.92,
    total_yield: 68.2,
    co2_reduction: 9.3,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-14",
    TOTAL_PRODUCTION: 12.2,
    EFFICIENCY: 44.36,
    peak_hour_production: "",
    carbon_neutral: 0.61,
    carbon_offset: 9.76,
    total_yield: 67.1,
    co2_reduction: 9.15,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-15",
    TOTAL_PRODUCTION: 12.9,
    EFFICIENCY: 46.91,
    peak_hour_production: "",
    carbon_neutral: 0.645,
    carbon_offset: 10.32,
    total_yield: 70.95,
    co2_reduction: 9.68,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-16",
    TOTAL_PRODUCTION: 14,
    EFFICIENCY: 50.91,
    peak_hour_production: "",
    carbon_neutral: 0.7,
    carbon_offset: 11.2,
    total_yield: 77,
    co2_reduction: 10.5,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-17",
    TOTAL_PRODUCTION: 15.7,
    EFFICIENCY: 57.09,
    peak_hour_production: "",
    carbon_neutral: 0.785,
    carbon_offset: 12.56,
    total_yield: 86.35,
    co2_reduction: 11.78,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-18",
    TOTAL_PRODUCTION: 13.3,
    EFFICIENCY: 48.36,
    peak_hour_production: "",
    carbon_neutral: 0.665,
    carbon_offset: 10.64,
    total_yield: 73.15,
    co2_reduction: 9.98,
    trees_planted: 0.47,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-19",
    TOTAL_PRODUCTION: 13.1,
    EFFICIENCY: 47.64,
    peak_hour_production: "",
    carbon_neutral: 0.655,
    carbon_offset: 10.48,
    total_yield: 72.05,
    co2_reduction: 9.83,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-20",
    TOTAL_PRODUCTION: 13.9,
    EFFICIENCY: 50.55,
    peak_hour_production: "",
    carbon_neutral: 0.695,
    carbon_offset: 11.12,
    total_yield: 76.45,
    co2_reduction: 10.43,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-21",
    TOTAL_PRODUCTION: 11.6,
    EFFICIENCY: 42.18,
    peak_hour_production: "",
    carbon_neutral: 0.58,
    carbon_offset: 9.28,
    total_yield: 63.8,
    co2_reduction: 8.7,
    trees_planted: 0.41,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-22",
    TOTAL_PRODUCTION: 13.9,
    EFFICIENCY: 50.55,
    peak_hour_production: "",
    carbon_neutral: 0.695,
    carbon_offset: 11.12,
    total_yield: 76.45,
    co2_reduction: 10.43,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-23",
    TOTAL_PRODUCTION: 13.5,
    EFFICIENCY: 49.09,
    peak_hour_production: "",
    carbon_neutral: 0.675,
    carbon_offset: 10.8,
    total_yield: 74.25,
    co2_reduction: 10.13,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-24",
    TOTAL_PRODUCTION: 9.5,
    EFFICIENCY: 34.55,
    peak_hour_production: "",
    carbon_neutral: 0.475,
    carbon_offset: 7.6,
    total_yield: 52.25,
    co2_reduction: 7.13,
    trees_planted: 0.33,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-25",
    TOTAL_PRODUCTION: 10,
    EFFICIENCY: 36.36,
    peak_hour_production: "",
    carbon_neutral: 0.5,
    carbon_offset: 8,
    total_yield: 55,
    co2_reduction: 7.5,
    trees_planted: 0.35,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-26",
    TOTAL_PRODUCTION: 12.3,
    EFFICIENCY: 44.73,
    peak_hour_production: "",
    carbon_neutral: 0.615,
    carbon_offset: 9.84,
    total_yield: 67.65,
    co2_reduction: 9.23,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-27",
    TOTAL_PRODUCTION: 14.6,
    EFFICIENCY: 53.09,
    peak_hour_production: "",
    carbon_neutral: 0.73,
    carbon_offset: 11.68,
    total_yield: 80.3,
    co2_reduction: 10.95,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-28",
    TOTAL_PRODUCTION: 14.4,
    EFFICIENCY: 52.36,
    peak_hour_production: "",
    carbon_neutral: 0.72,
    carbon_offset: 11.52,
    total_yield: 79.2,
    co2_reduction: 10.8,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-02-29",
    TOTAL_PRODUCTION: 13,
    EFFICIENCY: 47.27,
    peak_hour_production: "",
    carbon_neutral: 0.65,
    carbon_offset: 10.4,
    total_yield: 71.5,
    co2_reduction: 9.75,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-01",
    TOTAL_PRODUCTION: 13.4,
    EFFICIENCY: 48.73,
    peak_hour_production: "",
    carbon_neutral: 0.67,
    carbon_offset: 10.72,
    total_yield: 73.7,
    co2_reduction: 10.05,
    trees_planted: 0.47,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-02",
    TOTAL_PRODUCTION: 14.2,
    EFFICIENCY: 51.64,
    peak_hour_production: "",
    carbon_neutral: 0.71,
    carbon_offset: 11.36,
    total_yield: 78.1,
    co2_reduction: 10.65,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-03",
    TOTAL_PRODUCTION: 13,
    EFFICIENCY: 47.27,
    peak_hour_production: "",
    carbon_neutral: 0.65,
    carbon_offset: 10.4,
    total_yield: 71.5,
    co2_reduction: 9.75,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-04",
    TOTAL_PRODUCTION: 11,
    EFFICIENCY: 40,
    peak_hour_production: "",
    carbon_neutral: 0.55,
    carbon_offset: 8.8,
    total_yield: 60.5,
    co2_reduction: 8.25,
    trees_planted: 0.39,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-05",
    TOTAL_PRODUCTION: 13.1,
    EFFICIENCY: 47.64,
    peak_hour_production: "",
    carbon_neutral: 0.655,
    carbon_offset: 10.48,
    total_yield: 72.05,
    co2_reduction: 9.83,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-06",
    TOTAL_PRODUCTION: 14.1,
    EFFICIENCY: 51.27,
    peak_hour_production: "",
    carbon_neutral: 0.705,
    carbon_offset: 11.28,
    total_yield: 77.55,
    co2_reduction: 10.58,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-07",
    TOTAL_PRODUCTION: 12.2,
    EFFICIENCY: 44.36,
    peak_hour_production: "",
    carbon_neutral: 0.61,
    carbon_offset: 9.76,
    total_yield: 67.1,
    co2_reduction: 9.15,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-08",
    TOTAL_PRODUCTION: 13.4,
    EFFICIENCY: 48.73,
    peak_hour_production: "",
    carbon_neutral: 0.67,
    carbon_offset: 10.72,
    total_yield: 73.7,
    co2_reduction: 10.05,
    trees_planted: 0.47,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-09",
    TOTAL_PRODUCTION: 12.8,
    EFFICIENCY: 46.55,
    peak_hour_production: "",
    carbon_neutral: 0.64,
    carbon_offset: 10.24,
    total_yield: 70.4,
    co2_reduction: 9.6,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-10",
    TOTAL_PRODUCTION: 13,
    EFFICIENCY: 47.27,
    peak_hour_production: "",
    carbon_neutral: 0.65,
    carbon_offset: 10.4,
    total_yield: 71.5,
    co2_reduction: 9.75,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-11",
    TOTAL_PRODUCTION: 12.4,
    EFFICIENCY: 45.09,
    peak_hour_production: "",
    carbon_neutral: 0.62,
    carbon_offset: 9.92,
    total_yield: 68.2,
    co2_reduction: 9.3,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-12",
    TOTAL_PRODUCTION: 12.7,
    EFFICIENCY: 46.18,
    peak_hour_production: "",
    carbon_neutral: 0.635,
    carbon_offset: 10.16,
    total_yield: 69.85,
    co2_reduction: 9.53,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-13",
    TOTAL_PRODUCTION: 11.9,
    EFFICIENCY: 43.27,
    peak_hour_production: "",
    carbon_neutral: 0.595,
    carbon_offset: 9.52,
    total_yield: 65.45,
    co2_reduction: 8.93,
    trees_planted: 0.42,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-14",
    TOTAL_PRODUCTION: 12.6,
    EFFICIENCY: 45.82,
    peak_hour_production: "",
    carbon_neutral: 0.63,
    carbon_offset: 10.08,
    total_yield: 69.3,
    co2_reduction: 9.45,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-15",
    TOTAL_PRODUCTION: 11.6,
    EFFICIENCY: 42.18,
    peak_hour_production: "",
    carbon_neutral: 0.58,
    carbon_offset: 9.28,
    total_yield: 63.8,
    co2_reduction: 8.7,
    trees_planted: 0.41,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-16",
    TOTAL_PRODUCTION: 12.8,
    EFFICIENCY: 46.55,
    peak_hour_production: "",
    carbon_neutral: 0.64,
    carbon_offset: 10.24,
    total_yield: 70.4,
    co2_reduction: 9.6,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-17",
    TOTAL_PRODUCTION: 10.9,
    EFFICIENCY: 39.64,
    peak_hour_production: "",
    carbon_neutral: 0.545,
    carbon_offset: 8.72,
    total_yield: 59.95,
    co2_reduction: 8.18,
    trees_planted: 0.38,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-18",
    TOTAL_PRODUCTION: 12.5,
    EFFICIENCY: 45.45,
    peak_hour_production: "",
    carbon_neutral: 0.625,
    carbon_offset: 10,
    total_yield: 68.75,
    co2_reduction: 9.38,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-19",
    TOTAL_PRODUCTION: 8.6,
    EFFICIENCY: 31.27,
    peak_hour_production: "",
    carbon_neutral: 0.43,
    carbon_offset: 6.88,
    total_yield: 47.3,
    co2_reduction: 6.45,
    trees_planted: 0.3,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-20",
    TOTAL_PRODUCTION: 13.3,
    EFFICIENCY: 48.36,
    peak_hour_production: "",
    carbon_neutral: 0.665,
    carbon_offset: 10.64,
    total_yield: 73.15,
    co2_reduction: 9.98,
    trees_planted: 0.47,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-21",
    TOTAL_PRODUCTION: 16.3,
    EFFICIENCY: 59.27,
    peak_hour_production: "",
    carbon_neutral: 0.815,
    carbon_offset: 13.04,
    total_yield: 89.65,
    co2_reduction: 12.23,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-22",
    TOTAL_PRODUCTION: 15.9,
    EFFICIENCY: 57.82,
    peak_hour_production: "",
    carbon_neutral: 0.795,
    carbon_offset: 12.72,
    total_yield: 87.45,
    co2_reduction: 11.93,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-23",
    TOTAL_PRODUCTION: 14.8,
    EFFICIENCY: 53.82,
    peak_hour_production: "",
    carbon_neutral: 0.74,
    carbon_offset: 11.84,
    total_yield: 81.4,
    co2_reduction: 11.1,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-24",
    TOTAL_PRODUCTION: 13.1,
    EFFICIENCY: 47.64,
    peak_hour_production: "",
    carbon_neutral: 0.655,
    carbon_offset: 10.48,
    total_yield: 72.05,
    co2_reduction: 9.83,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-25",
    TOTAL_PRODUCTION: 9.3,
    EFFICIENCY: 33.82,
    peak_hour_production: "",
    carbon_neutral: 0.465,
    carbon_offset: 7.44,
    total_yield: 51.15,
    co2_reduction: 6.98,
    trees_planted: 0.33,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-26",
    TOTAL_PRODUCTION: 14.7,
    EFFICIENCY: 53.45,
    peak_hour_production: "",
    carbon_neutral: 0.735,
    carbon_offset: 11.76,
    total_yield: 80.85,
    co2_reduction: 11.03,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-27",
    TOTAL_PRODUCTION: 15,
    EFFICIENCY: 54.55,
    peak_hour_production: "",
    carbon_neutral: 0.75,
    carbon_offset: 12,
    total_yield: 82.5,
    co2_reduction: 11.25,
    trees_planted: 0.53,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-28",
    TOTAL_PRODUCTION: 14.7,
    EFFICIENCY: 53.45,
    peak_hour_production: "",
    carbon_neutral: 0.735,
    carbon_offset: 11.76,
    total_yield: 80.85,
    co2_reduction: 11.03,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-29",
    TOTAL_PRODUCTION: 14.2,
    EFFICIENCY: 51.64,
    peak_hour_production: "",
    carbon_neutral: 0.71,
    carbon_offset: 11.36,
    total_yield: 78.1,
    co2_reduction: 10.65,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-30",
    TOTAL_PRODUCTION: 13.1,
    EFFICIENCY: 47.64,
    peak_hour_production: "",
    carbon_neutral: 0.655,
    carbon_offset: 10.48,
    total_yield: 72.05,
    co2_reduction: 9.83,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-03-31",
    TOTAL_PRODUCTION: 14.7,
    EFFICIENCY: 53.45,
    peak_hour_production: "",
    carbon_neutral: 0.735,
    carbon_offset: 11.76,
    total_yield: 80.85,
    co2_reduction: 11.03,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-01",
    TOTAL_PRODUCTION: 14.6,
    EFFICIENCY: 53.09,
    peak_hour_production: "",
    carbon_neutral: 0.73,
    carbon_offset: 11.68,
    total_yield: 80.3,
    co2_reduction: 10.95,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-02",
    TOTAL_PRODUCTION: 14.1,
    EFFICIENCY: 51.27,
    peak_hour_production: "",
    carbon_neutral: 0.705,
    carbon_offset: 11.28,
    total_yield: 77.55,
    co2_reduction: 10.58,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-03",
    TOTAL_PRODUCTION: 15.2,
    EFFICIENCY: 55.27,
    peak_hour_production: "",
    carbon_neutral: 0.76,
    carbon_offset: 12.16,
    total_yield: 83.6,
    co2_reduction: 11.4,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-04",
    TOTAL_PRODUCTION: 15.5,
    EFFICIENCY: 56.36,
    peak_hour_production: "",
    carbon_neutral: 0.775,
    carbon_offset: 12.4,
    total_yield: 85.25,
    co2_reduction: 11.63,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-05",
    TOTAL_PRODUCTION: 15.6,
    EFFICIENCY: 56.73,
    peak_hour_production: "",
    carbon_neutral: 0.78,
    carbon_offset: 12.48,
    total_yield: 85.8,
    co2_reduction: 11.7,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-06",
    TOTAL_PRODUCTION: 15.2,
    EFFICIENCY: 55.27,
    peak_hour_production: "",
    carbon_neutral: 0.76,
    carbon_offset: 12.16,
    total_yield: 83.6,
    co2_reduction: 11.4,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-07",
    TOTAL_PRODUCTION: 16,
    EFFICIENCY: 58.18,
    peak_hour_production: "",
    carbon_neutral: 0.8,
    carbon_offset: 12.8,
    total_yield: 88,
    co2_reduction: 12,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-08",
    TOTAL_PRODUCTION: 15.7,
    EFFICIENCY: 57.09,
    peak_hour_production: "",
    carbon_neutral: 0.785,
    carbon_offset: 12.56,
    total_yield: 86.35,
    co2_reduction: 11.78,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-09",
    TOTAL_PRODUCTION: 16.2,
    EFFICIENCY: 58.91,
    peak_hour_production: "",
    carbon_neutral: 0.81,
    carbon_offset: 12.96,
    total_yield: 89.1,
    co2_reduction: 12.15,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-10",
    TOTAL_PRODUCTION: 17,
    EFFICIENCY: 61.82,
    peak_hour_production: "",
    carbon_neutral: 0.85,
    carbon_offset: 13.6,
    total_yield: 93.5,
    co2_reduction: 12.75,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-11",
    TOTAL_PRODUCTION: 14.6,
    EFFICIENCY: 53.09,
    peak_hour_production: "",
    carbon_neutral: 0.73,
    carbon_offset: 11.68,
    total_yield: 80.3,
    co2_reduction: 10.95,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-12",
    TOTAL_PRODUCTION: 12.9,
    EFFICIENCY: 46.91,
    peak_hour_production: "",
    carbon_neutral: 0.645,
    carbon_offset: 10.32,
    total_yield: 70.95,
    co2_reduction: 9.68,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-13",
    TOTAL_PRODUCTION: 12.2,
    EFFICIENCY: 44.36,
    peak_hour_production: "",
    carbon_neutral: 0.61,
    carbon_offset: 9.76,
    total_yield: 67.1,
    co2_reduction: 9.15,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-14",
    TOTAL_PRODUCTION: 16,
    EFFICIENCY: 58.18,
    peak_hour_production: "",
    carbon_neutral: 0.8,
    carbon_offset: 12.8,
    total_yield: 88,
    co2_reduction: 12,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-15",
    TOTAL_PRODUCTION: 15.9,
    EFFICIENCY: 57.82,
    peak_hour_production: "",
    carbon_neutral: 0.795,
    carbon_offset: 12.72,
    total_yield: 87.45,
    co2_reduction: 11.93,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-16",
    TOTAL_PRODUCTION: 15.7,
    EFFICIENCY: 57.09,
    peak_hour_production: "",
    carbon_neutral: 0.785,
    carbon_offset: 12.56,
    total_yield: 86.35,
    co2_reduction: 11.78,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-17",
    TOTAL_PRODUCTION: 11.9,
    EFFICIENCY: 43.27,
    peak_hour_production: "",
    carbon_neutral: 0.595,
    carbon_offset: 9.52,
    total_yield: 65.45,
    co2_reduction: 8.93,
    trees_planted: 0.42,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-18",
    TOTAL_PRODUCTION: 14.1,
    EFFICIENCY: 51.27,
    peak_hour_production: "",
    carbon_neutral: 0.705,
    carbon_offset: 11.28,
    total_yield: 77.55,
    co2_reduction: 10.58,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-19",
    TOTAL_PRODUCTION: 15.9,
    EFFICIENCY: 57.82,
    peak_hour_production: "",
    carbon_neutral: 0.795,
    carbon_offset: 12.72,
    total_yield: 87.45,
    co2_reduction: 11.93,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-20",
    TOTAL_PRODUCTION: 9.8,
    EFFICIENCY: 35.64,
    peak_hour_production: "",
    carbon_neutral: 0.49,
    carbon_offset: 7.84,
    total_yield: 53.9,
    co2_reduction: 7.35,
    trees_planted: 0.35,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-21",
    TOTAL_PRODUCTION: 18,
    EFFICIENCY: 65.45,
    peak_hour_production: "",
    carbon_neutral: 0.9,
    carbon_offset: 14.4,
    total_yield: 99,
    co2_reduction: 13.5,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-22",
    TOTAL_PRODUCTION: 18.5,
    EFFICIENCY: 67.27,
    peak_hour_production: "",
    carbon_neutral: 0.925,
    carbon_offset: 14.8,
    total_yield: 101.75,
    co2_reduction: 13.88,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-23",
    TOTAL_PRODUCTION: 20,
    EFFICIENCY: 72.73,
    peak_hour_production: "",
    carbon_neutral: 1,
    carbon_offset: 16,
    total_yield: 110,
    co2_reduction: 15,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-24",
    TOTAL_PRODUCTION: 19.7,
    EFFICIENCY: 71.64,
    peak_hour_production: "",
    carbon_neutral: 0.985,
    carbon_offset: 15.76,
    total_yield: 108.35,
    co2_reduction: 14.78,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-25",
    TOTAL_PRODUCTION: 19.4,
    EFFICIENCY: 70.55,
    peak_hour_production: "",
    carbon_neutral: 0.97,
    carbon_offset: 15.52,
    total_yield: 106.7,
    co2_reduction: 14.55,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-26",
    TOTAL_PRODUCTION: 18.8,
    EFFICIENCY: 68.36,
    peak_hour_production: "",
    carbon_neutral: 0.94,
    carbon_offset: 15.04,
    total_yield: 103.4,
    co2_reduction: 14.1,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-27",
    TOTAL_PRODUCTION: 19.2,
    EFFICIENCY: 69.82,
    peak_hour_production: "",
    carbon_neutral: 0.96,
    carbon_offset: 15.36,
    total_yield: 105.6,
    co2_reduction: 14.4,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-28",
    TOTAL_PRODUCTION: 17.6,
    EFFICIENCY: 64,
    peak_hour_production: "",
    carbon_neutral: 0.88,
    carbon_offset: 14.08,
    total_yield: 96.8,
    co2_reduction: 13.2,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-29",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-04-30",
    TOTAL_PRODUCTION: 18.6,
    EFFICIENCY: 67.64,
    peak_hour_production: "",
    carbon_neutral: 0.93,
    carbon_offset: 14.88,
    total_yield: 102.3,
    co2_reduction: 13.95,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-01",
    TOTAL_PRODUCTION: 18.7,
    EFFICIENCY: 68,
    peak_hour_production: "",
    carbon_neutral: 0.935,
    carbon_offset: 14.96,
    total_yield: 102.85,
    co2_reduction: 14.03,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-02",
    TOTAL_PRODUCTION: 19.2,
    EFFICIENCY: 69.82,
    peak_hour_production: "",
    carbon_neutral: 0.96,
    carbon_offset: 15.36,
    total_yield: 105.6,
    co2_reduction: 14.4,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-03",
    TOTAL_PRODUCTION: 19.6,
    EFFICIENCY: 71.27,
    peak_hour_production: "",
    carbon_neutral: 0.98,
    carbon_offset: 15.68,
    total_yield: 107.8,
    co2_reduction: 14.7,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-04",
    TOTAL_PRODUCTION: 15.6,
    EFFICIENCY: 56.73,
    peak_hour_production: "",
    carbon_neutral: 0.78,
    carbon_offset: 12.48,
    total_yield: 85.8,
    co2_reduction: 11.7,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-05",
    TOTAL_PRODUCTION: 14.2,
    EFFICIENCY: 51.64,
    peak_hour_production: "",
    carbon_neutral: 0.71,
    carbon_offset: 11.36,
    total_yield: 78.1,
    co2_reduction: 10.65,
    trees_planted: 0.5,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-06",
    TOTAL_PRODUCTION: 16.1,
    EFFICIENCY: 58.55,
    peak_hour_production: "",
    carbon_neutral: 0.805,
    carbon_offset: 12.88,
    total_yield: 88.55,
    co2_reduction: 12.08,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-07",
    TOTAL_PRODUCTION: 18.4,
    EFFICIENCY: 66.91,
    peak_hour_production: "",
    carbon_neutral: 0.92,
    carbon_offset: 14.72,
    total_yield: 101.2,
    co2_reduction: 13.8,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-08",
    TOTAL_PRODUCTION: 20.7,
    EFFICIENCY: 75.27,
    peak_hour_production: "",
    carbon_neutral: 1.035,
    carbon_offset: 16.56,
    total_yield: 113.85,
    co2_reduction: 15.53,
    trees_planted: 0.73,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-09",
    TOTAL_PRODUCTION: 15.8,
    EFFICIENCY: 57.45,
    peak_hour_production: "",
    carbon_neutral: 0.79,
    carbon_offset: 12.64,
    total_yield: 86.9,
    co2_reduction: 11.85,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-10",
    TOTAL_PRODUCTION: 18.2,
    EFFICIENCY: 66.18,
    peak_hour_production: "",
    carbon_neutral: 0.91,
    carbon_offset: 14.56,
    total_yield: 100.1,
    co2_reduction: 13.65,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-11",
    TOTAL_PRODUCTION: 19.3,
    EFFICIENCY: 70.18,
    peak_hour_production: "",
    carbon_neutral: 0.965,
    carbon_offset: 15.44,
    total_yield: 106.15,
    co2_reduction: 14.48,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-12",
    TOTAL_PRODUCTION: 20.1,
    EFFICIENCY: 73.09,
    peak_hour_production: "",
    carbon_neutral: 1.005,
    carbon_offset: 16.08,
    total_yield: 110.55,
    co2_reduction: 15.08,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-13",
    TOTAL_PRODUCTION: 16.1,
    EFFICIENCY: 58.55,
    peak_hour_production: "",
    carbon_neutral: 0.805,
    carbon_offset: 12.88,
    total_yield: 88.55,
    co2_reduction: 12.08,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-14",
    TOTAL_PRODUCTION: 17.9,
    EFFICIENCY: 65.09,
    peak_hour_production: "",
    carbon_neutral: 0.895,
    carbon_offset: 14.32,
    total_yield: 98.45,
    co2_reduction: 13.43,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-15",
    TOTAL_PRODUCTION: 17.8,
    EFFICIENCY: 64.73,
    peak_hour_production: "",
    carbon_neutral: 0.89,
    carbon_offset: 14.24,
    total_yield: 97.9,
    co2_reduction: 13.35,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-16",
    TOTAL_PRODUCTION: 14.5,
    EFFICIENCY: 52.73,
    peak_hour_production: "",
    carbon_neutral: 0.725,
    carbon_offset: 11.6,
    total_yield: 79.75,
    co2_reduction: 10.88,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-17",
    TOTAL_PRODUCTION: 13.2,
    EFFICIENCY: 48,
    peak_hour_production: "",
    carbon_neutral: 0.66,
    carbon_offset: 10.56,
    total_yield: 72.6,
    co2_reduction: 9.9,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-18",
    TOTAL_PRODUCTION: 12.9,
    EFFICIENCY: 46.91,
    peak_hour_production: "",
    carbon_neutral: 0.645,
    carbon_offset: 10.32,
    total_yield: 70.95,
    co2_reduction: 9.68,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-19",
    TOTAL_PRODUCTION: 15.4,
    EFFICIENCY: 56,
    peak_hour_production: "",
    carbon_neutral: 0.77,
    carbon_offset: 12.32,
    total_yield: 84.7,
    co2_reduction: 11.55,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-20",
    TOTAL_PRODUCTION: 18.1,
    EFFICIENCY: 65.82,
    peak_hour_production: "",
    carbon_neutral: 0.905,
    carbon_offset: 14.48,
    total_yield: 99.55,
    co2_reduction: 13.58,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-21",
    TOTAL_PRODUCTION: 20.2,
    EFFICIENCY: 73.45,
    peak_hour_production: "",
    carbon_neutral: 1.01,
    carbon_offset: 16.16,
    total_yield: 111.1,
    co2_reduction: 15.15,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-22",
    TOTAL_PRODUCTION: 19.8,
    EFFICIENCY: 72,
    peak_hour_production: "",
    carbon_neutral: 0.99,
    carbon_offset: 15.84,
    total_yield: 108.9,
    co2_reduction: 14.85,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-23",
    TOTAL_PRODUCTION: 13.8,
    EFFICIENCY: 50.18,
    peak_hour_production: "",
    carbon_neutral: 0.69,
    carbon_offset: 11.04,
    total_yield: 75.9,
    co2_reduction: 10.35,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-24",
    TOTAL_PRODUCTION: 15.2,
    EFFICIENCY: 55.27,
    peak_hour_production: "",
    carbon_neutral: 0.76,
    carbon_offset: 12.16,
    total_yield: 83.6,
    co2_reduction: 11.4,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-25",
    TOTAL_PRODUCTION: 17.6,
    EFFICIENCY: 64,
    peak_hour_production: "",
    carbon_neutral: 0.88,
    carbon_offset: 14.08,
    total_yield: 96.8,
    co2_reduction: 13.2,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-26",
    TOTAL_PRODUCTION: 17.9,
    EFFICIENCY: 65.09,
    peak_hour_production: "",
    carbon_neutral: 0.895,
    carbon_offset: 14.32,
    total_yield: 98.45,
    co2_reduction: 13.43,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-27",
    TOTAL_PRODUCTION: 17,
    EFFICIENCY: 61.82,
    peak_hour_production: "",
    carbon_neutral: 0.85,
    carbon_offset: 13.6,
    total_yield: 93.5,
    co2_reduction: 12.75,
    trees_planted: 0.6,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-28",
    TOTAL_PRODUCTION: 14.7,
    EFFICIENCY: 53.45,
    peak_hour_production: "",
    carbon_neutral: 0.735,
    carbon_offset: 11.76,
    total_yield: 80.85,
    co2_reduction: 11.03,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-29",
    TOTAL_PRODUCTION: 19.9,
    EFFICIENCY: 72.36,
    peak_hour_production: "",
    carbon_neutral: 0.995,
    carbon_offset: 15.92,
    total_yield: 109.45,
    co2_reduction: 14.93,
    trees_planted: 0.7,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-30",
    TOTAL_PRODUCTION: 19.5,
    EFFICIENCY: 70.91,
    peak_hour_production: "",
    carbon_neutral: 0.975,
    carbon_offset: 15.6,
    total_yield: 107.25,
    co2_reduction: 14.63,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-05-31",
    TOTAL_PRODUCTION: 19.3,
    EFFICIENCY: 70.18,
    peak_hour_production: "",
    carbon_neutral: 0.965,
    carbon_offset: 15.44,
    total_yield: 106.15,
    co2_reduction: 14.48,
    trees_planted: 0.68,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-01",
    TOTAL_PRODUCTION: 15.7,
    EFFICIENCY: 57.09,
    peak_hour_production: "",
    carbon_neutral: 0.785,
    carbon_offset: 12.56,
    total_yield: 86.35,
    co2_reduction: 11.78,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-02",
    TOTAL_PRODUCTION: 18.8,
    EFFICIENCY: 68.36,
    peak_hour_production: "",
    carbon_neutral: 0.94,
    carbon_offset: 15.04,
    total_yield: 103.4,
    co2_reduction: 14.1,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-03",
    TOTAL_PRODUCTION: 13.6,
    EFFICIENCY: 49.45,
    peak_hour_production: "",
    carbon_neutral: 0.68,
    carbon_offset: 10.88,
    total_yield: 74.8,
    co2_reduction: 10.2,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-04",
    TOTAL_PRODUCTION: 13.2,
    EFFICIENCY: 48,
    peak_hour_production: "",
    carbon_neutral: 0.66,
    carbon_offset: 10.56,
    total_yield: 72.6,
    co2_reduction: 9.9,
    trees_planted: 0.46,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-05",
    TOTAL_PRODUCTION: 17.7,
    EFFICIENCY: 64.36,
    peak_hour_production: "",
    carbon_neutral: 0.885,
    carbon_offset: 14.16,
    total_yield: 97.35,
    co2_reduction: 13.28,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-06",
    TOTAL_PRODUCTION: 16.7,
    EFFICIENCY: 60.73,
    peak_hour_production: "",
    carbon_neutral: 0.835,
    carbon_offset: 13.36,
    total_yield: 91.85,
    co2_reduction: 12.53,
    trees_planted: 0.59,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-07",
    TOTAL_PRODUCTION: 14.4,
    EFFICIENCY: 52.36,
    peak_hour_production: "",
    carbon_neutral: 0.72,
    carbon_offset: 11.52,
    total_yield: 79.2,
    co2_reduction: 10.8,
    trees_planted: 0.51,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-08",
    TOTAL_PRODUCTION: 11.8,
    EFFICIENCY: 42.91,
    peak_hour_production: "",
    carbon_neutral: 0.59,
    carbon_offset: 9.44,
    total_yield: 64.9,
    co2_reduction: 8.85,
    trees_planted: 0.42,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-09",
    TOTAL_PRODUCTION: 15,
    EFFICIENCY: 54.55,
    peak_hour_production: "",
    carbon_neutral: 0.75,
    carbon_offset: 12,
    total_yield: 82.5,
    co2_reduction: 11.25,
    trees_planted: 0.53,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-10",
    TOTAL_PRODUCTION: 15.1,
    EFFICIENCY: 54.91,
    peak_hour_production: "",
    carbon_neutral: 0.755,
    carbon_offset: 12.08,
    total_yield: 83.05,
    co2_reduction: 11.33,
    trees_planted: 0.53,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-11",
    TOTAL_PRODUCTION: 15.1,
    EFFICIENCY: 54.91,
    peak_hour_production: "",
    carbon_neutral: 0.755,
    carbon_offset: 12.08,
    total_yield: 83.05,
    co2_reduction: 11.33,
    trees_planted: 0.53,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-12",
    TOTAL_PRODUCTION: 17.5,
    EFFICIENCY: 63.64,
    peak_hour_production: "",
    carbon_neutral: 0.875,
    carbon_offset: 14,
    total_yield: 96.25,
    co2_reduction: 13.13,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-13",
    TOTAL_PRODUCTION: 18.7,
    EFFICIENCY: 68,
    peak_hour_production: "",
    carbon_neutral: 0.935,
    carbon_offset: 14.96,
    total_yield: 102.85,
    co2_reduction: 14.03,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-14",
    TOTAL_PRODUCTION: 18.4,
    EFFICIENCY: 66.91,
    peak_hour_production: "",
    carbon_neutral: 0.92,
    carbon_offset: 14.72,
    total_yield: 101.2,
    co2_reduction: 13.8,
    trees_planted: 0.65,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-15",
    TOTAL_PRODUCTION: 20.5,
    EFFICIENCY: 74.55,
    peak_hour_production: "",
    carbon_neutral: 1.025,
    carbon_offset: 16.4,
    total_yield: 112.75,
    co2_reduction: 15.38,
    trees_planted: 0.72,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-16",
    TOTAL_PRODUCTION: 18.6,
    EFFICIENCY: 67.64,
    peak_hour_production: "",
    carbon_neutral: 0.93,
    carbon_offset: 14.88,
    total_yield: 102.3,
    co2_reduction: 13.95,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-17",
    TOTAL_PRODUCTION: 17.4,
    EFFICIENCY: 63.27,
    peak_hour_production: "",
    carbon_neutral: 0.87,
    carbon_offset: 13.92,
    total_yield: 95.7,
    co2_reduction: 13.05,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-18",
    TOTAL_PRODUCTION: 20.7,
    EFFICIENCY: 75.27,
    peak_hour_production: "",
    carbon_neutral: 1.035,
    carbon_offset: 16.56,
    total_yield: 113.85,
    co2_reduction: 15.53,
    trees_planted: 0.73,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-19",
    TOTAL_PRODUCTION: 12,
    EFFICIENCY: 43.64,
    peak_hour_production: "",
    carbon_neutral: 0.6,
    carbon_offset: 9.6,
    total_yield: 66,
    co2_reduction: 9,
    trees_planted: 0.42,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-20",
    TOTAL_PRODUCTION: 18.7,
    EFFICIENCY: 68,
    peak_hour_production: "",
    carbon_neutral: 0.935,
    carbon_offset: 14.96,
    total_yield: 102.85,
    co2_reduction: 14.03,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-21",
    TOTAL_PRODUCTION: 15.9,
    EFFICIENCY: 57.82,
    peak_hour_production: "",
    carbon_neutral: 0.795,
    carbon_offset: 12.72,
    total_yield: 87.45,
    co2_reduction: 11.93,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-22",
    TOTAL_PRODUCTION: 12.6,
    EFFICIENCY: 45.82,
    peak_hour_production: "",
    carbon_neutral: 0.63,
    carbon_offset: 10.08,
    total_yield: 69.3,
    co2_reduction: 9.45,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-23",
    TOTAL_PRODUCTION: 12.1,
    EFFICIENCY: 44,
    peak_hour_production: "",
    carbon_neutral: 0.605,
    carbon_offset: 9.68,
    total_yield: 66.55,
    co2_reduction: 9.08,
    trees_planted: 0.43,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-24",
    TOTAL_PRODUCTION: 19.5,
    EFFICIENCY: 70.91,
    peak_hour_production: "",
    carbon_neutral: 0.975,
    carbon_offset: 15.6,
    total_yield: 107.25,
    co2_reduction: 14.63,
    trees_planted: 0.69,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-25",
    TOTAL_PRODUCTION: 16.4,
    EFFICIENCY: 59.64,
    peak_hour_production: "",
    carbon_neutral: 0.82,
    carbon_offset: 13.12,
    total_yield: 90.2,
    co2_reduction: 12.3,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-26",
    TOTAL_PRODUCTION: 19.1,
    EFFICIENCY: 69.45,
    peak_hour_production: "",
    carbon_neutral: 0.955,
    carbon_offset: 15.28,
    total_yield: 105.05,
    co2_reduction: 14.33,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-27",
    TOTAL_PRODUCTION: 14,
    EFFICIENCY: 50.91,
    peak_hour_production: "",
    carbon_neutral: 0.7,
    carbon_offset: 11.2,
    total_yield: 77,
    co2_reduction: 10.5,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-28",
    TOTAL_PRODUCTION: 12,
    EFFICIENCY: 43.64,
    peak_hour_production: "",
    carbon_neutral: 0.6,
    carbon_offset: 9.6,
    total_yield: 66,
    co2_reduction: 9,
    trees_planted: 0.42,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-29",
    TOTAL_PRODUCTION: 10.5,
    EFFICIENCY: 38.18,
    peak_hour_production: "",
    carbon_neutral: 0.525,
    carbon_offset: 8.4,
    total_yield: 57.75,
    co2_reduction: 7.88,
    trees_planted: 0.37,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-06-30",
    TOTAL_PRODUCTION: 10.5,
    EFFICIENCY: 38.18,
    peak_hour_production: "",
    carbon_neutral: 0.525,
    carbon_offset: 8.4,
    total_yield: 57.75,
    co2_reduction: 7.88,
    trees_planted: 0.37,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-01",
    TOTAL_PRODUCTION: 11.9,
    EFFICIENCY: 43.27,
    peak_hour_production: "",
    carbon_neutral: 0.595,
    carbon_offset: 9.52,
    total_yield: 65.45,
    co2_reduction: 8.93,
    trees_planted: 0.42,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-02",
    TOTAL_PRODUCTION: 15,
    EFFICIENCY: 54.55,
    peak_hour_production: "",
    carbon_neutral: 0.75,
    carbon_offset: 12,
    total_yield: 82.5,
    co2_reduction: 11.25,
    trees_planted: 0.53,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-03",
    TOTAL_PRODUCTION: 17.4,
    EFFICIENCY: 63.27,
    peak_hour_production: "",
    carbon_neutral: 0.87,
    carbon_offset: 13.92,
    total_yield: 95.7,
    co2_reduction: 13.05,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-04",
    TOTAL_PRODUCTION: 19,
    EFFICIENCY: 69.09,
    peak_hour_production: "",
    carbon_neutral: 0.95,
    carbon_offset: 15.2,
    total_yield: 104.5,
    co2_reduction: 14.25,
    trees_planted: 0.67,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-05",
    TOTAL_PRODUCTION: 16.2,
    EFFICIENCY: 58.91,
    peak_hour_production: "",
    carbon_neutral: 0.81,
    carbon_offset: 12.96,
    total_yield: 89.1,
    co2_reduction: 12.15,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-06",
    TOTAL_PRODUCTION: 14.7,
    EFFICIENCY: 53.45,
    peak_hour_production: "",
    carbon_neutral: 0.735,
    carbon_offset: 11.76,
    total_yield: 80.85,
    co2_reduction: 11.03,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-07",
    TOTAL_PRODUCTION: 18.1,
    EFFICIENCY: 65.82,
    peak_hour_production: "",
    carbon_neutral: 0.905,
    carbon_offset: 14.48,
    total_yield: 99.55,
    co2_reduction: 13.58,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-08",
    TOTAL_PRODUCTION: 8.3,
    EFFICIENCY: 30.18,
    peak_hour_production: "",
    carbon_neutral: 0.415,
    carbon_offset: 6.64,
    total_yield: 45.65,
    co2_reduction: 6.23,
    trees_planted: 0.29,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-09",
    TOTAL_PRODUCTION: 11.3,
    EFFICIENCY: 41.09,
    peak_hour_production: "",
    carbon_neutral: 0.565,
    carbon_offset: 9.04,
    total_yield: 62.15,
    co2_reduction: 8.48,
    trees_planted: 0.4,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-10",
    TOTAL_PRODUCTION: 18.3,
    EFFICIENCY: 66.55,
    peak_hour_production: "",
    carbon_neutral: 0.915,
    carbon_offset: 14.64,
    total_yield: 100.65,
    co2_reduction: 13.73,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-11",
    TOTAL_PRODUCTION: 20.2,
    EFFICIENCY: 73.45,
    peak_hour_production: "",
    carbon_neutral: 1.01,
    carbon_offset: 16.16,
    total_yield: 111.1,
    co2_reduction: 15.15,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-12",
    TOTAL_PRODUCTION: 15.6,
    EFFICIENCY: 56.73,
    peak_hour_production: "",
    carbon_neutral: 0.78,
    carbon_offset: 12.48,
    total_yield: 85.8,
    co2_reduction: 11.7,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-13",
    TOTAL_PRODUCTION: 14,
    EFFICIENCY: 50.91,
    peak_hour_production: "",
    carbon_neutral: 0.7,
    carbon_offset: 11.2,
    total_yield: 77,
    co2_reduction: 10.5,
    trees_planted: 0.49,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-14",
    TOTAL_PRODUCTION: 7.8,
    EFFICIENCY: 28.36,
    peak_hour_production: "",
    carbon_neutral: 0.39,
    carbon_offset: 6.24,
    total_yield: 42.9,
    co2_reduction: 5.85,
    trees_planted: 0.27,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-15",
    TOTAL_PRODUCTION: 6.8,
    EFFICIENCY: 24.73,
    peak_hour_production: "",
    carbon_neutral: 0.34,
    carbon_offset: 5.44,
    total_yield: 37.4,
    co2_reduction: 5.1,
    trees_planted: 0.24,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-16",
    TOTAL_PRODUCTION: 15.6,
    EFFICIENCY: 56.73,
    peak_hour_production: "",
    carbon_neutral: 0.78,
    carbon_offset: 12.48,
    total_yield: 85.8,
    co2_reduction: 11.7,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-17",
    TOTAL_PRODUCTION: 14.8,
    EFFICIENCY: 53.82,
    peak_hour_production: "",
    carbon_neutral: 0.74,
    carbon_offset: 11.84,
    total_yield: 81.4,
    co2_reduction: 11.1,
    trees_planted: 0.52,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-18",
    TOTAL_PRODUCTION: 9.8,
    EFFICIENCY: 35.64,
    peak_hour_production: "",
    carbon_neutral: 0.49,
    carbon_offset: 7.84,
    total_yield: 53.9,
    co2_reduction: 7.35,
    trees_planted: 0.35,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-19",
    TOTAL_PRODUCTION: 10.7,
    EFFICIENCY: 38.91,
    peak_hour_production: "",
    carbon_neutral: 0.535,
    carbon_offset: 8.56,
    total_yield: 58.85,
    co2_reduction: 8.03,
    trees_planted: 0.38,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-20",
    TOTAL_PRODUCTION: 2.2,
    EFFICIENCY: 8,
    peak_hour_production: "",
    carbon_neutral: 0.11,
    carbon_offset: 1.76,
    total_yield: 12.1,
    co2_reduction: 1.65,
    trees_planted: 0.08,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-21",
    TOTAL_PRODUCTION: 2,
    EFFICIENCY: 7.27,
    peak_hour_production: "",
    carbon_neutral: 0.1,
    carbon_offset: 1.6,
    total_yield: 11,
    co2_reduction: 1.5,
    trees_planted: 0.07,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-22",
    TOTAL_PRODUCTION: 11.4,
    EFFICIENCY: 41.45,
    peak_hour_production: "",
    carbon_neutral: 0.57,
    carbon_offset: 9.12,
    total_yield: 62.7,
    co2_reduction: 8.55,
    trees_planted: 0.4,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-23",
    TOTAL_PRODUCTION: 8.4,
    EFFICIENCY: 30.55,
    peak_hour_production: "",
    carbon_neutral: 0.42,
    carbon_offset: 6.72,
    total_yield: 46.2,
    co2_reduction: 6.3,
    trees_planted: 0.3,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-24",
    TOTAL_PRODUCTION: 1.1,
    EFFICIENCY: 4,
    peak_hour_production: "",
    carbon_neutral: 0.055,
    carbon_offset: 0.88,
    total_yield: 6.05,
    co2_reduction: 0.83,
    trees_planted: 0.04,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-25",
    TOTAL_PRODUCTION: 2.8,
    EFFICIENCY: 10.18,
    peak_hour_production: "",
    carbon_neutral: 0.14,
    carbon_offset: 2.24,
    total_yield: 15.4,
    co2_reduction: 2.1,
    trees_planted: 0.1,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-26",
    TOTAL_PRODUCTION: 9.4,
    EFFICIENCY: 34.18,
    peak_hour_production: "",
    carbon_neutral: 0.47,
    carbon_offset: 7.52,
    total_yield: 51.7,
    co2_reduction: 7.05,
    trees_planted: 0.33,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-27",
    TOTAL_PRODUCTION: 6.7,
    EFFICIENCY: 24.36,
    peak_hour_production: "",
    carbon_neutral: 0.335,
    carbon_offset: 5.36,
    total_yield: 36.85,
    co2_reduction: 5.03,
    trees_planted: 0.24,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-28",
    TOTAL_PRODUCTION: 13.6,
    EFFICIENCY: 49.45,
    peak_hour_production: "",
    carbon_neutral: 0.68,
    carbon_offset: 10.88,
    total_yield: 74.8,
    co2_reduction: 10.2,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-29",
    TOTAL_PRODUCTION: 16.2,
    EFFICIENCY: 58.91,
    peak_hour_production: "",
    carbon_neutral: 0.81,
    carbon_offset: 12.96,
    total_yield: 89.1,
    co2_reduction: 12.15,
    trees_planted: 0.57,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-30",
    TOTAL_PRODUCTION: 15.7,
    EFFICIENCY: 57.09,
    peak_hour_production: "",
    carbon_neutral: 0.785,
    carbon_offset: 12.56,
    total_yield: 86.35,
    co2_reduction: 11.78,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-07-31",
    TOTAL_PRODUCTION: 18.7,
    EFFICIENCY: 68,
    peak_hour_production: "",
    carbon_neutral: 0.935,
    carbon_offset: 14.96,
    total_yield: 102.85,
    co2_reduction: 14.03,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-01",
    TOTAL_PRODUCTION: 11,
    EFFICIENCY: 40,
    peak_hour_production: 2.2,
    carbon_neutral: 0.55,
    carbon_offset: 8.8,
    total_yield: 60.5,
    co2_reduction: 8.25,
    trees_planted: 0.39,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-02",
    TOTAL_PRODUCTION: 12.8,
    EFFICIENCY: 46.55,
    peak_hour_production: 2.56,
    carbon_neutral: 0.64,
    carbon_offset: 10.24,
    total_yield: 70.4,
    co2_reduction: 9.6,
    trees_planted: 0.45,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-03",
    TOTAL_PRODUCTION: 13.5,
    EFFICIENCY: 49.09,
    peak_hour_production: 2.7,
    carbon_neutral: 0.675,
    carbon_offset: 10.8,
    total_yield: 74.25,
    co2_reduction: 10.13,
    trees_planted: 0.48,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-04",
    TOTAL_PRODUCTION: 16,
    EFFICIENCY: 58.18,
    peak_hour_production: 3.2,
    carbon_neutral: 0.8,
    carbon_offset: 12.8,
    total_yield: 88,
    co2_reduction: 12,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-05",
    TOTAL_PRODUCTION: 18.7,
    EFFICIENCY: 68,
    peak_hour_production: 3.74,
    carbon_neutral: 0.935,
    carbon_offset: 14.96,
    total_yield: 102.85,
    co2_reduction: 14.03,
    trees_planted: 0.66,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-06",
    TOTAL_PRODUCTION: 10.1,
    EFFICIENCY: 36.73,
    peak_hour_production: 2.02,
    carbon_neutral: 0.505,
    carbon_offset: 8.08,
    total_yield: 55.55,
    co2_reduction: 7.58,
    trees_planted: 0.36,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-07",
    TOTAL_PRODUCTION: 5.1,
    EFFICIENCY: 18.55,
    peak_hour_production: 1.02,
    carbon_neutral: 0.255,
    carbon_offset: 4.08,
    total_yield: 28.05,
    co2_reduction: 3.83,
    trees_planted: 0.18,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-08",
    TOTAL_PRODUCTION: 11,
    EFFICIENCY: 40,
    peak_hour_production: 2.2,
    carbon_neutral: 0.55,
    carbon_offset: 8.8,
    total_yield: 60.5,
    co2_reduction: 8.25,
    trees_planted: 0.39,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-09",
    TOTAL_PRODUCTION: 16.5,
    EFFICIENCY: 60,
    peak_hour_production: 3.3,
    carbon_neutral: 0.825,
    carbon_offset: 13.2,
    total_yield: 90.75,
    co2_reduction: 12.38,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-10",
    TOTAL_PRODUCTION: 17.6,
    EFFICIENCY: 64,
    peak_hour_production: 3.52,
    carbon_neutral: 0.88,
    carbon_offset: 14.08,
    total_yield: 96.8,
    co2_reduction: 13.2,
    trees_planted: 0.62,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-11",
    TOTAL_PRODUCTION: 15.7,
    EFFICIENCY: 57.09,
    peak_hour_production: 3.14,
    carbon_neutral: 0.785,
    carbon_offset: 12.56,
    total_yield: 86.35,
    co2_reduction: 11.78,
    trees_planted: 0.55,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-12",
    TOTAL_PRODUCTION: 15.3,
    EFFICIENCY: 55.64,
    peak_hour_production: 3.06,
    carbon_neutral: 0.765,
    carbon_offset: 12.24,
    total_yield: 84.15,
    co2_reduction: 11.48,
    trees_planted: 0.54,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-13",
    TOTAL_PRODUCTION: 10.7,
    EFFICIENCY: 38.91,
    peak_hour_production: 2.14,
    carbon_neutral: 0.535,
    carbon_offset: 8.56,
    total_yield: 58.85,
    co2_reduction: 8.03,
    trees_planted: 0.38,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-14",
    TOTAL_PRODUCTION: 15.1,
    EFFICIENCY: 54.91,
    peak_hour_production: 3.02,
    carbon_neutral: 0.755,
    carbon_offset: 12.08,
    total_yield: 83.05,
    co2_reduction: 11.33,
    trees_planted: 0.53,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-15",
    TOTAL_PRODUCTION: 17.2,
    EFFICIENCY: 62.55,
    peak_hour_production: 3.44,
    carbon_neutral: 0.86,
    carbon_offset: 13.76,
    total_yield: 94.6,
    co2_reduction: 12.9,
    trees_planted: 0.61,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-16",
    TOTAL_PRODUCTION: 16,
    EFFICIENCY: 58.18,
    peak_hour_production: 3.2,
    carbon_neutral: 0.8,
    carbon_offset: 12.8,
    total_yield: 88,
    co2_reduction: 12,
    trees_planted: 0.56,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-17",
    TOTAL_PRODUCTION: 20.1,
    EFFICIENCY: 73.09,
    peak_hour_production: 4.02,
    carbon_neutral: 1.005,
    carbon_offset: 16.08,
    total_yield: 110.55,
    co2_reduction: 15.08,
    trees_planted: 0.71,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-18",
    TOTAL_PRODUCTION: 18,
    EFFICIENCY: 65.45,
    peak_hour_production: 3.6,
    carbon_neutral: 0.9,
    carbon_offset: 14.4,
    total_yield: 99,
    co2_reduction: 13.5,
    trees_planted: 0.63,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-19",
    TOTAL_PRODUCTION: 12.4,
    EFFICIENCY: 45.09,
    peak_hour_production: 2.48,
    carbon_neutral: 0.62,
    carbon_offset: 9.92,
    total_yield: 68.2,
    co2_reduction: 9.3,
    trees_planted: 0.44,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-20",
    TOTAL_PRODUCTION: 16.6,
    EFFICIENCY: 66.4,
    peak_hour_production: 3.32,
    carbon_neutral: 0.83,
    carbon_offset: 13.28,
    total_yield: 91.3,
    co2_reduction: 12.45,
    trees_planted: 0.58,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-21",
    TOTAL_PRODUCTION: 18.1,
    EFFICIENCY: 66.18,
    peak_hour_production: 3.62,
    carbon_neutral: 0.905,
    carbon_offset: 14.48,
    total_yield: 99.55,
    co2_reduction: 13.8,
    trees_planted: 0.64,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-22",
    TOTAL_PRODUCTION: 17.0,
    EFFICIENCY: 61.45,
    peak_hour_production: 3.4,
    carbon_neutral: 0.845,
    carbon_offset: 13.52,
    total_yield: 92.95,
    co2_reduction: 12.67,
    trees_planted: 1.36,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },

  {
    PLANT_ID: "AE76-45RT-ERT5-RTYU",
    capacity: 5,
    DATE: "2024-08-23",
    TOTAL_PRODUCTION: 16.1,
    EFFICIENCY: 58.2,
    peak_hour_production: 3.22,
    carbon_neutral: 0.845,
    carbon_offset: 13.52,
    total_yield: 92.95,
    co2_reduction: 12.67,
    trees_planted: 1.36,
    plant_status: "offline",
    critical_alerts_open: 0,
    major_alerts_open: 0,
    critical_alerts_closed: 0,
    major_alerts_closed: 0,
    critical_alerts_open_closed: 0,
    major_alerts_open_closed: 0,
  },
];

export default sampleData;
