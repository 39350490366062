import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./antsfp.css";
import backgroundImage from "../assets/solar-dl-login-bg-2x.png";
import logo from "../assets/logog.svg";
import { MessageContext } from '../App';

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(20);
  const [showResend, setShowResend] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [otpVerified, setOtpVerified] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const navigate = useNavigate();

  const onMessageChange = useContext(MessageContext);

  const handleLogin = () => {
    navigate("/");
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      onMessageChange({
        type: "Danger",
        info: "Please enter a valid email address.",
      });
      return;
    }
  
    try {
      // Check if email exists
      const checkEmailResponse = await fetch(
        "https://solar-api.antsai.in/checkemail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );
  
      const checkEmailData = await checkEmailResponse.json();
  
      if (!checkEmailData.exists) {
        onMessageChange({
          type: "Warning",
          info: "User does not exist.",
        });
        return;
      }
  
      // Email exists, proceed to send OTP
      const otpResponse = await fetch("https://solar-api.antsai.in/send-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_email: email }),
      });
  
      if (otpResponse.ok) {
        setIsOtpSent(true);
        setTimer(20);
        setShowResend(false);
        setOtp(["", "", "", ""]);
        onMessageChange({
          type: "Success",
          info: "OTP sent to your email!",
        });
      } else {
        onMessageChange({
          type: "Danger",
          info: "Failed to send OTP. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error checking email or sending OTP:", error);
      onMessageChange({
        type: "Danger",
        info: "An error occurred. Please try again.",
      });
    }
  };

  //   if (validateEmail(email)) {
  //     try {
  //       const response = await fetch("https://solar-api.antsai.in/send-otp", {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({ user_email: email }),
  //       });
  //       if (response.ok) {
  //         setIsOtpSent(true);
  //         setTimer(20);
  //         setShowResend(false);
  //       } else {
  //         // alert("Failed to send OTP. Please try again.");
  //         onMessageChange({ type: 'Danger', 'info': 'Failed to send OTP. Please try again.' })
  //       }
  //     } catch (error) {
  //       console.error("Error sending OTP:", error);
  //     }
  //   } else {
  //     // alert("Please enter a valid email address.");
  //     onMessageChange({ type: 'Danger', 'info': 'Please enter a valid email address.' })
  //   }
  // };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== "" && index < 3) {
        otpRefs[index + 1].current.focus();
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join("");
    if (otpValue.length === 4) {
      try {
        const response = await fetch("https://solar-api.antsai.in/verify-otp", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_email: email, otp: otpValue }),
        });
        if (response.ok) {
          setOtpVerified(true);
        } else {
          setIsOtpValid(false);
          // alert("OTP verification failed. Please try again.");
          onMessageChange({ type: 'Danger', 'info': 'OTP verification failed. Please try again.' })
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
      }
    } else {
      setIsOtpValid(false);
    }
  };

  // const handlePasswordSubmit = async (e) => {
  //   e.preventDefault();
  //   if (newPassword === confirmPassword) {
  //     try {
  //       const response = await fetch(
  //         "https://solar-api.antsai.in/forgot-pass",
  //         {
  //           method: "POST",
  //           headers: { "Content-Type": "application/json" },
  //           body: JSON.stringify({ email: email, newPassword: newPassword }),
  //         }
  //       );
  //       if (response.ok) {
  //         console.log("Password reset successful");
  //         handleLogin();
  //       } else {
  //         // alert("Failed to reset password. Please try again.");
  //         onMessageChange({ type: 'Danger', 'info': 'Failed to reset password. Please try again.' })
  //       }
  //     } catch (error) {
  //       console.error("Error resetting password:", error);
  //     }
  //   } else {
  //     setPasswordMatchError(true);
  //   }
  // };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

 // Check password format before submission
 if (
  !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&()_+{}\[\]:;<>,.?~\-=/\\]).{8,15}$/.test(
    newPassword
  )
) {
  onMessageChange({
    type: "Danger",
    info: "Password must be 8-15 characters, contain at least one uppercase letter, one number, and one special character.",
  });
  return;
}

    if (newPassword === confirmPassword) {
      try {
        const response = await fetch(
          "https://solar-api.antsai.in/forgot-pass",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: email, newPassword: newPassword }),
          }
        );
        if (response.ok) {
          onMessageChange({
            type: "Success",
            info: "Password reset successfully!",
          });
          setTimeout(() => {
            handleLogin(); // Redirects to login page after displaying message
          }, 2000); // Optional delay for showing the message before navigating
        } else {
          onMessageChange({
            type: "Danger",
            info: "Failed to reset password. Please try again.",
          });
        }
      } catch (error) {
        console.error("Error resetting password:", error);
        onMessageChange({
          type: "Danger",
          info: "An error occurred. Please try again.",
        });
      }
    } else {
      setPasswordMatchError(true);
    }
  };  

  useEffect(() => {
    if (isOtpSent && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setShowResend(true);
    }
  }, [timer, isOtpSent]);

  const handleResendOtp = async () => {
    setTimer(60);
    setShowResend(false);
    setOtp(["", "", "", ""]);
    otpRefs[0].current.focus();
    try {
      const response = await fetch("https://solar-api.antsai.in/send-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_email: email }),
      });
      if (response.ok) {
        // alert("OTP resent successfully!");
        onMessageChange({ type: 'Success', 'info': 'OTP resent successfully!' })
      } else {
        // alert("Failed to resend OTP. Please try again.");
        onMessageChange({ type: 'Danger', 'info': 'Failed to resend OTP. Please try again.' })
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  return (
    <div
      className="forgotp-card-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}

    >
      <div className="forgotp-card">
        <img src={logo} className="forgotp-logo" />
        <h2 className="forgotp-title">
          <span>Forgot Password</span>
        </h2>
        <div className="">
          {!isOtpSent ? (
            <form onSubmit={handleSubmit} className="forgotp-form">
              <div className="forgotp-input-container">
                <label className="forgotp-input-label">Email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="forgotp-input"
                />
              </div>
              <button type="submit" className="forgotp-submit-button">
                Send OTP
              </button>
            </form>
          ) : !otpVerified ? (
            <form onSubmit={handleOtpSubmit} className="forgotp-otp-form">
              <div className="forgotp-input-group forgotp-otp-input-group">
                <label>Enter OTP:</label>
                <div className="forgotp-otp-boxes">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      ref={otpRefs[index]}
                      className="forgotp-otp-input"
                      required
                    />
                  ))}
                </div>
                <span className="forgotp-timer">
                  {timer > 0 ? (
                    `Time left: ${timer}s`
                  ) : (
                    <button
                      type="button"
                      className="forgotp-resend-button"
                      onClick={handleResendOtp}
                    >
                      Resend OTP
                    </button>
                  )}
                </span>
              </div>
              {!isOtpValid && (
                <p className="forgotp-error-message">
                  Please enter a valid 4-digit OTP.
                </p>
              )}
              <button type="submit" className="forgotp-submit-button">
                Submit OTP
              </button>
            </form>
          ) : (
            <form
              onSubmit={handlePasswordSubmit}
              className="forgotp-password-form"
            >
              <div className="forgotp-input-container">
                <label className="forgotp-input-label">New Password</label>
                <div style={{ position: "relative" }}>
                <input
                  type={isNewPasswordVisible ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onBlur={() => {
                    if (
                      !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&()_+{}\[\]:;<>,.?~\-=/\\]).{8,15}$/.test(
                        newPassword
                      )
                    ) {
                      onMessageChange({
                        type: "Danger",
                        info: "Password must be 8-15 characters, contain at least one uppercase letter, one number, and one special character.",
                      });
                    }
                  }}
                  required
                  placeholder="Enter new password"
                  className="forgotp-input"
                />
                  <span
                    onClick={toggleNewPasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "42%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#888",
                    }}
                  >
                    {isNewPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
              </div>
              <div className="forgotp-input-container">
                <label className="forgotp-input-label">Confirm Password</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    placeholder="Confirm new password"
                    className="forgotp-input"
                  />
                  <span
                    onClick={toggleConfirmPasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "42%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#888",
                    }}
                  >
                    {isConfirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
              </div>
              {passwordMatchError && (
                <p className="forgotp-error-message">
                  Passwords do not match. Please try again.
                </p>
              )}
              <button type="submit" className="forgotp-submit-button">
                Reset Password
              </button>
            </form>
          )}
        </div>
        <a href="" className="forgotp-login-back" onClick={handleLogin}>
          Back to Login
        </a>
      </div>
    </div>
  );
};

export default ForgotPassword;