
import { Card, Col, Container, Image, OverlayTrigger, Popover, Row, } from "react-bootstrap";
import Datalogger from "../../../assets/Datalogger.png";
import Inverter from "../../../assets/Inverter.png";
import InfoIcon from '@mui/icons-material/Info';
import { Divider } from "@mui/material";
function DevicesTab(props) {
    function Styles() {
        return <style>{`
            .invertor-line::before{
             content: "";
    display: block;
    position: relative;
    z-index: 1;
    left: 30px;
    top: -71px;
    bottom: 0;
    border: 1px dotted;
    border-width: 0 0 0 1px;
    height: 100px;
            }
    .invertor-lines{
     height:100px;

    }
            
            `}</style>
    }

    const renderTooltip = (values) => (
        <Popover className="shadow border-0">
            <Popover.Body>
                <Container fluid style={{ width: 'auto', padding: 0, margin: 0 }}>
                    {values.map((item, index) => (
                        <>
                            {Object.entries(item).map(([key, value]) => (
                                <Row className="w-auto" key={key}><Col xs={5} className="me-1"><p className="text-start text-capitalize text-nowrap fw-bold">{key}</p></Col><Col xs={3} className="ms-3  text-start"><p className="text-start text-nowrap">{value}</p></Col></Row>
                            ))}
                        </>
                    ))}
                </Container>
                <div className="p-0 m-0 d-flex justify-content-end"><p style={{ color: '#F75A34' }}>more...</p></div>
            </Popover.Body>
        </Popover>
    );

    return (
        <div>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="w-auto d-flex flex-row justify-items-center justify-content-center">
                    <Card style={{ backgroundColor: "#fafafa", width: "fit-content" }}>
                        <Card.Body>

                            <Card.Title className="text-center">
                                Data Loggers
                            </Card.Title>

                            <div>
                                <Image src={Datalogger} width={200} height={90} />
                            </div>

                        </Card.Body>
                    </Card>
                    <div className="m-4 align-self-start">
                        <OverlayTrigger
                            placement="auto"
                            overlay={renderTooltip([{ 'ID': 'AEO5' }, { 'Type': 'Micro Invertor' }, { 'Make': 'SolarEdge' }, { 'Serial Number': 'N-1034978' }, { 'Model Number': '205' }, { 'Firmware Version': '1.05.203' }])}
                            trigger='click'
                            rootClose
                        >
                            <InfoIcon sx={{ color: '#167DB0' }} width={16} height={16} />
                        </OverlayTrigger>
                    </div>

                </div>
                <div className="d-flex justify-content-center"> <Divider className="invertor-lines" orientation="vertical" flexItem /></div>
            </div>
            <Container className="d-flex flex-column justify-content-center align-self-center justify-self-center">
                <Row className="w-100 align-self-center  align-items-center d-flex justify-content-center  mb-0 p-0">
                    <Divider className="bg-black w-75 justify-content-center align-self-center justify-self-center" />
                    <div className=" w-100 d-flex align-items-center d-flex justify-content-center p-0">
                        <div className="w-75 d-flex justify-content-between">
                            <Divider className="invertor-lines" orientation="vertical" flexItem />

                            <Divider className="invertor-lines" orientation="vertical" flexItem />

                            <Divider className="invertor-lines" orientation="vertical" flexItem />

                        </div>
                    </div>
                </Row>
                <Row className="d-flex flex-row  w-100 mb-5 mt-0 gap-5 m-0 p-0">
                    <Styles />
                    <Col className="w-auto d-flex flex-column justify-items-center justify-content-center p-0 m-0 m-0 me-lg-2">

                        <div className="d-flex flex-row justify-items-center justify-content-center p-0 m-0 ">
                            <Card style={{ backgroundColor: "#fafafa" }}>
                                <Card.Body>
                                    <Card.Title className="text-center" >
                                        Inverters
                                    </Card.Title>
                                    <div>
                                        <Image src={Inverter} width={50} height={90} />
                                    </div>
                                </Card.Body>
                            </Card>
                            <div className="m-4 align-self-start">
                                <OverlayTrigger
                                    placement="auto"
                                    overlay={renderTooltip([{ 'ID': 'AEO5' }, { 'Type': 'Micro Invertor' }, { 'Make': 'SolarEdge' }, { 'Serial Number': 'N-1034978' }, { 'Model Number': '205' }, { 'Power Rating': '5kw' }, { 'Firmware Version': '1.05.203' }])}
                                    trigger='click'
                                    rootClose
                                >
                                    <InfoIcon sx={{ color: '#167DB0' }} width={16} height={16} />
                                </OverlayTrigger>
                            </div>
                        </div>

                    </Col>
                    <Col className="w-auto d-flex flex-column justify-items-center justify-content-center p-0 m-0 m-0 me-lg-2">

                        <div className="d-flex flex-row justify-items-center justify-content-center p-0 m-0 ms-5">
                            <Card style={{ backgroundColor: "#fafafa" }}>
                                <Card.Body>
                                    <Card.Title className="text-center">
                                        Inverters
                                    </Card.Title>
                                    <div>
                                        <Image src={Inverter} width={50} height={90} />
                                    </div>

                                </Card.Body>
                            </Card>
                            <div className="m-4 align-self-start">
                                <OverlayTrigger
                                    placement="auto"
                                    overlay={renderTooltip([{ 'ID': 'AEO5' }, { 'Type': 'Micro Invertor' }, { 'Make': 'SolarEdge' }, { 'Serial Number': 'N-1034978' }, { 'Model Number': '205' }, { 'Power Rating': '5kw' }, { 'Firmware Version': '1.05.203' }])}
                                    trigger='click'
                                    rootClose
                                >
                                    <InfoIcon sx={{ color: '#167DB0' }} width={16} height={16} />
                                </OverlayTrigger>
                            </div>
                        </div>

                    </Col>
                    <Col className="w-auto d-flex flex-column justify-items-center justify-content-center p-0 m-0 m-0 me-lg-2">

                        <div className="d-flex flex-row justify-items-center justify-content-center p-0 m-0 ">
                            <Card style={{ backgroundColor: "#fafafa", marginLeft: '150px' }}>
                                <Card.Body>
                                    <Card.Title className="text-center" >
                                        Inverters
                                    </Card.Title>
                                    <div>
                                        <Image src={Inverter} width={50} height={90} />
                                    </div>
                                </Card.Body>
                            </Card>
                            <div className="m-4 align-self-start">
                                <OverlayTrigger
                                    placement="auto"
                                    overlay={renderTooltip([{ 'ID': 'AEO5' }, { 'Type': 'Micro Invertor' }, { 'Make': 'SolarEdge' }, { 'Serial Number': 'N-1034978' }, { 'Model Number': '205' }, { 'Power Rating': '5kw' }, { 'Firmware Version': '1.05.203' }])}
                                    trigger='click'
                                    rootClose
                                >
                                    <InfoIcon sx={{ color: '#167DB0' }} width={16} height={16} />
                                </OverlayTrigger>
                            </div>

                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default DevicesTab;