import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Legend,
} from "recharts";
import NoDataIcon from "./../../../assets/no-data-icon.svg";
import { ReactComponent as GridIcon } from "./../../../assets/grid2_icon.svg";
import { ReactComponent as InfoIcon } from "./../../../assets/information_icon.svg";
import expandAltIcon from "./../../../assets/expand_alt_icon.svg";
import sampleData from "./SampleData"; // Adjust the path as necessary
import { Card } from "react-bootstrap";

import dayjs from "dayjs";
import DateFilterOption from "./DateFilterOption";
import MuiDatePickerComponent from "./MuiDatePickerComponent";
import MuiDateRangePicker from "../../utilities/MuiDateRangePicker";


const HistoricalProduction = () => {

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [chartOption, setChartOption] = useState('year'); // Default to "year
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [xLabel, setxLabel] = useState('');

  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [update, setUpdate] = useState(false);

  const chartOptions = ["Day", "Month", "Year", "Total", 'Custom'];

  useEffect(() => {
    processData();
  }, [selectedDate, chartOption, update]);

  function customDateSelection(cDates) {
    setFromDate(cDates[0])
    setToDate(cDates[1])
    setUpdate(!update)
  }
  function customDateClear() {
    setFromDate(null)
    setToDate(null)
    setUpdate(!update)
  }

  const processData = () => {
    let filteredData = [];

    if (chartOption === 'Day') {
      const date = selectedDate.get('date');
      const month = selectedDate.get('month');
      const year = selectedDate.get('year');
      filteredData = sampleData
        .filter((item) => {
          const lDate = new Date(item.DATE);
          return (lDate.getDate() === date && lDate.getMonth() === month && lDate.getFullYear() === year)

        })
        .sort((a, b) => dayjs(a.DATE).diff(b.DATE) >= 0 ? 1 : -1)
        .map((item) => { 
          return {
            date: dayjs(item.DATE).format('LT'),
            production: parseInt(item.TOTAL_PRODUCTION) || 0,
          }
        })
    } else if (chartOption === 'Month') {
      const month = selectedDate.get('month');
      const year = selectedDate.get('year')
      filteredData = sampleData
        .filter((item) => {
          const date = dayjs(item.DATE);
          return (month === date.get('month') && year === date.get('year'))
        })
        .reduce((acc, item) => {
          let hm = dayjs(item.DATE).get('D');
          let index = acc.findIndex(k => k.date === hm);
          if (index === -1) {
            acc.push({
              date: hm ,
              production: parseInt(item.TOTAL_PRODUCTION) || 0,
            })
          } else {
            acc[index]["production"] += parseInt(item.TOTAL_PRODUCTION) || 0
          }
          return acc

        }, []).sort((a, b) => a.date - b.date);
    } else if (chartOption === "Year") {
      filteredData = sampleData
        .filter((item) => selectedDate.diff(item.DATE, 'y') === 0)
        .sort((a, b) => dayjs(a.DATE).get('month') - dayjs(b.DATE).get('month'))
        .reduce((acc, item) => {
          let hm = dayjs(item.DATE).format('MMM');
          let index = acc.findIndex(k => k.date === hm);
          if (index === -1) {
            acc.push({
              date: hm,
              production: parseInt(item.TOTAL_PRODUCTION) || 0,
            })
          } else {
            acc[index]["production"] += parseInt(item.TOTAL_PRODUCTION) || 0
          }
          return acc;
        }, []);
    } else if (chartOption === "Total") {
      filteredData = sampleData
        .filter((item) => selectedDate.diff(item.DATE, 'y') === 0)
        .sort((a, b) => dayjs(a.DATE).get('year') - dayjs(b.DATE).get('year'))
        .reduce((acc, item) => {
          let date = dayjs(item.DATE);
          let index = acc.findIndex(k => k.date === date.format('YYYY'));
          if (index === -1) {
            acc.push({
              date: date.format('YYYY'),
              production: parseInt(item.TOTAL_PRODUCTION) || 0,
            })
          } else {
            acc[index]["production"] += parseInt(item.TOTAL_PRODUCTION) || 0
          }
          return acc;
        }, [])
    } else if (chartOption === "Custom" && fromDate !== null && toDate !== null) {
      filteredData = sampleData.filter((item) => dayjs(item.DATE).isBetween(fromDate, toDate))
      .sort((a, b) => dayjs(a.DATE).diff(b.DATE) >= 0 ? 1 : -1)
        .reduce((acc, item) => {
          let hm = dayjs(item.DATE).format('DD MMM YYYY');
          let index = acc.findIndex(k => k.date === hm);
          if (index === -1) {
            acc.push({
              date: hm,
              production: parseInt(item.TOTAL_PRODUCTION) || 0,
            })
          } else {
            acc[index]["production"] += parseInt(item.TOTAL_PRODUCTION) || 0
          }
          return acc;
        }, [])
    }

    setData(filteredData);
  };

  const renderNoData = () => (
    <div className="hp-no-data-container">
      <img
        src={NoDataIcon}
        alt="No Data Available"
        className="hp-no-data-icon"
      />
      <p>No Data Available</p>
    </div>
  );

  const getDisplayLabel = () => {
    let label = "";
    if (chartOption === "Month") {
      label = selectedDate.toDate().toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });
    } else if (chartOption === "Year") {
      label = ` ${selectedDate.get('year')}`;
    } else if (chartOption === "Total") {
      const earliestYear = Math.min(
        ...sampleData.map((item) => new Date(item.DATE).getFullYear())
      );
      const latestYear = new Date().getFullYear();
      label = `${earliestYear} ~ ${latestYear}`;
    } else if (chartOption === "Custom" && fromDate !== null && toDate !== null) {

      label = `${dayjs(fromDate).format('DD/MMM/YYYY')} ~ ${dayjs(toDate).format('DD/MMM/YYYY')}`
    }
    return label;
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    const year = newValue.year();
    if (chartOption === 'year' && newValue) {
      setxLabel('Year ' + year);
    } else if (chartOption === 'month' && newValue) {
      setxLabel(newValue.format('MMMM') + ' ' + year);
    } else if (chartOption === 'custom' && newValue) {
      setxLabel(newValue.format(''))
    }
  };

  const chartOptionsChange = (event) => {
    setChartOption(event.target.value);
    if (event.target.value === 'total') {
      // setSelectedDate(null);
      setxLabel('Total');
    } else if (selectedDate) {
      handleDateChange(selectedDate);
    }
  };

  const getOpenToView = () => {
    // if(chartOption === 'week') return  'week'
    if (chartOption === 'Year') return 'year';
    if (chartOption === 'Month') return 'month';
    return 'day'; // Default to 'day' if needed
  };

  const renderData = () => {
    if (data.length === 0) {
      return renderNoData();
    }

    return (
      <BarChart
        data={data}
        margin={{ top: 10, right: 30, left: 10, bottom: 40 }} // Reduced the left margin to 20px
      >
        <CartesianGrid syncWithTicks vertical={false} strokeDasharray="4 4" strokeOpacity={0.5} />
        <XAxis dataKey="date" axisLine={false}
          tickLine={false} strokeOpacity={0.1} padding={{ left: 10 }} tick={{ fontSize: 11 }}>
          <Label
            value={getDisplayLabel()}
            offset={-5} // Adjust as needed
            position="insideBottom"
            style={{
              fontSize: "0.9rem",
              fill: "#333",
              textAnchor: "middle",
              fontFamily: "sans-serif",
              fontWeight: 600,
            }}
          />
        </XAxis>
        <YAxis tickFormatter={(value) => (parseFloat(value) >= 1000? parseInt(value) : parseInt(value))} axisLine={false}
          tickLine={false} padding={{ bottom: 5 }} strokeOpacity={0.01} tick={{ fontSize: 11 }}>
          <Label
             value={"Energy"}
             formatter={(value) => data.findIndex((val)=>val.production>=1000)!==-1?"Energy MWh":"Energy Kwh"}
             angle={-90}
            position="insideLeft"
            offset={10} // Adjust as needed
            style={{
              fontSize: "1.0rem",
              fill: "#333",
              textAnchor: "middle",
              fontFamily: "sans-serif",
              fontWeight: 600,
            }}
          />
        </YAxis>
        <Legend offset={10}
          formatter={(va) => 'Production'}
          align="right"
          wrapperStyle={{
            top: -20,
            left: 25,
            color: "#23453e",
          }}
          iconType="square" verticalAlign="top" />
        <Tooltip
          cursor={false}
          formatter={(value) =>
            typeof value === "number" ? (value >= 1000? value.toFixed(2) +"MWh" : value.toFixed(2) + " kWh") : "N/A"
          }
          contentStyle={{
            backgroundColor: "#eee",
            color: "#333",
            borderRadius: "5px",
            fontSize: "12px",
          }}
          labelStyle={{ color: "#333" }}
        />
        <Bar
          dataKey="production"
          fill="#68CE89"
          barSize={30} // Increase the bar size to reduce the gap
          radius={[4, 4, 4, 4]}
        />
      </BarChart>
    );
  };

  return (
    <Card className=" rounded-3 shadow-sm h-95" >
      <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center justify-content-between border-bottom border-bottom-2 shadow-sm border-secondary-subtle">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <GridIcon width={12} height={20} />
          <div className="production-title">Historical Production</div>
          <InfoIcon width={20} height={20} />
        </div>
        <div className="hp-controls">

          <div className="d-flex flex-row">
            <DateFilterOption options={chartOptions} onValueSelect={chartOptionsChange} />
            {chartOption === "Custom" ?
              <MuiDateRangePicker ok={customDateSelection} clean={customDateClear} /> : <MuiDatePickerComponent
                value={selectedDate}
                onChange={handleDateChange}
                views={chartOption === 'Year' ? ['year'] : chartOption === 'Month' ? ['year', 'month'] : ['day']}
                openTo={getOpenToView()}
                disabled={chartOption === 'Total'} />
            }
          </div>

          <div className="hp-divider" />
          <img
            src={expandAltIcon}
            alt="Expand"
            className="hp-expand-icon"
            onClick={() => setIsModalOpen(true)} // Open modal on click
          />
        </div>

      </Card.Header>
      <Card.Body>


        <div className="hp-chart-container">
          <ResponsiveContainer width="100%" height={200}>
            {renderData()}
          </ResponsiveContainer>
        </div>

        {/* Modal structure */}
        {isModalOpen && (
          <div className={`hp-modal open`}>
            <div className="hp-modal-content">
              <button
                className="hp-modal-close"
                onClick={() => setIsModalOpen(false)}
              >
                &times;
              </button>
              <div className="hp-modal-header">
                <h2 className="hp-modal-title">Historical Production</h2>
              </div>
              <div className="hp-modal-card-content">
                <ResponsiveContainer width="100%" height={400}>
                  {renderData()} {/* Reuse the same chart rendering logic */}
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        )}

      </Card.Body>
    </Card>
  );
};

export default HistoricalProduction;