export const HistoricalData=[
    {
      "datetime": "4/1/2023 6:00",
      "plant_id": "Green-tek-A001",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.65,
      "peak_power_hours": 1.6,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 806,
      "max temp": 36,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 38,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 14.4925,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "4/2/2023 6:15",
      "plant_id": "Green-tek-A002",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.5,
      "peak_power_hours": 2.1,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 814,
      "max temp": 27,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 51,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 20.163,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "4/3/2023 6:30",
      "plant_id": "Green-tek-A003",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 3.2,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 732,
      "max temp": 40,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 35,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 19.36,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "4/4/2023 6:45",
      "plant_id": "Green-tek-A004",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 1.7,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 797,
      "max temp": 26,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 36,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 16.5165,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "4/5/2023 7:00",
      "plant_id": "Green-tek-A005",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 2.3,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 848,
      "max temp": 33,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 37,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 16.83,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "4/6/2023 7:15",
      "plant_id": "Green-tek-A006",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 3.2,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 815,
      "max temp": 37,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 35,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 15.708,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "4/7/2023 7:30",
      "plant_id": "Green-tek-A007",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 1.5,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 822,
      "max temp": 26,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 42,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 16.4615,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "4/8/2023 7:45",
      "plant_id": "Green-tek-A008",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 2.5,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 841,
      "max temp": 42,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 47,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 15.895,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "4/9/2023 8:00",
      "plant_id": "Green-tek-A009",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 1.7,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 749,
      "max temp": 33,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 37,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 18.7,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "4/10/2023 8:15",
      "plant_id": "Green-tek-A010",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 1.7,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 827,
      "max temp": 36,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 47,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 16.863,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "4/11/2023 8:30",
      "plant_id": "Green-tek-A011",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 1.9,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 733,
      "max temp": 31,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 48,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 18.66975,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "4/12/2023 8:45",
      "plant_id": "Green-tek-A012",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 2.7,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 746,
      "max temp": 34,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 42,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 16.698,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "4/13/2023 9:00",
      "plant_id": "Green-tek-A013",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 1.5,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 774,
      "max temp": 29,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 53,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 16.5,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "4/14/2023 9:15",
      "plant_id": "Green-tek-A014",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.4,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 816,
      "max temp": 30,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 54,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 16.016,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "4/15/2023 9:30",
      "plant_id": "Green-tek-A015",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 2.5,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 737,
      "max temp": 25,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 38,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 18.81,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "4/16/2023 9:45",
      "plant_id": "Green-tek-A016",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 1.5,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 758,
      "max temp": 42,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 52,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 17.9025,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "4/17/2023 10:00",
      "plant_id": "Green-tek-A017",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 2.1,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 725,
      "max temp": 31,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 40,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 15.73,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "4/18/2023 10:15",
      "plant_id": "Green-tek-A018",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 2.6,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 751,
      "max temp": 27,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 42,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 17.3195,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "4/19/2023 10:30",
      "plant_id": "Green-tek-A019",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 2,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 820,
      "max temp": 27,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 38,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 17.9025,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "4/20/2023 10:45",
      "plant_id": "Green-tek-A020",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 2.4,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 738,
      "max temp": 36,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 44,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 16.60725,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "4/21/2023 11:00",
      "plant_id": "Green-tek-A021",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 3.1,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 769,
      "max temp": 31,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 40,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 17.99875,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "4/22/2023 11:15",
      "plant_id": "Green-tek-A022",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.4,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 827,
      "max temp": 34,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 46,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 16.643,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "4/23/2023 11:30",
      "plant_id": "Green-tek-A023",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 2,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 790,
      "max temp": 26,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 42,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 17.50375,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "4/24/2023 11:45",
      "plant_id": "Green-tek-A024",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 3,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 850,
      "max temp": 40,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 48,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 14.432,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "4/25/2023 12:00",
      "plant_id": "Green-tek-A025",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 3,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 819,
      "max temp": 29,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 37,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 16.2855,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "4/26/2023 12:15",
      "plant_id": "Green-tek-A026",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 3.1,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 787,
      "max temp": 34,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 54,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 20.35,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "4/27/2023 12:30",
      "plant_id": "Green-tek-A027",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 3.1,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 825,
      "max temp": 33,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 36,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 17.94375,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "4/28/2023 12:45",
      "plant_id": "Green-tek-A028",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 2.7,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 843,
      "max temp": 33,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 39,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 16.76675,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "4/29/2023 13:00",
      "plant_id": "Green-tek-A029",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 3.3,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 807,
      "max temp": 36,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 55,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 18.6615,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "4/30/2023 13:15",
      "plant_id": "Green-tek-A030",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 1.5,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 755,
      "max temp": 35,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 41,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 20.1465,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "5/1/2023 13:30",
      "plant_id": "Green-tek-A031",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 1.7,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 777,
      "max temp": 33,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 47,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 16.62375,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "5/2/2023 13:45",
      "plant_id": "Green-tek-A032",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 3.3,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 840,
      "max temp": 35,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 39,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 20.46,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "5/3/2023 14:00",
      "plant_id": "Green-tek-A033",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 3.1,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 741,
      "max temp": 40,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 44,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 18.66975,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "5/4/2023 14:15",
      "plant_id": "Green-tek-A034",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 1.9,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 776,
      "max temp": 42,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 35,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 18.6835,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "5/5/2023 14:30",
      "plant_id": "Green-tek-A035",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 3,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 746,
      "max temp": 29,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 37,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 14.96,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "5/6/2023 14:45",
      "plant_id": "Green-tek-A036",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 3.2,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 718,
      "max temp": 31,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 38,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 20.064,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "5/7/2023 15:00",
      "plant_id": "Green-tek-A037",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.4,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 703,
      "max temp": 37,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 44,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 19.7395,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "5/8/2023 15:15",
      "plant_id": "Green-tek-A038",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 3.4,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 841,
      "max temp": 27,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 43,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 15.488,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "5/9/2023 15:30",
      "plant_id": "Green-tek-A039",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 2.2,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 821,
      "max temp": 40,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 44,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 17.974,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "5/10/2023 15:45",
      "plant_id": "Green-tek-A040",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 2.9,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 706,
      "max temp": 40,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 42,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 17.7375,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "5/11/2023 16:00",
      "plant_id": "Green-tek-A041",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 2.5,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 825,
      "max temp": 41,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 36,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 15.686,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "5/12/2023 16:15",
      "plant_id": "Green-tek-A042",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2.5,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 791,
      "max temp": 30,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 53,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 20.075,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "5/13/2023 16:30",
      "plant_id": "Green-tek-A043",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 1.7,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 784,
      "max temp": 29,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 53,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 17.666,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "5/14/2023 16:45",
      "plant_id": "Green-tek-A044",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 2.4,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 746,
      "max temp": 31,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 44,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 14.8335,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "5/15/2023 17:00",
      "plant_id": "Green-tek-A045",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 2,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 759,
      "max temp": 38,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 53,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 17.33875,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "5/16/2023 17:15",
      "plant_id": "Green-tek-A046",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 2.9,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 846,
      "max temp": 30,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 50,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 14.784,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "5/17/2023 17:30",
      "plant_id": "Green-tek-A047",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 3.2,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 731,
      "max temp": 26,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 48,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 15.29275,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "5/18/2023 17:45",
      "plant_id": "Green-tek-A048",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 3.1,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 724,
      "max temp": 28,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 37,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 18.5185,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "5/19/2023 18:00",
      "plant_id": "Green-tek-A049",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 1.8,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 736,
      "max temp": 40,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 48,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 14.59425,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "5/20/2023 18:15",
      "plant_id": "Green-tek-A050",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 2.6,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 776,
      "max temp": 29,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 42,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 18.92,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "5/21/2023 18:30",
      "plant_id": "Green-tek-A051",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.6,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 836,
      "max temp": 30,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 48,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 17.15175,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "5/22/2023 18:45",
      "plant_id": "Green-tek-A052",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.5,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 824,
      "max temp": 41,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 53,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 14.608,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "5/23/2023 19:00",
      "plant_id": "Green-tek-A053",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 3,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 732,
      "max temp": 26,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 44,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 16.17,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "5/24/2023 19:15",
      "plant_id": "Green-tek-A054",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 1.5,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 788,
      "max temp": 25,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 42,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 18.6725,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "5/25/2023 19:30",
      "plant_id": "Green-tek-A055",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.55,
      "peak_power_hours": 2.9,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 719,
      "max temp": 33,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 38,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 16.17,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "5/26/2023 19:45",
      "plant_id": "Green-tek-A056",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.65,
      "peak_power_hours": 2.5,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 721,
      "max temp": 33,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 38,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 19.0905,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "5/27/2023 20:00",
      "plant_id": "Green-tek-A057",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 1.6,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 819,
      "max temp": 35,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 47,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 14.83625,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "5/28/2023 20:15",
      "plant_id": "Green-tek-A058",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 2.3,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 842,
      "max temp": 27,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 48,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 17.138,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "5/29/2023 20:30",
      "plant_id": "Green-tek-A059",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 3.4,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 791,
      "max temp": 35,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 53,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 18.15,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "5/30/2023 20:45",
      "plant_id": "Green-tek-A060",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 1.8,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 772,
      "max temp": 38,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 49,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 14.52,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "5/31/2023 21:00",
      "plant_id": "Green-tek-A061",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 2,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 804,
      "max temp": 38,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 54,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 17.6,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "6/1/2023 21:15",
      "plant_id": "Green-tek-A062",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 3.2,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 817,
      "max temp": 40,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 46,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 17.7375,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "6/2/2023 21:30",
      "plant_id": "Green-tek-A063",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 2,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 728,
      "max temp": 25,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 54,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 17.424,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "6/3/2023 21:45",
      "plant_id": "Green-tek-A064",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.8,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 800,
      "max temp": 37,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 52,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 19.118,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "6/4/2023 22:00",
      "plant_id": "Green-tek-A065",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 3,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 730,
      "max temp": 34,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 36,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 17.59725,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "6/5/2023 22:15",
      "plant_id": "Green-tek-A066",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 1.8,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 722,
      "max temp": 30,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 41,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 17.33875,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "6/6/2023 22:30",
      "plant_id": "Green-tek-A067",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.1,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 786,
      "max temp": 31,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 39,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 20.35,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "6/7/2023 22:45",
      "plant_id": "Green-tek-A068",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 1.9,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 722,
      "max temp": 25,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 37,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 15.345,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "6/8/2023 23:00",
      "plant_id": "Green-tek-A069",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 1.9,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 805,
      "max temp": 30,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 54,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 17.325,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "6/9/2023 23:15",
      "plant_id": "Green-tek-A070",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 3.3,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 746,
      "max temp": 28,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 53,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 16.9785,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "6/10/2023 23:30",
      "plant_id": "Green-tek-A071",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 2.1,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 754,
      "max temp": 27,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 44,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 15.334,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "6/11/2023 23:45",
      "plant_id": "Green-tek-A072",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 2.2,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 827,
      "max temp": 42,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 51,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 17.061,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "6/13/2023 0:00",
      "plant_id": "Green-tek-A073",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2.1,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 797,
      "max temp": 37,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 40,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 18.03175,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "6/14/2023 0:15",
      "plant_id": "Green-tek-A074",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 1.9,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 830,
      "max temp": 41,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 42,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 16.632,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "6/15/2023 0:30",
      "plant_id": "Green-tek-A075",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 3.1,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 753,
      "max temp": 28,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 36,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 18.46625,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "6/16/2023 0:45",
      "plant_id": "Green-tek-A076",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 828,
      "max temp": 32,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 40,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 15.488,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "6/17/2023 1:00",
      "plant_id": "Green-tek-A077",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 3.4,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 828,
      "max temp": 29,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 48,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 16.17,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "6/18/2023 1:15",
      "plant_id": "Green-tek-A078",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 2.2,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 727,
      "max temp": 30,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 38,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 17.6,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "6/19/2023 1:30",
      "plant_id": "Green-tek-A079",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 2.5,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 805,
      "max temp": 25,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 38,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 19.855,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "6/20/2023 1:45",
      "plant_id": "Green-tek-A080",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 3.3,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 783,
      "max temp": 28,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 42,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 20.00625,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "6/21/2023 2:00",
      "plant_id": "Green-tek-A081",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 2.8,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 817,
      "max temp": 33,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 54,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 17.347,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "6/22/2023 2:15",
      "plant_id": "Green-tek-A082",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 1.7,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 823,
      "max temp": 36,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 53,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 15.66125,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "6/23/2023 2:30",
      "plant_id": "Green-tek-A083",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 3.2,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 779,
      "max temp": 42,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 46,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 19.0575,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "6/24/2023 2:45",
      "plant_id": "Green-tek-A084",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.9,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 826,
      "max temp": 40,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 50,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 18.40575,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "6/25/2023 3:00",
      "plant_id": "Green-tek-A085",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 1.7,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 701,
      "max temp": 36,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 35,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 17.787,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "6/26/2023 3:15",
      "plant_id": "Green-tek-A086",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 1.5,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 749,
      "max temp": 26,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 35,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 19.8,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "6/27/2023 3:30",
      "plant_id": "Green-tek-A087",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 2.6,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 710,
      "max temp": 32,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 55,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 15.81525,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "6/28/2023 3:45",
      "plant_id": "Green-tek-A088",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 1.9,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 821,
      "max temp": 29,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 38,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 17.38,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "6/29/2023 4:00",
      "plant_id": "Green-tek-A089",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 2.3,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 810,
      "max temp": 36,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 50,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 19.76975,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "6/30/2023 4:15",
      "plant_id": "Green-tek-A090",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 3.5,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 771,
      "max temp": 41,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 44,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 18.42225,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "7/1/2023 4:30",
      "plant_id": "Green-tek-A091",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 2.2,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 842,
      "max temp": 26,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 49,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 17.5725,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "7/2/2023 4:45",
      "plant_id": "Green-tek-A092",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 3.3,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 842,
      "max temp": 41,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 51,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 15.73,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "7/3/2023 5:00",
      "plant_id": "Green-tek-A093",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 1.5,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 714,
      "max temp": 32,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 48,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 16.12875,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "7/4/2023 5:15",
      "plant_id": "Green-tek-A094",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 2.9,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 774,
      "max temp": 42,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 52,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 13.981,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "7/5/2023 5:30",
      "plant_id": "Green-tek-A095",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 2,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 748,
      "max temp": 34,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 49,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 19.855,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "7/6/2023 5:45",
      "plant_id": "Green-tek-A096",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 1.7,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 786,
      "max temp": 27,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 40,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 13.86,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "7/7/2023 6:00",
      "plant_id": "Green-tek-A097",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 2.9,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 732,
      "max temp": 37,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 51,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 14.355,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "7/8/2023 6:15",
      "plant_id": "Green-tek-A098",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 2.8,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 796,
      "max temp": 39,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 54,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 16.863,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "7/9/2023 6:30",
      "plant_id": "Green-tek-A099",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 3.1,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 774,
      "max temp": 38,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 51,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 17.666,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "7/10/2023 6:45",
      "plant_id": "Green-tek-A100",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 2.8,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 776,
      "max temp": 40,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 44,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 17.57525,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "7/11/2023 6:59",
      "plant_id": "Green-tek-A101",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 2.9,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 741,
      "max temp": 34,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 43,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 16.59625,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "7/12/2023 7:14",
      "plant_id": "Green-tek-A102",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 2.8,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 778,
      "max temp": 27,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 55,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 16.027,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "7/13/2023 7:29",
      "plant_id": "Green-tek-A103",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 3.2,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 816,
      "max temp": 30,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 50,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 18.612,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "7/14/2023 7:44",
      "plant_id": "Green-tek-A104",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.3,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 745,
      "max temp": 29,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 36,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 17.622,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "7/15/2023 7:59",
      "plant_id": "Green-tek-A105",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 3.3,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 784,
      "max temp": 28,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 54,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 15.686,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "7/16/2023 8:14",
      "plant_id": "Green-tek-A106",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 3.4,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 765,
      "max temp": 40,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 51,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 13.64,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "7/17/2023 8:29",
      "plant_id": "Green-tek-A107",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 2.9,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 754,
      "max temp": 42,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 40,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 17.094,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "7/18/2023 8:44",
      "plant_id": "Green-tek-A108",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.75,
      "peak_power_hours": 3.5,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 827,
      "max temp": 30,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 50,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 14.96,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "7/19/2023 8:59",
      "plant_id": "Green-tek-A109",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.55,
      "peak_power_hours": 2.5,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 828,
      "max temp": 31,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 48,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 18.24075,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "7/20/2023 9:14",
      "plant_id": "Green-tek-A110",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 3.4,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 788,
      "max temp": 37,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 53,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 21.725,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "7/21/2023 9:29",
      "plant_id": "Green-tek-A111",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 3.5,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 804,
      "max temp": 30,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 42,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 19.3325,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "7/22/2023 9:44",
      "plant_id": "Green-tek-A112",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 2.5,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 817,
      "max temp": 28,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 47,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 13.64,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "7/23/2023 9:59",
      "plant_id": "Green-tek-A113",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2.7,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 781,
      "max temp": 30,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 49,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 17.5725,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "7/24/2023 10:14",
      "plant_id": "Green-tek-A114",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.5,
      "peak_power_hours": 1.5,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 725,
      "max temp": 27,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 43,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 18.92,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "7/25/2023 10:29",
      "plant_id": "Green-tek-A115",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 2.2,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 783,
      "max temp": 34,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 50,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 17.0775,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "7/26/2023 10:44",
      "plant_id": "Green-tek-A116",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.1,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 714,
      "max temp": 32,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 54,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 15.26525,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "7/27/2023 10:59",
      "plant_id": "Green-tek-A117",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 3.3,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 789,
      "max temp": 37,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 39,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 17.3745,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "7/28/2023 11:14",
      "plant_id": "Green-tek-A118",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 3.4,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 766,
      "max temp": 42,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 42,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 17.3635,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "7/29/2023 11:29",
      "plant_id": "Green-tek-A119",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 1.5,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 812,
      "max temp": 35,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 39,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 20.63875,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "7/30/2023 11:44",
      "plant_id": "Green-tek-A120",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.65,
      "peak_power_hours": 2.9,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 753,
      "max temp": 36,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 42,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 16.62375,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "7/31/2023 11:59",
      "plant_id": "Green-tek-A121",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 3.1,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 794,
      "max temp": 37,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 39,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 16.7915,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "8/1/2023 12:14",
      "plant_id": "Green-tek-A122",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 2,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 808,
      "max temp": 39,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 35,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 17.15175,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "8/2/2023 12:29",
      "plant_id": "Green-tek-A123",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 2.3,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 841,
      "max temp": 41,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 54,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 18.2105,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "8/3/2023 12:44",
      "plant_id": "Green-tek-A124",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 3,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 780,
      "max temp": 28,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 50,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 18.6615,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "8/4/2023 12:59",
      "plant_id": "Green-tek-A125",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 2.4,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 706,
      "max temp": 33,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 55,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 16.687,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "8/5/2023 13:14",
      "plant_id": "Green-tek-A126",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 1.6,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 701,
      "max temp": 28,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 48,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 19.47275,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "8/6/2023 13:29",
      "plant_id": "Green-tek-A127",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 3.4,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 720,
      "max temp": 30,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 45,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 17.69625,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "8/7/2023 13:44",
      "plant_id": "Green-tek-A128",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.35,
      "peak_power_hours": 2.5,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 793,
      "max temp": 29,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 48,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 15.972,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "8/8/2023 13:59",
      "plant_id": "Green-tek-A129",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.5,
      "peak_power_hours": 1.8,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 828,
      "max temp": 37,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 48,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 16.4835,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "8/9/2023 14:14",
      "plant_id": "Green-tek-A130",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 2.1,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 831,
      "max temp": 27,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 50,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 16.0105,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "8/10/2023 14:29",
      "plant_id": "Green-tek-A131",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 2.6,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 772,
      "max temp": 30,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 41,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 18.183,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "8/11/2023 14:44",
      "plant_id": "Green-tek-A132",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 2.3,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 778,
      "max temp": 37,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 51,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 19.646,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "8/12/2023 14:59",
      "plant_id": "Green-tek-A133",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 1.7,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 712,
      "max temp": 42,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 47,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 18.6615,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "8/13/2023 15:14",
      "plant_id": "Green-tek-A134",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 1.5,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 732,
      "max temp": 27,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 41,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 19.58,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "8/14/2023 15:29",
      "plant_id": "Green-tek-A135",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 1.9,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 728,
      "max temp": 30,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 53,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 14.59425,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "8/15/2023 15:44",
      "plant_id": "Green-tek-A136",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 3.1,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 804,
      "max temp": 41,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 55,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 15.74925,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "8/16/2023 15:59",
      "plant_id": "Green-tek-A137",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 3.1,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 714,
      "max temp": 27,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 42,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 18.8705,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "8/17/2023 16:14",
      "plant_id": "Green-tek-A138",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 1.9,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 771,
      "max temp": 37,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 36,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 13.8105,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "8/18/2023 16:29",
      "plant_id": "Green-tek-A139",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 1.9,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 720,
      "max temp": 42,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 38,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 18.81,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "8/19/2023 16:44",
      "plant_id": "Green-tek-A140",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 1.6,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 834,
      "max temp": 26,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 37,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 17.76775,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "8/20/2023 16:59",
      "plant_id": "Green-tek-A141",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 2.7,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 750,
      "max temp": 37,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 39,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 16.94,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "8/21/2023 17:14",
      "plant_id": "Green-tek-A142",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.5,
      "peak_power_hours": 3.2,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 770,
      "max temp": 31,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 49,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 16.8795,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "8/22/2023 17:29",
      "plant_id": "Green-tek-A143",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.45,
      "peak_power_hours": 2.7,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 740,
      "max temp": 41,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 49,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 17.204,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "8/23/2023 17:44",
      "plant_id": "Green-tek-A144",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 3.4,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 838,
      "max temp": 41,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 45,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 18.66975,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "8/24/2023 17:59",
      "plant_id": "Green-tek-A145",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 1.5,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 728,
      "max temp": 32,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 38,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 20.9,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "8/25/2023 18:14",
      "plant_id": "Green-tek-A146",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 3.4,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 813,
      "max temp": 36,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 49,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 17.3745,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "8/26/2023 18:29",
      "plant_id": "Green-tek-A147",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.7,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 738,
      "max temp": 39,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 46,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 18.216,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "8/27/2023 18:44",
      "plant_id": "Green-tek-A148",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 1.5,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 705,
      "max temp": 30,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 35,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 17.424,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "8/28/2023 18:59",
      "plant_id": "Green-tek-A149",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.75,
      "peak_power_hours": 3.5,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 847,
      "max temp": 33,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 47,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 14.2065,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "8/29/2023 19:14",
      "plant_id": "Green-tek-A150",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 2.2,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 736,
      "max temp": 34,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 40,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 15.5595,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "8/30/2023 19:29",
      "plant_id": "Green-tek-A151",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.65,
      "peak_power_hours": 3.1,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 818,
      "max temp": 31,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 54,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 16.038,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "8/31/2023 19:44",
      "plant_id": "Green-tek-A152",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 2.5,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 843,
      "max temp": 42,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 44,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 16.8795,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "9/1/2023 19:59",
      "plant_id": "Green-tek-A153",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 1.7,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 727,
      "max temp": 37,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 38,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 20.328,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "9/2/2023 20:14",
      "plant_id": "Green-tek-A154",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.75,
      "peak_power_hours": 2.9,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 722,
      "max temp": 37,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 40,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 14.92425,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "9/3/2023 20:29",
      "plant_id": "Green-tek-A155",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 3,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 773,
      "max temp": 36,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 49,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 16.269,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "9/4/2023 20:44",
      "plant_id": "Green-tek-A156",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 2.4,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 749,
      "max temp": 40,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 48,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 16.1975,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "9/5/2023 20:59",
      "plant_id": "Green-tek-A157",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 791,
      "max temp": 33,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 52,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 16.687,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "9/6/2023 21:14",
      "plant_id": "Green-tek-A158",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2.3,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 754,
      "max temp": 29,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 37,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 15.345,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "9/7/2023 21:29",
      "plant_id": "Green-tek-A159",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 2.4,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 768,
      "max temp": 33,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 37,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 16.434,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "9/8/2023 21:44",
      "plant_id": "Green-tek-A160",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 1.9,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 843,
      "max temp": 38,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 54,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 17.1325,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "9/9/2023 21:59",
      "plant_id": "Green-tek-A161",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.35,
      "peak_power_hours": 1.5,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 717,
      "max temp": 30,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 40,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 16.17,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "9/10/2023 22:14",
      "plant_id": "Green-tek-A162",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 2.1,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 845,
      "max temp": 34,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 47,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 17.46525,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "9/11/2023 22:29",
      "plant_id": "Green-tek-A163",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 846,
      "max temp": 27,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 44,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 16.951,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "9/12/2023 22:44",
      "plant_id": "Green-tek-A164",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 3,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 736,
      "max temp": 34,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 44,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 17.38,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "9/13/2023 22:59",
      "plant_id": "Green-tek-A165",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.75,
      "peak_power_hours": 2.5,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 763,
      "max temp": 41,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 47,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 17.87225,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "9/14/2023 23:14",
      "plant_id": "Green-tek-A166",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 3.2,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 772,
      "max temp": 28,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 35,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 14.59425,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "9/15/2023 23:29",
      "plant_id": "Green-tek-A167",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 3,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 753,
      "max temp": 31,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 48,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 18.216,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "9/16/2023 23:44",
      "plant_id": "Green-tek-A168",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 2.7,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 761,
      "max temp": 42,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 41,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 17.325,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "9/17/2023 23:59",
      "plant_id": "Green-tek-A169",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 2.5,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 833,
      "max temp": 27,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 39,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 15.36975,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "9/19/2023 0:14",
      "plant_id": "Green-tek-A170",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 1.8,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 712,
      "max temp": 33,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 36,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 18.0675,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "9/20/2023 0:29",
      "plant_id": "Green-tek-A171",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 1.9,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 763,
      "max temp": 38,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 37,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 14.8335,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "9/21/2023 0:44",
      "plant_id": "Green-tek-A172",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 3.2,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 709,
      "max temp": 30,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 42,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 13.53,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "9/22/2023 0:59",
      "plant_id": "Green-tek-A173",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 2.6,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 783,
      "max temp": 41,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 55,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 20.1465,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "9/23/2023 1:14",
      "plant_id": "Green-tek-A174",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 2.3,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 726,
      "max temp": 37,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 47,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 17.226,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "9/24/2023 1:29",
      "plant_id": "Green-tek-A175",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 2,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 804,
      "max temp": 37,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 49,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 17.666,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "9/25/2023 1:44",
      "plant_id": "Green-tek-A176",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 3.4,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 806,
      "max temp": 32,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 54,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 14.7015,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "9/26/2023 1:59",
      "plant_id": "Green-tek-A177",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.55,
      "peak_power_hours": 2.7,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 818,
      "max temp": 41,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 37,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 14.25875,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "9/27/2023 2:14",
      "plant_id": "Green-tek-A178",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 1.6,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 729,
      "max temp": 42,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 39,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 16.8905,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "9/28/2023 2:29",
      "plant_id": "Green-tek-A179",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 1.7,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 774,
      "max temp": 26,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 48,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 16.60725,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "9/29/2023 2:44",
      "plant_id": "Green-tek-A180",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 2,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 814,
      "max temp": 32,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 38,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 14.08,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "9/30/2023 2:59",
      "plant_id": "Green-tek-A181",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 3.2,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 790,
      "max temp": 27,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 54,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 18.722,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "10/1/2023 3:14",
      "plant_id": "Green-tek-A182",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 2.7,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 771,
      "max temp": 34,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 49,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 16.214,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "10/2/2023 3:29",
      "plant_id": "Green-tek-A183",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.55,
      "peak_power_hours": 3.5,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 719,
      "max temp": 42,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 42,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 14.6575,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "10/3/2023 3:44",
      "plant_id": "Green-tek-A184",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2.3,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 700,
      "max temp": 25,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 42,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 16.39825,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "10/4/2023 3:59",
      "plant_id": "Green-tek-A185",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 2.7,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 810,
      "max temp": 35,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 37,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 16.5,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "10/5/2023 4:14",
      "plant_id": "Green-tek-A186",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 3.4,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 846,
      "max temp": 41,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 55,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 18.8705,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "10/6/2023 4:29",
      "plant_id": "Green-tek-A187",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 1.7,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 766,
      "max temp": 30,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 55,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 15.07275,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "10/7/2023 4:44",
      "plant_id": "Green-tek-A188",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 2.1,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 749,
      "max temp": 26,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 50,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 18.42225,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "10/8/2023 4:59",
      "plant_id": "Green-tek-A189",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 2.2,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 761,
      "max temp": 31,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 55,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 20.9,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "10/9/2023 5:14",
      "plant_id": "Green-tek-A190",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 3.1,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 731,
      "max temp": 29,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 52,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 17.33875,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "10/10/2023 5:29",
      "plant_id": "Green-tek-A191",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2.9,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 806,
      "max temp": 40,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 48,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 19.87425,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "10/11/2023 5:44",
      "plant_id": "Green-tek-A192",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 3.2,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 758,
      "max temp": 38,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 41,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 18.46625,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "10/12/2023 5:59",
      "plant_id": "Green-tek-A193",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2.2,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 707,
      "max temp": 26,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 52,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 17.325,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "10/13/2023 6:14",
      "plant_id": "Green-tek-A194",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 1.5,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 820,
      "max temp": 28,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 51,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 16.9785,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "10/14/2023 6:29",
      "plant_id": "Green-tek-A195",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2.7,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 729,
      "max temp": 38,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 54,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 16.98675,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "10/15/2023 6:44",
      "plant_id": "Green-tek-A196",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 1.6,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 780,
      "max temp": 31,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 55,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 16.1535,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "10/16/2023 6:59",
      "plant_id": "Green-tek-A197",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.35,
      "peak_power_hours": 2.1,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 798,
      "max temp": 26,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 41,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 16.62375,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "10/17/2023 7:14",
      "plant_id": "Green-tek-A198",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 1.7,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 808,
      "max temp": 25,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 44,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 15.939,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "10/18/2023 7:29",
      "plant_id": "Green-tek-A199",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 2,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 756,
      "max temp": 29,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 48,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 15.26525,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "10/19/2023 7:44",
      "plant_id": "Green-tek-A200",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.55,
      "peak_power_hours": 3.5,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 750,
      "max temp": 40,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 48,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 17.7045,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "10/20/2023 7:59",
      "plant_id": "Green-tek-A201",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 1.6,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 846,
      "max temp": 33,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 35,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 17.6,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "10/21/2023 8:14",
      "plant_id": "Green-tek-A202",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 3.4,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 753,
      "max temp": 37,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 37,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 17.0775,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "10/22/2023 8:29",
      "plant_id": "Green-tek-A203",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 1.8,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 808,
      "max temp": 26,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 41,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 18.5185,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "10/23/2023 8:44",
      "plant_id": "Green-tek-A204",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 2.6,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 780,
      "max temp": 34,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 48,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 16.94,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "10/24/2023 8:59",
      "plant_id": "Green-tek-A205",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 2.5,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 765,
      "max temp": 32,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 42,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 14.19,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "10/25/2023 9:14",
      "plant_id": "Green-tek-A206",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 1.5,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 820,
      "max temp": 31,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 48,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 14.6575,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "10/26/2023 9:29",
      "plant_id": "Green-tek-A207",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 2.9,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 772,
      "max temp": 40,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 49,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 18.15,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "10/27/2023 9:44",
      "plant_id": "Green-tek-A208",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.65,
      "peak_power_hours": 1.8,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 822,
      "max temp": 27,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 50,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 21.2355,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "10/28/2023 9:59",
      "plant_id": "Green-tek-A209",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 2.3,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 785,
      "max temp": 28,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 49,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 15.1085,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "10/29/2023 10:14",
      "plant_id": "Green-tek-A210",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.45,
      "peak_power_hours": 3,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 840,
      "max temp": 41,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 50,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 17.875,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "10/30/2023 10:29",
      "plant_id": "Green-tek-A211",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.45,
      "peak_power_hours": 1.9,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 831,
      "max temp": 27,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 49,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 17.666,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "10/31/2023 10:44",
      "plant_id": "Green-tek-A212",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.1,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 812,
      "max temp": 37,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 45,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 17.16,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "11/1/2023 10:59",
      "plant_id": "Green-tek-A213",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 3.4,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 764,
      "max temp": 30,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 38,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 16.709,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "11/2/2023 11:14",
      "plant_id": "Green-tek-A214",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.65,
      "peak_power_hours": 2.5,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 778,
      "max temp": 27,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 49,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 15.29275,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "11/3/2023 11:29",
      "plant_id": "Green-tek-A215",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 1.6,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 707,
      "max temp": 40,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 43,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 18.0675,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "11/4/2023 11:44",
      "plant_id": "Green-tek-A216",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.55,
      "peak_power_hours": 1.7,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 817,
      "max temp": 27,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 51,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 16.709,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "11/5/2023 11:59",
      "plant_id": "Green-tek-A217",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 1.5,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 777,
      "max temp": 41,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 54,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 21.175,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "11/6/2023 12:14",
      "plant_id": "Green-tek-A218",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.4,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 762,
      "max temp": 28,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 46,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 18.601,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "11/7/2023 12:29",
      "plant_id": "Green-tek-A219",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 3,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 818,
      "max temp": 25,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 41,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 15.4275,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "11/8/2023 12:44",
      "plant_id": "Green-tek-A220",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 1.6,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 797,
      "max temp": 41,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 53,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 17.072,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "11/9/2023 12:59",
      "plant_id": "Green-tek-A221",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.45,
      "peak_power_hours": 2.1,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 706,
      "max temp": 28,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 38,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 16.4615,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "11/10/2023 13:14",
      "plant_id": "Green-tek-A222",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 1.6,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 799,
      "max temp": 29,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 55,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 17.765,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "11/11/2023 13:29",
      "plant_id": "Green-tek-A223",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 3.4,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 792,
      "max temp": 31,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 36,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 17.1325,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "11/12/2023 13:44",
      "plant_id": "Green-tek-A224",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 2.6,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 835,
      "max temp": 36,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 35,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 14.2065,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "11/13/2023 13:59",
      "plant_id": "Green-tek-A225",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 2.7,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 841,
      "max temp": 32,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 41,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 16.643,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "11/14/2023 14:14",
      "plant_id": "Green-tek-A226",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 1.6,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 763,
      "max temp": 40,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 38,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 16.0105,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "11/15/2023 14:29",
      "plant_id": "Green-tek-A227",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 2,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 801,
      "max temp": 32,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 44,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 18.03175,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "11/16/2023 14:44",
      "plant_id": "Green-tek-A228",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 1.5,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 788,
      "max temp": 40,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 36,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 16.9785,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "11/17/2023 14:59",
      "plant_id": "Green-tek-A229",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 2.6,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 732,
      "max temp": 25,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 51,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 13.64,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "11/18/2023 15:14",
      "plant_id": "Green-tek-A230",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 3.2,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 702,
      "max temp": 29,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 50,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 21.2355,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "11/19/2023 15:29",
      "plant_id": "Green-tek-A231",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.55,
      "peak_power_hours": 2.6,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 707,
      "max temp": 29,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 39,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 15.1085,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "11/20/2023 15:44",
      "plant_id": "Green-tek-A232",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 1.5,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 735,
      "max temp": 27,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 40,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 20.46,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "11/21/2023 15:59",
      "plant_id": "Green-tek-A233",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 3,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 844,
      "max temp": 39,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 43,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 17.138,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "11/22/2023 16:14",
      "plant_id": "Green-tek-A234",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.5,
      "peak_power_hours": 1.8,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 817,
      "max temp": 41,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 39,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 20.7515,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "11/23/2023 16:29",
      "plant_id": "Green-tek-A235",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 2.4,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 715,
      "max temp": 35,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 48,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 17.6055,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "11/24/2023 16:44",
      "plant_id": "Green-tek-A236",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 1.5,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 799,
      "max temp": 41,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 52,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 15.1085,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "11/25/2023 16:59",
      "plant_id": "Green-tek-A237",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.2,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 712,
      "max temp": 26,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 54,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 20.3775,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "11/26/2023 17:14",
      "plant_id": "Green-tek-A238",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 2.4,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 758,
      "max temp": 39,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 37,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 14.7015,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "11/27/2023 17:29",
      "plant_id": "Green-tek-A239",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.45,
      "peak_power_hours": 2.4,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 840,
      "max temp": 34,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 35,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 16.896,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "11/28/2023 17:44",
      "plant_id": "Green-tek-A240",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 2.7,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 791,
      "max temp": 35,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 54,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 19.0575,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "11/29/2023 17:59",
      "plant_id": "Green-tek-A241",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 3.3,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 763,
      "max temp": 25,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 54,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 18.15,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "11/30/2023 18:14",
      "plant_id": "Green-tek-A242",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 2.2,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 843,
      "max temp": 42,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 44,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 20.3775,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "12/1/2023 18:29",
      "plant_id": "Green-tek-A243",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 2,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 771,
      "max temp": 30,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 50,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 19.987,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "12/2/2023 18:44",
      "plant_id": "Green-tek-A244",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 2.6,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 829,
      "max temp": 41,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 41,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 15.312,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "12/3/2023 18:59",
      "plant_id": "Green-tek-A245",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 1.5,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 823,
      "max temp": 31,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 41,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 15.3725,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "12/4/2023 19:14",
      "plant_id": "Green-tek-A246",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 3.3,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 806,
      "max temp": 40,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 52,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 15.74925,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "12/5/2023 19:29",
      "plant_id": "Green-tek-A247",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2.7,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 704,
      "max temp": 25,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 50,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 18.6835,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "12/6/2023 19:44",
      "plant_id": "Green-tek-A248",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.35,
      "peak_power_hours": 2.4,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 737,
      "max temp": 41,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 35,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 20.68,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "12/7/2023 19:59",
      "plant_id": "Green-tek-A249",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 3,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 798,
      "max temp": 26,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 47,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 20.02,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "12/8/2023 20:14",
      "plant_id": "Green-tek-A250",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 3.4,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 740,
      "max temp": 34,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 35,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 18.6725,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "12/9/2023 20:29",
      "plant_id": "Green-tek-A251",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 3.3,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 817,
      "max temp": 42,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 48,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 16.951,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "12/10/2023 20:44",
      "plant_id": "Green-tek-A252",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2.3,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 703,
      "max temp": 40,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 36,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 20.8065,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "12/11/2023 20:59",
      "plant_id": "Green-tek-A253",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 1.5,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 780,
      "max temp": 32,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 44,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 17.26725,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "12/12/2023 21:14",
      "plant_id": "Green-tek-A254",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 3.3,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 780,
      "max temp": 28,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 47,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 16.27175,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "12/13/2023 21:29",
      "plant_id": "Green-tek-A255",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 1.5,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 811,
      "max temp": 26,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 37,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 17.347,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "12/14/2023 21:44",
      "plant_id": "Green-tek-A256",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2.6,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 815,
      "max temp": 26,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 41,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 19.26925,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "12/15/2023 21:59",
      "plant_id": "Green-tek-A257",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.45,
      "peak_power_hours": 2,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 713,
      "max temp": 32,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 39,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 20.625,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "12/16/2023 22:14",
      "plant_id": "Green-tek-A258",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.6,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 706,
      "max temp": 34,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 49,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 17.11875,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "12/17/2023 22:29",
      "plant_id": "Green-tek-A259",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 2.1,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 818,
      "max temp": 38,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 55,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 17.71,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "12/18/2023 22:44",
      "plant_id": "Green-tek-A260",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.45,
      "peak_power_hours": 1.7,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 803,
      "max temp": 42,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 38,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 19.69275,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "12/19/2023 22:59",
      "plant_id": "Green-tek-A261",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.65,
      "peak_power_hours": 3.4,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 805,
      "max temp": 38,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 35,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 19.646,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "12/20/2023 23:14",
      "plant_id": "Green-tek-A262",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 2.6,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 742,
      "max temp": 37,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 38,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 14.025,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "12/21/2023 23:29",
      "plant_id": "Green-tek-A263",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.55,
      "peak_power_hours": 2.4,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 706,
      "max temp": 26,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 37,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 19.305,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "12/22/2023 23:44",
      "plant_id": "Green-tek-A264",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 3.5,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 815,
      "max temp": 31,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 41,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 17.82,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "12/23/2023 23:59",
      "plant_id": "Green-tek-A265",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 2.4,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 779,
      "max temp": 31,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 50,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 16.62375,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "12/25/2023 0:14",
      "plant_id": "Green-tek-A266",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 2.4,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 820,
      "max temp": 40,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 39,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 16.236,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "12/26/2023 0:29",
      "plant_id": "Green-tek-A267",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2.9,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 832,
      "max temp": 25,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 35,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 16.863,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "12/27/2023 0:44",
      "plant_id": "Green-tek-A268",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 3.5,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 773,
      "max temp": 33,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 53,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 16.038,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "12/28/2023 0:59",
      "plant_id": "Green-tek-A269",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.65,
      "peak_power_hours": 1.5,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 739,
      "max temp": 39,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 49,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 17.017,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "12/29/2023 1:14",
      "plant_id": "Green-tek-A270",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 1.8,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 846,
      "max temp": 35,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 47,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 19.14,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "12/30/2023 1:29",
      "plant_id": "Green-tek-A271",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 3.4,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 847,
      "max temp": 34,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 35,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 18.139,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "12/31/2023 1:44",
      "plant_id": "Green-tek-A272",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 3.1,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 702,
      "max temp": 41,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 49,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 19.943,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "1/1/2024 1:59",
      "plant_id": "Green-tek-A273",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 3.3,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 820,
      "max temp": 36,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 46,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 19.6735,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "1/2/2024 2:14",
      "plant_id": "Green-tek-A274",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 3,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 752,
      "max temp": 38,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 54,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 16.401,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "1/3/2024 2:29",
      "plant_id": "Green-tek-A275",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 2.1,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 732,
      "max temp": 30,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 37,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 16.2855,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "1/4/2024 2:44",
      "plant_id": "Green-tek-A276",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 2.7,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 819,
      "max temp": 41,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 48,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 17.017,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "1/5/2024 2:59",
      "plant_id": "Green-tek-A277",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 3.4,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 817,
      "max temp": 28,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 50,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 15.73,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "1/6/2024 3:14",
      "plant_id": "Green-tek-A278",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.9,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 738,
      "max temp": 42,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 47,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 14.52,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "1/7/2024 3:29",
      "plant_id": "Green-tek-A279",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 3.4,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 779,
      "max temp": 41,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 47,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 15.41925,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "1/8/2024 3:44",
      "plant_id": "Green-tek-A280",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 2.2,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 790,
      "max temp": 26,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 54,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 18.722,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "1/9/2024 3:59",
      "plant_id": "Green-tek-A281",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 2.8,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 790,
      "max temp": 28,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 37,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 14.37975,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "1/10/2024 4:14",
      "plant_id": "Green-tek-A282",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.35,
      "peak_power_hours": 1.6,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 741,
      "max temp": 29,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 43,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 20.2125,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "1/11/2024 4:29",
      "plant_id": "Green-tek-A283",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 2.3,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 818,
      "max temp": 28,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 35,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 15.41925,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "1/12/2024 4:44",
      "plant_id": "Green-tek-A284",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 2.7,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 806,
      "max temp": 39,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 43,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 16.72,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "1/13/2024 4:59",
      "plant_id": "Green-tek-A285",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.45,
      "peak_power_hours": 1.6,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 738,
      "max temp": 30,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 46,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 16.02975,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "1/14/2024 5:14",
      "plant_id": "Green-tek-A286",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.5,
      "peak_power_hours": 1.7,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 814,
      "max temp": 34,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 42,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 18.04,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "1/15/2024 5:29",
      "plant_id": "Green-tek-A287",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 2.5,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 832,
      "max temp": 36,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 40,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 15.345,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "1/16/2024 5:44",
      "plant_id": "Green-tek-A288",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.5,
      "peak_power_hours": 1.7,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 801,
      "max temp": 30,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 49,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 19.019,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "1/17/2024 5:59",
      "plant_id": "Green-tek-A289",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 1.8,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 807,
      "max temp": 25,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 43,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 17.952,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "1/18/2024 6:14",
      "plant_id": "Green-tek-A290",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 2.8,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 833,
      "max temp": 42,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 44,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 13.92325,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "1/19/2024 6:29",
      "plant_id": "Green-tek-A291",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 2.1,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 726,
      "max temp": 30,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 41,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 16.7915,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "1/20/2024 6:44",
      "plant_id": "Green-tek-A292",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 3.4,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 705,
      "max temp": 38,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 37,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 14.37975,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "1/21/2024 6:59",
      "plant_id": "Green-tek-A293",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 2.3,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 760,
      "max temp": 41,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 38,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 17.2645,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "1/22/2024 7:14",
      "plant_id": "Green-tek-A294",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 1.5,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 774,
      "max temp": 35,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 47,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 14.37975,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "1/23/2024 7:29",
      "plant_id": "Green-tek-A295",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 2.2,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 841,
      "max temp": 35,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 48,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 18.8705,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "1/24/2024 7:44",
      "plant_id": "Green-tek-A296",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 2.7,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 745,
      "max temp": 36,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 52,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 18.24075,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "1/25/2024 7:59",
      "plant_id": "Green-tek-A297",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 2.6,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 725,
      "max temp": 27,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 52,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 14.52,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "1/26/2024 8:14",
      "plant_id": "Green-tek-A298",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.5,
      "peak_power_hours": 3.1,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 842,
      "max temp": 26,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 50,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 20.482,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "1/27/2024 8:29",
      "plant_id": "Green-tek-A299",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2.3,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 780,
      "max temp": 38,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 37,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 16.8025,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "1/28/2024 8:44",
      "plant_id": "Green-tek-A300",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 3.2,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 786,
      "max temp": 28,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 54,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 14.432,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "1/29/2024 8:59",
      "plant_id": "Green-tek-A301",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 811,
      "max temp": 39,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 46,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 20.46,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "1/30/2024 9:14",
      "plant_id": "Green-tek-A302",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 2.3,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 848,
      "max temp": 29,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 48,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 20.24,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "1/31/2024 9:29",
      "plant_id": "Green-tek-A303",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 2.6,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 799,
      "max temp": 36,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 38,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 18.876,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "2/1/2024 9:44",
      "plant_id": "Green-tek-A304",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 1.7,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 772,
      "max temp": 42,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 43,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 14.92425,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "2/2/2024 9:59",
      "plant_id": "Green-tek-A305",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 2.2,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 732,
      "max temp": 41,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 43,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 21.2355,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "2/3/2024 10:14",
      "plant_id": "Green-tek-A306",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.35,
      "peak_power_hours": 1.9,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 771,
      "max temp": 34,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 46,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 18.414,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "2/4/2024 10:29",
      "plant_id": "Green-tek-A307",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 1.7,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 775,
      "max temp": 40,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 53,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 15.7905,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "2/5/2024 10:44",
      "plant_id": "Green-tek-A308",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.3,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 729,
      "max temp": 41,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 54,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 17.765,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "2/6/2024 10:59",
      "plant_id": "Green-tek-A309",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 1.7,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 722,
      "max temp": 32,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 37,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 16.28,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "2/7/2024 11:14",
      "plant_id": "Green-tek-A310",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 3.4,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 714,
      "max temp": 37,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 53,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 16.27175,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "2/8/2024 11:29",
      "plant_id": "Green-tek-A311",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 3,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 723,
      "max temp": 29,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 52,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 16.94,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "2/9/2024 11:44",
      "plant_id": "Green-tek-A312",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 3,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 847,
      "max temp": 40,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 41,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 15.55125,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "2/10/2024 11:59",
      "plant_id": "Green-tek-A313",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 2.5,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 817,
      "max temp": 36,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 55,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 19.8,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "2/11/2024 12:14",
      "plant_id": "Green-tek-A314",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 2.7,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 805,
      "max temp": 26,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 44,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 18.425,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "2/12/2024 12:29",
      "plant_id": "Green-tek-A315",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.1,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 832,
      "max temp": 29,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 41,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 14.2065,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "2/13/2024 12:44",
      "plant_id": "Green-tek-A316",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 3.3,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 721,
      "max temp": 42,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 52,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 14.47875,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "2/14/2024 12:59",
      "plant_id": "Green-tek-A317",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 2.8,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 719,
      "max temp": 39,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 38,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 16.434,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "2/15/2024 13:14",
      "plant_id": "Green-tek-A318",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 1.6,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 758,
      "max temp": 25,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 38,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 19.26925,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "2/16/2024 13:29",
      "plant_id": "Green-tek-A319",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 1.5,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 807,
      "max temp": 36,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 43,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 17.6,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "2/17/2024 13:44",
      "plant_id": "Green-tek-A320",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 1.6,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 791,
      "max temp": 31,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 44,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 20.328,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "2/18/2024 13:59",
      "plant_id": "Green-tek-A321",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 2.4,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 817,
      "max temp": 31,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 37,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 16.038,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "2/19/2024 14:14",
      "plant_id": "Green-tek-A322",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 3,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 759,
      "max temp": 26,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 51,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 15.246,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "2/20/2024 14:29",
      "plant_id": "Green-tek-A323",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.55,
      "peak_power_hours": 2.5,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 832,
      "max temp": 37,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 54,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 19.07125,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "2/21/2024 14:44",
      "plant_id": "Green-tek-A324",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 2,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 713,
      "max temp": 39,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 43,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 21.07325,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "2/22/2024 14:59",
      "plant_id": "Green-tek-A325",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2.7,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 735,
      "max temp": 41,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 52,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 15.939,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "2/23/2024 15:14",
      "plant_id": "Green-tek-A326",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.75,
      "peak_power_hours": 2.4,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 812,
      "max temp": 32,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 47,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 17.16,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "2/24/2024 15:29",
      "plant_id": "Green-tek-A327",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 2.8,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 716,
      "max temp": 37,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 37,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 13.695,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "2/25/2024 15:44",
      "plant_id": "Green-tek-A328",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 2.7,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 811,
      "max temp": 35,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 44,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 18.392,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "2/26/2024 15:59",
      "plant_id": "Green-tek-A329",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 1.8,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 707,
      "max temp": 27,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 44,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 15.29275,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "2/27/2024 16:14",
      "plant_id": "Green-tek-A330",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 2.2,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 716,
      "max temp": 41,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 40,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 16.775,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "2/28/2024 16:29",
      "plant_id": "Green-tek-A331",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2.6,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 737,
      "max temp": 37,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 55,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 18.48,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "2/29/2024 16:44",
      "plant_id": "Green-tek-A332",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 814,
      "max temp": 32,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 44,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 14.762,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "3/1/2024 16:59",
      "plant_id": "Green-tek-A333",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2.1,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 804,
      "max temp": 32,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 39,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 17.325,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "3/2/2024 17:14",
      "plant_id": "Green-tek-A334",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.75,
      "peak_power_hours": 2,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 722,
      "max temp": 36,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 42,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 17.578,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "3/3/2024 17:29",
      "plant_id": "Green-tek-A335",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.5,
      "peak_power_hours": 2,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 763,
      "max temp": 30,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 39,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 15.1085,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "3/4/2024 17:44",
      "plant_id": "Green-tek-A336",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 2.4,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 749,
      "max temp": 32,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 38,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 18.48,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "3/5/2024 17:59",
      "plant_id": "Green-tek-A337",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 2.7,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 704,
      "max temp": 27,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 48,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 18.2875,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "3/6/2024 18:14",
      "plant_id": "Green-tek-A338",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 2.7,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 760,
      "max temp": 28,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 53,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 17.963,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "3/7/2024 18:29",
      "plant_id": "Green-tek-A339",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.45,
      "peak_power_hours": 1.5,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 818,
      "max temp": 37,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 41,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 18.2325,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "3/8/2024 18:44",
      "plant_id": "Green-tek-A340",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 3,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 794,
      "max temp": 40,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 40,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 14.8335,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "3/9/2024 18:59",
      "plant_id": "Green-tek-A341",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 2.9,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 705,
      "max temp": 39,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 52,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 15.1085,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "3/10/2024 19:14",
      "plant_id": "Green-tek-A342",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 2.5,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 768,
      "max temp": 31,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 35,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 15.015,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "3/11/2024 19:29",
      "plant_id": "Green-tek-A343",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 1.7,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 710,
      "max temp": 30,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 39,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 18.865,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "3/12/2024 19:44",
      "plant_id": "Green-tek-A344",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2,
      "peak_power_hours": 2.2,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 712,
      "max temp": 31,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 48,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 19.0575,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "3/13/2024 19:59",
      "plant_id": "Green-tek-A345",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 3.2,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 828,
      "max temp": 42,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 51,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 18.469,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "3/14/2024 20:14",
      "plant_id": "Green-tek-A346",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 2.8,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 782,
      "max temp": 42,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 36,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 17.26725,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "3/15/2024 20:29",
      "plant_id": "Green-tek-A347",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 3.4,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 703,
      "max temp": 26,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 55,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 16.236,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "3/16/2024 20:44",
      "plant_id": "Green-tek-A348",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 3,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 788,
      "max temp": 37,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 47,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 17.061,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "3/17/2024 20:59",
      "plant_id": "Green-tek-A349",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 1.9,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 826,
      "max temp": 33,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 39,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 16.1975,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "3/18/2024 21:14",
      "plant_id": "Green-tek-A350",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 3.4,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 755,
      "max temp": 28,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 40,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 14.3,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "3/19/2024 21:29",
      "plant_id": "Green-tek-A351",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 2.1,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 828,
      "max temp": 39,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 45,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 16.4615,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "3/20/2024 21:44",
      "plant_id": "Green-tek-A352",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 2.8,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 787,
      "max temp": 29,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 49,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 19.47275,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "3/21/2024 21:59",
      "plant_id": "Green-tek-A353",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 2.2,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 732,
      "max temp": 31,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 54,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 17.424,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "3/22/2024 22:14",
      "plant_id": "Green-tek-A354",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 3.1,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 815,
      "max temp": 36,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 47,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 20.9,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "3/23/2024 22:29",
      "plant_id": "Green-tek-A355",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 3,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 781,
      "max temp": 32,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 36,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 15.246,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "3/24/2024 22:44",
      "plant_id": "Green-tek-A356",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 3.5,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 737,
      "max temp": 37,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 39,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 16.2855,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "3/25/2024 22:59",
      "plant_id": "Green-tek-A357",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 2,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 782,
      "max temp": 37,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 48,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 17.82,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "3/26/2024 23:14",
      "plant_id": "Green-tek-A358",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 2.6,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 721,
      "max temp": 33,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 50,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 16.50825,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "3/27/2024 23:29",
      "plant_id": "Green-tek-A359",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.35,
      "peak_power_hours": 3.4,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 767,
      "max temp": 42,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 44,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 19.206,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "3/28/2024 23:44",
      "plant_id": "Green-tek-A360",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 2.9,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 809,
      "max temp": 37,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 48,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 16.0105,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "3/29/2024 23:59",
      "plant_id": "Green-tek-A361",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 1.7,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 850,
      "max temp": 35,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 49,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 16.7915,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "3/31/2024 0:14",
      "plant_id": "Green-tek-A362",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 2.9,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 767,
      "max temp": 28,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 51,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 16.5,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "4/1/2024 0:29",
      "plant_id": "Green-tek-A363",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 1.7,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 814,
      "max temp": 35,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 43,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 15.675,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "4/2/2024 0:44",
      "plant_id": "Green-tek-A364",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 2.7,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 804,
      "max temp": 32,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 51,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 14.72625,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "4/3/2024 0:59",
      "plant_id": "Green-tek-A365",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 1.5,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 778,
      "max temp": 36,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 50,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 15.345,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "4/4/2024 1:14",
      "plant_id": "Green-tek-A366",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.5,
      "peak_power_hours": 2.5,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 848,
      "max temp": 34,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 51,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 18.139,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "4/5/2024 1:29",
      "plant_id": "Green-tek-A367",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 1.7,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 787,
      "max temp": 28,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 44,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 17.82,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "4/6/2024 1:44",
      "plant_id": "Green-tek-A368",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 2,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 721,
      "max temp": 42,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 50,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 13.42,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "4/7/2024 1:59",
      "plant_id": "Green-tek-A369",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.7,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 775,
      "max temp": 37,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 49,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 14.03325,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "4/8/2024 2:14",
      "plant_id": "Green-tek-A370",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 2.9,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 737,
      "max temp": 38,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 42,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 14.52,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "4/9/2024 2:29",
      "plant_id": "Green-tek-A371",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 1.6,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 749,
      "max temp": 26,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 45,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 15.433,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "4/10/2024 2:44",
      "plant_id": "Green-tek-A372",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 2.5,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 789,
      "max temp": 30,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 47,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 19.36,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "4/11/2024 2:59",
      "plant_id": "Green-tek-A373",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 3.1,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 845,
      "max temp": 36,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 46,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 19.3875,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "4/12/2024 3:14",
      "plant_id": "Green-tek-A374",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.5,
      "peak_power_hours": 2.8,
      "output Energy": 19.25,
      "Ac output": 19.25,
      "dc output": 19.635,
      "irradiance": 849,
      "max temp": 27,
      "rec count": 0.01925,
      "carbon offset": 24.0625,
      "trees planted": 0.693,
      "PR": 53,
      "CUF": 70,
      "Normalized_power": 2.40625,
      "next day forecast": 18.6725,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 105.875
    },
    {
      "datetime": "4/13/2024 3:29",
      "plant_id": "Green-tek-A375",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 2.6,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 746,
      "max temp": 32,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 38,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 14.92975,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "4/14/2024 3:44",
      "plant_id": "Green-tek-A376",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 1.8,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 744,
      "max temp": 35,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 48,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 17.325,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "4/15/2024 3:59",
      "plant_id": "Green-tek-A377",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 2.1,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 773,
      "max temp": 36,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 37,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 15.708,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "4/16/2024 4:14",
      "plant_id": "Green-tek-A378",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 2.8,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 737,
      "max temp": 35,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 44,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 17.6055,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "4/17/2024 4:29",
      "plant_id": "Green-tek-A379",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 3.3,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 780,
      "max temp": 29,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 48,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 17.248,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "4/18/2024 4:44",
      "plant_id": "Green-tek-A380",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 2,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 743,
      "max temp": 25,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 40,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 20.075,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "4/19/2024 4:59",
      "plant_id": "Green-tek-A381",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.75,
      "peak_power_hours": 2,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 817,
      "max temp": 42,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 53,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 20.2125,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "4/20/2024 5:14",
      "plant_id": "Green-tek-A382",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 2,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 791,
      "max temp": 37,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 42,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 15.07275,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "4/21/2024 5:29",
      "plant_id": "Green-tek-A383",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.7,
      "peak_power_hours": 3.1,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 779,
      "max temp": 30,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 48,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 15.675,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "4/22/2024 5:44",
      "plant_id": "Green-tek-A384",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.7,
      "peak_power_hours": 1.9,
      "output Energy": 17.875,
      "Ac output": 17.875,
      "dc output": 18.2325,
      "irradiance": 814,
      "max temp": 42,
      "rec count": 0.017875,
      "carbon offset": 22.34375,
      "trees planted": 0.6435,
      "PR": 40,
      "CUF": 65,
      "Normalized_power": 2.234375,
      "next day forecast": 16.445,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 98.3125
    },
    {
      "datetime": "4/23/2024 5:59",
      "plant_id": "Green-tek-A385",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.3,
      "peak_power_hours": 2.3,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 736,
      "max temp": 33,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 35,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 18.66975,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "4/24/2024 6:14",
      "plant_id": "Green-tek-A386",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.35,
      "peak_power_hours": 2.1,
      "output Energy": 17.325,
      "Ac output": 17.325,
      "dc output": 17.6715,
      "irradiance": 806,
      "max temp": 34,
      "rec count": 0.017325,
      "carbon offset": 21.65625,
      "trees planted": 0.6237,
      "PR": 44,
      "CUF": 63,
      "Normalized_power": 2.165625,
      "next day forecast": 17.325,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 95.2875
    },
    {
      "datetime": "4/25/2024 6:29",
      "plant_id": "Green-tek-A387",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 3.3,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 726,
      "max temp": 28,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 54,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 15.51,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "4/26/2024 6:44",
      "plant_id": "Green-tek-A388",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.15,
      "peak_power_hours": 3.2,
      "output Energy": 18.7,
      "Ac output": 18.7,
      "dc output": 19.074,
      "irradiance": 830,
      "max temp": 27,
      "rec count": 0.0187,
      "carbon offset": 23.375,
      "trees planted": 0.6732,
      "PR": 53,
      "CUF": 68,
      "Normalized_power": 2.3375,
      "next day forecast": 17.765,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 102.85
    },
    {
      "datetime": "4/27/2024 6:59",
      "plant_id": "Green-tek-A389",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.9,
      "peak_power_hours": 1.9,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 830,
      "max temp": 36,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 48,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 17.556,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "4/28/2024 7:14",
      "plant_id": "Green-tek-A390",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 1.6,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 828,
      "max temp": 30,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 35,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 15.8565,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "4/29/2024 7:29",
      "plant_id": "Green-tek-A391",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.3,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 842,
      "max temp": 39,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 43,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 15.7685,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "4/30/2024 7:44",
      "plant_id": "Green-tek-A392",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 1.5,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 787,
      "max temp": 29,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 36,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 16.8795,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "5/1/2024 7:59",
      "plant_id": "Green-tek-A393",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.05,
      "peak_power_hours": 3.3,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 723,
      "max temp": 34,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 47,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 20.691,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "5/2/2024 8:14",
      "plant_id": "Green-tek-A394",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 2.7,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 761,
      "max temp": 31,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 52,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 18.90075,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "5/3/2024 8:29",
      "plant_id": "Green-tek-A395",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.6,
      "peak_power_hours": 2.5,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 726,
      "max temp": 25,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 38,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 16.236,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "5/4/2024 8:44",
      "plant_id": "Green-tek-A396",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 1.6,
      "output Energy": 21.45,
      "Ac output": 21.45,
      "dc output": 21.879,
      "irradiance": 820,
      "max temp": 25,
      "rec count": 0.02145,
      "carbon offset": 26.8125,
      "trees planted": 0.7722,
      "PR": 55,
      "CUF": 78,
      "Normalized_power": 2.68125,
      "next day forecast": 20.3775,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 117.975
    },
    {
      "datetime": "5/5/2024 8:59",
      "plant_id": "Green-tek-A397",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 3,
      "output Energy": 19.8,
      "Ac output": 19.8,
      "dc output": 20.196,
      "irradiance": 808,
      "max temp": 28,
      "rec count": 0.0198,
      "carbon offset": 24.75,
      "trees planted": 0.7128,
      "PR": 50,
      "CUF": 72,
      "Normalized_power": 2.475,
      "next day forecast": 16.83,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 108.9
    },
    {
      "datetime": "5/6/2024 9:14",
      "plant_id": "Green-tek-A398",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 2,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 814,
      "max temp": 28,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 44,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 17.64675,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "5/7/2024 9:29",
      "plant_id": "Green-tek-A399",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 2.3,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 707,
      "max temp": 38,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 41,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 14.52,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "5/8/2024 9:44",
      "plant_id": "Green-tek-A400",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 3,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 756,
      "max temp": 33,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 39,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 20.35,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "5/9/2024 9:59",
      "plant_id": "Green-tek-A401",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 3.4,
      "output Energy": 20.9,
      "Ac output": 20.9,
      "dc output": 21.318,
      "irradiance": 726,
      "max temp": 29,
      "rec count": 0.0209,
      "carbon offset": 26.125,
      "trees planted": 0.7524,
      "PR": 43,
      "CUF": 76,
      "Normalized_power": 2.6125,
      "next day forecast": 18.392,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 114.95
    },
    {
      "datetime": "5/10/2024 10:14",
      "plant_id": "Green-tek-A402",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 2.1,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 843,
      "max temp": 36,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 55,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 13.64,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "5/11/2024 10:29",
      "plant_id": "Green-tek-A403",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 1.5,
      "output Energy": 21.725,
      "Ac output": 21.725,
      "dc output": 22.1595,
      "irradiance": 777,
      "max temp": 37,
      "rec count": 0.021725,
      "carbon offset": 27.15625,
      "trees planted": 0.7821,
      "PR": 50,
      "CUF": 79,
      "Normalized_power": 2.715625,
      "next day forecast": 21.07325,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 119.4875
    },
    {
      "datetime": "5/12/2024 10:44",
      "plant_id": "Green-tek-A404",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.95,
      "peak_power_hours": 3.4,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 793,
      "max temp": 41,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 49,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 17.5725,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "5/13/2024 10:59",
      "plant_id": "Green-tek-A405",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.45,
      "peak_power_hours": 3.5,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 782,
      "max temp": 28,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 55,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 14.1515,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "5/14/2024 11:14",
      "plant_id": "Green-tek-A406",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.9,
      "peak_power_hours": 3.4,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 845,
      "max temp": 30,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 52,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 16.94,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "5/15/2024 11:29",
      "plant_id": "Green-tek-A407",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.55,
      "peak_power_hours": 2.8,
      "output Energy": 17.6,
      "Ac output": 17.6,
      "dc output": 17.952,
      "irradiance": 829,
      "max temp": 26,
      "rec count": 0.0176,
      "carbon offset": 22,
      "trees planted": 0.6336,
      "PR": 54,
      "CUF": 64,
      "Normalized_power": 2.2,
      "next day forecast": 17.072,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 96.8
    },
    {
      "datetime": "5/16/2024 11:44",
      "plant_id": "Green-tek-A408",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 2.3,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 821,
      "max temp": 26,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 55,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 17.6,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "5/17/2024 11:59",
      "plant_id": "Green-tek-A409",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.8,
      "peak_power_hours": 2,
      "output Energy": 18.425,
      "Ac output": 18.425,
      "dc output": 18.7935,
      "irradiance": 809,
      "max temp": 30,
      "rec count": 0.018425,
      "carbon offset": 23.03125,
      "trees planted": 0.6633,
      "PR": 51,
      "CUF": 67,
      "Normalized_power": 2.303125,
      "next day forecast": 18.0565,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 101.3375
    },
    {
      "datetime": "5/18/2024 12:14",
      "plant_id": "Green-tek-A410",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.6,
      "peak_power_hours": 1.5,
      "output Energy": 20.35,
      "Ac output": 20.35,
      "dc output": 20.757,
      "irradiance": 844,
      "max temp": 39,
      "rec count": 0.02035,
      "carbon offset": 25.4375,
      "trees planted": 0.7326,
      "PR": 41,
      "CUF": 74,
      "Normalized_power": 2.54375,
      "next day forecast": 19.3325,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 111.925
    },
    {
      "datetime": "5/19/2024 12:29",
      "plant_id": "Green-tek-A411",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.85,
      "peak_power_hours": 2.8,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 832,
      "max temp": 33,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 48,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 20.328,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "5/20/2024 12:44",
      "plant_id": "Green-tek-A412",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 1.8,
      "output Energy": 20.625,
      "Ac output": 20.625,
      "dc output": 21.0375,
      "irradiance": 832,
      "max temp": 37,
      "rec count": 0.020625,
      "carbon offset": 25.78125,
      "trees planted": 0.7425,
      "PR": 52,
      "CUF": 75,
      "Normalized_power": 2.578125,
      "next day forecast": 16.5,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 113.4375
    },
    {
      "datetime": "5/21/2024 12:59",
      "plant_id": "Green-tek-A413",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.65,
      "peak_power_hours": 2.2,
      "output Energy": 21.175,
      "Ac output": 21.175,
      "dc output": 21.5985,
      "irradiance": 843,
      "max temp": 26,
      "rec count": 0.021175,
      "carbon offset": 26.46875,
      "trees planted": 0.7623,
      "PR": 42,
      "CUF": 77,
      "Normalized_power": 2.646875,
      "next day forecast": 20.7515,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 116.4625
    },
    {
      "datetime": "5/22/2024 13:14",
      "plant_id": "Green-tek-A414",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.55,
      "peak_power_hours": 3.5,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 780,
      "max temp": 40,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 52,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 16.59625,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "5/23/2024 13:29",
      "plant_id": "Green-tek-A415",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.8,
      "peak_power_hours": 2.3,
      "output Energy": 22,
      "Ac output": 22,
      "dc output": 22.44,
      "irradiance": 764,
      "max temp": 26,
      "rec count": 0.022,
      "carbon offset": 27.5,
      "trees planted": 0.792,
      "PR": 47,
      "CUF": 80,
      "Normalized_power": 2.75,
      "next day forecast": 18.04,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 121
    },
    {
      "datetime": "5/24/2024 13:44",
      "plant_id": "Green-tek-A416",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.95,
      "peak_power_hours": 1.9,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 702,
      "max temp": 26,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 47,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 14.25875,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "5/25/2024 13:59",
      "plant_id": "Green-tek-A417",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.1,
      "peak_power_hours": 3.4,
      "output Energy": 18.15,
      "Ac output": 18.15,
      "dc output": 18.513,
      "irradiance": 710,
      "max temp": 27,
      "rec count": 0.01815,
      "carbon offset": 22.6875,
      "trees planted": 0.6534,
      "PR": 42,
      "CUF": 66,
      "Normalized_power": 2.26875,
      "next day forecast": 15.0645,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 99.825
    },
    {
      "datetime": "5/26/2024 14:14",
      "plant_id": "Green-tek-A418",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.2,
      "peak_power_hours": 1.8,
      "output Energy": 17.05,
      "Ac output": 17.05,
      "dc output": 17.391,
      "irradiance": 799,
      "max temp": 33,
      "rec count": 0.01705,
      "carbon offset": 21.3125,
      "trees planted": 0.6138,
      "PR": 48,
      "CUF": 62,
      "Normalized_power": 2.13125,
      "next day forecast": 16.027,
      "Anomaly": 0,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 93.775
    },
    {
      "datetime": "5/27/2024 14:29",
      "plant_id": "Green-tek-A419",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.25,
      "peak_power_hours": 2.1,
      "output Energy": 16.5,
      "Ac output": 16.5,
      "dc output": 16.83,
      "irradiance": 768,
      "max temp": 38,
      "rec count": 0.0165,
      "carbon offset": 20.625,
      "trees planted": 0.594,
      "PR": 47,
      "CUF": 60,
      "Normalized_power": 2.0625,
      "next day forecast": 15.18,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 90.75
    },
    {
      "datetime": "5/28/2024 14:44",
      "plant_id": "Green-tek-A420",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.4,
      "peak_power_hours": 1.9,
      "output Energy": 19.525,
      "Ac output": 19.525,
      "dc output": 19.9155,
      "irradiance": 716,
      "max temp": 41,
      "rec count": 0.019525,
      "carbon offset": 24.40625,
      "trees planted": 0.7029,
      "PR": 42,
      "CUF": 71,
      "Normalized_power": 2.440625,
      "next day forecast": 18.54875,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 107.3875
    },
    {
      "datetime": "5/29/2024 14:59",
      "plant_id": "Green-tek-A421",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 3,
      "peak_power_hours": 2.6,
      "output Energy": 16.775,
      "Ac output": 16.775,
      "dc output": 17.1105,
      "irradiance": 801,
      "max temp": 28,
      "rec count": 0.016775,
      "carbon offset": 20.96875,
      "trees planted": 0.6039,
      "PR": 39,
      "CUF": 61,
      "Normalized_power": 2.096875,
      "next day forecast": 14.4265,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 92.2625
    },
    {
      "datetime": "5/30/2024 15:14",
      "plant_id": "Green-tek-A422",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 1.75,
      "peak_power_hours": 2.6,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 792,
      "max temp": 26,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 53,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 16.50825,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    },
    {
      "datetime": "5/31/2024 15:29",
      "plant_id": "Green-tek-A423",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.85,
      "peak_power_hours": 1.7,
      "output Energy": 20.075,
      "Ac output": 20.075,
      "dc output": 20.4765,
      "irradiance": 837,
      "max temp": 27,
      "rec count": 0.020075,
      "carbon offset": 25.09375,
      "trees planted": 0.7227,
      "PR": 49,
      "CUF": 73,
      "Normalized_power": 2.509375,
      "next day forecast": 17.666,
      "Anomaly": 1,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 110.4125
    },
    {
      "datetime": "6/1/2024 15:44",
      "plant_id": "Green-tek-A424",
      "plant_name": "Gayathri",
      "installed_capacity": 5,
      "country": "India",
      "state": "Telangana",
      "district": "Hyderabad",
      "plant_type": "Residential",
      "peak_power": 2.75,
      "peak_power_hours": 3.1,
      "output Energy": 18.975,
      "Ac output": 18.975,
      "dc output": 19.3545,
      "irradiance": 725,
      "max temp": 36,
      "rec count": 0.018975,
      "carbon offset": 23.71875,
      "trees planted": 0.6831,
      "PR": 47,
      "CUF": 69,
      "Normalized_power": 2.371875,
      "next day forecast": 15.74925,
      "Anomaly": 2,
      "critical Alert": 0,
      "Major Alert": 1,
      "yield": 104.3625
    }
   ]