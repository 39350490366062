import dayjs from "dayjs";
import { useEffect, useState } from "react"
import { Toast, Button } from "react-bootstrap";

function Styles() {
    return <style>{`
        
        .toast {
  transition: opacity 0.3s ease-in-out;
}

.toast-enter {
  opacity: 0;
}

.toast-enter-active {
  opacity: 1;
}

.toast-leave {
  opacity: 1;
}

.toast-leave-active {
  opacity: 0;
}
 .toast-container {
  position: fixed;
  bottom: 0;
  right: 10px;
  width:100%;
  z-index: 1050;
}

.custom-toast {
  position: relative;
  right: -15px;
  width: 300px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateY(100%); /* Start off-screen */
  opacity: 0; /* Start invisible */
  margin-bottom: 5px !important;
}

.custom-toast.slide-in {
  transform: translateY(0); /* Slide in */
  opacity: 1; /* Fade in */
}

.custom-toast.slide-out {
  transform: translateY(100%); /* Slide out */
  opacity: 0; /* Fade out */
}
        `}</style>
}

function MyNotification({ note, index, remove, length }) {

    function removeToast() {
        remove(note.id)
    }

    return <div key={note.id}>
        <Styles key={note.id + 'styles'} />
        <Toast
            onClose={() => removeToast()}
            bg={note.type.toLowerCase()}
            key={note.id + 'toast'}
            delay={3000}
            autohide
            animation={{
                enter: 'toast-enter',
                enterActive: 'toast-enter-active',
                leave: 'toast-leave',
                leaveActive: 'toast-leave-active',
            }}
            className={`custom-toast ${length ? 'slide-in' : 'slide-out'}`}
        >
            <Toast.Header>
                <strong className="me-auto">{note.title}</strong>
                <small>{dayjs().format('LT')}</small>
            </Toast.Header>
            <Toast.Body className={'text-white'}>
                {note.message}
            </Toast.Body>
        </Toast>
    </div>

    //   const [toasts, setToasts] = useState([]);

    //   const addToast = () => {
    //     const newToast = {
    //       id: Math.random(),
    //       message: `This is toast #${toasts.length + 1}`,
    //     };
    //     setToasts([...toasts, newToast]);
    //   };

    //   const removeToast = (id) => {
    //     setToasts(toasts.filter((toast) => toast.id !== id));
    //   };

    //   return (
    //     <div>
    //         <Styles/>
    //       <Button onClick={addToast}>Add Toast</Button>

    //       <div className="toast-container">
    //         {toasts.map((toast, index) => (
    //           <Toast
    //             key={toast.id}
    //             onClose={() => removeToast(toast.id)}
    //             delay={6000}
    //             autohide
    //              className={`custom-toast ${toasts.length ? 'slide-in' : 'slide-out'}`}
    //             animation={true}
    //            style={{ bottom: `${index * 5}px` }} // Stack toasts dynamically based on index
    //           >
    //             <Toast.Header>
    //               <strong className="mr-auto">React-Bootstrap</strong>
    //             </Toast.Header>
    //             <Toast.Body>{toast.message}</Toast.Body>
    //           </Toast>
    //         ))}
    //       </div>
    //     </div>
    //   );
}

export default MyNotification;