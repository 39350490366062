import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as GridIcon } from "./../../../assets/grid2_icon.svg";
import { ReactComponent as InfoIcon } from "./../../../assets/information_icon.svg";
import expandAltIcon from "./../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
import EnvironmentalBenefitsSVG from "./EnvironmentalBenefitsSVG"; // Import the SVG component
import sampleData from "./SampleData"; // Adjust the path as necessary
import { Card } from "react-bootstrap";


const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 47%; /* Ensure the image container doesn't take too much space */
  max-height: 100px; /* Ensure the image doesn't overflow */
  margin-right: 80px;
  margin-left: 50px;
`;

const MetricsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-top: 10px;
  max-width: 30%; /* Ensure the metrics container fits within the card */
`;

const Metric = styled.div`
  margin-bottom: 16px;
  font-size: 1em;

  &:last-child {
    margin-bottom: 0; /* Remove bottom margin from last metric */
  }

  &:not(:last-child)::after {
    content: "";
    display: block;
    border-bottom: 1px dashed #e0e0e0;
    margin: 2px 0;
  }
`;

const Label = styled.div`
  font-size: 0.8em;
  color: #888;
  margin-bottom: px;
`;

const Value = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: #167db0; /* Adjust color to match design */
  span {
    color: #101117; /* Black color for kINR, t, Trees */
    font-size: 10px;
  }
`;

const EnvironmentalBenefits = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState("From Starting");
  const [totalYields, setTotalYields] = useState(0);
  const [co2Reduction, setCo2Reduction] = useState(0);
  const [treesPlanted, setTreesPlanted] = useState(0);
  const [co2Positive, setCo2Positive] = useState(0);
  const [co2Negative, setCo2Negative] = useState(0);

  useEffect(() => {
    // Aggregate data from sampleData
    const aggregatedData = sampleData.reduce(
      (acc, curr) => {
        acc.totalYields += curr.total_yield;
        acc.co2Reduction += curr.co2_reduction / 1000; // Convert kg to tons
        acc.treesPlanted += curr.trees_planted;
        acc.co2Positive += curr.carbon_neutral;
        acc.co2Negative += curr.carbon_offset;
        return acc;
      },
      {
        totalYields: 0,
        co2Reduction: 0,
        treesPlanted: 0,
        co2Positive: 0,
        co2Negative: 0,
      }
    );

    // Convert to tons if value exceeds 1000 kg
    const totalYieldsInTons =
      aggregatedData.totalYields >= 1000
        ? aggregatedData.totalYields / 1000
        : aggregatedData.totalYields;
    const co2PositiveInTons =
      aggregatedData.co2Positive >= 1000
        ? aggregatedData.co2Positive / 1000
        : aggregatedData.co2Positive;
    const co2NegativeInTons =
      aggregatedData.co2Negative >= 1000
        ? aggregatedData.co2Negative / 1000
        : aggregatedData.co2Negative;

    // Update state with aggregated values
    setTotalYields(totalYieldsInTons);
    setCo2Reduction(aggregatedData.co2Reduction);
    setTreesPlanted(Math.round(aggregatedData.treesPlanted)); // Round to nearest whole number
    setCo2Positive(co2PositiveInTons.toFixed(2)); // Round to 2 decimal places
    setCo2Negative(co2NegativeInTons.toFixed(2)); // Round to 2 decimal places
  }, [selectedOption]);

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Card className=" rounded-3 shadow-sm h-100">
      <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center justify-content-between border-bottom border-bottom-2 shadow-sm border-secondary-subtle">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <GridIcon width={12} height={20} />
          <div className="production-title">Environmental Benefits</div>
          <InfoIcon width={20} height={20} />
        </div>
        {/* <SvgIcon component={expandAltIcon} /> */}
        <img
          src={expandAltIcon}
          alt="Expand"
          width={16}
          height={16}
          className="hp-expand-icon"
          onClick={() => setIsModalOpen(true)} // Open modal on click
        />
      </Card.Header>
      <Card.Body>
        <div className=" d-flex flex-row align-items-center justify-content-between h-100">
          <ImageContainer>
            <EnvironmentalBenefitsSVG
              co2Positive={co2Positive}
              co2Negative={co2Negative}
            />
          </ImageContainer>
          <MetricsContainer>
            <Metric>
              <Label>Total Yields</Label>
              <Value>
                {totalYields.toFixed(2)} <span>kINR</span>
              </Value>
            </Metric>

            <Metric>
              <Label>Rec Count</Label>
              <Value>
                {treesPlanted} <span></span>{" "}
                {/* Display as a whole number */}
              </Value>
            </Metric>
          </MetricsContainer>
        </div>
        {/* Modal structure */}
        {isModalOpen && (
          <div className={`hp-modal open`}>
            <div className="hp-modal-content">
              <button
                className="hp-modal-close"
                onClick={() => setIsModalOpen(false)}>
                &times;
              </button>
              <div className="hp-modal-header">
                <h2 className="hp-modal-title">Environment Benefits</h2>
              </div>
              <div className="hp-modal-card-content">
                <div className=" d-flex flex-row align-items-center justify-content-between h-100 w-100">
                  <ImageContainer>
                    <EnvironmentalBenefitsSVG
                      co2Positive={co2Positive}
                      co2Negative={co2Negative}
                    />
                  </ImageContainer>
                  <MetricsContainer>
                    <Metric>
                      <Label>Total Yields</Label>
                      <Value>
                        {totalYields.toFixed(2)} <span>kINR</span>
                      </Value>
                    </Metric>

                    <Metric>
                      <Label>Rec Count</Label>
                      <Value>
                        {treesPlanted} <span></span>{" "}
                        {/* Display as a whole number */}
                      </Value>
                    </Metric>
                  </MetricsContainer>
                </div>
              </div>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default EnvironmentalBenefits;
