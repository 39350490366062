import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Forgotpassword.css";
import backgroundImage from "../assets/solar-dl-login-bg-2x.png"; // Adjust the path according to your project structure
import logo from "../assets/logog.svg";
import details from "../assets/detailsbutton.svg";

const ForgotpasswordCard = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleOTP = () => {
    // Perform login validation here (e.g., check if fields are filled)

    // Redirect to the /dashboard/overview route
    navigate("/forgotpassword/Otp");
  };

  const handleLogin = () => {
    // Perform login validation here (e.g., check if fields are filled)

    // Redirect to the /dashboard/overview route
    navigate("/");
  };
  

  return (
    <div
      className="login-card-container fp-card-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="fp-card">

        <div className="fp-container">
          <div className="logo-container">
            <img 
              src={logo}
              className="login-logo"
            />

          </div>
          <div className="inputs-container">
            <div className="fp-title-container">
              <h2 className="fp-title">
                <span>Forgot Password</span>
              </h2>
            </div>
            <div className="fp-inputs-container">
              <div>
                <div className="fp-input-container">
                  <label className="fp-input-label" htmlFor="user-id">
                  Email ID
                  </label>
                  <input
                    type="text"
                    id="fp-email-id"
                    placeholder="Email ID"
                    className="fp-input"
                  />
                </div>

                <div >
                  <label className="fp-or-label" htmlFor="user-id">
                  OR
                  </label>
                  
                </div>
                
                <div className="fp-input-container">
                  <label className="fp-input-label" htmlFor="password">
                  Mobile Number
                  </label>
                  <div style={{display:"flex", gap:"10px"}}>
                    <select style={{width:"20%"}} className="fp-input">
                    <option value="+91">+91</option>
                    <option value="+1">+1</option>
                    <option value="+2">+2</option>
                    <option value="+3">+3</option>
                    </select>
                    <input
                      type="password"
                      id="fp-mobile-number"
                      placeholder="Mobile Number"
                      className="fp-input"
                    />
                  </div>
                  <div style={{display:"flex", justifyContent:"flex-start", color:"#77799F", paddingLeft:"10px"}} className="fp-input-msg">
                    <span>OTP will be sent to your Mobile Number</span>
                  </div>   
                </div>

                <div className="fp-otp-container">

                </div>
                

                <button className="fp-button" onClick={handleOTP}>
                  Send OTP
                </button>


              </div>
            </div>
             
          </div>
          
        </div>





      
        
        
        
        
        
        
      </div>
    </div>
  );
};

export default ForgotpasswordCard;
