import React  from "react";
import "./Entity.css";
import { Col, Container,  Nav, NavItem, NavLink, Row, Stack, Tab } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Divider,} from "@mui/material";
import EntityTable from "./EntityTable";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import UserTable from "../user/UserTable";
// import SolarNavbar from "../../dashboard/navbar/SolarNavbar";
import AdminBottomHeaderBar from "../../dashboard/navbar/AdminBottomHeaderBar";
import PlantTable from "../plant/PlantTable";
import EntityAddPage from "./EntityAdd";
import EntityEditPage from "./EntityEdit";
import AdminPlantEdit from "./../plant/PlantEdit";
import AdminPlantAdd from "./../plant/PlantAdd";
import UserAddPage from "./../user/UserAdd";
import UserEditPage from "./../user/UserEdit";
import SettingsForm from "../settings/Settings";
import Roles from "../role/Roles";
import AdminReportTable from "../report/AdminReportTable";
import AdminReportAdd from "../report/AdminReportAdd";

const Entity = () => {
    const navigate=useNavigate();
    const entityCount = 20;

    function addNewEntity() {
        navigate("/admin/entity/add");
    }
    function addNewPlant() {
        navigate("/admin/plant/add");
    }
    function addNewUser() {
        navigate("/admin/user");
    }

    const [active, setActive] = React.useState(1);
    const [rowid, setRowid] = React.useState(1);
    const [rowData,setRowData]=React.useState();

    const SetView = (active) => {
      setActive(active);
    };
    const SetRowIdInView = (rowid) => {
        setRowid(rowid);
    };
    const setEditRow=(rowData)=>{
        setRowData(rowData);
        setActive(9);
    }

    const ActiveView = () => {
        switch (active) {
        case 1:
            return <EntityTable stateChanger={setActive} rowId={setRowid}/>;
        case 2:
            return <EntityAddPage stateChanger={setActive}/>;
        case 3:
            return <EntityEditPage stateChanger={setActive} rowId={rowid}/>;
        case 4:
            //Plant list page
            return <PlantTable stateChanger={setActive} rowId={setRowid}/>;
        case 5:
            return <AdminPlantAdd stateChanger={setActive} />;
        case 6:
            //Plant Edit
            return <AdminPlantEdit stateChanger={setActive} rowId={rowid}/>;
        case 7:
            //User Add
            return <UserTable stateChanger={setEditRow}  rowId={setRowid}/>;
        case 8:
            //User Add
            return <UserAddPage stateChanger={setActive} />;
        case 9:
            //User Edit
            return <UserEditPage stateChanger={setActive} rowData={rowData} rowId={rowid}/>;
        case 10:
            return <AdminReportTable stateChanger={setActive} />
        case 11:
            //report add 
            return <AdminReportAdd stateChanger={setActive} />        
        default:
            return <EntityTable stateChanger={setActive} rowId={setRowid}/>;
        }
    };
    

    const { t } = useTranslation(['entity','plant','menu','user']);

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            {/* <SolarNavbar /> */}
            <AdminBottomHeaderBar />
           
            <Container className="mw-100">
                <Row>
                    <Tab.Container id="left-tabs-example p-2 m-2" defaultActiveKey="first">
                        <Container className="mw-100"> <Stack gap={0} direction="vertical">
                            <Nav as={"div"} variant="underline" className="flex-row tab-nav">
                                <NavItem>
                                    <NavLink eventKey="first"onClick={() => SetView(1)}> {t('menu:entity')} </NavLink>
                                </NavItem>
                                <NavItem >
                                    <NavLink eventKey="second" onClick={() => SetView(4)}> {t('menu:plant')} </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="third" onClick={() => SetView(3)}>{t('menu:role')}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="fourth" onClick={() => SetView(7)}>{t('menu:user')}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="fifth" onClick={()=>SetView(10)} >{t('menu:report.template')}</NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink eventKey="sixth" >{t('menu:catalogue')}</NavLink>
                                </NavItem> */}
                                <NavItem>
                                    <NavLink eventKey="seventh" >{t('menu:setting')}</NavLink>
                                </NavItem>
                            </Nav>
                            <Divider className="bg-black" />
                            <Tab.Content className="mt-2 pt-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Tab.Pane eventKey="first">  
                                    {(active == 1)  &&  
                                        <Container as={"div"} fluid className="mw-100">
                                            <Row className="mt-2 ml-1 pt-2 align-items-center">
                                                <Col className="px-0">
                                                    <div>
                                                        <h1 className="header-title">
                                                        {t('entity:entity.list')} 
                                                            {/* <span className="entity-count"></span> */}
                                                        </h1>
                                                    </div>
                                                </Col>
                                                <Col className="px-0">
                                                    <div className="d-flex  flex-row  align-items-center justify-content-end ">
                                                        
                                                        {/*
                                                        <button className="add-entity-button " onClick={()=>addNewEntity()}> {t('entity:entity.addnew')} </button>
                                                        */}
                                                        <button className="add-entity-button " onClick={() => SetView(2)}> {t('entity:entity.addnew')} </button>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>    
                                    }
                                    {ActiveView()}

                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    {(active == 4)  &&  
                                        <Container as={"div"} fluid className="mw-100">
                                            <Row className="mt-2 ml-1 pt-2 align-items-center">
                                            <Col className="px-0">
                                                    <div>
                                                        <h1 className="header-title">
                                                        {t('plant:plantList')} 
                                                            {/* <span className="entity-count"></span> */}
                                                        </h1>
                                                    </div>
                                                </Col>
                                                <Col className="px-0">
                                                    <div className="d-flex  flex-row  align-items-center justify-content-end ">
                                                        <button className="add-plant-button " onClick={()=>addNewPlant()}> {t('plant:addNewPlant')} </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>    
                                    }
                                    {ActiveView()}
                                </Tab.Pane>
                                <Tab.Pane eventKey="third"> <Roles/> </Tab.Pane>
                                <Tab.Pane eventKey="fourth">  
                                    {(active == 7)  &&  
                                        <Container as={"div"} fluid className="mw-100">
                                            <Row className="mt-2 ml-1 pt-2 align-items-center">
                                            <Col className="px-0">
                                                    <div >
                                                        <h1 className="header-title">
                                                        {t('user:user.list')} 
                                                            {/* <span className="entity-count"></span> */}
                                                        </h1>
                                                    </div>
                                                </Col>
                                                <Col className="px-0">
                                                    <div className="d-flex  flex-row  align-items-center justify-content-end ">
                                                        <button className="add-entity-button " onClick={()=>SetView(8)}> {t('user:user.addnew')} </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>    
                                    }
                                    {ActiveView()}
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth">
                                {/* {(active == 10)  &&  
                                        <Container as={"div"} fluid className="mw-100">
                                            <Row className="mt-2 ml-1 pt-2 align-items-center">
                                                <Col className="px-0">
                                                    <div>
                                                        <h1 className="header-title">
                                                        Report Templates                                                          
                                                        </h1>
                                                    </div>
                                                </Col>
                                                <Col className="px-0">
                                                    <div className="d-flex  flex-row  align-items-center justify-content-end ">
                                                       <button className="add-entity-button " onClick={() => SetView(11)}> New Report </button>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>    
                                    } */}
                                    {ActiveView()}
                             
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="sixth">  Catalogue </Tab.Pane> */}
                                <Tab.Pane eventKey="seventh"> <SettingsForm /> </Tab.Pane>
                                </LocalizationProvider>
                            </Tab.Content>
                        </Stack>
                        </Container>
                    </Tab.Container>
                </Row>
            </Container>
        </div> 
    );
};

export default Entity;