const PlantsData = [{
  id: "AE76-45RT-ERT5-RTYU",
  plantName: "Lakshmi",
  plantType: "Large Power Producer",
  capacity: "1KWH",
  peakPower: "13KWH",
  district: "Karimnagar",
  state: "Andhra Pradesh",
  country: "Japan",
  installationDate: "1-2-2023",
  pr: "90%",
  cuf: "29%",
  yield: "1016kWh",
  recCount: "49",
  alerts: "Normal",
  status: "Online",
  PLANT_ID: "AE76-45RT-ER5-RTYU",
  capacity: 5,
  DATE: "2024-10-09",
  Time:"11:23:20",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 14.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,

},
{
  id: 2,
  plantName: "Lakshmi",
  plantType: "Commercial & Industry",
  capacity: "2KWH",
  peakPower: "5KWH",
  district: "Karimnagar",
  state: "Karnataka",
  country: "Japan",
  installationDate: "8-3-2023",
  pr: "70%",
  cuf: "27%",
  yield: "4636kWh",
  recCount: "45",
  alerts: "Major",
  status: "Offline",
  PLANT_ID: "E76-45RT-ERT5-RTYU",
  capacity: 5,
  DATE: "2024-10-13",
  Time:"10:25",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 14.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,
},
{
  id: 4,
  plantName: "Vijaya",
  plantType: "Large Power Producer",
  capacity: "10KWH",
  peakPower: "5KWH",
  district: "Hyderabad",
  state: "Telangana",
  country: "UK",
  installationDate: "1-11-2022",
  pr: "39%",
  cuf: "13%",
  yield: "648kWh",
  recCount: "78",
  alerts: "Normal",
  status: "Online",
  PLANT_ID: "AE7-45RT-ERT5-RTYU",
  capacity: 5,
  DATE: "2024-10-12",
  Time:"08:23",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 14.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,
},
{
  id: 6,
  plantName: "Vijaya",
  plantType: "Large Power Producer",
  capacity: "4KWH",
  peakPower: "11KWH",
  district: "Karimnagar",
  state: "Tamil Nadu",
  country: "Germany",
  installationDate: "19-10-2021",
  pr: "52%",
  cuf: "18%",
  yield: "4552kWh",
  recCount: "61",
  alerts: "Critical",
  status: "Partially Offline",
  PLANT_ID: "AE6-45RT-ERT5-RTYU",
  capacity: 5,
  DATE: "2024-10-01",
  Time:"11:13",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 14.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,
},
{
  id: 7,
  plantName: "Vijaya",
  plantType: "Large Power Producer",
  capacity: "4KWH",
  peakPower: "11KWH",
  district: "Karimnagar",
  state: "Tamil Nadu",
  country: "Germany",
  installationDate: "19-10-2021",
  pr: "52%",
  cuf: "18%",
  yield: "4552kWh",
  recCount: "61",
  alerts: "Critical",
  status: "Partially Offline",
  PLANT_ID: "AE76-45T-ERT5-RTYU",
  capacity: 5,
  DATE: "2024-10-25",
  Time:"11:43",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 14.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,
},
{
  id: 8,
  plantName: "Vijaya",
  plantType: "Large Power Producer",
  capacity: "4KWH",
  peakPower: "11KWH",
  district: "Karimnagar",
  state: "Tamil Nadu",
  country: "Germany",
  installationDate: "19-10-2021",
  pr: "52%",
  cuf: "18%",
  yield: "4552kWh",
  recCount: "61",
  alerts: "Critical",
  status: "Partially Offline",
  PLANT_ID: "AE76-4RT-ERT5-RTYU",
  capacity: 5,
  DATE: "2024-08-11",
  Time:"11:53",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 14.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,
},
{
  id: 9,
  plantName: "Vijaya",
  plantType: "Large Power Producer",
  capacity: "4KWH",
  peakPower: "11KWH",
  district: "Karimnagar",
  state: "Tamil Nadu",
  country: "Germany",
  installationDate: "19-10-2021",
  pr: "52%",
  cuf: "18%",
  yield: "4552kWh",
  recCount: "61",
  alerts: "Critical",
  status: "Partially Offline",
  PLANT_ID: "AE76-45RT-ERT5-RTY",
  capacity: 5,
  DATE: "2024-07-10",
  Time:"09:20",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 14.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,
},
{
  id: 10,
  plantName: "Vijaya",
  plantType: "Large Power Producer",
  capacity: "4KWH",
  peakPower: "11KWH",
  district: "Karimnagar",
  state: "Tamil Nadu",
  country: "Germany",
  installationDate: "19-10-2021",
  pr: "52%",
  cuf: "18%",
  yield: "4552kWh",
  recCount: "61",
  alerts: "Critical",
  status: "Partially Offline",
  PLANT_ID: "AE76-45RT-ET5-RTYU",
  capacity: 5,
  DATE: "2024-08-08",
  Time:"06:23",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 14.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,
},
{
  id: 10,
  plantName: "Vijaya",
  plantType: "Large Power Producer",
  capacity: "4KWH",
  peakPower: "11KWH",
  district: "Karimnagar",
  state: "Tamil Nadu",
  country: "Germany",
  installationDate: "19-10-2021",
  pr: "52%",
  cuf: "18%",
  yield: "4552kWh",
  recCount: "61",
  alerts: "Critical",
  status: "Partially Offline",
  PLANT_ID: "AE76-45RT-ET5-RTYUM",
  capacity: 5,
  DATE: "2024-09-10",
  Time:"06:23",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 140.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,
},
{
  id: 10,
  plantName: "Vijaya",
  plantType: "Large Power Producer",
  capacity: "4KWH",
  peakPower: "11KWH",
  district: "Karimnagar",
  state: "Tamil Nadu",
  country: "Germany",
  installationDate: "19-10-2021",
  pr: "52%",
  cuf: "18%",
  yield: "4552kWh",
  recCount: "61",
  alerts: "Critical",
  status: "Partially Offline",
  PLANT_ID: "AE76-45RT-ET5-RTYUB",
  capacity: 5,
  DATE: "2024-13-10",
  Time:"05:20",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 240.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,
},
{
  id: 10,
  plantName: "Vijaya",
  plantType: "Large Power Producer",
  capacity: "4KWH",
  peakPower: "11KWH",
  district: "Karimnagar",
  state: "Tamil Nadu",
  country: "Germany",
  installationDate: "19-10-2021",
  pr: "52%",
  cuf: "18%",
  yield: "4552kWh",
  recCount: "61",
  alerts: "Critical",
  status: "Partially Offline",
  PLANT_ID: "AE76-45RT-ET5-RTYUF",
  capacity: 5,
  DATE: "2024-12-10",
  Time:"05:03",
  normalized_power: 0,
  irradiance: 0,
  max_temprature: 10,
  output_energy: 0,
  TOTAL_PRODUCTION: 149.9,
  EFFICIENCY: 54.18,
  peak_hour_production: "",
  carbon_neutral: 0.745,
  carbon_offset: 11.92,
  total_yield: 81.95,
  co2_reduction: 11.18,
  trees_planted: 0.52,
  plant_status: "offline",
  critical_alerts_open: 0,
  major_alerts_open: 0,
  critical_alerts_closed: 0,
  major_alerts_closed: 0,
  critical_alerts_open_closed: 0,
  major_alerts_open_closed: 0,
}]
export default PlantsData;