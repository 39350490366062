import { useEffect, useState } from "react";

function GroupView({ group }) {
    const [columns, setColumns] = useState(['groupId', 'groupName', 'plantName'])

    useEffect(() => {

    }, [group])

    return (<div style={{ marginTop: '20px', width: "fit-content" }}>
        <div className="w-auto  " style={{ overflowX: "auto" }}>
            <div className="border rounded border-light-subtle table-container">
                <table style={{ whiteSpace: "nowrap" }}>
                    <thead>
                        <tr>
                            {columns.map((column) => (
                                <th key={column} className="table-header">
                                    {column.charAt(0).toUpperCase() + column.slice(1)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>

                        {group.length > 0 ? group[0]?.groupDetails.map((rowdata, index) => (
                            <>
                             
                                <tr key={rowdata.id} onClick={(e) => setSortGroup(rowdata)}>
                                    {/* {columns.map((column) => ( */}
                                        <td  key={`${rowdata.id}-${'groupId'}`} className="table-header"> {group[0]['groupId']} </td>
                                        <td  key={`${rowdata.id}-${'groupName'}`} className="table-header"> {group[0]['groupName']} </td>
                                    {/* ))} */}
                                   {/* {columns.map((column) => ( */}
                                    <td key={`${rowdata.id}-${'plantName'}`} className="table-header"> {rowdata['plantName']} </td>
                                   {/* ))}  */}
                                </tr>



                            </>
                        )) :
                            <tr key="d-row">
                                {columns.map((column) => (

                                    <td></td>

                                ))}
                                <td className="delete-column table-header" >

                                </td>
                            </tr>
                        }

                        {/* Filling empty rows to maintain table height */}
                        {group.length < 0 &&
                            Array.from({ length: group.length }).map((_, index) => (
                                <tr key={`empty-${index}`}>
                                    <td colSpan="9" className="empty-row"></td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>)
}
export default GroupView;