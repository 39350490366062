import React from "react";
import "./OverviewSection4.css";
import TotalPlants from "./TotalPlants";
import TotalCapacity from "./TotalCapacity";
import Weather from "./Weather";

function OverviewSection4() {
  return (
    <div className="overviewsection4">
      <div className="overviewsection4-container">
        <TotalPlants />
        <TotalCapacity />
        <Weather />
      </div>
    </div>
  );
}

export default OverviewSection4;
