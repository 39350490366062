import { Card, Col, Image, Row, Stack } from "react-bootstrap";
import { ReactComponent as GridIcon } from "./../../../assets/grid2_icon.svg";
import { ReactComponent as InfoIcon } from "./../../../assets/information_icon.svg";
import Datalogger from "./../../../assets/Datalogger.png";
import Inverter from "./../../../assets/Inverter.png";
import device3 from "./../../../assets/device3.png";
import expandAltIcon from "./../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
import { SvgIcon } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

function Devices(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [devices, setDevices] = useState({});

  useEffect(() => { }, [devices])

  return (
    <Card className=" rounded-3 shadow-sm h-100" >
      <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center justify-content-between border-bottom border-bottom-2 shadow-sm border-secondary-subtle">
        <div className="d-flex flex-row align-items-center justify-content-between"> 
          <GridIcon width={12} height={20} />
          <div className="production-title">Devices</div>
          <InfoIcon width={20} height={20} />
        </div>
        {/* <SvgIcon component={expandAltIcon} /> */}
        <img
          src={expandAltIcon}
          alt="Expand"
          className="hp-expand-icon"
          onClick={() => setIsModalOpen(true)} // Open modal on click
        />
      </Card.Header>
      <Card.Body onClick={()=>props.updatekey('second')}>
        <Row className="d-flex flex-row align-items-center justify-content-around text-center">
          <Col direction="vertical" gap={3}>
            <Card style={{ backgroundColor: "#fafafa" }}>
              <Card.Body>

                <Card.Text>
                  6
                </Card.Text>
                <div>
                  <Image src={Datalogger} width={200} height={90} />
                </div>
                <Card.Title >
                  Data Loggers
                </Card.Title>

              </Card.Body>
            </Card>
          </Col>
          <Col direction="vertical" gap={3}>
            <Card style={{ backgroundColor: "#fafafa" }}>
              <Card.Body>

                <Card.Text>
                  3
                </Card.Text>
                <div>
                  <Image src={Inverter} width={50} height={90} />
                </div>
                <Card.Title >
                  Inverters
                </Card.Title>

              </Card.Body>
            </Card>
          </Col>
          <Col direction="vertical" gap={3}>
            <Card style={{ backgroundColor: "#fafafa" }}>
              <Card.Body>

                <Card.Text>
                  2
                </Card.Text>
                <div>
                  <Image src={device3} width={50} height={90} />
                </div>
                <Card.Title >
                  Devices
                </Card.Title>

              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Modal structure */}
        {isModalOpen && (
          <div className={`hp-modal open`}>
            <div className="hp-modal-content">
              <button
                className="hp-modal-close"
                onClick={() => setIsModalOpen(false)}>
                &times;
              </button>
              <div className="hp-modal-header">
                <h2 className="hp-modal-title">Devices</h2>
              </div>
              <div className="hp-modal-card-content">
                <Row className="d-flex flex-row align-items-center justify-content-around text-center w-100">
                  <Col direction="vertical" gap={3}>
                    <Card style={{ backgroundColor: "#fafafa" }}>
                      <Card.Body>

                        <Card.Text>
                          6
                        </Card.Text>
                        <div>
                          <Image src={Datalogger} width={100} height={90} />
                        </div>
                        <Card.Title >
                          Data Loggers
                        </Card.Title>

                      </Card.Body>
                    </Card>
                  </Col>
                  <Col direction="vertical" gap={3}>
                    <Card style={{ backgroundColor: "#fafafa" }}>
                      <Card.Body>

                        <Card.Text>
                          3
                        </Card.Text>
                        <div>
                          <Image src={Inverter} width={50} height={90} />
                        </div>
                        <Card.Title >
                          Inverters
                        </Card.Title>

                      </Card.Body>
                    </Card>
                  </Col>
                  <Col direction="vertical" gap={3}>
                    <Card style={{ backgroundColor: "#fafafa" }}>
                      <Card.Body>

                        <Card.Text>
                          2
                        </Card.Text>
                        <div>
                          <Image src={device3} width={50} height={90} />
                        </div>
                        <Card.Title >
                          Devices
                        </Card.Title>

                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>)
}

export default Devices;