import React, { useMemo, useState } from "react";
import {  PieChart, Pie, Cell, ResponsiveContainer, Tooltip  } from "recharts";
import { ReactComponent as GridIcon } from "./../../assets/grid2_icon.svg";
import "./Alerts.css"; // Importing the CSS file
import { Card } from "react-bootstrap";

// Data for the Pie Chart
const plantsData = [
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 1, critical_alerts_closed: 1, major_alerts_open: 1, major_alerts_closed: 0, plant_status: 'offline' },
  // Add more plants if needed
];
// Custom label rendering for the numbers outside the chart
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#000"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      fontSize={14}
      fontWeight={600}
    >
      {value}
    </text>
  );
};

// Constants for colors
const COLORS = {
  critical_open: '#FF4032',
  critical_closed: '#FF4032',
  major_open: '#FF9500',
  major_closed: '#FF9500',
  normal: '#88BFFF',
};


const Alerts = () => {

  const [selectedOption, setSelectedOption] = useState('open'); // Default selection
  const [anchorEl, setAnchorEl] = useState(null); // For handling the dropdown menu

  // Calculate aggregated data based on selected filters
  const aggregatedData = useMemo(() => {
    let criticalOpen = 0;
    let criticalClosed = 0;
    let majorOpen = 0;
    let majorClosed = 0;
    let normalCount = 0;

    plantsData.forEach((plant) => {
      if (plant.critical_alerts_open === 0 && plant.critical_alerts_closed === 0 && plant.major_alerts_open === 0 && plant.major_alerts_closed === 0 && plant.plant_status === 'online') {
        normalCount++;
      }

      criticalOpen += plant.critical_alerts_open;
      criticalClosed += plant.critical_alerts_closed;
      majorOpen += plant.major_alerts_open;
      majorClosed += plant.major_alerts_closed;
    });

    const result = [
      ...(selectedOption === 'open' ? [
        { name: 'Plants with Critical Alerts', value: criticalOpen, color: COLORS.critical_open },
        { name: 'Plants with Major Alerts', value: majorOpen, color: COLORS.major_open },
      ] : []),
      ...(selectedOption === 'closed' ? [
        { name: 'Plants with Critical Alerts', value: criticalClosed, color: COLORS.critical_closed },
        { name: 'Plants with Major Alerts', value: majorClosed, color: COLORS.major_closed },
      ] : []),
      ...(selectedOption === 'both' ? [
        { name: 'Plants with Critical Alerts Open', value: criticalOpen, color: COLORS.critical_open },
        { name: 'Plants with Major Alerts Open', value: majorOpen, color: COLORS.major_open },
        { name: 'Plants with Critical Alerts Closed', value: criticalClosed, color: COLORS.critical_closed },
        { name: 'Plants with Major Alerts Closed', value: majorClosed, color: COLORS.major_closed },
      ] : []),
      { name: 'Plants Running Normal', value: normalCount, color: COLORS.normal },
    ];

    return result;
  }, [selectedOption]);

  return (
    <Card className=" rounded-3 shadow-sm ">
      <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center border-bottom border-bottom-2 shadow-sm border-secondary-subtle">
        <GridIcon width={12} height={20} />
        <div className="production-title">Alerts</div>

      </Card.Header>
      <Card.Body>
        <div className="alerts-chart-wrapper">
          <ResponsiveContainer width={200} height={200}>
            <PieChart>
              <Pie
                data={aggregatedData}
                cx="50%"
                cy="50%"
                innerRadius={40}
                outerRadius={70}
                startAngle={90}
                endAngle={-270}
                paddingAngle={1}
                dataKey="value"
                nameKey="name"
                textAnchor='start'
                fill="#8884d8"
                label
              >
                {aggregatedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>

          <div className="alerts-details">
            {aggregatedData.map((entry, index) => (
              <div className="alerts-item" style={{ color: entry.color }}>
                <span className="alerts-count" >
                  {entry.value}
                </span>
                <span className="alerts-text">{entry.name}</span>
              </div>
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Alerts;

