import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  LineChart,
  Line,
  Legend,
} from "recharts";
import NoDataIcon from "./../../../assets/no-data-icon.svg";
import { ReactComponent as GridIcon } from "./../../../assets/drag_handle_dots.svg";
import { ReactComponent as InfoIcon } from "./../../../assets/information_icon.svg";
import  expandAltIcon from "./../../../assets/expand_alt_icon.svg";
import { SvgIcon } from "@mui/material";
import { Card,Stack } from "react-bootstrap";
import dayjs from "dayjs";
import MuiDatePickerComponent from "./MuiDatePickerComponent";
import DateFilterOption from "./DateFilterOption";
import PlantDailyData from "../../../dataassets/GeneratedPlantData";

const DailyEnergyOutput = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [chartOption, setChartOption] = useState('year');
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [xLabel, setxLabel] = useState('');

  const chartOptions = ["AC", "DC"];

  //calculating date ordinals
  const nth = (d) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  const renderLegend = (props) => {
    return (
      <Stack direction="horizontal" gap={3}>
        <div className="d-flex flex-row gap-1 align-items-center  " key={'item1'} ><div  style={{ width:"10px",height:"10px", backgroundColor: "#F03C25", color:'black',  border: '2px solid grey', borderRadius:'10px' }} /><span style={{fontSize:'12px'}} >R</span></div>
        <div className="d-flex flex-row gap-1 align-items-center" key={'item2'}><div style={{ backgroundColor: "#EA8713",width:"10px",height:"10px",  color:'black',  border: '2px solid grey', borderRadius:'10px' }} /><span style={{fontSize:'12px'}}>S</span></div>
        <div className="d-flex flex-row gap-1 align-items-center"  key={'item3'} ><div style={{ backgroundColor: "#5C10EA", width:"10px",height:"10px",  color:'black',  border: '2px solid grey', borderRadius:'10px' }} /><span style={{fontSize:'12px'}}>T</span></div>
      </Stack>
    );
  }

  useEffect(() => {
    processData();
  }, [selectedDate, chartOption]);

  const processData = () => {
    let filteredData = [];
    if (chartOption === "DC") {
      filteredData = PlantDailyData
        .filter((item) => selectedDate.diff(item.datetime, 'M') === 0)
        .sort((a, b) =>  dayjs(a.datetime).diff(b.datetime) >= 0 ? 1 : -1 )
        .reduce((acc, item) => {
          const date = dayjs(item.datetime);
          const dv = date.format('MMM DD') + nth(date.date)
          if (!acc.includes({ date: dv })) {
            acc.push({
              date: dv,
              routput: item.DC_R_output.toFixed(4) || 0,
              soutput: item.DC_S_output.toFixed(4) || 0,
              toutput: item.DC_T_output.toFixed(4) || 0,
            })
          } else {
            acc[{ date: dv }].routput += item.DC_R_output.toFixed(4) || 0,
              acc[{ date: dv }].soutput += item.DC_S_output.toFixed(4) || 0,
              acc[{ date: dv }].toutput += item.DC_T_output.toFixed(4) || 0

          }
          return acc;
        }, [])
     } else if (chartOption === "AC") {
      filteredData = PlantDailyData
        .filter((item) => selectedDate.diff(item.datetime, 'M') === 0)
        .sort((a, b) =>  dayjs(a.datetime).diff(b.datetime) >= 0 ? 1 : -1 )
        .reduce((acc, item) => {
          const date = dayjs(item.datetime);
          const dv = date.format('MMM DD') + nth(date.date)
          if (!acc.includes({ date: dv })) {
            acc.push({
              date: dv,
              routput: item.AC_R_output.toFixed(4) || 0,
              soutput: item.AC_S_output.toFixed(4) || 0,
              toutput: item.AC_T_output.toFixed(4) || 0,
              })
          } else {
            acc[{ date: dv }].routput += item.AC_R_output.toFixed(4) || 0,
              acc[{ date: dv }].soutput += item.AC_S_output.toFixed(4) || 0,
              acc[{ date: dv }].toutput += item.AC_T_output.toFixed(4) || 0

          }
          return acc;
        }, [])
       }

    setData(filteredData);
  };

  const renderNoData = () => (
    <div className="hp-no-data-container">
      <img
        src={NoDataIcon}
        alt="No Data Available"
        className="hp-no-data-icon"
      />
      <p>No Data Available</p>
    </div>
  );

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    const year = newValue.year();
    setxLabel(newValue.format('MMMM') + ' ' + year);
  };

  const chartOptionsChange = (event) => {
    setChartOption(event.target.value);
    if (event.target.value === 'total') {
      // setSelectedDate(null);
      setxLabel('Total');
    }
  };

  const getOpenToView = () => {
    return 'year'; // Default to 'day' if needed
  };


  const renderData = () => {
    if (data.length === 0) {
      return renderNoData();
    }

    return (
      <LineChart data={data} margin={{ top: 20, right: 10, left: 20, bottom: 10 }}>
        <CartesianGrid syncWithTicks vertical={false} strokeDasharray="4 4" strokeOpacity={0.5} />
        <XAxis dataKey="date"  axisLine={false}
          tickLine={false} strokeOpacity={0.1} padding={{ left: 5 }} tick={{ fontSize: 11 }}
        >
          <Label
            value=""
            offset={-5}
            position="insideBottom"
            style={{
              paddingTop: '40px',
              paddingLeft:"20px",
              fontSize: "0.9rem",
              fill: "#333",
              textAnchor: "middle",
              fontFamily: "sans-serif",
              fontWeight: 600,
            }}
          />
        </XAxis>
        <YAxis
          axisLine={false}
          tickLine={false}
          allowDataOverflow
          angle={0}
          axisType="yAxis"
          tickFormatter={(v) => v + "kW"}
          padding={{ bottom: 10  }}
        >
          <Label
            value="Energy"
            angle={-90}
            position="left"
            offset={10} // Adjust as needed
            style={{
              fontSize: "1.0rem",
              fill: "#333",
              textAnchor: "middle",
              fontFamily: "sans-serif",
              fontWeight: 600,
            }}
          />
        </YAxis>

        <Legend content={renderLegend} />
        <Legend
          formatter={(va) => va === 'soutput' ? 'S' : va === 'routput' ? 'R' : 'T'}
          align="left"
          wrapperStyle={{
            color: '#23453e'
          }}
          iconType="circle" verticalAlign="bottom" />
        <Tooltip
          formatter={(value) => value + " kW"
          }
          contentStyle={{
            backgroundColor: "#eee",
            color: "#333",
            borderRadius: "5px",
            fontSize: "12px",
          }}
          labelStyle={{ color: "#333" }}
        />
        <Line dot={false} legendType="circle" type="monotone" dataKey="routput" stroke="#F03C25" yAxisId={0} />
        <Line dot={false} legendType="circle" type="monotone" dataKey="soutput" stroke="#EA8713" yAxisId={0} />
        <Line dot={false} legendType="circle" type="monotone" dataKey="toutput" stroke="#5C10EA" yAxisId={0} />
      </LineChart>
    );
  };


  return (
    <Card className=" rounded-3 shadow-sm h-100" >
      <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center justify-content-between border-bottom border-bottom-2 shadow-sm border-secondary-subtle">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <GridIcon width={12} height={20} />
          <div className="production-title">Daily Energy Output</div>
          <InfoIcon width={20} height={20} />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row">
            <DateFilterOption options={chartOptions} onValueSelect={chartOptionsChange} />
            <MuiDatePickerComponent
              value={selectedDate}
              onChange={handleDateChange}
              views={['year', 'month']}
              openTo={getOpenToView()}
              disabled={chartOption === 'Total'} />
          </div>
          <div className="hp-divider"></div>

          {/* <SvgIcon component={expandAltIcon} onClick={() => setIsModalOpen(true)} /> */}
          <img
            src={expandAltIcon}
            alt="Expand"
            className="hp-expand-icon"
            onClick={() => setIsModalOpen(true)} // Open modal on click
          />
        </div>
      </Card.Header>
      <Card.Body>

        <div className="hp-chart-container">
          <ResponsiveContainer width="100%" height={200}>
            {renderData()}
          </ResponsiveContainer>
        </div>

        {/* Modal structure */}
        {isModalOpen && (
          <div className={`hp-modal open`}>
            <div className="hp-modal-content">
              <button
                className="hp-modal-close"
                onClick={() => setIsModalOpen(false)}
              >
                &times;
              </button>
              <div className="hp-modal-header">
                <h2 className="hp-modal-title">Daily Energy Output</h2>
              </div>
              <div className="hp-modal-card-content">
                <ResponsiveContainer width="100%" height={400}>
                  {renderData()} {/* Reuse the same chart rendering logic */}
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default DailyEnergyOutput;