import { Button, Col, Container, Form, Image, Row, Stack } from "react-bootstrap";
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import blueSolidLeftIcon from "../../assets/blue_solid_left.png";
import InfoIcon from '@mui/icons-material/Info';
import { Divider } from "@mui/material";


function Styles() {
    return (
        <style>{`
               .row-card{
                 background-color:#f2f5f7;
                 text-align: left;
                 align-items: center;
                 justify-content-center;
                 box-shadow: 0px 0px 3px 1px #d7dadb;
                 border-radius: 5px;
               }

               .form-button{
                   background-color: #167db0;
                   color: white;
                   border-radius: 7px;
                   padding: 3px 20px 3px 20px
                }
               .verify-message{
                    color: #3767A2;
                    font-weight:700px;
                    font-size:16px;
                    padding-left:13px;  
                    letter-spacing: 1px;
                }

                .fw-bold{
                    font-weight:700px;
                    font-size:22px;
                    letter-spacing: 2px;
                    align-items: center;
                }

                .form-button:hover{
                background-color: white !important;
                color : black !important;
                border-color:#167db0 !important;
                }

                .form-button-sp{
                    padding: 1px 10px 1px 10px !important;
                    border-radius: 20px !important;
                }

              .card-height{
               height: 88% !important
              }           
                .user-add-form{
                    font-family: 'Open Sans';
                    font-weight: normal;
                } 
                .user-add-form .row{
                align-items: center;
                }
                .user-add-form .form-label{
                 font-weight:bold;
                 margin: 0px;
                }
                 .user-add-form .form-select{
                 font-weight:normal !important;
                 font-family: 'Open Sans';
                }

                .user-add-form .form-button{
                  font-family: 'Open Sans';
                    font-weight: bold;
                }
               .user-add-form .form-control{
                  font-family: 'Open Sans';
                    font-weight: normal;
                }
               .user-add-form .form-check-input{
                   box-shadow: 0px 1px 3px #00000029;
                   border: 1px solid #0F0D0D;
                   border-radius: 5px;
                   opacity: 1;
                   width: 20px;
                   height: 20px;

               }
               .user-add-form .form-check-input:checked {
               background-image:url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27><path fill=%27none%27 stroke=%27black%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/></svg>');
              border-color: black;
              background-color: white;
              }    
              .entity-form-left-arrow{
                width: 23px;
                height: 23px;
                opacity: 1;
                margin-right: 5px;
               }
                .user-title{
                font-family: 'Open Sans';
                font-weight: bold;
                font-size: 20px;
                color: #0F0D0D;
                }
               
                .report-field .col .row:nth-child(odd){
                  background-color:#f2f4f5;
                  height:60px;
                  text-wrap:nowrap;
                }
                .report-field .col .row:nth-child(even){
                  background-color:white;
                  height:60px;
                  text-wrap:nowrap;
                }
                
                .report-field{
                 box-shadow: 1px 2px 3px #CCDBE54B;
                  border: 1px solid #9D9D9D;
                  border-radius: 9px;
                }

                .report-field  .report-col{
                  box-shadow: 1px 2px 3px #CCDBE54B;
                  border: solid #9D9D9D;
                  border-width: 1px 1px 1px 1px;              
                  
                }
               
                .report-field .report-col .row:last-child {
                    border-radius: 9px;
                }
                
                .report-title{
                 font-weight:bold;
                  border-radius: 9px;
                }
                  .report-col .row {
  
    padding-left: 20px;
    padding-right: 10px;
    border: none;
}
               
        `}

        </style>
    );
}

function AdminReportAdd({ stateChanger }) {
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        templateId: '',
        reportName: "",
        reportMenu: "",
        scheduling: "",
        createdBy: "",
        creationDate: "",
    })

    const [moreLength, setMoreLength] = useState(5);
    const [reportMenu, setReportMenu] = useState(['Master', 'Operations', 'Maintenence'])
    const [schedules, setSchedules] = useState(['None', 'Daily', 'Weekly', 'Monthly', 'Quarterly'])
    const [masters, setMasters] = useState([{ name: 'Entity', status: false }, { name: 'Plant', status: false }, { name: 'Device', status: false }])
    const handleMastersSelection = (m) => {
        masters.forEach((md) => (md.title === m.title ? md.status = true : md.status = false));
        setMasters([...masters])
    }

    const [entityFiled, setEntityFiled] = useState([
        { field: 'Entity_Name', selected: false, filter: false }, { field: 'Category', selected: false, filter: false },
        { field: 'Location', selected: false, filter: undefined }, { field: 'Country', selected: false, filter: false },
        { field: 'State', selected: false, filter: false }, { field: 'District', selected: false, filter: false },
        { field: 'Entity_ID', selected: false, filter: undefined }, { field: 'Region', selected: false, filter: false },
        { field: 'Address Line1', selected: false, filter: undefined }, { field: 'Address Line2', selected: false, filter: undefined },
        { field: 'Pincode', selected: false, filter: undefined }, { field: 'Contact Person', selected: false, filter: undefined },
        { field: 'First Name', selected: false, filter: false }, { field: 'Last Name', selected: false, filter: false },
        { field: 'Email', selected: false, filter: false }, { field: 'Mobile', selected: false, filter: false },
    ]);

    const handleEntitySelection = (m) => {
        entityFiled.forEach((md) => (md.field === m.field ? md.selected = !m.selected : ''));
        setEntityFiled([...entityFiled])
    }
    const handleEntityFilterOne = (m) => {
        entityFiled.forEach((md) => (md.field === m.field ? md.filter = !m.filter : ''));
        setEntityFiled([...entityFiled])
    }
    const [plantFiled, setPlantFiled] = useState([
        { field: 'Plant_name', selected: false, filter: false }, { field: 'Capacity', selected: false, filter: false },
        { field: 'Plant_type', selected: false, filter: false }, { field: 'Country', selected: false, filter: false },
        { field: 'State', selected: false, filter: false }, { field: 'District', selected: false, filter: false },
        { field: 'Plant_Id', selected: false, filter: undefined }, { field: 'Installation_Date', selected: false, filter: undefined },
        { field: 'Installation_status', selected: false, filter: false }, { field: 'Category', selected: false, filter: false },
        { field: 'Location', selected: false, filter: undefined }, { field: 'Region', selected: false, filter: false },
        { field: 'Longitude', selected: false, filter: false }, { field: 'Latitude', selected: false, filter: false },]);

    const handlePlantSelection = (m) => {
        plantFiled.forEach((md) => (md.field === m.field ? md.selected = !m.selected : ''));
        setPlantFiled([...plantFiled])
    }

    const handlePlantFilterOne = (m) => {
        plantFiled.forEach((md) => (md.field === m.field ? md.filter = !m.filter : ''));
        setPlantFiled([...plantFiled])
    }

    const [deviceFiled, setDeviceFiled] = useState([
        { field: 'Device_id', selected: false, filter: undefined }, { field: 'Device serial number', selected: false, filter: undefined },
        { field: 'Device Type', selected: false, filter: false }, { field: 'Make', selected: false, filter: false },
        { field: 'Model number', selected: false, filter: false }, { field: 'Power rating', selected: false, filter: false },
        { field: 'Device sub-type', selected: false, filter: undefined }, { field: 'Software version', selected: false, filter: false },
        { field: 'Firmware1 version', selected: false, filter: false }, { field: 'Firmware2 version', selected: false, filter: false },
        { field: 'Master Device Type', selected: false, filter: undefined }, { field: 'Master Device_id', selected: false, filter: undefined },
        { field: 'Master Device Serial number', selected: false, filter: undefined }]);

    const handleDeviceSelection = (m) => {
        deviceFiled.forEach((md) => (md.field === m.field ? md.selected = !m.selected : ''));
        setDeviceFiled([...deviceFiled])
    }
    const handleDeviceFilterOne = (m) => {
        deviceFiled.forEach((md) => (md.field === m.field ? md.filter = !m.filter : ''));
        setDeviceFiled([...deviceFiled])
    }

    const [more, setMore] = useState(false);

    function handleChange(event) {
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name;
        setFormData({ ...formData, [name]: value });
    }


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };

    return (<div className="d-flex flex-column align-items-center justify-content-center user-add-form">
        {/* <Container className="mw-100"> */}
        <Styles />
        {/* <Row className="mt-2 mb-2">
                <Col className="d-flex flex-row align-items-center justify-content-start center-block">
                   <div className="text-decoration-none d-flex align-items-center">
                        <img
                          onClick={()=>stateChanger(10)}
                            className="entity-form-left-arrow"
                            src={blueSolidLeftIcon}
                            alt="sort"
                            style={{ cursor: "pointer" }}                        />

                        <span className="fw-bold text-decoration-none text-black">New Report</span>
                    </div>
                </Col>
                <Col className="d-flex flex-row align-items-center justify-content-end">

                </Col>
            </Row> */}
        {/* </Container> */}
        <Container className="mw-100 user-add-form">
            <Form className="w-100 p-0 m-0 " noValidate validated={validated} onSubmit={handleSubmit}>
                <div className=" d-flex flex-column align-items-center justify-content-center w-80 p-0 mt-2">
                    <div className="w-100 text-left">
                        <span className="user-title">New Report</span>
                    </div>
                    <Row className="gap-2 w-100 row-card p-4 m-2 d-flex flex-lg-row  flex-column">
                        <Col>
                            <Stack as="row" gap={2} direction="vertical" >
                                <Col>
                                    <Form.Group controlId="templateId">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label className="text-nowrap">Template-Id <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} />  <spa>:</spa></Form.Label>
                                            </Col><Col md={9} >
                                                <Form.Control name="templateId" required type="string" onChange={(e) => handleChange(e)} placeholder="Greentek-VPH-SUB-1003" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col span>
                                    <Form.Group controlId="reportName">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label className="text-nowrap">Report Name <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} />  <spa>:</spa></Form.Label>
                                            </Col><Col md={9} >
                                                <Form.Control name="reportName" required type="string" onChange={(e) => handleChange(e)} placeholder="Name" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="reportMenu">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label className="text-nowrap">Report Menu <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} />  <spa>:</spa></Form.Label>
                                            </Col>
                                            <Col md={9} >
                                                <Form.Select name="reportMenu" defaultValue='Master' onChange={(e) => handleChange(e)} required placeholder="Select Report Time" size="md">
                                                    <option value="">Select Report Type</option>
                                                    {reportMenu.map((v) => {
                                                        return <option value={v}>{v.toString()}</option>
                                                    })}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="scheduling">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label className="text-nowrap">Scheduling <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} />  <spa>:</spa></Form.Label>
                                            </Col>
                                            <Col md={9} >
                                                <Form.Select name="scheduling" defaultValue='None' onChange={(e) => handleChange(e)} required placeholder="Scheduleing" size="md">
                                                    <option value="">Select Report Schedule</option>
                                                    {schedules.map((v) => {
                                                        return <option value={v}>{v.toString()}</option>
                                                    })}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Stack>
                        </Col>
                        <Col>
                            <Stack gap={3} direction="vertical">

                                <Form.Group controlId="createdBy">
                                    <Row className="w-100">
                                        <Col md={3}>
                                            <Form.Label className="text-nowrap">Created by <spa>:</spa></Form.Label>
                                        </Col><Col md={8} >
                                            <Form.Control name="createdBy" disabled readOnly type="string" onChange={(e) => handleChange(e)} placeholder="EmailID of logged in peron" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="creationDate">
                                    <Row className="w-100">
                                        <Col md={3}>
                                            <Form.Label className="text-nowrap">Creation Date <spa>:</spa></Form.Label>
                                        </Col><Col md={8} >
                                            <Form.Control name="creationDate" disabled readOnly type="string" onChange={(e) => handleChange(e)} placeholder="12/01/2024" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <div className="p-4"></div>
                                <div className="p-2"></div>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className="w-100">
                        <div className=" gap-2 w-100 d-flex flex-row align-items-center justify-content-end">

                            <Button onClick={() => stateChanger(10)} className="ml-3 m-1 form-button">Cancel</Button><Button type="submit" className="ml-3 m-1 form-button">Submit</Button>


                        </div>
                    </Row>
                </div>
            </Form>
            <div className="user-add-form fw-bold">Masters</div>

            <Row className="w-100 my-3 mx-0 report-title">
                {masters.map((m, index) => (
                    <Col>
                        <div className="d-flex flex-row gap-2 align-items-center justify-content-left">
                            <Form.Check value={m.name}
                                type="checkbox"
                                id={index + "m"}
                                checked={m.status}
                                onChange={() => handleMastersSelection(m)}
                            />
                            <div className="text-center">{m.name}</div>

                        </div>
                    </Col>

                ))}
            </Row>

            <Divider sx={{
                color: 'lightgray', height: '1px',
                background: 'lightgrey'
            }} className="my-2" direction="horizontal" />
            <Row className="report-field align-items-baseline user-add-form">
                <Col className="report-col rounded-start">
                    <Row className="bg-white report-title" direction="horizontal">
                        <Col md={6}>Field</Col>
                        <Col>Selected</Col>
                        <Col>Filter</Col>
                    </Row>


                    <div className="d-flex flex-column gap-3 mt-2">
                        {entityFiled.filter((v, index) => index <= moreLength).map((e) => (
                            <Row gap={2} direction="horizontal">
                                <Col md={6}>{e.field}</Col>
                                <Col>
                                    {e.selected !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={e.selected}
                                        onChange={() => handleEntitySelection(e)}
                                    /> : <></>}
                                </Col>
                                <Col>
                                    {e.filter !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={e.filter}
                                        onChange={() => handleEntityFilterOne(e)}
                                    /> : <></>}
                                </Col>
                            </Row>
                        ))}
                        <Row><div onClick={() => setMore(!more)}>{more ? 'Less...' : 'More...'}</div></Row>
                        {more ? entityFiled.filter((v, index) => index > moreLength).map((e) => (
                            <Row gap={2} direction="horizontal">
                                <Col md={6}>{e.field}</Col>
                                <Col>
                                    {e.selected !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={e.selected}
                                        onChange={() => handleDeviceSelection(e)}
                                    /> : <></>}
                                </Col>
                                <Col>
                                    {e.filter !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={e.filter}
                                        onChange={() => handleDeviceFilterOne(e)}
                                    /> : <></>}
                                </Col>
                            </Row>
                        )) : ''}

                        <Row className="bg-white"><div></div></Row>
                    </div>
                </Col>

                <Col className="report-col">
                    <Row className="bg-white report-title" direction="horizontal">
                        <Col md={5}>Field</Col>
                        <Col>Selected</Col>
                        <Col>Filter 1</Col>
                        <Col>Filter 2</Col>
                    </Row>


                    <div className="d-flex flex-column gap-3 mt-2">
                        {plantFiled.filter((v, index) => index <= moreLength).map((p) => (
                            <Row gap={2} direction="horizontal">
                                <Col md={6}>{p.field}</Col>
                                <Col>
                                    {p.selected !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={p.selected}
                                        onChange={() => handlePlantSelection(p)}
                                    /> : <></>}
                                </Col>
                                <Col>
                                    {p.filter !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={p.filter}
                                        onChange={() => handlePlantFilterOne(p)}
                                    /> : <></>}
                                </Col>
                                <Col></Col>
                            </Row>
                        ))}
                        <Row> <div></div></Row>
                        {more ? plantFiled.filter((v, index) => index > moreLength).map((p) => (
                            <Row gap={2} direction="horizontal">
                                <Col md={6}>{p.field}</Col>
                                <Col>
                                    {p.selected !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={p.selected}
                                        onChange={() => handleDeviceSelection(p)}
                                    /> : <></>}
                                </Col>
                                <Col>
                                    {p.filter !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={p.filter}
                                        onChange={() => handleDeviceFilterOne(p)}
                                    /> : <></>}
                                </Col>
                                <Col></Col>
                            </Row>
                        ))
                            : ''}
                        {more ?
                            <><Row ><div></div></Row>
                                <Row ><div></div></Row>

                            </> : ''}

                        <Row className="bg-white"><div></div></Row>
                    </div>
                </Col>

                <Col className="report-col rounded-end">
                    <Row className="bg-white report-title" direction="horizontal">
                        <Col md={6}>Field</Col>
                        <Col>Selected</Col>
                        <Col>Filter</Col>
                    </Row>


                    <div className="d-flex flex-column gap-3 mt-2">
                        {deviceFiled.filter((v, index) => index <= moreLength).map((d) => (
                            <Row gap={2} direction="horizontal">
                                <Col md={6}>{d.field}</Col>
                                <Col>
                                    {d.selected !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={d.selected}
                                        onChange={() => handleDeviceSelection(d)}
                                    /> : <></>}
                                </Col>
                                <Col>
                                    {d.filter !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={d.filter}
                                        onChange={() => handleDeviceFilterOne(d)}
                                    /> : <></>}
                                </Col>
                            </Row>
                        ))}
                        <Row><div></div></Row>
                        {more ? deviceFiled.filter((v, index) => index > moreLength).map((d) => (
                            <Row gap={2} direction="horizontal">
                                <Col md={6}>{d.field}</Col>
                                <Col>
                                    {d.selected !== undefined ? <Form.Check
                                        type="checkbox"
                                        checked={d.selected}
                                        onChange={() => handleDeviceSelection(d)}
                                    /> : <></>}
                                </Col>
                                <Col>
                                    {d.filter !== undefined ? <Form.Check
                                        type="checkbox"

                                        checked={d.filter}
                                        onChange={() => handleDeviceFilterOne(d)}
                                    />
                                        : <></>}
                                </Col>
                            </Row>
                        )) : ''}
                        {more ?
                            <> <Row ><div></div></Row>
                                <Row ><div></div></Row>
                                <Row ><div></div></Row>
                            </> : ''}
                        <Row className="bg-white"><div></div></Row>
                    </div>
                </Col>

            </Row>

            <Row className="w-100 mt-3">
                <div className=" gap-2 w-100 d-flex flex-row align-items-center justify-content-end">

                    <Button onClick={() => stateChanger(10)} className="ml-3 m-1 form-button">Cancel</Button><Button type="submit" className="ml-3 m-1 form-button">Submit</Button>


                </div>
            </Row>

        </Container>
    </div>)
}

export default AdminReportAdd;