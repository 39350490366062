export const ReportData=[
    {
      "templateId":"R007AB123401",
      "reportName":"Plant Master",
      "reportMenu":"Maintenance",
      "createdBy":"Rinku",
      "creationDate":"08:11:2024",
      "lastUpdateDate":"14:06:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123402",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"02:04:2024",
      "lastUpdateDate":"15:01:2025",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123403",
      "reportName":"Plant Master",
      "reportMenu":"Maintenance",
      "createdBy":"Rinku",
      "creationDate":"04:07:2024",
      "lastUpdateDate":"14:06:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123404",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"01:05:2024",
      "lastUpdateDate":"14:07:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123405",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"28:02:2024",
      "lastUpdateDate":"05:08:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123406",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"13:07:2024",
      "lastUpdateDate":"18:07:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123407",
      "reportName":"Plant Master",
      "reportMenu":"Master",
      "createdBy":"Rinku",
      "creationDate":"26:05:2024",
      "lastUpdateDate":"15:02:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123408",
      "reportName":"Plant Master",
      "reportMenu":"Master",
      "createdBy":"Rinku",
      "creationDate":"03:09:2024",
      "lastUpdateDate":"24:10:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123409",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"23:07:2024",
      "lastUpdateDate":"04:03:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123410",
      "reportName":"Plant Master",
      "reportMenu":"Master",
      "createdBy":"Rinku",
      "creationDate":"10:06:2024",
      "lastUpdateDate":"10:12:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123411",
      "reportName":"Plant Master",
      "reportMenu":"Master",
      "createdBy":"Rinku",
      "creationDate":"15:07:2024",
      "lastUpdateDate":"14:11:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123412",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"06:01:2025",
      "lastUpdateDate":"28:02:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123413",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"15:12:2024",
      "lastUpdateDate":"19:05:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123414",
      "reportName":"Plant Master",
      "reportMenu":"Maintenance",
      "createdBy":"Rinku",
      "creationDate":"21:02:2024",
      "lastUpdateDate":"22:05:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123415",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"24:07:2024",
      "lastUpdateDate":"14:06:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123416",
      "reportName":"Plant Master",
      "reportMenu":"Maintenance",
      "createdBy":"Rinku",
      "creationDate":"02:12:2024",
      "lastUpdateDate":"28:07:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123417",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"19:07:2024",
      "lastUpdateDate":"23:07:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123418",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"20:12:2024",
      "lastUpdateDate":"08:06:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123419",
      "reportName":"Plant Master",
      "reportMenu":"Master",
      "createdBy":"Rinku",
      "creationDate":"26:12:2024",
      "lastUpdateDate":"20:12:2024",
      "comment":"Lorem Ipsum"
    },
    {
      "templateId":"R007AB123420",
      "reportName":"Plant Master",
      "reportMenu":"Operations",
      "createdBy":"Rinku",
      "creationDate":"18:11:2024",
      "lastUpdateDate":"14:12:2024",
      "comment":"Lorem Ipsum"
    }
  ]