import React from "react";
// import SolarNavbar from "./../navbar/SolarNavbar"
import MaintenanceTable from "./MaintenanceTable";
import "./Maintenance.css";

const Maintenance = () => {
  return (
    <div className="maintenance">
      {/* <SolarNavbar /> */}
      <MaintenanceTable/>
    </div>
  );
};

export default Maintenance;