// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import downArrowIcon from "../../../assets/drop_down_arrow.svg"; // Adjust the path as necessary
// import expandAltIcon from "../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
// import sampleData from "../SampleData"; // Adjust the path as necessary
// import dragHandleDots from "../../../assets/drag_handle_dots.svg"; // Adjust the path as necessary
// import informationIcon from "../../../assets/information_icon.svg";

// const Card = styled.div`
//   border: 1px solid #e0e0e0;
//   border-radius: 8px;
//   padding: 16px;
//   margin: 16px;
//   width: 30%;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   background: #fff;
//   height: 250px;
// `;

// const Title = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 12px;
//   font-weight: bold;
//   font-size: 1rem;
// `;

// const TitleContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 8px;

//   .drag-handle {
//     width: 16px; /* Set the desired width for the drag handle icon */
//     height: 16px; /* Set the desired height if needed */
//   }

//   .info-icon {
//     width: 17px; /* Set the desired width for the information icon */
//     height: 17px; /* Set the desired height if needed */
//   }
// `;

// const Controls = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const CustomDropdown = styled.div`
//   display: flex;
//   align-items: center;
//   position: relative;
//   cursor: pointer;

//   select {
//     appearance: none;
//     border: none;
//     background: none;
//     font-weight: bold;
//     font-size: 0.8em;
//     cursor: pointer;
//     padding-right: 20px;
//   }

//   img {
//     position: absolute;
//     right: 0;
//     pointer-events: none;
//     width: 20px;
//   }
// `;

// const Icon = styled.img`
//   margin-left: 8px;
//   cursor: pointer;
// `;

// const Divider = styled.div`
//   width: 1px;
//   height: 24px;
//   background-color: #e0e0e0;
//   margin: 0 8px;
// `;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;

//   th,
//   td {
//     padding: 6px;
//     text-align: left;
//   }

//   th {
//     font-weight: bold;
//     font-size: 0.8em;
//     color: #ff;
//     background-color: transparent;
//     padding-bottom: 10px;
//   }

//   td {
//     font-size: 0.8em;
//     color: #333;
//     background-color: #f7f8fa; /* Background color for table rows */
//   }

//   tr:nth-child(odd) td {
//     background-color: #edf0f5; /* Slightly darker background for alternating rows */
//   }

//   tr td {
//     border: none; /* Remove the border lines */
//   }
// `;

// const TitleDivider = styled.div`
//   border-bottom: 1px solid #e0e0e0;
//   width: 100%;
//   margin-bottom: 8px;
// `;

// const PeakHourRanking = () => {
//   const [selectedOption, setSelectedOption] = useState("Last 5");
//   const [peakHourData, setPeakHourData] = useState([]);

//   useEffect(() => {
//     const today = new Date().toDateString();

//     const filteredData = sampleData
//       .filter((entry) => new Date(entry.DATE).toDateString() === today)
//       .map((entry) => ({
//         plantName: "V Shashidhar", // Use the specified plant name
//         address: "Secunderabad", // Use the specified address
//         peakHourProduction:
//           entry.peak_hour_production !== null
//             ? entry.peak_hour_production
//             : "0", // Display as 0 if null
//       }));

//     setPeakHourData(filteredData);
//   }, [selectedOption]);

//   const handleDropdownChange = (event) => {
//     setSelectedOption(event.target.value);
//   };

//   return (
//     <Card>
//       <Title>
//         <TitleContainer>
//           <img src={dragHandleDots} alt="Drag Handle" className="drag-handle" />
//           <span>Peak hour Ranking</span>
//           <img
//             src={informationIcon}
//             alt="Information Icon"
//             className="info-icon"
//           />
//         </TitleContainer>
//         <Controls>
//           <CustomDropdown>
//             <select value={selectedOption} onChange={handleDropdownChange}>
//               <option value="Top 5">Top 5</option>
//               <option value="Last 5">Last 5</option>
//             </select>
//             <img src={downArrowIcon} alt="Dropdown icon" />
//           </CustomDropdown>
//           <Divider />
//           <Icon src={expandAltIcon} alt="Expand icon" />
//         </Controls>
//       </Title>
//       <TitleDivider />
//       <Table>
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>Address</th>
//             <th>Hours</th> {/* Updated to match the image's column title */}
//           </tr>
//         </thead>
//         <tbody>
//           {peakHourData.map((plant, index) => (
//             <tr key={index}>
//               <td>{plant.plantName}</td>
//               <td>{plant.address}</td>
//               <td>{plant.peakHourProduction}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </Card>
//   );
// };

// export default PeakHourRanking;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import downArrowIcon from "../../../assets/drop_down_arrow.svg"; // Adjust the path as necessary
import expandAltIcon from "../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
import sampleData from "../SampleData"; // Adjust the path as necessary
import dragHandleDots from "../../../assets/drag_handle_dots.svg"; // Adjust the path as necessary
import informationIcon from "../../../assets/information_icon.svg";
import { Dialog, DialogContent  } from '@mui/material';
import CloseBlue from "../../../assets/closeBtnBlue.svg";

const Card = styled.div`
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #888888;
  border-radius: 8px;
  padding: 12px;
  margin: 16px;
  width: 30%;
  background: #fff;
  max-height: 350px;
  position: relative; /* Added for positioning the "more" text */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures the "more" text stays at the bottom */
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;

  .drag-handle {
    /*width: 16px; /* Set the desired width for the drag handle icon */
    /*height: 16px; /* Set the desired height if needed */
  }

  .info-icon {
    width: 20px; /* Set the desired width for the information icon */
    height: 20px; /* Set the desired height if needed */
  }

  .card-title{
    font-family: "Lato", Open Sans;
    font-weight: bold;
    font-size: 18px;
    color: #1C2E38;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const CustomDropdown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  select {
    appearance: none;
    border: none;
    background: none;
    font-family: Open Sans, bold;
    font-weight: bold;
    font-size: 18px;
    color: #818F96;
    cursor: pointer;
    padding-right: 25px;
  }

  img {
    position: absolute;
    right: 0;
    pointer-events: none;
    width: 25px;
  }
`;

const Icon = styled.img`
  margin-left: 11px;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: #e0e0e0;
  margin: 0 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  
  th,
  td {
    padding: 6px;
    text-align: left;
  }

  th {
    font-family: "LATO", Open Sans;
    font-weight: bold;
    font-size: 16px;
    color: #343544;
    background-color: transparent;
    padding-bottom: 10px;
  }

  td {
    font-family: "LATO", Open Sans;
    font-weight: normal;
    font-size: 18px;
    color: #343544;
    background-color: #f7f8fa; /* Background color for table rows */
  }

  tr:nth-child(odd) td {
    background-color: #edf0f5; /* Slightly darker background for alternating rows */
  }

  tr td {
    border: none; /* Remove the border lines */
  }
`;

const TitleDivider = styled.div`
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  margin-bottom: 8px;
`;

const MoreText = styled.div`
  color: #f75a34; /* Set the color */
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  text-align: right;
  margin-top: auto; /* Pushes the "more" text to the bottom of the card */
`;

const PeakHourRanking = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [selectedOption, setSelectedOption] = useState("Last 5");
  const [peakHourData, setPeakHourData] = useState([]);

  useEffect(() => {
    const today = new Date().toDateString();

    const filteredData = sampleData
      .filter((entry) => new Date(entry.DATE).toDateString() === today)
      .map((entry) => ({
        plantName: "Plant Name", // Use the specified plant name
        address: "Secunderabad", // Use the specified address
        peakHourProduction:
          entry.peak_hour_production !== null
            ? entry.peak_hour_production
            : "0", // Display as 0 if null
      }));

    setPeakHourData(filteredData);
  }, [selectedOption]);

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Card>
      <div>
        <Title>
          <TitleContainer>
            <img
              src={dragHandleDots}
              alt="Drag Handle"
              className="drag-handle"
            />
            <span className="card-title">Peak hour Ranking</span>
            <img
              src={informationIcon}
              alt="Information Icon"
              className="info-icon"
            />
          </TitleContainer>
          <Controls>
            <CustomDropdown>
              <select value={selectedOption} onChange={handleDropdownChange}>
                <option value="Top 5">Top Five</option>
                <option value="Last 5">Bottom Five</option>
              </select>
              <img src={downArrowIcon} alt="Dropdown icon" />
            </CustomDropdown>
            
            <Icon src={expandAltIcon} alt="Expand icon" onClick={handleOpenModal} style={{cursor:'pointer'}}/>
          </Controls>
        </Title>
        <TitleDivider />
        <Table>
          
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Hours</th> {/* Updated to match the image's column title */}
            </tr>
          
          
            {peakHourData.map((plant, index) => (
              <tr key={index}>
                <td>{plant.plantName}</td>
                <td>{plant.address}</td>
                <td>{plant.peakHourProduction}</td>
              </tr>
            ))}
          
        </Table>

        {/* Modal Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth={'xl'} PaperProps={{
    sx: {
      width: "50%",
      minHeight: "45%"
    }
  }}
  fullWidth>
        <DialogContent>
          <div>
          <Title>
          <TitleContainer>
            <img
              src={dragHandleDots}
              alt="Drag Handle"
              className="drag-handle"
            />
            <span className="card-title">Peak hour Ranking</span>
            <img
              src={informationIcon}
              alt="Information Icon"
              className="info-icon"
            />
          </TitleContainer>
          <Controls>
            <CustomDropdown>
              <select value={selectedOption} onChange={handleDropdownChange}>
                <option value="Top 5">Top Five</option>
                <option value="Last 5">Bottom Five</option>
              </select>
              <img src={downArrowIcon} alt="Dropdown icon" />
            </CustomDropdown>
            
            <Icon src={CloseBlue} alt="Close icon" onClick={handleCloseModal} style={{width: 35, cursor:'pointer'}}/>
          </Controls>
        </Title>
        <TitleDivider />
        <Table>
          
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Hours</th> {/* Updated to match the image's column title */}
            </tr>
          
          
            {peakHourData.map((plant, index) => (
              <tr key={index}>
                <td>{plant.plantName}</td>
                <td>{plant.address}</td>
                <td>{plant.peakHourProduction}</td>
              </tr>
            ))}
          
        </Table>
          </div>
        
        </DialogContent>
      </Dialog>
      </div>
      <MoreText>more...</MoreText> {/* Positioned at the bottom right */}
    </Card>
  );
};

export default PeakHourRanking;
