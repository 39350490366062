import { Col, Container, FloatingLabel, Form, Row, Stack,Button } from "react-bootstrap";
import BottomHeaderBar from "../navbar/BottomHeaderBar";
// import SolarNavbar from "../navbar/SolarNavbar";
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { green } from "@mui/material/colors";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
function Styles() {
    return (
        <style>{`
           .row-card{
             background-color:#f2f5f7;
             text-align: left;
             align-items: center;
             justify-content-center;
             box-shadow: 0px 0px 3px 1px #d7dadb;
             border-radius: 5px;
           }    

           .form-button{
               background-color: #167db0;
               color: white;
               border-radius: 7px;
               padding: 3px 20px 3px 20px
            }
        
            .form-button:hover{
            background-color: white !important;
            color : black !important;
            border-color:#167db0 !important;
            }

            .form-button-sp{
                padding: 1px 10px 1px 10px !important;
                border-radius: 20px !important;
            }

          .card-height{
           height: 88% !important
          }
           
    `}

        </style>
    );
}
function NewTicket(props) {
    const { t } = useTranslation('ticket')
    const navigate=useNavigate();
    const [validated, setValidated] = useState(false);
    const location = useLocation();

    const [reasons, setReasons] = useState(['Network Issue', 'Not Performing', 'Over heating'])

    const [formData, setFormData] = useState({
        reason: "",
        description: "",

    })
    const [plant,setPlant]=useState();

    useEffect(()=>{
        if (location.state.plant !== undefined) {
            setPlant(location.state.plant)
        }
    },[plant])
    
    
    function handleBackArrow(){
        navigate("/dashboard/plants/plantStatus",{replace:true, state:{"plant":plant}})
    }

    function handleChange(event) {
        const target = event.currentTarget;
        const value =  target.value;
        const name = target.name;
        setFormData({...formData,[name]: value});
    }
    const handleSubmit = (event) => {

    };


    return (<div className="d-flex flex-column align-items-center justify-content-center">
        {/* <SolarNavbar /> */}
        <BottomHeaderBar />
        <Container className="mw-100 px-3">
            <Styles />
            <Row className="mt-2 mb-2 p-0">

                <Col className="d-flex flex-row align-items-center justify-content-start">
                <div className="text-decoration-none"  >
                        <ArrowCircleLeftRoundedIcon onClick={()=>handleBackArrow()} sx={{ color: green[500], marginRight: "10px" }} />
                        <span className="fw-bold text-decoration-none text-black">New Ticket ID :</span>
                    </div>

                </Col>

                <Col className="d-flex flex-row align-items-center justify-content-end">

                </Col>
            </Row>
        </Container>
        <Container className="mw-100 px-3 mt-2">
            <Form className="w-100 p-0 m-0 plant-add-form" noValidate validated={validated} onSubmit={handleSubmit}>
                <Container className=" d-flex flex-column align-items-center justify-content-center mw-100 p-0">
                    <div className="w-100 text-left"><h5>Plant-Name</h5></div>
                    <Row className="w-100 row-card m-2 p-4  d-flex flex-lg-row  flex-column">

                        <Col>
                            <Stack as="row" gap={2} direction="vertical" >
                                <Col >
                                    <Form.Group controlId="ticketId">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label aria-colspan={2} className="text-nowrap">Ticket-Id <spa>:</spa></Form.Label>
                                            </Col><Col md={9}>
                                                <Form.Label aria-colspan={2} defaultValue={formData.plantId} className="text-nowrap">Greentek-VPH-SUB-1003</Form.Label>
                                            </Col>
                                            {/* <Form.Control aria-colspan={3} readOnly type="string" /> */}
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group controlId="plantId">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label aria-colspan={2} className="text-nowrap">Plant-Id <spa>:</spa></Form.Label>
                                            </Col><Col md={9}>
                                                <Form.Label aria-colspan={2} defaultValue={formData.plantId} className="text-nowrap">Greentek-VPH-SUB-1003</Form.Label>
                                            </Col>
                                            {/* <Form.Control aria-colspan={3} readOnly type="string" /> */}
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col span>
                                    <Form.Group controlId="location">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label aria-colspan={2} className="text-nowrap">Location <spa>:</spa></Form.Label>
                                            </Col><Col md={8} >
                                                <Form.Label aria-colspan={2} className="text-nowrap">District Name <spa>:</spa></Form.Label>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="contact">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label aria-colspan={2} className="text-nowrap">Contact Number <spa>:</spa></Form.Label>
                                            </Col><Col md={9}>
                                                <Form.Label aria-colspan={2} defaultValue={formData.plantId} className="text-nowrap">+91752272382344</Form.Label>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="capacity">
                                        <Row className="w-100">
                                            <Col md={3}><Form.Label className="text-nowrap">Reason <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} /> <spa>:</spa></Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Select  onChange={(e)=>handleChange(e)} required name="reason" size="md">
                                                    {reasons.map((r) => {
                                                      return  <option value={r}>{r}</option>
                                                    })}

                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>

                            </Stack>
                        </Col>
                        <Col>
                            <Stack as="row" gap={2} direction="vertical" >
                                <Col >
                                    <Form.Group controlId="datetime">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label aria-colspan={2} className="text-nowrap">Date and Time <spa>:</spa></Form.Label>
                                            </Col><Col md={9}>
                                                <Form.Label aria-colspan={2} defaultValue={formData.plantId} className="text-nowrap">dd/mm/yyyy</Form.Label>
                                            </Col>
                                            {/* <Form.Control aria-colspan={3} readOnly type="string" /> */}
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group controlId="plantname">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label aria-colspan={2} className="text-nowrap">Plant Name <spa>:</spa></Form.Label>
                                            </Col><Col md={9}>
                                                <Form.Label aria-colspan={2} defaultValue={formData.plantId} className="text-nowrap">Plant Name</Form.Label>
                                            </Col>
                                            {/* <Form.Control aria-colspan={3} readOnly type="string" /> */}
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col span>
                                    <Form.Group controlId="contactname">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label aria-colspan={2} className="text-nowrap">Contact Name <spa>:</spa></Form.Label>
                                            </Col><Col md={8} >
                                                <Form.Label aria-colspan={2} className="text-nowrap">johndeo<spa>:</spa></Form.Label>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="contactemail">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label aria-colspan={2} className="text-nowrap">Contact Email Id <spa>:</spa></Form.Label>
                                            </Col><Col md={9}>
                                                <Form.Label aria-colspan={2} defaultValue={formData.plantId} className="text-nowrap">+johndeo@gmail.com</Form.Label>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="description">
                                        <Row className="w-100">
                                            <Col md={3}><Form.Label className="text-nowrap">Description <StarOutlinedIcon style={{ color: 'red', fontSize: 'smaller' }} /> <spa>:</spa></Form.Label>
                                            </Col>
                                            <Col md={7}>
                                                <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                                    <Form.Control onChange={(e)=>handleChange(e)}
                                                      required
                                                        as="textarea"
                                                        placeholder="Description"
                                                        style={{ height: '100px' }}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>

                            </Stack>
                        </Col>
                    </Row>
                    <Row className="w-100">
                        <div className="w-100 d-flex flex-row align-items-center justify-content-end">  <Button type="submit" className="m-1 form-button">Create</Button></div>
                    </Row>
                </Container>
            </Form>
        </Container>
    </div>
    )
}
export default NewTicket