// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import downArrowIcon from "../../../assets/drop_down_arrow.svg"; // Adjust the path as necessary
// import expandAltIcon from "../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
// import EnvironmentalBenefitsSVG from "./EnvironmentalBenefitsSVG"; // Import the SVG component
// import sampleData from "../SampleData"; // Adjust the path as necessary

// const Card = styled.div`
//   border: 1px solid #e0e0e0;
//   border-radius: 8px;
//   padding: 16px;
//   margin: 16px;
//   width: 100%;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   background: #fff;
//   display: flex;
//   flex-direction: column;
//   height: 250px; /* Adjust height to match other cards */
// `;

// const Title = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 8px;
//   font-weight: bold;
//   font-size: 1em;
// `;

// const Controls = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const CustomDropdown = styled.div`
//   display: flex;
//   align-items: center;
//   position: relative;
//   cursor: pointer;

//   select {
//     appearance: none;
//     border: none;
//     background: none;
//     font-weight: medium;
//     color: #818f96;
//     font-size: 0.9em;
//     cursor: pointer;
//     padding-right: 20px;
//   }

//   img {
//     position: absolute;
//     right: 0;
//     pointer-events: none;
//     width: 16px; /* Adjust width to match icon size */
//   }
// `;

// const Icon = styled.img`
//   margin-left: 8px;
//   cursor: pointer;
//   width: 16px; /* Adjust width to match icon size */
// `;

// const Divider = styled.div`
//   width: 1px;
//   height: 2px;
//   background-color: #e0e0e0;
//   margin: 0 8px;
// `;

// const DividerBelowTitle = styled.div`
//   width: 100%;
//   border-bottom: 1px solid #e0e0e0;
//   margin: 8px 0;
// `;

// const Content = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 90%;
// `;

// const ImageContainer = styled.div`
//   flex: 1;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   max-width: 40%; /* Ensure the image container doesn't take too much space */
//   max-height: 100px; /* Ensure the image doesn't overflow */
//   margin-right: 20px;
// `;

// const MetricsContainer = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   text-align: left;
//   margin-top: 10px;
//   max-width: 50%; /* Ensure the metrics container fits within the card */
// `;

// const Metric = styled.div`
//   margin-bottom: 16px;
//   font-size: 1em;

//   &:last-child {
//     margin-bottom: 0; /* Remove bottom margin from last metric */
//   }

//   &:not(:last-child)::after {
//     content: "";
//     display: block;
//     border-bottom: 1px dashed #e0e0e0;
//     margin: 2px 0;
//   }
// `;

// const Label = styled.div`
//   font-size: 0.8em;
//   color: #888;
//   margin-bottom: px;
// `;

// const Value = styled.div`
//   font-size: 1.2em;
//   font-weight: bold;
//   color: #167db0; /* Adjust color to match design */
//   span {
//     color: #101117; /* Black color for kINR, t, Trees */
//     font-size: 10px;
//   }
// `;

// const EnvironmentalBenefits = () => {
//   const [selectedOption, setSelectedOption] = useState("From Starting");
//   const [totalYields, setTotalYields] = useState(0);
//   const [co2Reduction, setCo2Reduction] = useState(0);
//   const [treesPlanted, setTreesPlanted] = useState(0);
//   const [co2Positive, setCo2Positive] = useState(0);
//   const [co2Negative, setCo2Negative] = useState(0);

//   useEffect(() => {
//     // Aggregate data from sampleData
//     const aggregatedData = sampleData.reduce(
//       (acc, curr) => {
//         acc.totalYields += curr.total_yield;
//         acc.co2Reduction += curr.co2_reduction / 1000; // Convert kg to tons
//         acc.treesPlanted += curr.trees_planted;
//         acc.co2Positive += curr.carbon_neutral;
//         acc.co2Negative += curr.carbon_offset;
//         return acc;
//       },
//       {
//         totalYields: 0,
//         co2Reduction: 0,
//         treesPlanted: 0,
//         co2Positive: 0,
//         co2Negative: 0,
//       }
//     );

//     // Update state with aggregated values
//     setTotalYields(aggregatedData.totalYields);
//     setCo2Reduction(aggregatedData.co2Reduction);
//     setTreesPlanted(Math.round(aggregatedData.treesPlanted)); // Round to nearest whole number
//     setCo2Positive(Math.round(aggregatedData.co2Positive)); // Round to nearest whole number
//     setCo2Negative(Math.round(aggregatedData.co2Negative)); // Round to nearest whole number
//   }, [selectedOption]);

//   const handleDropdownChange = (event) => {
//     setSelectedOption(event.target.value);
//   };

//   return (
//     <Card>
//       <Title>
//         <span>Environmental Benefits</span>
//         <Controls>
//           <CustomDropdown>
//             <select value={selectedOption} onChange={handleDropdownChange}>
//               <option value="From Starting">From Starting</option>
//               <option value="Last Year">Last Year</option>
//             </select>
//             <img src={downArrowIcon} alt="Dropdown icon" />
//           </CustomDropdown>
//           <Divider />
//           <Icon src={expandAltIcon} alt="Expand icon" />
//         </Controls>
//       </Title>
//       <DividerBelowTitle /> {/* Add the divider here */}
//       <Content>
//         <ImageContainer>
//           <EnvironmentalBenefitsSVG
//             co2Positive={co2Positive}
//             co2Negative={co2Negative}
//           />
//         </ImageContainer>
//         <MetricsContainer>
//           <Metric>
//             <Label>Total Yields</Label>
//             <Value>
//               {totalYields.toFixed(2)} <span>kINR</span>
//             </Value>
//           </Metric>
//           <Metric>
//             <Label>CO2 Emission Reduction</Label>
//             <Value>
//               {co2Reduction.toFixed(2)} <span>t</span> {/* Display as tons */}
//             </Value>
//           </Metric>
//           <Metric>
//             <Label>Trees Planted</Label>
//             <Value>
//               {treesPlanted} <span>Trees</span>{" "}
//               {/* Display as a whole number */}
//             </Value>
//           </Metric>
//         </MetricsContainer>
//       </Content>
//     </Card>
//   );
// };

// export default EnvironmentalBenefits;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import dragHandleDots from "../../../assets/drag_handle_dots.svg";
import informationIcon from "../../../assets/information_icon.svg";
import downArrowIcon from "../../../assets/drop_down_arrow.svg"; // Adjust the path as necessary
import expandAltIcon from "../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
import EnvironmentalBenefitsSVG from "./EnvironmentalBenefitsSVG"; // Import the SVG component
import sampleData from "../SampleData"; // Adjust the path as necessary
import { Dialog, DialogContent  } from '@mui/material';
import CloseBlue from "../../../assets/closeBtnBlue.svg";

const Card = styled.div`
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #888888;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-height: 350px; /* Adjust height to match other cards */
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 1em;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;

  .drag-handle {
    /*width: 16px; /* Set the desired width for the drag handle icon */
    /*height: 16px; /* Set the desired height if needed */
  }

  .info-icon {
    width: 20px; /* Set the desired width for the information icon */
    height: 20px; /* Set the desired height if needed */
  }

  .card-title{
    font-family: "Lato", Open Sans;
    font-weight: bold;
    font-size: 18px;
    color: #1C2E38;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const CustomDropdown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  select {
    appearance: none;
    border: none;
    background: none;
    font-weight: medium;
    color: #818f96;
    font-size: 0.9em;
    cursor: pointer;
    padding-right: 20px;
  }

  img {
    position: absolute;
    right: 0;
    pointer-events: none;
    width: 16px; /* Adjust width to match icon size */
  }
`;

const Icon = styled.img`
  margin-left: 8px;
  cursor: pointer;
  width: 16px; /* Adjust width to match icon size */
`;

const Divider = styled.div`
  width: 1px;
  height: 2px;
  background-color: #e0e0e0;
  margin: 0 8px;
`;

const DividerBelowTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  margin: 8px 0;
`;

const Content = styled.div`
  display: flex;
  background: #EFEFEF;
  border-radius: 9px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 47%; /* Ensure the image container doesn't take too much space */
  max-height: 100px; /* Ensure the image doesn't overflow */
  margin-right: 80px;
  margin-left: 50px;
`;

const MetricsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-top: 10px;
  max-width: 30%; /* Ensure the metrics container fits within the card */
`;

const Metric = styled.div`
  margin-bottom: 20px;
  font-size: 1em;

  &:last-child {
    margin-bottom: 0; /* Remove bottom margin from last metric */
  }

  &:not(:last-child)::after {
    content: "";
    display: block;
    /*border-bottom: 1px dashed #e0e0e0;*/
    margin: 12px 0;
  }
`;

const Label = styled.div`
  font-size: 18px;
  font-family: "LATO", Open Sans;
  font-weight: bold;
  color: #818F96;
  margin-bottom: 10px;
`;

const Value = styled.div`
  font-family: "LATO", Open Sans;
  font-size: 18px;
  font-weight: bold;
  color: #167db0; /* Adjust color to match design */
  span {
    font-family: "LATO", Open Sans;
    color: #101117; /* Black color for kINR, t, Trees */
    font-size: 14px;
    font-weight: 500;
  }
`;

const EnvironmentalBenefits = () => {
  const [selectedOption, setSelectedOption] = useState("From Starting");
  const [totalYields, setTotalYields] = useState(0);
  const [co2Reduction, setCo2Reduction] = useState(0);
  const [treesPlanted, setTreesPlanted] = useState(0);
  const [co2Positive, setCo2Positive] = useState(0);
  const [co2Negative, setCo2Negative] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Aggregate data from sampleData
    const aggregatedData = sampleData.reduce(
      (acc, curr) => {
        acc.totalYields += curr.total_yield;
        acc.co2Reduction += curr.co2_reduction / 1000; // Convert kg to tons
        acc.treesPlanted += curr.trees_planted;
        acc.co2Positive += curr.carbon_neutral;
        acc.co2Negative += curr.carbon_offset;
        return acc;
      },
      {
        totalYields: 0,
        co2Reduction: 0,
        treesPlanted: 0,
        co2Positive: 0,
        co2Negative: 0,
      }
    );

    // Convert to tons if value exceeds 1000 kg
    const totalYieldsInTons =
      aggregatedData.totalYields >= 1000
        ? aggregatedData.totalYields / 1000
        : aggregatedData.totalYields;
    const co2PositiveInTons =
      aggregatedData.co2Positive >= 1000
        ? aggregatedData.co2Positive / 1000
        : aggregatedData.co2Positive;
    const co2NegativeInTons =
      aggregatedData.co2Negative >= 1000
        ? aggregatedData.co2Negative / 1000
        : aggregatedData.co2Negative;

    // Update state with aggregated values
    setTotalYields(totalYieldsInTons);
    setCo2Reduction(aggregatedData.co2Reduction);
    setTreesPlanted(Math.round(aggregatedData.treesPlanted)); // Round to nearest whole number
    setCo2Positive(co2PositiveInTons.toFixed(2)); // Round to 2 decimal places
    setCo2Negative(co2NegativeInTons.toFixed(2)); // Round to 2 decimal places
  }, [selectedOption]);

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Card>
      <Title>
        <TitleContainer>
          <img
            src={dragHandleDots}
            clas="Drag Handle"
            className="drag-handle"
          />
          <span className="card-title">Environmental Benefits</span>
          <img
            src={informationIcon}
            alt="Information"
            className="info-icon"
          />
        </TitleContainer>
        <Controls>
          {/* <CustomDropdown>
            <select value={selectedOption} onChange={handleDropdownChange}>
              <option value="From Starting">From Starting</option>
              <option value="Last Year">Last Year</option>
            </select>
            <img src={downArrowIcon} alt="Dropdown icon" />
          </CustomDropdown> */}
          <Divider />
          <Icon src={expandAltIcon} alt="Expand icon" onClick={handleOpenModal} style={{cursor:'pointer'}} />
        </Controls>
      </Title>
      <DividerBelowTitle /> {/* Add the divider here */}
      <Content>
        <ImageContainer>
          <EnvironmentalBenefitsSVG
            co2Positive={co2Positive}
            co2Negative={co2Negative}
          />
        </ImageContainer>
        <MetricsContainer>
          <Metric>
            <Label>Total Yield</Label>
            <Value>
              {totalYields.toFixed(2)} <span>k INR</span>
            </Value>
          </Metric>
          <Metric>
            <Label>REC Count</Label>
            <Value>
              {co2Reduction.toFixed(2)}
            </Value>
          </Metric>
          
        </MetricsContainer>
      </Content>
      {/* Modal Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth={'xl'}
        PaperProps={{
          sx: {
            width: "50%",
            minHeight: "45%"
          }
        }}
        fullWidth>
        <DialogContent>
          <div>
          <Title>
            <TitleContainer>
              <img
                src={dragHandleDots}
                clas="Drag Handle"
                className="drag-handle"
              />
              <span className="card-title">Environmental Benefits</span>
              <img
                src={informationIcon}
                alt="Information"
                className="info-icon"
              />
            </TitleContainer>
            <Controls>
              {/* <CustomDropdown>
                <select value={selectedOption} onChange={handleDropdownChange}>
                  <option value="From Starting">From Starting</option>
                  <option value="Last Year">Last Year</option>
                </select>
                <img src={downArrowIcon} alt="Dropdown icon" />
              </CustomDropdown> */}
              <Divider />
              <Icon src={CloseBlue} alt="Close Button" onClick={handleCloseModal} style={{width:35, cursor:'pointer'}}/>
            </Controls>
          </Title>
          <DividerBelowTitle /> {/* Add the divider here */}
          <Content style={{minHeight:350}}>
            <ImageContainer>
              <EnvironmentalBenefitsSVG
                co2Positive={co2Positive}
                co2Negative={co2Negative}
              />
            </ImageContainer>
            <MetricsContainer>
              <Metric>
                <Label>Total Yield</Label>
                <Value>
                  {totalYields.toFixed(2)} <span>k INR</span>
                </Value>
              </Metric>
              <Metric>
                <Label>REC Count</Label>
                <Value>
                  {co2Reduction.toFixed(2)}
                </Value>
              </Metric>
              
            </MetricsContainer>
          </Content>          
          </div>      
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default EnvironmentalBenefits;
