import React, { useState, useMemo, useEffect, useContext } from "react";
import "./PlantTable.css"; // Importing the styles
import axios from "axios"; // Importing axios for API requests
// Importing icons from src/assets
import updownIcon from "../../assets/updown_icon.png";
//import deleteIcon from "../../assets/delete_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import { MessageContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { Col, Form, OverlayTrigger, Popover, Row, Modal, Button } from "react-bootstrap";
import Paginationcontrol from "./PaginationControl";
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import { useTranslation } from "react-i18next";

const PlantTable = ({ stateChanger }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    plantId: false,
    plantName: true,
    plantType: true,
    plantCategory: true,
    capacity: false,
    capacityUnit: false,
    country: true,
    region: false,
    state: false,
    district: false,
    pincode: false,
    longitude: false,
    latitude: false,
    installDate: false,
    azimuthAngle: false,
    tiltAngle: false,
    ownerFirstName: true,
    ownerLastName: true,
    ownerEmail: true,
  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableProps, setTableProps] = useState({
    rowsPerPage: 5,
    currentPage: 1,
    pageFirst: 1,
    pageLast: 5,
  });

  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);

  const onMessageChange = useContext(MessageContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal visibility state
  const [selectedPlant, setSelectedPlant] = useState(null); // Selected plant for confirmation

  const downloadLink = () => {
    alert("Plant list download action");
  }

  const fetchData = async () => {
    try {
      const entityId = JSON.parse(sessionStorage.getItem("userData"))?.entityId || "";
      console.log("Entity ID:", entityId);

      const authToken = sessionStorage.getItem("authToken");
      console.log("Auth Token:", authToken);

      if (!entityId) {
        console.warn("No entity ID found. Ensure session storage contains 'userData' with 'entityId'.");
        return;
      }

      // Fetching data from the API
      const response = await axios.get(
        `https://solar-api.antsai.in/api/plant/fetchPlantList?entityid=${entityId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      console.log("API Response Status:", response.status);
      console.log("API Response Data:", response.data);

      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((item) => ({
          plantId: item["Plant ID"],
          plantName: item["Plant Name"],
          plantType: item["Plant Type"],
          plantCategory: item["Plant Category"],
          capacity: item["Capacity"],
          capacityUnit: item["Capacity Unit"],
          country: item["Country"],
          region: item["Region"],
          state: item["State"],
          district: item["District"],
          pincode: item["Pincode"],
          longitude: item["Longitude"],
          latitude: item["Latitude"],
          installDate: item["Install Date"],
          azimuthAngle: item["Azimuth Angle"],
          tiltAngle: item["Tilt Angle"],
          ownerFirstName: item["Owner First Name"],
          ownerLastName: item["Owner Last Name"],
          ownerEmail: item["Owner Email"],

        }));
        setData(formattedData);
        console.log("Formatted Data for Table:", formattedData);
      } else {
        console.error("Unexpected data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const handleDropdownToggle = () => {
  //   setIsDropdownVisible(!isDropdownVisible);
  // };

  // const handleFilterToggle = () => {
  //   setIsFilterVisible(!isFilterVisible);
  // };

  // const handleRowsPerPage = (value) => {
  //   setRowsPerPage(value)
  // }

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setIsFilterVisible(false); // Close the filter dropdown after selecting
  };

  const handleColumnSelect = (column) => {
    if (column !== "plantName") {  // Prevent toggling for plantId and plantName
      setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
    }
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  // Sorting logic based on the updown icon click
  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig, searchQuery]);

  // Filtering logic (only filters if a filter is selected and a search query is entered)
  const filteredData = useMemo(() => {
    return sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true; // No filter if no filter or search query is provided
      return row[selectedFilter]
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase()); // Filter matches only if it starts with the query
    })
  }, [update, sortConfig, searchQuery, sortedData]);

  // Pagination logic
  const currentRows = useMemo(() => {
    if (filteredData.length > 0) {
      return filteredData.slice(((tableProps.currentPage - 1) * tableProps.rowsPerPage), (((tableProps.currentPage - 1) * tableProps.rowsPerPage) + tableProps.rowsPerPage))
    }
    return []
  }, [filteredData, selectedRows, tableProps]);

  const tablePropsChange = (tableProps) => {
    setTableProps(tableProps);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handling a maximum of 7 columns and hiding the rest in horizontal scrolling
  const visibleColumns = Object.keys(selectedColumns).filter(
    (key) => selectedColumns[key]
  );

  //handling filter and dropdown select toggle outside
  function handleAllToggleEvents() {
    isFilterVisible == true ? setIsFilterVisible(false) : "";
    isDropdownVisible == true ? setIsDropdownVisible(false) : ""
  }

  const disableRow = (e, plant) => {
    e.stopPropagation();
    setSelectedPlant(plant); // Store selected plant for confirmation
    setShowConfirmModal(true); // Show confirmation modal
  };

  const confirmDisable = async () => {
    try {
      const response = await fetch("https://solar-api.antsai.in/api/plant/disable", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
        body: JSON.stringify({ plant_id: selectedPlant.plantId }),
      });

      if (response.ok) {
        onMessageChange({ type: "Success", info: "Plant disabled successfully." });
        setShowConfirmModal(false); // Close the modal on success
        fetchData(); // Fetch updated data instead of reloading the page
      } else {
        const errorData = await response.json();
        console.error("Failed to disable plant:", errorData);
        onMessageChange({ type: 'Danger', info: `Failed to disable plant: ${errorData.message || "Unknown error"}` });
        setShowConfirmModal(false); // Close the modal in case of error
      }
    } catch (error) {
      console.error("Error disabling plant:", error);
      onMessageChange({ type: "Warning", info: "An error occurred while disabling the plant. Please try again later." });
      setShowConfirmModal(false); // Close the modal in case of error
    }
  };

  const selectRows = (e, rowData) => {
    e.stopPropagation();
    const index = selectedRows.findIndex(r => r.id === rowData.id);
    { index === -1 ? setSelectedRows([...selectedRows, rowData]) : setSelectedRows([...selectedRows.filter(r => r.id !== rowData.id)]) }
  }

  const emptyRows = currentRows.length !== 0 ? currentRows.length : 0;

  const downloadCSV = () => {
    const csvRows = [];
    const headers = Object.keys(data[0] || {});
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes by doubling them
        return `"${escaped}"`; // Wrap each value in quotes
      });
      csvRows.push(values.join(","));
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "PlantsList.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-container">
        <Row className="d-flex flex-row align-items-center justify-content-between my-4">

          <Col></Col>
          <Col className="gap-3 d-flex flex-row align-items-center justify-content-end" >
            <span className="downloadLink" onClick={downloadCSV}>
              Download
            </span>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="left-start"
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <ul>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <li key={column} onClick={() => handleFilterSelect(column)}>
                            {column.charAt(0).toUpperCase() + column.slice(1)}
                          </li>
                        ))}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <img src={filterIcon} alt="filter" className="filter-icon" />
            </OverlayTrigger>
            <div style={{position:'relative'}} className=" w-auto">
              <input
                type="text"
                style={{width: '341px',
                  height: '39px',
                  background: '#BBCCD9 0% 0% no-repeat padding-box',
                  borderRadius: '5px',
                  opacity:' 0.51'}}
                className="search-input"
                placeholder={`Search by ${selectedFilter || "..."}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={!selectedFilter}
              />
              <img src={searchIcon} alt="search" className="search-icon" />
            </div>
          </Col>
        </Row>
        <div className="border rounded border-light-subtle">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  <th></th>
                  {Object.keys(selectedColumns)
                    .filter((key) => selectedColumns[key])
                    .map((column) => (
                      <th key={column} onClick={() => handleSort(column)}>
                        {column
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^\w/, (c) => c.toUpperCase())} {/* Capitalizes the first letter */}
                        <img src={updownIcon} alt="sort" style={{ cursor: "pointer" }} />
                      </th>
                    ))}
                  <th>
                  <div className="gap-2 d-flex flex-row align-items-center justify-content-end px-2">
                    <OverlayTrigger
                      rootClose
                      trigger="click"
                      placement="auto-start"
                      overlay={
                        <Popover>
                          <Popover.Body className="overlay-body">
                            <div>
                              {Object.keys(selectedColumns).map((column) => (
                                <label key={column}>
                                  <input
                                    type="checkbox"
                                    checked={selectedColumns[column]}
                                    onChange={() => handleColumnSelect(column)}
                                    // disabled={column === "plantId" || column === "plantName"} // Disable for these columns
                                    disabled={column === "plantName"}
                                  />{" "}
                                  {column.charAt(0).toUpperCase() + column.slice(1)}
                                </label>
                              ))}
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                    {/* <div></div> */}
                    <div>
                      <img src={settingIcon} alt="settings" className="setting-icon" />
                      </div>
                     
                    </OverlayTrigger>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRows.map((row) => (
                  <tr key={row.id}>
                    <td></td>
                    {/* <td>
                       <Form.Check type="checkbox" /> 
                    </td> */}
                    {Object.keys(selectedColumns)
                      .filter((key) => selectedColumns[key])
                      .map((column) => (
                        <td key={`${row.id}-${column}`}>{row[column]}</td>

                      ))}
                    <td>
                    <div className="gap-2 d-flex flex-row align-items-center justify-content-end px-2">
                      <NotInterestedOutlinedIcon
                        onClick={(e) => disableRow(e, row)}
                        style={{ cursor: "pointer" }}
                      />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {filteredData.length > 0 ? <Paginationcontrol
              rowsCount={filteredData.length}
              tableProps={tableProps}
              tablePropsChange={tablePropsChange}
              alwaysShown={true}
            /> : <></>}
          </div>
        </div>
      </div>
            {/* Confirmation Modal */}
            <div className="d-flex flex-column align-items-center justify-content-center">
            <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Disable Plant ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to disable the plant "{selectedPlant?.plantName}"?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modal-button"
            onClick={() => setShowConfirmModal(false)}
            autoFocus
          >
            No
          </button>
          <button className="modal-button" onClick={confirmDisable}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      </div>
    </div>
  );
};

export default PlantTable;