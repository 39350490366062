import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import editBtn from "../../assets/editBtn.svg";
import './Settings.css';

const TIME_ZONES = [
  "Africa/Abidjan", "Africa/Accra", "Africa/Addis_Ababa", "Africa/Algiers", "Africa/Asmara",
  "America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles",
  "Asia/Kolkata", "Asia/Tokyo", "Asia/Dubai", "Asia/Shanghai", "Asia/Singapore",
  "Australia/Sydney", "Europe/London", "Europe/Berlin", "Europe/Madrid", "Europe/Paris",
  "Pacific/Auckland", "Pacific/Honolulu"
];

const locationTypeData = [
  { label: "Country, Region, State/UT, District, City/Town/Village, Pin code", country: "India", selected: false },
  { label: "Country, Region, State/Territory, County, Zip cCode", country: "United States", selected: false },
  { label: "Country, Region, State, Municipality, Zip Code", country: "Brazil, Mexico", selected: false },
  { label: "Country, Provinces/Territory, Counties/Districts, zip Code", country: "Canada", selected: false },
  { label: "Country, Region, Governate, District", country: "Saudi Arabia", selected: false },
  { label: "Country, Emirates, Municipality, Districts", country: "UAE", selected: false },
  { label: "Country, Governate, Wilayat", country: "Oman", selected: false },
  { label: "Country, Governate, District, Sub-District", country: "Jordan", selected: false },
  { label: "Country, State/Territory, LGA", country: "Australia", selected: false },
  { label: "Country, Region, District", country: "Germany", selected: false },
];

const makeOptions = ["Solarman", "Solar Edge", "Tata Power"];
const countryOptions = locationTypeData.map(location => location.country);

const currencyOptions = [
    "USD - United States Dollar", "EUR - Euro", "GBP - British Pound", "INR - Indian Rupee",
    "JPY - Japanese Yen", "CAD - Canadian Dollar", "AUD - Australian Dollar", "CNY - Chinese Yuan",
    "CHF - Swiss Franc", "HKD - Hong Kong Dollar", "SGD - Singapore Dollar", "KRW - South Korean Won",
    "ZAR - South African Rand", "BRL - Brazilian Real", "AED - UAE Dirham"
  ];

const SuccessModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Success Modal" className="modal-content">
    <div className="modal-header">Success!</div>
    <div className="modal-body">Data has been saved successfully.</div>
    <button className="modal-close-button" onClick={onClose}>Close</button>
  </Modal>
);

const SettingsForm = () => {
  const [language, setLanguage] = useState('');
  const [languageOptions, setLanguageOptions] = useState([]);
  const [timeZone, setTimeZone] = useState('');
  const [locationTypes, setLocationTypes] = useState(locationTypeData);
  const [temperatureUnit, setTemperatureUnit] = useState('');
  const [currency, setCurrency] = useState('');
  const [yieldCurrency, setYieldCurrency] = useState('');
  const [yieldValue, setYieldValue] = useState('');
  const [make, setMake] = useState('');
  const [file, setFile] = useState(null);
  const [apiKeyData, setApiKeyData] = useState({
    make: "",
    country: "",
    apiKey: "",
    token: "",
    username: "",
    password: ""
  });
  const [errors, setErrors] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [showPassword, setShowPassword] = useState({ username: false, password: false });
  const [isEditMode, setIsEditMode] = useState({
    language: false,
    timeZone: false,
    locationTypes: false,
    errorCodeSettings: false,
    apiKeys: false,
    temperature: false,
    currency: false,
  });
  

  // Toggle visibility for username and password
  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const toggleEditMode = (section) => {
    setIsEditMode((prevMode) => ({
      ...prevMode,
      [section]: !prevMode[section],
    }));
  };

  // Fetch languages and settings data on mount
  useEffect(() => {
    const fetchLanguagesAndSettings = async () => {
      try {
        const languageResponse = await axios.get('https://restcountries.com/v3.1/all');
        const languagesSet = new Set();
        
        languageResponse.data.forEach(country => {
          if (country.languages) {
            Object.values(country.languages).forEach(language => languagesSet.add(language));
          }
        });
        
        setLanguageOptions([...languagesSet].sort());

        // Fetch settings data
        const settingsResponse = await axios.get('/api/get-settings');
        const data = settingsResponse.data;

        setLanguage(data.language || '');
        setTimeZone(data.timeZone || '');
        setTemperatureUnit(data.temperatureUnit || '');
        setCurrency(data.currency || '');

        // Load selected location types
        setLocationTypes(locationTypeData.map((loc) => ({
          ...loc,
          selected: data.locationTypes?.some((selectedLoc) => selectedLoc.label === loc.label) || false
        })));
      } catch (error) {
        console.error('Error loading data', error);
      }
    };

    fetchLanguagesAndSettings();
  }, []);

  const showSuccessModal = () => setSuccessModal(true);

  const validateField = (value, fieldName) => (value ? '' : `${fieldName} is required`);

  // Individual form submission handlers
  const handleSaveLanguage = async () => {
    const error = validateField(language, 'Language');
    // if (error) {
    if (false) {
      setErrors({ language: error });
    } else {
      //await axios.post('/api/save-language', { language });
      showSuccessModal();
      setIsEditMode((prevMode) => ({

        ...prevMode,
  
        language: false,
  
      }));
    }
  };

  const handleSaveTimeZone = async () => {
    const error = validateField(timeZone, 'Time Zone');
    if (error) {
      setErrors({ timeZone: error });
    } else {
      await axios.post('/api/save-timezone', { timeZone });
      showSuccessModal();
      setIsEditMode((prevMode) => ({

        ...prevMode,
  
        timeZone: false,
  
      }));
    }
  };

  const handleSaveLocationTypes = async () => {
    const selectedLocations = locationTypes.filter((location) => location.selected);
    if (selectedLocations.length === 0) {
      setErrors({ locationTypes: 'At least one location must be selected.' });
    } else {
      await axios.post('/api/save-location-types', { locations: selectedLocations });
      showSuccessModal();
      setIsEditMode((prevMode) => ({

        ...prevMode,
  
        locationTypes: false,
  
      }));
    }
  };

  const handleSaveTemperatureUnit = async () => {
    const error = validateField(temperatureUnit, 'Temperature Unit');
    if (error) {
      setErrors({ temperatureUnit: error });
    } else {
      await axios.post('/api/save-temperature-unit', { temperatureUnit });
      showSuccessModal();
      setIsEditMode((prevMode) => ({

        ...prevMode,
  
        temperature: false,
  
      }));
    }
  };

//   const handleSaveCurrency = async () => {
//     const error = validateField(currency, 'Currency');
//     if (error) {
//       setErrors({ currency: error });
//     } else {
//       await axios.post('/api/save-currency', { currency });
//       showSuccessModal();
//     }
//   };
const handleSaveCurrencySettings = async () => {
    const errorCurrency = validateField(currency, 'Currency');
    const errorYieldValue = validateField(yieldValue, 'Yield/KWH');
    const errorYieldCurrency = validateField(yieldCurrency, 'Yield Currency');

    if (errorCurrency || errorYieldValue || errorYieldCurrency) {
      setErrors({
        currency: errorCurrency,
        yieldValue: errorYieldValue,
        yieldCurrency: errorYieldCurrency
      });
    } else {
      await axios.post('/api/save-currency-settings', { currency, yieldValue, yieldCurrency });
      showSuccessModal();
      setIsEditMode((prevMode) => ({

        ...prevMode,
  
        currency : false,
  
      }));
    }
  };

  // Toggle location selection
  const toggleLocationSelection = (index) => {
    setLocationTypes((prevLocationTypes) =>
      prevLocationTypes.map((location, idx) =>
        idx === index ? { ...location, selected: !location.selected } : location
      )
    );
  };

  // Handle file upload for Error Code Settings
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSaveErrorCodeSettings = async () => {
    const errorMake = validateField(make, 'Make');
    const errorFile = file ? '' : 'File is required';

    if (errorMake || errorFile) {
      setErrors({ make: errorMake, file: errorFile });
    } else {
      const formData = new FormData();
      formData.append('make', make);
      formData.append('file', file);

      await axios.post('/api/save-error-code-settings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      showSuccessModal();
      setIsEditMode((prevMode) => ({
        ...prevMode,
        errorCodeSettings : false,
      }));
    }
  };

  // Handle API Key form inputs
  const handleApiKeyInputChange = (e) => {
    const { name, value } = e.target;
    setApiKeyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveApiKeySettings = async () => {
    const { make, country, apiKey, token, username, password } = apiKeyData;
    const errorMake = validateField(make, 'Make');
    const errorCountry = validateField(country, 'Country');
    const errorApiKey = validateField(apiKey, 'API Key');
    const errorToken = validateField(token, 'Token');
    const errorUsername = validateField(username, 'Username');
    const errorPassword = validateField(password, 'Password');

    if (errorMake || errorCountry || errorApiKey || errorToken || errorUsername || errorPassword) {
      setErrors({
        make: errorMake,
        country: errorCountry,
        apiKey: errorApiKey,
        token: errorToken,
        username: errorUsername,
        password: errorPassword
      });
    } else {
      await axios.post('/api/save-api-keys', apiKeyData);
      showSuccessModal();
      setIsEditMode((prevMode) => ({
        ...prevMode,
        apiKeys : false,
      }));
    }
  };

  return (
    <div className="settings-container">
      <h2>Settings</h2>

      {/* Language Section */}
      {/* <div className="form-section">
        <div className="form-content-box">
            <div className="form-content-blocks">
            <label>Language<span className='required'> * </span>:</label>
            <select value={language} onChange={(e) => setLanguage(e.target.value)}>
            <option value="">Select Language</option>
            {languageOptions.map((lang, index) => (
                <option key={index} value={lang}>{lang}</option>
            ))}
            </select>
            {errors.language && <span className="error">{errors.language}</span>}
            </div>
        </div>
        <div className="button-container">
            <button className="button-save" onClick={handleSaveLanguage}>Save</button>
        </div>
      </div> */}

      {/* Language Section */}
      <div className="settings-form-section">
      <h3>Language Settings : <img src={editBtn} alt="Edit Icon" className='settings-button-edit' onClick={() => toggleEditMode('language')} /></h3>
      {/* <button className="button-edit" onClick={() => toggleEditMode('language')}>Edit</button></h3> */}
        <div className="settings-form-content-box">
            <div className="settings-form-content-blocks">
            <label>Language<span className='settings-required'> * </span>:</label>
            <select value={language} onChange={(e) => setLanguage(e.target.value)} disabled={!isEditMode.language}>
              <option value="">Select Language</option>
              {languageOptions.map((lang, index) => (
                <option key={index} value={lang}>{lang}</option>
              ))}
            </select>
            {errors.language && <span className="settings-error">{errors.language}</span>}
            </div>
        </div>
        <div className="settings-button-container">
            <button className="settings-button-save" onClick={handleSaveLanguage} disabled={!isEditMode.language}>Save</button>
        </div>
      </div>

      {/* Time Zone Section */}
      <div className="settings-form-section">
      <h3>Time Zone Settings : <img src={editBtn} alt="Edit Icon" className='settings-button-edit' onClick={() => toggleEditMode('timeZone')} /></h3>
        <div className="settings-form-content-box">
        {/* <button className="settings-button-edit" onClick={() => toggleEditMode('timeZone')}>Edit</button> */}
            <div className="settings-form-content-blocks">
            <label>Time Zone<span className='settings-required'> * </span>:</label>
            <select value={timeZone} onChange={(e) => setTimeZone(e.target.value)} disabled={!isEditMode.timeZone}>
            <option value="">Select Time Zone</option>
            {TIME_ZONES.map((tz, index) => (
                <option key={index} value={tz}>{tz}</option>
            ))}
            </select>
            {errors.timeZone && <span className="settings-error">{errors.timeZone}</span>}
            </div>
        </div>
        <div className="settings-button-container">
            <button className="settings-button-save" onClick={handleSaveTimeZone} disabled={!isEditMode.timeZone}
            >Save</button>
        </div>
      </div>

      {/* Location Type Section */}
      <div className="settings-form-section">
      <h3>Location Type Settings : <img src={editBtn} alt="Edit Icon" className='settings-button-edit' onClick={() => toggleEditMode('locationTypes')} /></h3>
      <div className="settings-form-content-box" style={{padding:0}}>
      {/* <button className="settings-button-edit" onClick={() => toggleEditMode('locationTypes')}>Edit</button> */}
        <table className="settings-location-type-table">
          <thead>
            <tr>
              <th>Location Type</th>
              {/* <th style={{paddingLeft:"15%"}}>Country</th> */}
              <th>Country</th>
              <th style={{textAlign:"center"}}>Selected</th>
            </tr>
          </thead>
          <tbody>
            {locationTypes.map((location, index) => (
              <tr key={index}>
                <td>{location.label}</td>
                {/* <td style={{paddingLeft:"15%"}}>{location.country}</td> */}
                <td>{location.country}</td>
                <td style={{textAlign:"center"}}>
                  <input
                    className='settings-checkboxes'
                    type="checkbox"
                    checked={location.selected}
                    onChange={() => toggleLocationSelection(index)}
                    disabled={!isEditMode.locationTypes}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {errors.locationTypes && <span className="settings-error">{errors.locationTypes}</span>}
        </div>
        <div className="settings-button-container">
          <button className="settings-button-save" onClick={handleSaveLocationTypes} disabled={!isEditMode.locationTypes}>Save</button>
        </div>
      </div>

      {/* Temperature Unit Section */}
      {/* <div className="form-section">
        <label>Temperature Unit:</label>
        <select value={temperatureUnit} onChange={(e) => setTemperatureUnit(e.target.value)}>
          <option value="">Select Temperature Unit</option>
          <option value="C">Celsius</option>
          <option value="F">Fahrenheit</option>
        </select>
        {errors.temperatureUnit && <span className="error">{errors.temperatureUnit}</span>}
        <div className="button-container">
          <button className="button-save" onClick={handleSaveTemperatureUnit}>Save</button>
        </div>
      </div> */}

      {/* Currency Section */}
      {/* <div className="form-section">
        <label>Currency:</label>
        <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
          <option value="">Select Currency</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="INR">INR</option>
        </select>
        {errors.currency && <span className="error">{errors.currency}</span>}
        <div className="button-container">
          <button className="button-save" onClick={handleSaveCurrency}>Save</button>
        </div>
      </div> */}

      {/* Error Code Settings Section */}
      <div className="settings-form-section">
        <h3>Error Code Settings : <img src={editBtn} alt="Edit Icon" className='settings-button-edit' onClick={() => toggleEditMode('errorCodeSettings')} /></h3>
        <div className="settings-form-content-box">
        {/* <button className="settings-button-edit" onClick={() => toggleEditMode('errorCodeSettings')}>Edit</button> */}
        <div className="settings-form-content-blocks">
            <label>Make<span className='settings-required'> * </span>:</label>
            <select value={make} onChange={(e) => setMake(e.target.value)} disabled={!isEditMode.errorCodeSettings}>
            <option value="">Select Make</option>
            {makeOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
            </select>
            {errors.make && <span className="settings-error">{errors.make}</span>}
        </div>
        <div className="settings-form-content-blocks">
        {/* <label style={{marginRight:"30px"}}>Upload File<span className='required'> * </span>:</label>
        <input type="file" onChange={handleFileChange} accept=".xls,.csv"  className="file-upload-input"/>
        {errors.file && <span className="error">{errors.file}</span>}
        <small>(Supported file types are .xls, .csv)</small> */}
        <label>Upload File<span className='settings-required'> * </span>:</label>
        <div className='settings-input-bottom-info'>
        <input
            type="file"
            onChange={handleFileChange}
            accept=".xls,.csv"
            className="settings-file-upload-input"
            disabled={!isEditMode.errorCodeSettings}
        />
        {errors.file && <span className="settings-error">{errors.file}</span>}
        <small className="settings-file-info">(Supported file types are .xls, .csv)</small>
        </div>
        </div>
        </div>
        <div className="settings-button-container">
          <button className="settings-button-save" onClick={handleSaveErrorCodeSettings} disabled={!isEditMode.errorCodeSettings}>Save</button>
        </div>
      </div>

      {/* API Keys Section */}
      <div className="settings-form-section">
        <h3>API Keys : <img src={editBtn} alt="Edit Icon" className='settings-button-edit' onClick={() => toggleEditMode('apiKeys')} /></h3>
        <div className="settings-form-content-box">
        {/* <button className="settings-button-edit" onClick={() => toggleEditMode('apiKeys')}>Edit</button> */}
        <div className="settings-form-content-blocks">
        <label>Make<span className='settings-required'> * </span>:</label>
        <select name="make" value={apiKeyData.make} onChange={handleApiKeyInputChange} disabled={!isEditMode.apiKeys}>
        <option value="">Select Make</option>
          {makeOptions.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
        {errors.make && <span className="settings-error">{errors.make}</span>}
        </div>
        <div className="settings-form-content-blocks">
        <label>Country<span className='settings-required'> * </span>:</label>
        <select name="country" value={apiKeyData.country} onChange={handleApiKeyInputChange} disabled={!isEditMode.apiKeys}>
        <option value="">Select Country</option>
          {countryOptions.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
        {errors.country && <span className="settings-error">{errors.country}</span>}
        </div>
        <div className="settings-form-content-blocks">
        <label>API Key<span className='settings-required'> * </span>:</label>
        <input type="text" name="apiKey" value={apiKeyData.apiKey} onChange={handleApiKeyInputChange} disabled={!isEditMode.apiKeys}/>
        {errors.apiKey && <span className="settings-error">{errors.apiKey}</span>}
        </div>
        <div className="settings-form-content-blocks">
        <label>Token<span className='settings-required'> * </span>:</label>
        <input type="text" name="token" value={apiKeyData.token} onChange={handleApiKeyInputChange} disabled={!isEditMode.apiKeys}/>
        {errors.token && <span className="settings-error">{errors.token}</span>}
        </div>
        <div className="settings-form-content-blocks">
        <label>Username<span className='settings-required'> * </span>:</label>
        <div className="settings-password-container">
          <input
            type={showPassword.username ? "text" : "password"}
            name="username"
            value={apiKeyData.username}
            onChange={handleApiKeyInputChange}
            disabled={!isEditMode.apiKeys}
          />
          <button
            type="button"
            className="settings-toggle-visibility"
            onClick={() => togglePasswordVisibility("username")}
          >
            {showPassword.username ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        {errors.username && <span className="settings-error">{errors.username}</span>}
        </div>
        <div className="settings-form-content-blocks">
        <label>Password<span className='settings-required'> * </span>:</label>
        <div className="settings-password-container">
          <input
            type={showPassword.password ? "text" : "password"}
            name="password"
            value={apiKeyData.password}
            onChange={handleApiKeyInputChange}
            disabled={!isEditMode.apiKeys}
          />
          <button
            type="button"
            className="settings-toggle-visibility"
            onClick={() => togglePasswordVisibility("password")}
          >
            {showPassword.password ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        {errors.password && <span className="settings-error">{errors.password}</span>}
        </div>
        </div>
        <div className="settings-button-container">
          <button className="settings-button-save" onClick={handleSaveApiKeySettings} disabled={!isEditMode.apiKeys}>Save</button>
        </div>
      </div>

      {/* Temperature Settings with Radio Buttons */}
      <div className="settings-form-section">
        <h3>Temperature Settings : <img src={editBtn} alt="Edit Icon" className='settings-button-edit' onClick={() => toggleEditMode('temperature')} /></h3>
        <div className="settings-form-content-box">
        {/* <button className="settings-button-edit" onClick={() => toggleEditMode('temperature')}>Edit</button> */}
        <div className="settings-temperature-options">
        <div className="settings-form-content-blocks">
          <label>Temperature<span className='settings-required'> * </span>:</label>
          <div>
            <label>
              <input
                type="radio"
                name="temperatureUnit"
                value="F"
                checked={temperatureUnit === 'F'}
                onChange={(e) => setTemperatureUnit(e.target.value)}
                disabled={!isEditMode.temperature}
              />
              <span style={{color:"#157EFB", fontSize:"20px", fontFamily:"Lato, Open Sans"}}>°F </span> Fahrenheit
            </label>
            </div><div>
            <label>
              <input
                type="radio"
                name="temperatureUnit"
                value="C"
                checked={temperatureUnit === 'C'}
                onChange={(e) => setTemperatureUnit(e.target.value)}
                disabled={!isEditMode.temperature}
              />
              <span style={{color:"#157EFB", fontSize:"20px", fontFamily:"Lato, Open Sans"}}>°C </span> Centigrade
            </label>
          </div>
          </div>
        </div>
        {errors.temperatureUnit && <span className="settings-error">{errors.temperatureUnit}</span>}
        </div>
        <div className="settings-button-container">
          <button className="settings-button-save" onClick={handleSaveTemperatureUnit} disabled={!isEditMode.temperature}>Save</button>
        </div>
      </div>

      {/* Currency Settings */}
      <div className="settings-form-section">
        <h3>Currency Settings : <img src={editBtn} alt="Edit Icon" className='settings-button-edit' onClick={() => toggleEditMode('currency')} /></h3>
        <div className="settings-form-content-box">
        {/* <button className="settings-button-edit" onClick={() => toggleEditMode('currency')}>Edit</button> */}
        <div className="settings-currency-options">
        <div className="settings-form-content-blocks">
          <label>Currency<span className='settings-required'> * </span>:</label>
          <select value={currency} onChange={(e) => setCurrency(e.target.value)} disabled={!isEditMode.currency}>
            <option value="">Select Currency</option>
            {currencyOptions.map((option, index) => (
              <option key={index} value={option.split(" - ")[0]}>{option}</option>
            ))}
          </select>
          </div>
          {errors.currency && <span className="settings-error">{errors.currency}</span>}

          <div className="settings-yield-section">
          <div className="settings-form-content-blocks">
            <label>Yield / KWH:</label>
            <div className='settings-yield-input-section'>
            <input
              type="text"
              value={yieldValue}
              onChange={(e) => setYieldValue(e.target.value)}
              placeholder="Enter yield value"
              disabled={!isEditMode.currency}
            />
            <select value={yieldCurrency} onChange={(e) => setYieldCurrency(e.target.value)} disabled={!isEditMode.currency}>
              <option value="">Select Yield Currency</option>
              {currencyOptions.map((option, index) => (
                <option key={index} value={option.split(" - ")[0]}>{option}</option>
              ))}
            </select>
            </div>
            </div>
          </div>
          {errors.yieldValue && <span className="settings-error">{errors.yieldValue}</span>}
          {errors.yieldCurrency && <span className="settings-error">{errors.yieldCurrency}</span>}
        </div>
        </div>
        
        <div className="settings-button-container">
          <button className="settings-button-save" onClick={handleSaveCurrencySettings} disabled={!isEditMode.currency}>Save</button>
        </div>
      </div>

      <SuccessModal isOpen={successModal} onClose={() => setSuccessModal(false)} />
    </div>
  );
};

export default SettingsForm;


