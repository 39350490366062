import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../Signup.css";
import backgroundImage from "../../assets/solar-dl-login-bg-2x.png"; // Adjust the path according to your project structure
import logo from "../../assets/logog.svg";
import backbtn from "../../assets/closebtnblack.svg";

const saveCard = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleSave = () => {
    // Perform login validation here (e.g., check if fields are filled)

    // Redirect to the /dashboard/overview route
    navigate("/signup/Signup");
  };

  const handleLogin = () => {
    // Perform login validation here (e.g., check if fields are filled)

    // Redirect to the /dashboard/overview route
    navigate("/");
  };
  

  return (
    <div
      className="signup-card-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="signup-card">
        <div className="signup-container">
          <div className="signup-logo-container">
            <img 
              src={logo}
              className="signup-logo"
            />
          </div>
          <div className="signup-form-container">
            <div className="signup-title-container">
              <h2 className="signup-title">
                <span>Sign Up</span>
              </h2>
              <img 
              src={backbtn}
              onClick={handleSave}
              width={38}
            />
            </div>
            <div className="signup-inputs-container">
              <div className="signup-input-left">
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                  Entity Name <span className="signup-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="entity-name"
                    placeholder="Sagar Enterprises"
                    className="signup-input"
                  />
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="password">
                  Country <span className="signup-required">*</span>
                  </label>
                    <select className="signup-input">
                    <option value="IN">India</option>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    <option value="GE">Germany</option>
                    </select>
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="password">
                  District <span className="signup-required">*</span>
                  </label>
                    <select className="signup-input">
                    <option value="BG">Bengaluru</option>
                    <option value="MY">Mysuru</option>
                    <option value="MA">Mangaluru</option>
                    <option value="HA">Hassan</option>
                    </select>
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="password">
                  Address Line 1
                  </label>
                  <input
                    type="text"
                    id="address1"
                    placeholder="Address"
                    className="signup-input"
                  />
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                  GSTIN <span className="signup-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="pincode"
                    placeholder="000000000"
                    className="signup-input"
                  />
                </div>
              </div>
              <div className="signup-input-right">
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="password">
                  Category <span className="signup-required">*</span>
                  </label>
                    <select className="signup-input">
                    <option value="pvt">Private Limited</option>
                    <option value="pub">Public Limited</option>
                    <option value="KL">Properitary</option>
                    <option value="AP">LLP</option>
                    </select>
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="password">
                  State <span className="signup-required">*</span>
                  </label>
                    <select className="signup-input">
                    <option value="KA">Karnataka</option>
                    <option value="TN">Tamil Nadu</option>
                    <option value="KL">Kerala</option>
                    <option value="AP">Anadra Pradesh</option>
                    </select>
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                  Pin Code <span className="signup-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="pincode"
                    placeholder="560076"
                    className="signup-input"
                  />
                </div>

                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                  Address Line 2
                  </label>
                  <input
                    type="text"
                    id="address2"
                    placeholder="Address Line 2"
                    className="signup-input"
                  />
                </div>
               
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="password">
                  Designation <span className="signup-required">*</span>
                  </label>                  
                    <select className="signup-input">
                    <option value="SM">Senior Manager</option>
                    <option value="Mng">Associate Manager</option>
                    <option value="adminx">Admin</option>
                    </select>
                </div>
                <button className="signup-button" onClick={handleLogin}>
                  Save
                </button>
              </div>
            </div>
            <div className="signup-button-container">
            <span className="signup-login-already">
                Already Have Account
              </span>
              <a href="" className="signup-login-back" onClick={handleLogin}>Login</a>
            </div>
            <div className="login-button-container">
            
            </div>  
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default saveCard;
