import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  Form,
  NavLink,
  Image,
  Row,
  Stack,
  Tab,
  Modal,
} from "react-bootstrap";
// import SolarNavbar from "../../dashboard/navbar/SolarNavbar";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { DatePicker } from "@mui/x-date-pickers";
import { Divider } from "@mui/material";
import { green } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import GMap from "../../dashboard/utilities/AddMapFromLibrary";
import { useEffect, useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import AdminBottomHeaderBar from "../../dashboard/navbar/AdminBottomHeaderBar";
import PlantBulkImportOne from "./PlantBulkImportOne";
import PlantBulkImportTwo from "./PlantBulkImportTwo";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import dayjs from "dayjs";
import { MessageContext } from "../../App";

function Styles() {
  return (
    <style>
      {`
               .row-card{
                 background-color:#f2f5f7;
                 text-align: left;
                 align-items: center;
                 justify-content-center;
                 box-shadow: 0px 0px 3px 1px #d7dadb;
                 border-radius: 5px;
               }    

               .form-button{
                   background-color: #167db0;
                   color: white;
                   border-radius: 7px;
                   padding: 3px 20px 3px 20px
                }
            
                .form-button:hover{
                background-color: white !important;
                color : black !important;
                border-color:#167db0 !important;
                }

                .form-button-sp{
                    padding: 1px 10px 1px 10px !important;
                    border-radius: 20px !important;
                }

              .card-height{
               height: 88% !important
              }
               .email-text{
               text-wrap:noWrap;
               font-size:16px !important;
               color:#4773a9 !important
               }
                .browse-image-text{
               color:#F75A34;
               font-size:12px;
               }
               .cell-status{
               color:#0F0D0D !important;
               font-size:16px;
               }
               
               .file-upload-button{
                 background: #167DB0;
                 color: white;
                 padding: 5px 10px;
                 border-radius: 4px;
                 width: 110px;
                 text-align: center;
               }
               .otp-backdrop {
                 position: fixed;
                 top: 0;
                 left: 0;
                 width: 100%;
                 height: 100%;
                 background-color: rgba(0, 0, 0, 0.5);
                 z-index: 10;
               }
               .plant-add-form{
                    font-family: 'Open Sans';
                    font-weight: normal;
                } 
                .plant-add-form .row{
                align-items: center;
                }
                .plant-add-form .form-label{
                 font-weight:bold;
                 margin: 0px;
                }
                 .plant-add-form .form-select{
                 font-weight:normal !important;
                 font-family: 'Open Sans';
                }

                .plant-add-form .form-button{
                  font-family: 'Open Sans';
                    font-weight: bold;
                }
               .plant-add-form .form-control{
                  font-family: 'Open Sans';
                    font-weight: normal;
                }
               .plant-add-form .form-check-input{
                   box-shadow: 0px 1px 3px #00000029;
                   border: 1px solid #0F0D0D;
                   border-radius: 5px;
                   opacity: 1;
                   width: 20px;
                   height: 20px;

               }
             .entity-form-left-arrow{
               width: 23px;
               height: 23px;
               opacity: 1;
               margin-right: 5px;
               }

               .form-map-container{
               background: #FFFFFF 0% 0% no-repeat padding-box;
              box-shadow: 1px 2px 3px #EFEFEF90;
               border: 1px solid #C2CAD2;
                border-radius: 9px;
               opacity: 1;
                padding: 5px;
                width:90%;
               }
                .custom-model .model-dialog{
  width: fit-content !important;
}
               
        `}
    </style>
  );
}

function AdminPlantAdd(e) {
  const [latlng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [address, setAddress] = useState({
    country: "",
    region: "",
    state: "",
    district: "",
    addressOne: "",
    addressTwo: "",
    pincode: "",
  });
  const [country, setCountry] = useState([]);
  const [regions, setRegions] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [plantTypes, setPlantTypes] = useState([]);
  const { t } = useTranslation("plantform");
  const navigate = useNavigate();
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);

  const [imageFile, setImageFile] = useState();

  const [plantCategories, setPlantCategories] = useState([]);
  const [entityNames, setEntityNames] = useState([]);
  const [entityDetails, setEntityDetails] = useState();

  const [dataLogger, setDataLogger] = useState("");
  const [plantSerial, setPlantSerial] = useState("");
  const [error, setError] = useState("");
  const [downloadLink, setDownloadLink] = useState("");

  const [isWarningShown, setIsWarningShown] = useState(false);

  // const handleInputChange = (e) => {
  //   setPlantSerial(e.target.value);
  // };

  // Single handler with conditional updates
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "dataLogger") {
      setDataLogger(value);
    } else if (name === "plantSerial") {
      setPlantSerial(value);
    }
  };

  const onMessageChange = useContext(MessageContext);

  // const [isIndividualSelected, setIsIndividualSelected] = useState(false);

  //User data taking from login or session storage
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const entityId = userData?.entityId || ""; // Extract entityId from session storage

  // const plantTypeOptions = [
  //   { label: "Individual", value: "individual" },
  //   { label: "Factory and Commercial", value: "factory and commercial" },
  //   { label: "Large Power Producer", value: "large power producer" },
  // ];

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    plantId: "",
    installationDate: "",
    plantName: "",
    capacity: "",
    capacityGuage: "Kwp",
    azimuthAngle: "",
    azimuthGuage: "deg",
    tiltAngle: "",
    tiltGauge: "deg",
    plantType: "",
    plantCategory: "",
    plantImage: [],
    country: "",
    region: "",
    state: "",
    district: "",
    addressOne: "",
    addressTwo: "",
    pincode: "",
    latlng: "",
    ownerPlantId: "",
    ownerIDate: "",
    ownerPlantName: "",
    ownerEmailId: "",
    entityName: "",
    entityId: "",
    basicEmailId: "",
    basicCategory: "",
    datalogger: "",
    plantSerial: "",
    firstName: "",
    lastName: "",
  });

  const [addressUpdate, setAddressUpdate] = useState(false);
  const [ltUpdate, setltUpdate] = useState(false);
  useEffect(() => {
    if (addressUpdate) {
      if (addressUpdate) {
        setAddressUpdate(false);
      }
    }
  }, [address, latlng]);

  const [nextPage, setNextPage] = useState(false);

  // State to track if "Individual" is selected
  // const isIndividualSelected = formData.plantType === "individual";

  function handleChange(event) {
    const { name, value } = event.currentTarget;

    // Check if "Individual" is selected in the plantType dropdown
    // if (name === "plantType" && value === "individual") {
    //   setIsIndividualSelected(true);
    // } else if (name === "plantType") {
    //   setIsIndividualSelected(false);
    // }

    setFormData({ ...formData, [name]: value });
  }

  function handleAddressChange(event) {
    const target = event.currentTarget;
    const value = target.value;
    const name = target.name;
    if (name === "country" && address[name] !== value) {
      setAddress({
        country: value,
        region: "",
        state: "",
        district: "",
        addressOne: "",
        addressTwo: "",
        pincode: "",
      });
    } else if (name === "region" && address[name] !== value) {
      setAddress({
        ...address,
        state: "",
        district: "",
        addressOne: "",
        addressTwo: "",
        pincode: "",
        [name]: value,
      });
    } else if (name === "state" && address[name] !== value) {
      setAddress({
        ...address,
        district: "",
        addressOne: "",
        addressTwo: "",
        pincode: "",
        [name]: value,
      });
    } else if (name === "district" && address[name] !== value) {
      setAddress({
        ...address,
        addressOne: "",
        addressTwo: "",
        pincode: "",
        [name]: value,
      });
    } else if (address[name] !== value) {
      setAddress({ ...address, [name]: value });
    }
  }

  useEffect(() => {
    // Check if entityId is available before making the request
    if (entityId) {
      fetch("https://solar-api.antsai.in/api/plant/generatePlantId", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entityid: entityId,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(
                `Error ${response.status}: ${
                  errorData.message || response.statusText
                }`
              );
            });
          }
          return response.json();
        })
        .then((data) => {
          setFormData((prevData) => ({
            ...prevData,
            plantId: data.plant_id, // Set plant_id from response
          }));
        })
        .catch((error) => {
          console.error("Error fetching Plant ID:", error.message);
          onMessageChange({
            type: "Danger",
            info: "Failed to retrieve Plant ID. Please try again.",
          });
          // alert("Failed to retrieve Plant ID. Please try again.");
        });
    }
  }, [entityId]);

  const handleLatLngUpdate = () => {
    if (latlng.lat === "" || latlng.lng === "") return;
    setltUpdate(true);
  };

  useEffect(() => {
    if (entityId) {
      // Fetch plant categories based on entityId
      fetch(
        `https://solar-api.antsai.in/api/plant/getPlantCategories?entityid=${entityId}`
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(
                `Error ${response.status}: ${
                  errorData.message || response.statusText
                }`
              );
            });
          }
          return response.json();
        })
        .then((data) => {
          setPlantCategories(data.categories || []); // Assuming 'categories' contains the list
        })
        .catch((error) => {
          console.error("Error fetching plant categories:", error.message);
          // alert("Failed to retrieve plant categories. Please try again.");
          onMessageChange({
            type: "Danger",
            info: "Failed to retrieve plant categories. Please try again.",
          });
        });
    }
  }, [entityId]);

  useEffect(() => {
    if (entityId) {
      fetch(
        `https://solar-api.antsai.in/api/plant/fetchEntityNames?entityid=${entityId}`
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(
                `Error ${response.status}: ${
                  errorData.message || response.statusText
                }`
              );
            });
          }
          return response.json();
        })
        .then((data) => {
          console.log("Data received from backend:", data);

          // Check if both currentEntity and entities exist, and format accordingly
          const combinedEntities = data.currentEntity
            ? [
                data.currentEntity,
                ...(Array.isArray(data.entities) ? data.entities : []),
              ]
            : Array.isArray(data.entities)
            ? data.entities
            : [];

          setEntityNames(combinedEntities); // Set entityNames with currentEntity and entities
        })
        .catch((error) => {
          console.error("Error fetching entity names:", error.message);
          // alert(`Failed to retrieve entity names: ${error.message}`);
          onMessageChange({
            type: "Danger",
            info: `Failed to retrieve entity names: ${error.message}`,
          });
        });
    }
  }, [entityId]);

  useEffect(() => {
    if (formData.plantType === "individual" && entityDetails !== undefined) {
      setFormData((prevData) => ({
        ...prevData,
        entityName: entityNames[0].entityname,
        entityId: entityDetails.entityid, // Set entityId from response or default to "N/A"
        basicCategory: entityDetails.category, // Set category from response or default to "N/A"
        basicEmailId: entityDetails.email, // Set email from response or default to "N/A"
      }));
      setIsEmailVerified(false);
      return;
    } else if (formData.plantType !== "" && entityDetails !== undefined) {
      setFormData((prevData) => ({
        ...prevData,
        entityId: entityDetails.entityid, // Set entityId from response or default to "N/A"
        basicCategory: entityDetails.category, // Set category from response or default to "N/A"
        basicEmailId: entityDetails.email, // Set email from response or default to "N/A"
        firstName: entityDetails.firstName,
        lastName: entityDetails.lastName,
        email: entityDetails.email,
      }));
      setIsEmailVerified(true);
    }
  }, [entityDetails]);

  // Update this in the useEffect for fetching entity details
  useEffect(() => {
    const eName =
      formData.plantType === "individual"
        ? entityNames[0].entityname
        : formData.entityName;
    if (eName !== "" && eName !== undefined) {
      // Fetch entity details when an entity name is selected
      fetch(
        `https://solar-api.antsai.in/api/plant/getEntityDetails?entityname=${eName}`
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(
                `Error ${response.status}: ${
                  errorData.message || response.statusText
                }`
              );
            });
          }
          return response.json();
        })
        .then((data) => {
          console.log("Entity details data:", data); // Debugging line
          // Update formData with the correct fields
          setEntityDetails(data);
          // setFormData((prevData) => ({
          //   ...prevData,
          //   entityId: data.entityid || "N/A", // Set entityId from response or default to "N/A"
          //   basicCategory: data.category || "N/A", // Set category from response or default to "N/A"
          //   basicEmailId: data.email || "N/A", // Set email from response or default to "N/A"
          // }));
        })
        .catch((error) => {
          console.error("Error fetching entity details:", error.message);
          // alert("Failed to retrieve entity details. Please try again.");
          onMessageChange({
            type: "Danger",
            info: "Failed to retrieve entity details. Please try again.",
          });
        });
    }
  }, [formData.entityName, formData.plantType]);

  // function handleChange(event) {
  //   const target = event.currentTarget;
  //   const value =
  //     target.type === "checkbox"
  //       ? target.checked
  //       : target.type === "file"
  //       ? target.files
  //       : target.value;
  //   const name = target.name;
  //   setFormData({ ...formData, [name]: value });
  // }

  // function handleChange(event) {
  //   const { name, value } = event.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  // }

  // function handleAddressChange(event) {
  //   const target = event.currentTarget;
  //   const value = target.value;
  //   const name = target.name;
  //   setAddress({ ...address, [name]: value });
  // }

  // const selectAddressChange = (e) => {
  //   setAddress({ ...address, country: e.value });
  // };

  function handleCorordinateChange(event) {
    const target = event.currentTarget;
    const value = parseFloat(target.value);
    const name = target.name;
    setLatLng({ ...latlng, [name]: value });
  }

  // Fetch countries from API
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://solar-api.antsai.in/api/countries",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({}),
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log("Fetched countries:", data);
          setCountry(data);
        } else {
          console.error(
            `Failed to fetch countries: ${response.status} ${response.statusText}`
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  // Fetch regions based on selected country (Scenario 1)
  useEffect(() => {
    if (!address.country) return;

    const selectedCountry = country.find(
      (c) => c.country_name === address.country
    );
    if (!selectedCountry) return;

    const fetchRegions = async () => {
      try {
        const response = await fetch(
          `https://solar-api.antsai.in/api/Region1?country_id=${selectedCountry.country_id}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await response.json();
        if (Array.isArray(data)) {
          setRegions(data.map((region) => region.region));
        } else {
          console.error("Unexpected data format:", data);
          setRegions([]); // Set to empty array if data format is unexpected
        }
        console.log("Fetched regions:", data); // Debugging statement
      } catch (error) {
        console.error("Error fetching regions:", error);
        setRegions([]); // Ensure `regions` is reset on error
      }
    };

    fetchRegions();
  }, [address.country, country]);

  // Fetch states based on selected country (if no region selected)
  useEffect(() => {
    if (!address.country) return;

    const selectedCountry = country.find(
      (c) => c.country_name === address.country
    );
    if (!selectedCountry) return;

    const fetchStates = async () => {
      try {
        const response = await fetch(
          `https://solar-api.antsai.in/api/state1/${selectedCountry.country_id}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await response.json();
        if (Array.isArray(data)) {
          setStates(data);
        } else {
          console.error("Unexpected data format:", data);
          setStates([]); // Ensure states is empty if data format is unexpected
        }
        console.log("Fetched states:", data); // Debugging statement
      } catch (error) {
        console.error("Error fetching states:", error);
        setStates([]); // Ensure `states` is reset on error
      }
    };

    fetchStates();
  }, [address.country, country]);

  //fetch states by region
  useEffect(() => {
    if (!address.country) return;

    const selectedCountry = country.find(
      (c) => c.country_name === address.country
    );
    if (!selectedCountry) return;

    const fetchStates = async () => {
      try {
        const url = `https://solar-api.antsai.in/api/state2?country_id=${selectedCountry.country_id}&region=${address.region}`;
        const response = await fetch(url, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        if (response.status === 200) {
          const data = await response.json();
          if (Array.isArray(data["states"])) {
            setStates(data["states"]);
          } else {
            console.error("Unexpected data format:", data);
            setStates([]); // Ensure states is empty if data format is unexpected
          }
          console.log("Fetched states:", data);
        } // Debugging statement
      } catch (error) {
        console.error("Error fetching states:", error);
        setStates([]); // Ensure `states` is reset on error
      }
    };

    fetchStates();
  }, [address.region]);

  // Region fetching function when state is selected
  useEffect(() => {
    const fetchRegionByState = async () => {
      const selectedCountry = country.find(
        (c) => c.country_name === address.country
      );
      if (!selectedCountry) return;

      try {
        const response = await fetch(
          `https://solar-api.antsai.in/api/Region2?country_id=${selectedCountry.country_id}&state_name=${address.state}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        const regionData = await response.json();
        if (response.status !== 404 && regionData && regionData.region) {
          setAddress((prevFormData) => ({
            ...prevFormData,
            region: regionData.region,
          }));
        } else {
          setAddress((prevFormData) => ({
            ...prevFormData,
            region: "",
          }));
          console.error(
            "Unexpected data format for region by state:",
            regionData
          );
        }
      } catch (error) {
        console.error("Error fetching region by state:", error);
      }
    };
    fetchRegionByState();
  }, [address.state, address]);

  useEffect(() => {
    if (address.state === "") return;
    // Fetch states based on selected state

    fetchDistricts();
  }, [address.state, states]);

  // Fetch districts based on selected state
  const fetchDistricts = async () => {
    try {
      const response = await fetch(
        `https://solar-api.antsai.in/api/districts/${
          states.find((c) => c.state_name === address.state).state_id
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          //   body: JSON.stringify({ country_id: selectedCountry.country_id }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched districts:", data);
        setDistricts(data);
      } else {
        console.error(
          `Failed to fetch districts: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  useEffect(() => {
    // Fetch plant types from the backend
    axios
      .get("https://solar-api.antsai.in/api/plant/getPlantTypes")
      .then((response) => {
        setPlantTypes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching plant types:", error);
      });
  }, []);

  // const handlePlantTypeChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));

  //   // Enable or disable the Owner field based on selected Plant Type
  //   setIsIndividualSelected(value === "individual");
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // if (!isEmailVerified) {
    //   onMessageChange({
    //     type: "Danger",
    //     info: "Please verify your email before submitting the form.",
    //   });
    //   return;
    // }

    // Check if "Individual" is selected and email verification is required
    if (formData.plantType === "individual" && !isEmailVerified) {
      onMessageChange({
        type: "Danger",
        info: "Please verify your email before submitting the form.",
      });
      return;
    }

    // Check if the form is valid before submitting
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // Format the data as per the required structure
    const payload = {
      plant_id: formData.plantId,
      entityid: entityId,
      plant_name: formData.plantName,
      install_date: formData.installationDate,
      azimuth_angle: parseFloat(formData.azimuthAngle),
      tilt_angle: parseFloat(formData.tiltAngle),
      plant_type: formData.plantType,
      plant_category: formData.plantCategory,
      capacity: parseFloat(formData.capacity),
      capacity_unit: formData.capacityGuage, // Sending the capacity unit (Kwp or Mwp)
      country: address.country,
      region: address.region,
      state: address.state,
      district: address.district,
      address_line1: address.addressOne,
      address_line2: address.addressTwo,
      pincode: address.pincode,
      longitude: parseFloat(latlng.lng),
      latitude: parseFloat(latlng.lat),
      data_logger: formData.datalogger,
      inverter: formData.inverter || "", // Default to empty string if not provided
      owner_first_name: formData.firstName,
      owner_last_name: formData.lastName,
      owner_email: formData.email,
      mobile_number: formData.mobileNumber || "", // Default to empty string if not provided
    };

    console.log("Payload Data:", payload);

    try {
      const response = await fetch(
        "https://solar-api.antsai.in/api/plant/addPlant",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Assuming authToken is stored during login
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        // alert("Plant added successfully!");
        onMessageChange({
          type: "Success",
          info: "Plant added successfully!",
        });
        console.log("Response from backend:", responseData);
        navigate("/admin"); // Redirect to admin page or show success message
      } else {
        const errorData = await response.json();
        console.error("Error response from backend:", errorData);
        // alert(`Failed to add plant: ${errorData.message}`);
        onMessageChange({
          type: "Danger",
          info: `Failed to add plant: ${errorData.message}`,
        });
      }
    } catch (error) {
      console.error("Error adding plant:", error);
      // alert("An error occurred while adding the plant. Please try again.");
      onMessageChange({
        type: "Danger",
        info: "An error occurred while adding the plant. Please try again.",
      });
    }
  };

  const fileChanged = (e) => {
    setImageFile(URL.createObjectURL(e.target.files[0]));
  };

  const onVerify = () => {};
  const oncancel = () => {};

  const navToAdmin = () => {
    navigate("/admin");
  };

  const onDialApi = async () => {
    if (!plantSerial) {
      setError("Please enter a valid serial number.");
      return;
    }

    // const payload = {
    // make: "Solaredge",
    //   plantSerialNumber: plantSerial, // Using the serial number entered by the user
    // };

    // Format dataLogger to lowercase and remove spaces
    const formattedDataLogger = dataLogger.toLowerCase().replace(/\s+/g, "");

    const payload = {
      make: formattedDataLogger,
      plantSerialNumber: plantSerial, // Keep plantSerial as entered by the user
    };

    try {
      const response = await fetch("https://solar-api.antsai.in/sync", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Assuming authToken is stored during login
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.downloadLink) {
          setDownloadLink(data.downloadLink); // Set the download link in the state

          // Save the link to sessionStorage for later use
          sessionStorage.setItem("downloadLink", data.downloadLink);

          //   alert("CSV file generated successfully.");
        } else {
          console.error("No download link found in response.");
          // alert("Failed to get the download link.");
          onMessageChange({
            type: "Danger",
            info: "Failed to get the download link.",
          });
        }
      } else {
        const errorData = await response.json();
        console.error("API error:", errorData.message);
        // alert(`Failed to sync data: ${errorData.message}`);
        onMessageChange({
          type: "Danger",
          info: `Failed to sync data: ${errorData.message}`,
        });
      }
    } catch (error) {
      console.error("Error during API call:", error);
      // alert("An error occurred. Please try again.");
      onMessageChange({
        type: "Danger",
        info: "An error occurred. Please try again.",
      });
    }
  };

  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(20);
  const [showResend, setShowResend] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  // Send OTP
  // const handleVerifyClick = async () => {
  //   if (!formData.email) {
  //     alert("Please enter your email first.");
  //     return;
  //   }
  //   try {
  //     const response = await fetch("https://solar-api.antsai.in/send-otp", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ user_email: formData.email }),
  //     });
  //     if (response.ok) {
  //       setIsOtpModalOpen(true);
  //       setTimer(20);
  //       setShowResend(false);
  //       setOtp(["", "", "", ""]);
  //       alert("OTP sent to your email!");
  //     } else {
  //       alert("Failed to send OTP. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error sending OTP:", error);
  //   }
  // };

  const handleVerifyClick = async () => {
    if (!formData.email) {
      // alert("Please enter your email first.");
      onMessageChange({
        type: "Warning",
        info: "Please enter your email first.",
      });
      return;
    }

    try {
      // Step 1: Verify individual user
      const verifyResponse = await fetch(
        "https://solar-api.antsai.in/api/plant/verify_individual_user",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            entityid: "XYZPRIVATELIMITED1",
            useremail: formData.email,
          }),
        }
      );

      const verifyData = await verifyResponse.json();

      if (verifyData.message === "User already verified") {
        // alert("User already verified");
        onMessageChange({
          type: "Warning",
          info: "User already verified",
        });
        setIsEmailDisabled(true); // Disable email field after verification
        return;
      } else if (
        verifyData.message === "Duplicate email, use different email"
      ) {
        // alert("User already exists with a different Entity ID or Role");
        onMessageChange({
          type: "Danger",
          info: "User already exists with a different Entity ID or Role",
        });
        return;
      }

      // Step 2: If user is not verified, send OTP
      const otpResponse = await fetch("https://solar-api.antsai.in/send-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_email: formData.email }),
      });

      if (otpResponse.ok) {
        setIsOtpModalOpen(true);
        setTimer(20);
        setShowResend(false);
        setOtp(["", "", "", ""]);
        // alert("OTP sent to your email!");
        onMessageChange({
          type: "Success",
          info: "OTP sent to your email!",
        });
      } else {
        // alert("Failed to send OTP. Please try again.");
        onMessageChange({
          type: "Warning",
          info: "Failed to send OTP. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error in user verification or sending OTP:", error);
      // alert("An error occurred. Please try again.");
      onMessageChange({
        type: "Danger",
        info: "An error occurred. Please try again.",
      });
    }
  };

  // Resend OTP
  const handleResendOtp = async () => {
    setTimer(20);
    setShowResend(false);
    setOtp(["", "", "", ""]);
    otpRefs[0].current.focus();
    try {
      const response = await fetch("https://solar-api.antsai.in/send-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_email: formData.email }),
      });
      if (response.ok) {
        // alert("OTP resent successfully!");
        onMessageChange({
          type: "Success",
          info: "OTP resent successfully!",
        });
      } else {
        // alert("Failed to resend OTP. Please try again.");
        onMessageChange({
          type: "Warning",
          info: "Failed to resend OTP. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  // Verify OTP
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join("");
    if (otpValue.length === 4) {
      try {
        const response = await fetch("https://solar-api.antsai.in/verify-otp", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_email: formData.email, otp: otpValue }),
        });
        if (response.ok) {
          setIsOtpValid(true);
          setIsEmailVerified(true);
          setIsEmailDisabled(true); // Lock the email field
          // alert("OTP Verified!");
          onMessageChange({
            type: "Success",
            info: "OTP Verified!",
          });
          setIsOtpModalOpen(false);
        } else {
          setIsOtpValid(false);
          // alert("Invalid OTP. Please try again.");
          onMessageChange({
            type: "Warning",
            info: "Invalid OTP. Please try again.",
          });
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
      }
    } else {
      setIsOtpValid(false);
      // alert("Please enter a valid 4-digit OTP.");
      onMessageChange({
        type: "Warning",
        info: "Please enter a valid 4-digit OTP.",
      });
    }
  };

  // OTP timer
  useEffect(() => {
    if (isOtpModalOpen && timer > 0) {
      const countdown = setInterval(
        () => setTimer((prevTime) => prevTime - 1),
        1000
      );
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setShowResend(true);
    }
  }, [timer, isOtpModalOpen]);

  // OTP input handler
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== "" && index < 3) {
        otpRefs[index + 1].current.focus();
      }
    }
  };

  // Close OTP modal
  const handleCloseOtpModal = () => setIsOtpModalOpen(false);

  function handleDateChange(date) {
    setFormData((prevData) => ({
      ...prevData,
      installationDate: date ? dayjs(date).format("YYYY-MM-DD") : null,
    }));
  }

  // function handleChange(event) {
  //   const { name, value } = event.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  // }

  const [makes, setMakes] = useState([]); // State to store makes data

  useEffect(() => {
    // Fetch Make Data Logger / Inverter options
    const fetchMakes = async () => {
      try {
        const response = await fetch(
          "https://solar-api.antsai.in/api/plant/fetchMakes",
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Fetched makes data:", data); // Debugging line
          setMakes(data); // Set fetched makes in state
        } else {
          console.error("Failed to fetch makes:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching makes:", error);
      }
    };

    fetchMakes();
  }, []);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {/* <SolarNavbar /> */}
      <AdminBottomHeaderBar />
      <Tab.Container
        id="left-tabs-example p-2 m-2 plant-add-form"
        defaultActiveKey="first"
      >
        <Container className="mw-100 px-4">
          <Styles />
          <Row className="mt-2 mb-2 p-0">
            <Col className="d-flex flex-row align-items-center justify-content-start">
              <NavLink
                className="text-decoration-none p-0"
                onClick={() => navToAdmin()}
              >
                <ArrowCircleLeftRoundedIcon
                  sx={{ color: green[500], marginRight: "10px" }}
                />
                <span className="fw-bold text-decoration-none text-black">
                  {t("left-arrow")}
                </span>
              </NavLink>
            </Col>

            <Col className="d-flex flex-row align-items-center justify-content-end"></Col>
          </Row>
        </Container>
        <Container className="mw-100 mt-2 px-4">
          {" "}
          <Stack gap={0} direction="vertical">
            <Nav as={"div"} variant="underline" className="flex-row tab-nav">
              <NavItem>
                <NavLink eventKey="first">Add Plant</NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink eventKey="second">Plant-Bulk Import</NavLink>
              </NavItem> */}
            </Nav>
            <Divider className="bg-black" />
            <Tab.Content className="">
              <Tab.Pane eventKey="first">
                <Container className="mw-100 px-0 ">
                  <Form
                    className="w-100 p-0 m-0 plant-add-form"
                    noValidate
                    validated={validated}
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <Container className="d-flex flex-column align-items-center justify-content-center mw-100 p-0 mt-4">
                      <div className="w-100 text-left">
                        <h2>{t("title")}</h2>
                      </div>
                      <Row className="gap-2 w-100 row-card p-4 m-2 d-flex flex-lg-row  flex-column">
                        <Col>
                          <Stack as="row" gap={2} direction="vertical">
                            <Col>
                              <Form.Group controlId="plantId">
                                <Row className="w-100">
                                  <Col md={3}>
                                    <Form.Label className="text-nowrap">
                                      {t("plant-id")} <span>:</span>
                                    </Form.Label>
                                  </Col>
                                  <Col md={8}>
                                    <Form.Control
                                      type="text"
                                      value={formData.plantId || "Loading..."}
                                      disabled
                                      readOnly
                                      className="text-nowrap fw-normal"
                                      placeholder="Plant ID"
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                            <Col span>
                              <Form.Group controlId="installationDate">
                                <Row className="w-100">
                                  <Col md={3}>
                                    <Form.Label
                                      aria-colspan={2}
                                      className="text-nowrap"
                                    >
                                      {t("i-date")}{" "}
                                      <StarOutlinedIcon
                                        style={{
                                          color: "red",
                                          fontSize: " xx-small",
                                        }}
                                      />{" "}
                                      <spa>:</spa>
                                    </Form.Label>
                                  </Col>
                                  <Col md={8}>
                                    <DatePicker
                                      name="installationDate"
                                      value={
                                        formData.installationDate !== ""
                                          ? dayjs(formData.installationDate)
                                          : null
                                      }
                                      disableFuture
                                      format="DD/MM/YYYY"
                                      onChange={handleDateChange}
                                      slotProps={{
                                        field: (
                                          <CalendarMonthOutlinedIcon
                                            style={{ fontSize: 27 }}
                                          />
                                        ),
                                        textField: { size: "small" },
                                      }}
                                      sx={{
                                        width: "100%",
                                        backgroundColor: "white",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group controlId="plantName">
                                <Row className="w-100">
                                  <Col md={3}>
                                    <Form.Label className="text-nowrap">
                                      {t("plant-name")}{" "}
                                      <StarOutlinedIcon
                                        style={{
                                          color: "red",
                                          fontSize: " xx-small",
                                        }}
                                      />{" "}
                                      <spa>:</spa>
                                    </Form.Label>
                                  </Col>
                                  <Col md={8}>
                                    <Form.Control
                                      name="plantName"
                                      defaultValue={formData.plantName}
                                      required
                                      type="string"
                                      onChange={(e) => handleChange(e)}
                                      placeholder="Plant Name"
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group controlId="capacity">
                                <Row className="w-100">
                                  <Col md={3}>
                                    <Form.Label className="text-nowrap">
                                      {t("capacity")}{" "}
                                      <StarOutlinedIcon
                                        style={{
                                          color: "red",
                                          fontSize: " xx-small",
                                        }}
                                      />{" "}
                                      <spa>:</spa>
                                    </Form.Label>
                                  </Col>
                                  <Col md={5}>
                                    {/* <Form.Control
                                      name="capacity"
                                      defaultValue={formData.capacity}
                                      onChange={(e) => handleChange(e)}
                                      required
                                      type="number"
                                      placeholder=""
                                    /> */}
                                    <Form.Control
                                      name="capacity"
                                      value={formData.capacity}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // Only allow positive numbers
                                        if (
                                          value === "" ||
                                          (Number(value) > 0 &&
                                            /^\d*$/.test(value))
                                        ) {
                                          handleChange(e);
                                          setIsWarningShown(false); // Reset warning when input is valid
                                        } else if (!isWarningShown) {
                                          // Show warning only once
                                          onMessageChange({
                                            type: "Warning",
                                            info: "Please enter a positive number.",
                                          });
                                          setIsWarningShown(true); // Set warning flag to true
                                        }
                                      }}
                                      required
                                      type="number"
                                      placeholder=""
                                    />
                                  </Col>
                                  <Col md={3}>
                                    <Form.Select
                                      defaultValue={formData.capacityGuage}
                                      name="capacityGuage"
                                      size="md"
                                      onChange={(e) => handleChange(e)}
                                    >
                                      <option value="kw">Kwp</option>
                                      <option value="kwh">Mwp</option>
                                    </Form.Select>
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Stack>
                        </Col>
                        <Col>
                          <Stack gap={3} direction="vertical">
                            <Form.Group controlId="azimuthAngle">
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    {t("azimuth-angle")}{" "}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: " xx-small",
                                      }}
                                    />{" "}
                                    <spa>:</spa>
                                  </Form.Label>
                                </Col>
                                <Col md={5}>
                                  <Form.Control
                                    name="azimuthAngle"
                                    value={formData.azimuthAngle}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Ensure the input is within the -180.00 to 180.00 range and 2 decimal places
                                      if (
                                        /^-?\d{0,3}(\.\d{0,2})?$/.test(value) &&
                                        value >= -180 &&
                                        value <= 180
                                      ) {
                                        handleChange(e); // Update formData if the value is valid
                                      }
                                    }}
                                    required
                                    type="number"
                                    placeholder=""
                                    max="180"
                                    min="-180"
                                    step="0.01"
                                  />
                                </Col>
                                <Col md={3}>
                                  <Form.Select
                                    name="azimuthGuage"
                                    defaultValue={formData.azimuthGuage}
                                    onChange={(e) => handleChange(e)}
                                    size="md"
                                  >
                                    <option value="deg">deg</option>
                                    {/* <option value="angle">angle</option> */}
                                  </Form.Select>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group controlId="tiltAngle">
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    {t("tilt-angle")}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: "xx-small",
                                      }}
                                    />
                                    <span>:</span>
                                  </Form.Label>
                                </Col>
                                <Col md={5}>
                                  <Form.Control
                                    name="tiltAngle"
                                    value={formData.tiltAngle}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Allow only positive numbers and limit the value to 90
                                      if (
                                        /^\d*\.?\d{0,2}$/.test(value) &&
                                        value <= 90
                                      ) {
                                        setFormData((prevData) => ({
                                          ...prevData,
                                          tiltAngle: value,
                                        }));
                                      }
                                    }}
                                    required
                                    type="text" // Set as text to allow validation checks
                                    placeholder="Tilt Angle"
                                    pattern="^\d*\.?\d{0,2}$" // Allows up to 2 decimal places
                                    maxLength="5" // Limits input length (e.g., "90.00")
                                  />
                                </Col>
                                <Col md={3}>
                                  <Form.Select
                                    name="tiltGuage"
                                    defaultValue={formData.tiltGauge}
                                    onChange={handleChange}
                                    required
                                    size="md"
                                  >
                                    <option value="deg">deg</option>
                                    {/* <option value="angle">angle</option> */}
                                  </Form.Select>
                                </Col>
                              </Row>
                            </Form.Group>

                            <Form.Group controlId="plantType">
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Plant Type{" "}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: "xx-small",
                                      }}
                                    />
                                    <span>:</span>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Select
                                    name="plantType"
                                    value={formData.plantType}
                                    onChange={handleChange}
                                    required
                                    aria-colspan={3}
                                    size="md"
                                  >
                                    {formData.plantType === "" ? (
                                      <option value="">
                                        Select Plant Type
                                      </option>
                                    ) : (
                                      ""
                                    )}
                                    {plantTypes.map((type) => (
                                      <option
                                        key={type.type_id}
                                        value={type.type_name.toLowerCase()}
                                      >
                                        {type.type_name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </Row>
                            </Form.Group>
                            {/* <Form.Group controlId="plantCategory">
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    {t("plant-category")}{" "}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: " xx-small",
                                      }}
                                    />{" "}
                                    <spa>:</spa>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Select
                                    name="plantCategory"
                                    defaultValue={formData.plantCategory}
                                    onChange={(e) => handleChange(e)}
                                    required
                                    placeholder=" Plant Category"
                                    size="md"
                                  >
                                    <option value="">Plant Category</option>
                                    {plantTypes.map((v) => {
                                      return (
                                        <option value={v}>
                                          {v.toString()}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </Col>
                              </Row>
                            </Form.Group> */}

                            <Form.Group controlId="plantCategory">
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    {t("plant-category")}{" "}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: "xx-small",
                                      }}
                                    />{" "}
                                    <spa>:</spa>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Select
                                    name="plantCategory"
                                    value={formData.plantCategory}
                                    onChange={handleChange}
                                    required
                                    placeholder="Plant Category"
                                    size="md"
                                  >
                                    <option value="">
                                      Select Plant Category
                                    </option>
                                    {plantCategories.map((category) => (
                                      <option
                                        key={category.category_name}
                                        value={category.category_name}
                                      >
                                        {category.category_name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Stack>
                        </Col>
                      </Row>

                      <div className="w-100 text-left">
                        <h3>Location</h3>
                      </div>
                      <Row className=" gap-2 w-100 row-card p-4 m-2 d-flex flex-column flex-lg-row">
                        <Col>
                          <Stack gap={3} direction="vertical">
                            <Form.Group controlId="country">
                              <Row className="w-100">
                                {" "}
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Country{" "}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: " xx-small",
                                      }}
                                    />{" "}
                                    <spa>:</spa>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Select
                                    name="country"
                                    value={address.country}
                                    onChange={(e) => handleAddressChange(e)}
                                    onBlur={() => setAddressUpdate(true)}
                                    placeholder={
                                      address.country === ""
                                        ? "Entity country"
                                        : address.country
                                    }
                                    required
                                    size="md"
                                  >
                                    <option value="">
                                      {t("entity:selectcountry")}
                                    </option>
                                    {country.map((country) => {
                                      return (
                                        <option
                                          key={country.country_id}
                                          value={country.country_name}
                                        >
                                          {country.country_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group controlId="region">
                              <Row className="w-100">
                                {" "}
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Region{" "}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: " xx-small",
                                      }}
                                    />{" "}
                                    <spa>:</spa>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  {/* <Form.Select
                                    placeholder={address.region.toLocaleLowerCase()}
                                    value={address.region.toLocaleLowerCase()}
                                    disabled={address.country === ""}
                                    name="region"
                                    onChange={(e) => handleAddressChange(e)}
                                    required
                                    size="md"
                                  >
                                    <option key="">
                                      {address.region !== ""
                                        ? address.region
                                        : "Select Region"}
                                    </option>
                                    {regions
                                      .find(
                                        (r) => r.country === address.country
                                      )
                                      ?.regions.map((v) => {
                                        return (
                                          <option
                                            key={v.toLocaleLowerCase()}
                                            value={v.toLocaleLowerCase()}
                                          >
                                            {v}
                                          </option>
                                        );
                                      })}
                                  </Form.Select> */}

                                  <Form.Select
                                    name="region"
                                    value={address.region}
                                    // onChange={(e) => {
                                    //   handleChange(e);
                                    //   setFormData((prevFormData) => ({
                                    //     ...prevFormData,
                                    //     ["state"]: "",
                                    //   }));
                                    // }}
                                    onChange={(e) => handleAddressChange(e)}
                                    onBlur={() => setAddressUpdate(true)}
                                    required
                                    size="md"
                                    disabled={regions.length === 0}
                                  >
                                    {address.region === "" ? (
                                      <option value="">
                                        {t("entity:selectRegion")}
                                      </option>
                                    ) : (
                                      ""
                                    )}
                                    {regions.map((region, index) => (
                                      <option key={index} value={region}>
                                        {region}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {address.country && regions.length === 0 && (
                                    <small className="text-muted">
                                      No regions available
                                    </small>
                                  )}
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group controlId="state">
                              <Row className="w-100">
                                {" "}
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    {" "}
                                    State
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: " xx-small",
                                      }}
                                    />{" "}
                                    <spa>:</spa>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Select
                                    name="state"
                                    value={address.state}
                                    // onChange={(e) => {
                                    //   handleChange(e);
                                    // }}
                                    onChange={(e) => handleAddressChange(e)}
                                    onBlur={() => setAddressUpdate(true)}
                                    required
                                    size="md"
                                    disabled={states.length === 0}
                                  >
                                    {address.state === "" ? (
                                      <option value="">
                                        {t("entity:selectstate")}
                                      </option>
                                    ) : (
                                      ""
                                    )}
                                    {states.map((state, index) => (
                                      <option
                                        key={state.state_id || index}
                                        value={state.state_name}
                                      >
                                        {state.state_name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {address.country && states.length === 0 && (
                                    <small className="text-muted">
                                      No states available
                                    </small>
                                  )}
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group controlId="district">
                              <Row className="w-100">
                                {" "}
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    District{" "}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: " xx-small",
                                      }}
                                    />{" "}
                                    <spa>:</spa>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  {/* <Form.Select
                                    placeholder={address.district.toLocaleLowerCase()}
                                    value={address.district.toLocaleLowerCase()}
                                    disabled={address.state === ""}
                                    name="district"
                                    onChange={(e) => handleAddressChange(e)}
                                    required
                                    size="md"
                                  >
                                    <option key="">
                                      {address.district !== ""
                                        ? address.district
                                        : "Select District"}
                                    </option>
                                    {districts
                                      .find((d) => d.state === address.state)
                                      ?.district.map((v) => {
                                        return (
                                          <option
                                            key={v.toLocaleLowerCase()}
                                            value={v.toLocaleLowerCase()}
                                          >
                                            {v}
                                          </option>
                                        );
                                      })}
                                  </Form.Select> */}

                                  <Form.Select
                                    name="district"
                                    value={address.district}
                                    onChange={(e) => handleAddressChange(e)}
                                    onBlur={() => setAddressUpdate(true)}
                                    required
                                    placeholder={
                                      address.district === ""
                                        ? "Entity district"
                                        : address.district
                                    }
                                    size="md"
                                  >
                                    {address.district === "" ? (
                                      <option value="">
                                        {t("entity:selectdistrict")}
                                      </option>
                                    ) : (
                                      ""
                                    )}
                                    {districts.map((district) => {
                                      return (
                                        <option
                                          key={district.district_id}
                                          value={district.district_name}
                                        >
                                          {district.district_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </Col>
                              </Row>
                            </Form.Group>

                            <Form.Group controlId="addressOne">
                              <Row className="w-100">
                                {" "}
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Address Line 1
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    placeholder={address.addressOne}
                                    value={address.addressOne}
                                    name="addressOne"
                                    onBlur={() => setAddressUpdate(true)}
                                    onChange={(e) => handleAddressChange(e)}
                                    as="textarea"
                                    rows={3}
                                    maxLength="50" // Limit input to 50 characters
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group controlId="addressTwo">
                              <Row className="w-100">
                                {" "}
                                <Col md={3}>
                                  {" "}
                                  <Form.Label className="text-nowrap">
                                    Address Line 2
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    name="addressTwo"
                                    placeholder={address.addressTwo}
                                    value={address.addressTwo}
                                    onBlur={() => setAddressUpdate(true)}
                                    onChange={(e) => handleAddressChange(e)}
                                    as="textarea"
                                    rows={3}
                                    maxLength="50" // Limit input to 50 characters
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group controlId="pincode">
                              <Row className="w-100">
                                {" "}
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Pincode <spa>:</spa>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    name="pincode"
                                    placeholder={address.pincode}
                                    value={address.pincode}
                                    onBlur={() => setAddressUpdate(true)}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Allow only digits and limit to 6 characters
                                      if (/^\d{0,6}$/.test(value)) {
                                        handleAddressChange(e); // Update address if the value is valid
                                      }
                                    }}
                                    type="text"
                                    maxLength="6" // Limit input length to 6 characters
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                            <Row className="w-100">
                              <Col className="p-0 m-0">
                                <Form.Group controlId="longitude">
                                  <Row className="w-100  align-items-center">
                                    <Col md={7}>
                                      <Form.Label className="text-nowrap">
                                        Longitude{" "}
                                        <StarOutlinedIcon
                                          style={{
                                            color: "red",
                                            fontSize: " xx-small",
                                          }}
                                        />
                                        <spa> :</spa>
                                      </Form.Label>
                                    </Col>
                                    <Col className="w-100 p-0">
                                      {/* <Form.Control
                                        cal
                                        name="lng"
                                        onBlur={() => handleLatLngUpdate()}
                                        onChange={(e) =>
                                          handleCorordinateChange(e)
                                        }
                                        value={latlng.lng}
                                        placeholder={formData.lng}
                                        type="number"
                                      /> */}
                                      <Form.Control
                                        name="lng"
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          // Allow '-' at the start, digits, and up to 4 decimal places
                                          if (
                                            /^-?\d*\.?\d{0,4}$/.test(value) ||
                                            value === ""
                                          ) {
                                            // Convert to number only when the value is not "-" or empty
                                            const parsedValue =
                                              parseFloat(value);
                                            if (
                                              value === "-" ||
                                              value === "" ||
                                              (parsedValue >= -180 &&
                                                parsedValue <= 180)
                                            ) {
                                              // setLatLng((prevLatLng) => ({
                                              //   ...prevLatLng,
                                              //   lng: value,
                                              // }));
                                              handleCorordinateChange(e);
                                            }
                                          }
                                        }}
                                        onBlur={() => handleLatLngUpdate()}
                                        value={latlng.lng}
                                        placeholder="Longitude"
                                        type="text"
                                        maxLength="9" // "-180.0000"
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                              <Col className="p-0 m-0">
                                <Form.Group controlId="latitude">
                                  <Row className="w-100 align-items-center">
                                    <Col md={4}>
                                      <Form.Label className="text-nowrap">
                                        Latitude{" "}
                                        <StarOutlinedIcon
                                          style={{
                                            color: "red",
                                            fontSize: " xx-small",
                                          }}
                                        />
                                        <spa> :</spa>
                                      </Form.Label>
                                    </Col>
                                    <Col md={6} className="p-0">
                                      <Form.Control
                                        name="lat"
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          // Allow '-' at the start, digits, and up to 4 decimal places
                                          if (
                                            /^-?\d*\.?\d{0,4}$/.test(value) ||
                                            value === ""
                                          ) {
                                            // Convert to number only when the value is not "-" or empty
                                            const parsedValue =
                                              parseFloat(value);
                                            if (
                                              value === "-" ||
                                              value === "" ||
                                              (parsedValue >= -90 &&
                                                parsedValue <= 90)
                                            ) {
                                              // setLatLng((prevLatLng) => ({
                                              //   ...prevLatLng,
                                              //   lat: value,
                                              // }));
                                              handleCorordinateChange(e);
                                            }
                                          }
                                        }}
                                        onBlur={() => handleLatLngUpdate()}
                                        value={latlng.lat}
                                        placeholder="Latitude"
                                        type="text"
                                        maxLength="8" // "-90.0000"
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Stack>
                        </Col>
                        <Col className="d-content">
                          <div className="form-map-container">
                            <GMap
                              ltUpdate={ltUpdate}
                              setltUpdate={setltUpdate}
                              addressUpdate={addressUpdate}
                              setAddressUpdate={setAddressUpdate}
                              setLatLng={setLatLng}
                              latlng={latlng}
                              address={address}
                              setAddress={setAddress}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="gap-2 w-100 d-flex flex-column flex-md-row">
                        <Col className="pr-0 p-1">
                          <div className="text-left w-100">
                            <h3>Entity :</h3>
                          </div>

                          <Stack
                            className="row-card card-height p-4"
                            gap={3}
                            direction="vertical"
                          >
                            <Form.Group
                              controlId="entityName"
                              className="w-100"
                            >
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Entity Name <span>:</span>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Select
                                    name="entityName"
                                    value={formData.entityName}
                                    onChange={handleChange}
                                    placeholder="Entity Name"
                                    size="md"
                                    disabled={
                                      formData.plantType === "individual"
                                    }
                                  >
                                    {formData.plantType !== "individual" &&
                                    formData.entityName === "" ? (
                                      <option value="">
                                        Select Entity Name
                                      </option>
                                    ) : (
                                      ""
                                    )}
                                    {/* Render options from entityNames */}
                                    {entityNames.map((entity) => (
                                      <option
                                        key={entity.entityid}
                                        value={entity.entityname}
                                      >
                                        {entity.entityname}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </Row>
                            </Form.Group>

                            <Form.Group controlId="entityId" className="w-100">
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Entity Id <span>:</span>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Label className="text-nowrap fw-normal">
                                    {formData.entityId || ""}
                                  </Form.Label>
                                </Col>
                              </Row>
                            </Form.Group>

                            <Form.Group
                              controlId="basicCategory"
                              className="w-100"
                            >
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Category <span>:</span>
                                  </Form.Label>
                                </Col>
                                <Col>
                                  <Form.Label className="text-nowrap fw-normal">
                                    {formData.basicCategory || ""}
                                  </Form.Label>
                                </Col>
                              </Row>
                            </Form.Group>

                            <Form.Group
                              controlId="basicEmailId"
                              className="w-100"
                            >
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Email Id <span>:</span>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Label className="text-nowrap fw-normal">
                                    {formData.basicEmailId || ""}
                                  </Form.Label>
                                </Col>
                              </Row>
                            </Form.Group>
                            <div style={{ padding: "1px" }}></div>
                          </Stack>
                        </Col>
                        <Col className="pl-0 p-1">
                          <div className="w-100 text-left">
                            <h3>Owner :</h3>
                          </div>
                          <Stack
                            className="row-card card-height p-3 "
                            gap={3}
                            direction="vertical"
                          >
                            <Form.Group
                              controlId="ownerFirstName"
                              className="w-100"
                            >
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    First Name{" "}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: "xx-small",
                                      }}
                                    />
                                    <span>:</span>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={(e) => handleChange(e)}
                                    disabled={
                                      formData.plantType !== "individual"
                                    } // Disable if not Individual
                                    type="string"
                                    placeholder="First Name"
                                  />
                                </Col>
                              </Row>
                            </Form.Group>

                            <Form.Group
                              controlId="ownerLastName"
                              className="w-100"
                            >
                              <Row className="w-100">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Last Name{" "}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: "xx-small",
                                      }}
                                    />
                                    <span>:</span>
                                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={(e) => handleChange(e)}
                                    disabled={
                                      formData.plantType !== "individual"
                                    } // Disable if not Individual
                                    type="string"
                                    placeholder="Last Name"
                                  />
                                </Col>
                              </Row>
                            </Form.Group>

                            <Form.Group
                              controlId="ownerEmailId"
                              className="w-100 mb-3"
                            >
                              <Row className="w-100 align-items-center">
                                <Col md={3}>
                                  <Form.Label className="text-nowrap">
                                    Email Id{" "}
                                    <StarOutlinedIcon
                                      style={{
                                        color: "red",
                                        fontSize: "xx-small",
                                      }}
                                    />
                                    <span>:</span>
                                  </Form.Label>
                                </Col>
                                <Col md={6}>
                                  {/* <Form.Control
                                    name="ownerEmailId"
                                    onChange={handleChange}
                                    disabled={
                                      formData.plantType !== "individual"
                                    } // Disable if not Individual
                                    type="mail"
                                    placeholder="username@email.com"
                                  /> */}
                                  <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter email"
                                    // disabled={
                                    //   formData.plantType !== "individual"
                                    // } // Disable if not Individual
                                    disabled={
                                      isEmailDisabled ||
                                      formData.plantType !== "individual"
                                    } // Disable based on verification status
                                  />
                                  {/* kindly check the row number 1722 for showing feedback label */}
                                </Col>
                                <Col md={3}>
                                  <Button
                                    className="form-button px-2"
                                    onClick={() => handleVerifyClick()}
                                    disabled={
                                      isEmailVerified &&
                                      formData.plantType !== "individual"
                                    }
                                  >
                                    {isEmailVerified ? "Verified" : "Verify"}
                                  </Button>
                                  {/* OTP Modal */}
                                  <Modal size="md" className="custom-model" show={isOtpModalOpen} centered onHide={() => setIsOtpModalOpen(false)}>
                                    <Modal.Body style={{
                                      background: 'rgba(232, 233, 234, 0.9)',
                                      padding: '30px',
                                      borderRadius: '20px',
                                      width: '100%',
                                      backdropFilter: 'blur(4px)',
                                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                                    }}>
                                      <div className="otp-modal-content">
                                        <span className="otp-close-icon" onClick={() => handleCloseOtpModal()} >
                                          <FaTimes />
                                        </span>
                                        <h3>Enter OTP</h3>
                                        <div className="otp-inputs">
                                          {otp.map((digit, index) => (
                                            <input
                                              key={index}
                                              type="text"
                                              maxLength="1"
                                              value={digit}
                                              onChange={(e) =>
                                                handleOtpChange(e, index)
                                              }
                                              ref={otpRefs[index]}
                                              className="otp-input"
                                            />
                                          ))}
                                        </div>
                                        <div className="d-flex flex-column gap-2 justify-content-center">
                                          <div className="text-center">
                                            {timer > 0 ? (<span className="otp-timer"> Time left: {timer}s</span>) : <></>}
                                          </div>
                                          <div className="d-flex flex-row gap-2">
                                            {timer > 0 ? <></> : (
                                              <Button className="form-button" onClick={() => handleResendOtp()}>
                                                Resend OTP
                                              </Button>
                                            )}
                                            <Button className="form-button" onClick={(e) => handleOtpSubmit(e)} >
                                              Submit OTP
                                            </Button>
                                          </div>

                                        </div>
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                  {/* {isOtpModalOpen && (
                                    <div>
                                      <div
                                        className="otp-backdrop"
                                        onClick={() => handleCloseOtpModal()}
                                      ></div>
                                      <div className="otp-modal">
                                        <div className="otp-modal-content">
                                          <span
                                            className="otp-close-icon"
                                            onClick={() =>
                                              handleCloseOtpModal()
                                            }
                                          >
                                            <FaTimes />
                                          </span>
                                          <h3>Enter OTP</h3>
                                          <div className="otp-inputs">
                                            {otp.map((digit, index) => (
                                              <input
                                                key={index}
                                                type="text"
                                                maxLength="1"
                                                value={digit}
                                                onChange={(e) =>
                                                  handleOtpChange(e, index)
                                                }
                                                ref={otpRefs[index]}
                                                className="otp-input"
                                              />
                                            ))}
                                          </div>
                                          <div className="d-flex flex-row gap-2">
                                            <div>
                                              {timer > 0 ? (
                                                <span className="otp-timer">
                                                  Time left: {timer}s
                                                </span>
                                              ) : (
                                                <Button
                                                  className="form-button"
                                                  onClick={() =>
                                                    handleResendOtp()
                                                  }
                                                >
                                                  Resend OTP
                                                </Button>
                                              )}
                                            </div>
                                             <Button
                                              className="form-button"
                                              onClick={(e) =>
                                                handleOtpSubmit(e)
                                              }
                                            >
                                              Submit OTP
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )} */}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}></Col>
                                <Col md={6}>
                                  <div className="m-1">
                                    {/* <h6 className="email-text">
                                      Email is sent to user for verification.
                                    </h6> */}
                                    <h6 className="email-text p-2"></h6>
                                  </div>
                                </Col>
                                <Col md={3}></Col>
                              </Row>
                            </Form.Group>
                          </Stack>
                        </Col>
                      </Row>

                      {/* <Row className="w-100  m-2 d-flex flex-column flex-md-row align-items-center justify-content-left">
                        <div className="w-100 text-left  mt-2 ">
                          <h3>Fetch Solar Data :</h3>
                        </div>

                        <Stack className="row-card p-4" gap={3}>
                          <Row className="w-100 d-flex flex-column flex-lg-row align-items-center justify-content-left">
                            <Col>
                              <Form.Group controlId="dataLogger">
                                <Row className="w-100">
                                  <Col md={3}>
                                    <Form.Label>
                                      Make Data Logger / Inverter <spa>:</spa>
                                    </Form.Label>
                                  </Col>
                                  <Col md={8}>
                                    <Form.Select
                                      name="dataLogger"
                                      defaultValue={formData.datalogger}
                                      onChange={(e) => handleChange(e)}
                                      placeholder="Data Logger / Inverter"
                                      size="md"
                                    >
                                      <option value={""}> Data Logger</option>
                                      <option value="Type1">Solar Edge</option>
                                    </Form.Select>
                                  </Col>
                                  <Col></Col>
                                </Row>
                              </Form.Group>
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row className="w-100 d-flex flex-column flex-lg-row align-items-center justify-content-left">
                            <Col>
                              <div className="w-100 d-flex flex-row align-item-center justify-content-center">
                                <Form.Group
                                  className="w-100"
                                  controlId="plantSerial"
                                >
                                  <Row className="w-100">
                                    <Col md={3}>
                                      <Form.Label className="">
                                        New Plant Serial Number <spa>:</spa>
                                      </Form.Label>
                                    </Col>
                                    <Col md={6}>
                                      <Form.Select
                                        defaultValue={formData.plantSerial}
                                        name="plantSerial"
                                        onChange={(e) => handleChange(e)}
                                        placeholder="XXXXXXXXXXXX"
                                        size="md"
                                      >
                                        <option value="Type1">
                                          XXXXXXXXXXXX
                                        </option>
                                      </Form.Select>
                                    </Col>
                                    <Col>
                                      <Button
                                        onClick={() => onDialApi()}
                                        className="m-1 ml-4 form-button form-button-sp text-nowrap"
                                      >
                                        Daial API
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </div>
                              <div className="w-100 d-flex align-items-center justify-content-around">
                                <h5 className="cell-status">
                                  Cell Status : Synced
                                </h5>
                              </div>
                            </Col>
                            <Col></Col>
                          </Row>
                        </Stack>
                      </Row> */}
                      <Row className="gap-2 w-100  m-2 d-flex flex-column flex-md-row align-items-center justify-content-left">
                        <div className="w-100 text-left mt-2">
                          <h3>Fetch Solar Data :</h3>
                        </div>

                        <Stack className="row-card p-4" gap={3}>
                          <Row className="w-100 d-flex flex-column flex-lg-row align-items-center justify-content-left">
                            <Col>
                              <Form.Group controlId="dataLogger">
                                <Row className="w-100">
                                  <Col md={3}>
                                    <Form.Label>
                                      Make Data Logger / Inverter <span>:</span>
                                    </Form.Label>
                                  </Col>
                                  <Col md={8}>
                                    {/* <Form.Select
                                      name="dataLogger"
                                      defaultValue="Solar Edge"
                                      onChange={(e) => handleInputChange(e)}
                                      placeholder="Data Logger / Inverter"
                                      size="md"
                                    >
                                      <option value="Data Logger">
                                        Data Logger
                                      </option>
                                      <option value="Solar Edge">
                                        Solar Edge
                                      </option>
                                    </Form.Select> */}
                                    <Form.Select
                                      name="dataLogger"
                                      value={dataLogger}
                                      onChange={handleInputChange}
                                      placeholder="Data Logger / Inverter"
                                      size="md"
                                    >
                                      <option value="">Select Make</option>
                                      {makes.length > 0 ? (
                                        makes.map((make) => (
                                          <option
                                            key={make.make_id}
                                            value={make.make_name}
                                          >
                                            {make.make_name}
                                          </option>
                                        ))
                                      ) : (
                                        <option disabled>Loading...</option>
                                      )}
                                    </Form.Select>
                                  </Col>
                                  <Col></Col>
                                </Row>
                              </Form.Group>
                            </Col>
                            <Col></Col>
                          </Row>

                          <Row className="w-100 d-flex flex-column flex-lg-row align-items-center justify-content-left">
                            <Col>
                              <div className="w-100 d-flex flex-row align-item-center justify-content-center">
                                <Form.Group
                                  className="w-100"
                                  controlId="plantSerial"
                                >
                                  <Row className="w-100 d-flex flex-row align-items-center justify-content-left">
                                    <Col md={3}>
                                      <Form.Label>
                                        Enter Plant Serial Number:
                                      </Form.Label>
                                    </Col>
                                    <Col md={6}>
                                      <Form.Control
                                        name="plantSerial"
                                        type="text"
                                        value={plantSerial}
                                        onChange={handleInputChange}
                                        placeholder="Enter Serial Number"
                                        size="md"
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        onClick={onDialApi}
                                        className="m-1 ml-4 form-button form-button-sp text-nowrap"
                                      >
                                        Sync Data
                                      </Button>
                                    </Col>
                                  </Row>
                                  {error && (
                                    <p style={{ color: "red" }}>{error}</p>
                                  )}{" "}
                                  {/* Display error message */}
                                </Form.Group>
                              </div>

                              {/* Only show the download link, nothing else */}
                              <div className="w-100 d-flex align-items-center justify-content-around">
                                {downloadLink && (
                                  <p className="text-success m-0">
                                    CSV File Generated:{" "}
                                    <a
                                      href={downloadLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-primary"
                                      style={{
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      Click here to download
                                    </a>
                                  </p>
                                )}
                              </div>
                            </Col>
                            <Col></Col>
                          </Row>
                        </Stack>
                      </Row>
                      <Row className="w-100">
                        <div className="w-100 d-flex flex-row align-items-center justify-content-end">
                          {" "}
                          <Button
                            onClick={() => oncancel()}
                            className="mr-3 m-1 form-button"
                          >
                            Cancel
                          </Button>{" "}
                          <Button
                            type="submit"
                            className="ml-3 m-1 form-button"
                          >
                            Save Plant
                          </Button>
                        </div>
                      </Row>
                    </Container>
                  </Form>
                </Container>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div>
                  {!nextPage && <PlantBulkImportOne />}
                  {nextPage && <PlantBulkImportTwo />}
                </div>
                <div className="w-100 p-0 m-4">
                  {" "}
                  <div className="d-flex flex-row w-100 justify-content-between">
                    <div
                      className="file-upload-button"
                      onClick={() => setNextPage(false)}
                    >
                      previous
                    </div>{" "}
                    {nextPage === true ? (
                      <div
                        className="file-upload-button w-auto"
                        onClick={() => setNextPage(true)}
                      >
                        Save Plants and Devices
                      </div>
                    ) : (
                      <div
                        className="file-upload-button"
                        onClick={() => setNextPage(true)}
                      >
                        next
                      </div>
                    )}
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Stack>
        </Container>
      </Tab.Container>
    </div>
  );
}
export default AdminPlantAdd;
