import NTFilter from "../../assets/NTFilter.svg"; // Adjust path as necessary
import searchIcon from "../../assets/search_icon.svg"; // Adjust path as necessary
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import pdfIcon from "../../assets/pdf_icon.svg"; // Adjust path as necessary
import NTSettings from "../../assets/NTSettings.svg"; // Adjust path as necessary
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import "./Navbar.css"


function Styles(){
    return <style>{`
          
            .top-search::placeholder {
                  color:white !important;
                  opacity:0.6
             }
            
        
        `}</style>
}

function BottomHeaderBar() {
    return (
        <Container as={"div"} fluid className="mw-100 d-flex flex-column flex-lg-row align-items-sm-end align-items-center justify-content-sm-between bottom-bar" >

            <h1 className="overview-heading"> </h1>
            <Styles/>
            <Row  className="d-flex flex-sm-row flex-column align-items-center justify-content-sm-between gap-2">
                <Col >
                    {/* <div className="top-search-container">
                        <input
                            type="text"
                            placeholder="Search"
                            className="top-search"
                        />
                        <SearchOutlinedIcon sx={{color:'white' }} className="over-view-search-icon"/>
                    </div> */}
                    
                    <InputGroup className="top-search-container">                      
                        <Form.Control className="bg-transparent border-0"
                        size="sm"
                            placeholder="Search"
                        />
                        <InputGroup.Text id="basic-addon1" className="bg-transparent border-0">                         
                        <SearchOutlinedIcon sx={{color:'white' }}/>
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col  className="d-flex flex-row align-items-center justify-content-between">
                    <img src={NTFilter} alt="Filter Icon" className="overview-icon" />
                    <div className="overview-divider"></div>
                    <img src={pdfIcon} alt="PDF Icon" className="overview-icon" />
                    <div className="overview-divider"></div>
                    <img
                        src={NTSettings}
                        alt="Settings Icon"
                        className="overview-icon"
                    />
                </Col>
            </Row>

        </Container >

    )
}
export default BottomHeaderBar;