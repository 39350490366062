import React from "react";
import { ReactComponent as GridIcon } from "./../../assets/grid2_icon.svg";
import { ReactComponent as PeakPowerIcon } from "./../../assets/peak_power_icon.svg";
import "./PeakPower.css"; // Importing the CSS file
import { Card } from "react-bootstrap";
import dayjs from "dayjs";

const PeakPower = (totalPlants) => {
  return (
    <Card className=" rounded-3 shadow-sm h-95">
    <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center border-bottom border-bottom-2 shadow-sm border-secondary-subtle"> 
     <GridIcon  width={12} height={20}  />
     <div className="production-title">Peak Power</div>
   
     </Card.Header>
 <Card.Body>
  
      <div className="peakpower-wrapper d-flex flex-row">
        <PeakPowerIcon width={200} height={200} />
        <div className="d-flex flex-column pl-3 ml-5 m-0">
        <span className="peakpower-value">1.15 MW</span>
        <div className="align-self-center">Total plants: {totalPlants==undefined?totalPlants:187}</div>
        <div className="align-self-center" style={{fontSize:12}}>Last Read: {totalPlants==undefined?totalPlants:dayjs().format('YYYY/MM/DD LT')}</div>
        </div>
      </div>
   
    </Card.Body>
    </Card>
  );
};

export default PeakPower;
