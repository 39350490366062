import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

function Loader(){
  return(<>....Loading</>)
}

root.render(
  <StrictMode>
    <Suspense fallback={<Loader />}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <App />
    </LocalizationProvider>
    </Suspense>
  </StrictMode>
);

