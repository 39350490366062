import { Button, Col, Container, Row } from "react-bootstrap";
import FileUploadIcon from "./../../assets/file_upload_icon.svg";
import { useState } from "react";


function PlantBulkImportOne() {
    function Styles() {
        return <style>
            {`
            .file-holder{
                position: relative;
    display: flex;
    align-items: center;
    min-width: 322px;
    max-width: 508px;
    height: 48px;
    border: dashed 2px #6B7A97;
    padding: 8px 16px 8px 8px;
    border-radius: 5px;
    cursor: pointer;
    flex-grow: 0;
        width: 350px;
        height:200px;
    box-sizing: border-box;
        color: #0F0D0D;
    font-weight: 500;
            }
    .file-holder input{
     
        display: block;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    }
    .file-upload-button{
        background: #167DB0;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
        width: 110px;
    text-align: center;
    }
            `}
        </style>
    }

    const [files,setFiles]=useState();
    const fileHandle=(e)=>{
        const files=e.currentTarget.files;
        setFiles(files);
    }

    const fileUpload=()=>{

    }
    return <>
        <Container className="mw-100 ">
            <Styles />
            <Row className="mt-2 mb-2 ">

                <Col className="d-flex flex-row align-items-center justify-content-start">
                    <span className="fw-bold text-decoration-none text-black">Step 1 - Upload Template</span>
                </Col>

                <Col className="d-flex flex-row align-items-center justify-content-end">

                </Col>
            </Row>
        </Container>
        <Container className="w-100 p-0 m-3">
            <div className="d-flex flex-column justify-content-center" style={{width:'fit-content'}}>
            <div><span>Add Plant From Template</span></div>
            <div><span>Upload XLS file...</span></div>
            <div><label class="file-holder  " for="file" role="button" style={{}}>
                <div className="d-flex flex-column justify-content-center w-100">
                    <input accept=".xls" type="file" id="file" name="file" onChange={(e)=>fileHandle(e)} />
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <img src={FileUploadIcon} width={50} height={50} />

                        <span>Drag and Drop files here</span>
                        <span>-OR-</span>
                        <div className="file-upload-button">Browse File</div>
                    </div>
                </div>
            </label>
            </div>
            <div className="d-flex align-items-center justify-content-center"><div className="file-upload-button mt-3" onClick={()=>fileUpload()}>Upload</div></div>
            </div>
        </Container>
    </>

}

export default PlantBulkImportOne;