import React, { useState, useMemo } from "react";
import "./MaintenanceTable.css"; // Importing the styles

// Importing icons from src/assets
import updownIcon from "../../assets/updown_icon.png";
import deleteIcon from "../../assets/delete_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import Paginationcontrol from "./../plants/PaginationControl";

const MaintenanceTable = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    ticketNumber: true,
    plantId: true,
    plantName: true, // Always visible and uncheckable
    plantType: true,
    ticketDateTime: true,
    ftrDateTime: true,
    ticketType: true,
    priority: true,
    assignedTo: true,
    status: true,
  });
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedFilter, setSelectedFilter] = useState(""); // No filter selected by default
  const [searchQuery, setSearchQuery] = useState(""); // Search input
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 8; // Rows per page fixed
  
  const [tableProps,setTableProps]=useState({rowsPerPage:5,currentPage:1,pageFirst:1,pageLast:5})

  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleFilterToggle = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleRowsPerPage = (value) => {
    setRowsPerPage(value)
  }

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setIsFilterVisible(false); // Close the filter dropdown after selecting
  };

  const handleColumnSelect = (column) => {
    setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const tablePropsChange = (tableProps) =>{
    setTableProps(tableProps);
  }

  

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  const ticketNumbers = [
    "GSAI5571",
    "GSAI5572",
    "GSAI5573",
    "GSAI5574",
    "GSAI5575",
  ];

  const plantNames = ["Gayitri", "Sashidhar", "Vijaya", "Lakshmi", "Madan"];
  const plantIds = ["PL-0056", "PL-0057", "PL-4830", "PL-0076", "PL-3109"];
  const plantTypes = [
    "Residential",
    "Commercial & Industry",
    "Large Power Producer",
  ];
  const ticketTypes = ["Service", "Trouble Shooting", "Support"];
  const priorities = ["Low", "Moderate", "High"];
  const assignedTos = ["Ravish", "Anand", "Naveen"];

  const statuss = [
    "Pending with Plant Manager",
    "Pending with FE",
    "Pending with customer",
    "Open",
    "Close",
  ];

  // Generate 20 random plant entries
  const data = Array.from({ length: 20 }, (_, index) => ({
    id: index + 1,
    ticketNumber:
      ticketNumbers[Math.floor(Math.random() * ticketNumbers.length)],
    plantId: plantIds[Math.floor(Math.random() * plantNames.length)],
    plantName: plantNames[Math.floor(Math.random() * plantNames.length)],
    plantType: plantTypes[Math.floor(Math.random() * plantTypes.length)],
    ticketDateTime: `${Math.floor(Math.random() * 30) + 1}-${
      Math.floor(Math.random() * 12) + 1
    }-202${Math.floor(Math.random() * 4)}`,
    ftrDateTime: `${Math.floor(Math.random() * 30) + 1}-${
      Math.floor(Math.random() * 12) + 1
    }-202${Math.floor(Math.random() * 4)}`,
    ticketType: ticketTypes[Math.floor(Math.random() * ticketTypes.length)],
    priority: priorities[Math.floor(Math.random() * priorities.length)],
    assignedTo: assignedTos[Math.floor(Math.random() * assignedTos.length)],
    status: statuss[Math.floor(Math.random() * statuss.length)],
  }));

  // Sorting logic based on the updown icon click
  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  // Filtering logic (only filters if a filter is selected and a search query is entered)
  const filteredData = sortedData.filter((row) => {
    if (!selectedFilter || !searchQuery) return true; // No filter if no filter or search query is provided
    return row[selectedFilter]
      .toLowerCase()
      .startsWith(searchQuery.toLowerCase()); // Filter matches only if it starts with the query
  });

    // Pagination logic
    const currentRows = useMemo(() => {    
      if (filteredData.length > 0) {
        return filteredData.slice(((tableProps.currentPage - 1) * tableProps.rowsPerPage), (((tableProps.currentPage - 1) * tableProps.rowsPerPage) + tableProps.rowsPerPage))
      }
      return []
    }, [filteredData, selectedRows, tableProps]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handling a maximum of 7 columns and hiding the rest in horizontal scrolling
  const visibleColumns = Object.keys(selectedColumns).filter(
    (key) => selectedColumns[key]
  );

  const selectRows = (e, rowData) => {
    e.stopPropagation();
    const index = selectedRows.findIndex(r => r.id === rowData.id);
    { index === -1 ? setSelectedRows([...selectedRows, rowData]) : setSelectedRows([...selectedRows.filter(r => r.id !== rowData.id)]) }
  }

  // Filling empty rows to keep the table height consistent
  const emptyRows = rowsPerPage - currentRows.length;

  function handleAllToggleEvents() {
    isFilterVisible == true ? setIsFilterVisible(false) : "";
    isDropdownVisible == true ? setIsDropdownVisible(false) : "";
  }

  return (
   
    <div className=" d-flex flex-column align-items-center justify-content-center" onClick={() => handleAllToggleEvents()}>
      <div className="main-container">
      {/* Search Bar Container */}
      <div className="search-bar-container gap-3" >
        <OverlayTrigger trigger="click" placement="left-start" overlay={
          <Popover>
            <Popover.Body className="overlay-body-menu">

              <ul>
                {Object.keys(selectedColumns).filter((key) => selectedColumns[key] == true).map(
                  (column) => (
                    <li key={column} onClick={() => handleFilterSelect(column)}>
                      {column.charAt(0).toUpperCase() + column.slice(1)}
                    </li>
                  )
                )}
              </ul>

            </Popover.Body>
          </Popover>
        }>
          <img
            src={filterIcon}
            alt="filter"
            className="filter-icon"
          />
        </OverlayTrigger>

        <div className="search-input-container">
          <input
            type="text"
            className="search-input"
            placeholder={`Search by ${selectedFilter || "..."}`}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            disabled={!selectedFilter} // Disable search input until a filter is selected
          />
          <img src={searchIcon} alt="search" className="search-icon" />
        </div>
      </div>

      {/* Card Container */}
      <div className="border rounded border-light-subtle">
        <div className="table-container" style={{ overflowX: "auto" }}>
          <table style={{ width: "100%", whiteSpace: "nowrap" }}>
            <thead>
              <tr>
                <th></th>
                {visibleColumns.map((column) => (
                  <th key={column}>
                    {column.charAt(0).toUpperCase() + column.slice(1)}
                    <img
                      src={updownIcon}
                      alt="sort"
                      onClick={() => handleSort(column)}
                      style={{ cursor: "pointer" }}
                    />
                  </th>
                ))}
                <th>
                  <OverlayTrigger trigger="click" placement="auto-start" overlay={
                    <Popover>
                      <Popover.Body className="overlay-body">

                        <div>
                          <label>
                            <input
                              type="checkbox"
                              checked={selectedColumns.plantName}
                              disabled // Plant Name is uncheckable
                            />{" "}
                            Plant Name
                          </label>
                          {Object.keys(selectedColumns).map(
                            (column) =>
                              column !== "plantName" && (
                                <label key={column}>
                                  <input
                                    type="checkbox"
                                    checked={selectedColumns[column]}
                                    onChange={() => handleColumnSelect(column)}
                                  />{" "}
                                  {column.charAt(0).toUpperCase() + column.slice(1)}
                                </label>
                              )
                          )}
                        </div>

                      </Popover.Body>
                    </Popover>
                  }>
                    <img
                      src={settingIcon}
                      alt="settings"
                      className="setting-icon"
                    />
                  </OverlayTrigger>

                </th>
              </tr>
            </thead>
            <tbody>
              {/* Rendering filtered rows */}
              {currentRows.map((rowdata, index) => (
                <tr className={`${selectedRows.findIndex(rd => rd.id === rowdata.id) !== -1 ? 'row-select' : ''}`} key={rowdata.id}>
                  <td className="checkbox-column">
                    <Form>
                      <Form.Check // prettier-ignore
                        type='checkbox'
                        onClick={(e) => selectRows(e, rowdata)}
                        checked={selectedRows.findIndex(rd => rd.id === rowdata.id) !== -1 ? true : false}
                      />
                    </Form>

                  </td>
                  {visibleColumns.map((column) => (
                    <td key={`${rowdata.id}-${column}`}>
                      {column === "alerts" ? (
                        <span
                          style={{
                            color:
                              rowdata.alerts === "Normal"
                                ? "blue"
                                : rowdata.alerts === "Major"
                                  ? "orange"
                                  : "red",
                          }}
                        >
                          {rowdata[column]}
                        </span>
                      ) : (
                        rowdata[column]
                      )}
                    </td>
                  ))}
                  <td className="delete-column">
                    <img
                      src={deleteIcon}
                      alt="delete"
                      className="delete-icon"
                      onClick={(e) => { disableRow(e, rowdata) }}
                    />
                  </td>
                </tr>
              ))}

              {/* Filling empty rows to maintain table height */}
              {emptyRows < 0 &&
                Array.from({ length: emptyRows }).map((_, index) => (
                  <tr key={`empty-${index}`}>
                    <td colSpan="9" className="empty-row"></td>
                  </tr>
                ))}
            </tbody>
          </table>
          <>
          <Paginationcontrol
              rowsCount={filteredData.length}
              tableProps={tableProps}
              tablePropsChange={tablePropsChange}
              alwaysShown={true}
            />
          </>
        </div>
      </div>
      </div>
    </div >
  );
};

export default MaintenanceTable;
