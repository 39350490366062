import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Stack,
} from "react-bootstrap";
// import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import blueSolidLeftIcon from "../../assets/blue_solid_left.png";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { green } from "@mui/material/colors";
import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useRef, useContext } from "react"; // Unified import for all hooks
import { useTranslation } from "react-i18next";
import axios from "axios"; // Import axios
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { MessageContext } from "../../App";

function Styles() {
  return (
    <style>
      {`
                 .row-card{
                   background-color:#f2f5f7;
                   text-align: left;
                   align-items: center;
                   justify-content-center;
                   box-shadow: 0px 0px 3px 1px #d7dadb;
                   border-radius: 5px;
                 }
  
                 .form-button{
                   background-color: #167db0;
                   color: white;
                   border-radius: 7px;
                   padding: 3px 20px 3px 20px
                }

                .form-button:hover{
                background-color: white !important;
                color : black !important;
                border-color:#167db0 !important;
                }

                .form-button-sp{
                    padding: 1px 10px 1px 10px !important;
                    border-radius: 20px !important;
                }
  
                .card-height{
                 height: 88% !important
                } 
                .entity-add-form{
                    font-family: 'Open Sans';
                    font-weight: normal;
                } 
                .entity-add-form .row{
                align-items: center;
                }
                .entity-add-form .form-label{
                 font-weight:bold;
                 margin: 0px;
                }
                 .entity-add-form .form-select{
                 font-weight:normal !important;
                 font-family: 'Open Sans';
                }

                .entity-add-form .form-button{
                  font-family: 'Open Sans';
                    font-weight: bold;
                }
               .entity-add-form .form-control{
                  font-family: 'Open Sans';
                    font-weight: normal;
                }
               .entity-form-left-arrow{
                width: 23px;
                height: 23px;
                opacity: 1;
                margin-right: 5px;
               }
                 
          `}
    </style>
  );
}

function EntityAddPage(e) {
  const [latlngs, setLatLngs] = useState({
    lat: 12.936847666033527,
    lng: 77.57351512286992,
  });
  const [country, setCountry] = useState([]);
  const [regions, setRegions] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [entityTypes, setEntityTypes] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const navigate = useNavigate();
  const onMessageChange = useContext(MessageContext);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const entityId = userData?.entityId || ""; // Extract entityId from session storage

  const { t } = useTranslation("entity");

  useEffect(() => {}, [latlngs]);

  const [formData, setFormData] = useState({
    entityId: "",
    entityName: "",
    category: "",
    country: "",
    region: "",
    state: "", // Corrected to state
    district: "", // Corrected to district
    addressOne: "", // Matches address_line_1
    addressTwo: "", // Matches address_line_2
    pincode: "", // Corrected to pincode
    latlng: "",
    ownerIDate: "",
    ownerEntityName: "",
    ownerEmailId: "",
    ownerFirstName: "",
    ownerLastName: "",
    basicEmailId: "",
    // basicCategory: "",
    datalogger: "",
    entitySerial: "",
    GSTIN: "", // Corrected to GSTIN
    Region: "", // Corrected to Region
    mobile: "", // Corrected to mobile
  });
  const [validated, setValidated] = useState(false);

  // Fetch generated entityId on load
  useEffect(() => {
    fetch("https://solar-api.antsai.in/api/entity/generateEntityId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        entityid: entityId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(
              `Error ${response.status}: ${
                errorData.message || response.statusText
              }`
            );
          });
        }
        return response.json();
      })
      .then((data) => {
        setFormData((prevData) => ({
          ...prevData,
          entityId: data.entityid,
        }));
      })
      .catch((error) => {
        console.error("Error fetching Entity ID:", error.message);
        alert("Failed to retrieve Entity ID. Please try again.");
      });
  }, [entityId]);

  const refreshPage = () => {
    window.location.reload(); // Or define any other logic you want here
  };

  // Fetch category options using entityId
  useEffect(() => {
    const fetchCategoryOptions = async () => {
      try {
        const response = await fetch(
          `https://solar-api.antsai.in/api/categories?entityid=${entityId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (Array.isArray(data.categories)) {
          setCategoryOptions(data.categories);
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategoryOptions();
  }, [entityId]);

  // Fetch countries from API
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://solar-api.antsai.in/api/countries",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({}),
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log("Fetched countries:", data);
          setCountry(data);
        } else {
          console.error(
            `Failed to fetch countries: ${response.status} ${response.statusText}`
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  // Fetch regions based on selected country (Scenario 1)
  useEffect(() => {
    if (!formData.country) return;

    const selectedCountry = country.find(
      (c) => c.country_name === formData.country
    );
    if (!selectedCountry) return;

    const fetchRegions = async () => {
      try {
        const response = await fetch(
          `https://solar-api.antsai.in/api/Region1?country_id=${selectedCountry.country_id}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await response.json();
        if (Array.isArray(data)) {
          setRegions(data.map((region) => region.region));
        } else {
          console.error("Unexpected data format:", data);
          setRegions([]); // Set to empty array if data format is unexpected
        }
        console.log("Fetched regions:", data); // Debugging statement
      } catch (error) {
        console.error("Error fetching regions:", error);
        setRegions([]); // Ensure `regions` is reset on error
      }
    };

    fetchRegions();
  }, [formData.country, country]);

  // Fetch states based on selected country (if no region selected)
  useEffect(() => {
    if (!formData.country) return;

    const selectedCountry = country.find(
      (c) => c.country_name === formData.country
    );
    if (!selectedCountry) return;

    const fetchStates = async () => {
      try {
        const response = await fetch(
          `https://solar-api.antsai.in/api/state1/${selectedCountry.country_id}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await response.json();
        if (Array.isArray(data)) {
          setStates(data);
        } else {
          console.error("Unexpected data format:", data);
          setStates([]); // Ensure states is empty if data format is unexpected
        }
        console.log("Fetched states:", data); // Debugging statement
      } catch (error) {
        console.error("Error fetching states:", error);
        setStates([]); // Ensure `states` is reset on error
      }
    };

    fetchStates();
  }, [formData.country, country]);

  //fetch states by region
  useEffect(() => {
    if (!formData.country) return;

    const selectedCountry = country.find(
      (c) => c.country_name === formData.country
    );
    if (!selectedCountry) return;

    const fetchStates = async () => {
      try {
        const url = `https://solar-api.antsai.in/api/state2?country_id=${selectedCountry.country_id}&region=${formData.region}`;
        const response = await fetch(url, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        if (response.status === 200) {
          const data = await response.json();
          if (Array.isArray(data["states"])) {
            setStates(data["states"]);
          } else {
            console.error("Unexpected data format:", data);
            setStates([]); // Ensure states is empty if data format is unexpected
          }
          console.log("Fetched states:", data);
        } // Debugging statement
      } catch (error) {
        console.error("Error fetching states:", error);
        setStates([]); // Ensure `states` is reset on error
      }
    };

    fetchStates();
  }, [formData.region]);

  // Region fetching function when state is selected
  useEffect(() => {
    const fetchRegionByState = async () => {
      const selectedCountry = country.find(
        (c) => c.country_name === formData.country
      );
      if (!selectedCountry) return;

      try {
        const response = await fetch(
          `https://solar-api.antsai.in/api/Region2?country_id=${selectedCountry.country_id}&state_name=${formData.state}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        const regionData = await response.json();
        if (regionData && regionData.region) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            region: regionData.region,
          }));
        } else {
          console.error(
            "Unexpected data format for region by state:",
            regionData
          );
        }
      } catch (error) {
        console.error("Error fetching region by state:", error);
      }
    };
    fetchRegionByState();
  }, [formData.state]);

  useEffect(() => {
    if (formData.state === "") return;
    // Fetch states based on selected state

    fetchDistricts();
  }, [formData.state, states]);

  // Fetch districts based on selected state
  const fetchDistricts = async () => {
    try {
      const response = await fetch(
        `https://solar-api.antsai.in/api/districts/${
          states.find((c) => c.state_name === formData.state).state_id
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          //   body: JSON.stringify({ country_id: selectedCountry.country_id }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched districts:", data);
        setDistricts(data);
      } else {
        console.error(
          `Failed to fetch districts: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  // const handleChange = (event) => {
  //   const { name, value, type, checked, files } = event.currentTarget;

  //   let validValue = value;
  //   if (name === "mobile" && !/^\d{0,15}$/.test(value)) return; // Allow only numbers, up to 15 digits
  //   if (name === "pincode" && !/^\d{0,6}$/.test(value)) return; // Allow only numbers, up to 6 digits
  //   if ((name === "addressOne" || name === "addressTwo") && value.length > 50) {
  //     onMessageChange({
  //       type: "Warning",
  //       info: "Address line should be within 50 characters.",
  //     });
  //     return; // Limit address to 50 characters
  //   }
  //   if (name === "gstin") {
  //     // Limit GSTIN to 15 characters and convert to uppercase
  //     validValue = value.toUpperCase().slice(0, 15);
  //   }

  //   const regex = /^[A-Za-z]*$/;

  //   if (name === "entityName") {
  //     // Entity name validation for special characters
  //     if (!/^[a-zA-Z0-9 &'']*$/.test(value)) {
  //       onMessageChange({
  //         type: "Warning",
  //         info: "Entity name should not contain special characters except '&' and single quote.",
  //       });
  //       return;
  //     }

  //     // Updated checks for ownerFirstName and ownerLastName with debug statements
  //     if (name === "ownerFirstName" || name === "ownerLastName") {
  //       console.log(`Validating ${name}: ${value}`); // Debug statement

  //       // Only allow alphabetic characters and spaces
  //       if (!/^[a-zA-Z\s]*$/.test(value)) {
  //         onMessageChange({
  //           type: "Warning",
  //           info: `${
  //             name === "ownerFirstName" ? "First Name" : "Last Name"
  //           } should only contain alphabetic characters.`,
  //         });
  //         return;
  //       }

  //       // Limit First Name and Last Name to 30 characters
  //       if (value.length > 30) {
  //         onMessageChange({
  //           type: "Warning",
  //           info: `${
  //             name === "ownerFirstName" ? "First Name" : "Last Name"
  //           } should not exceed 30 characters.`,
  //         });
  //         return;
  //       }
  //     }

  //     // Limits name to 30 characters
  //     if (value.length > 30) {
  //       onMessageChange({
  //         type: "Warning",
  //         info: "Entity name should not exceed 30 characters.",
  //       });
  //       return;
  //     }
  //   }

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]:
  //       type === "checkbox" ? checked : type === "file" ? files : validValue,
  //   }));
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;

    let validValue = value;

    // Validation for each field
    switch (name) {
      case "mobile":
        // Allow only digits and limit to 15 characters
        if (/^\d{0,15}$/.test(value)) {
          validValue = value;
        } else {
          onMessageChange({
            type: "Warning",
            info: "Mobile number should only contain digits and be up to 15 characters.",
          });
          return;
        }
        break;

      case "pincode":
        // Allow only digits, and it must be exactly 6 characters
        if (/^\d{0,6}$/.test(value)) {
          validValue = value;
        } else {
          onMessageChange({
            type: "Warning",
            info: "Pincode should contain exactly 6 digits.",
          });
          return;
        }
        break;

      case "entityName":
        // Allow letters, numbers, spaces, `&`, `'`, and limit to 30 characters
        if (/^[a-zA-Z0-9 &'']{0,30}$/.test(value)) {
          validValue = value;
        } else {
          onMessageChange({
            type: "Warning",
            info: "Entity name should contain only letters, numbers, spaces, '&', and single quote, up to 30 characters.",
          });
          return;
        }
        break;

      case "ownerFirstName":
      case "ownerLastName":
        // Allow only alphabetic characters and spaces, limit to 30 characters
        if (/^[a-zA-Z\s]{0,30}$/.test(value)) {
          validValue = value;
        } else {
          onMessageChange({
            type: "Warning",
            info: `${
              name === "ownerFirstName" ? "First Name" : "Last Name"
            } should contain only letters and spaces, up to 30 characters.`,
          });
          return;
        }
        break;

      case "gstin":
        // Limit GSTIN to 15 characters and convert to uppercase
        validValue = value.toUpperCase().slice(0, 15);
        break;

      // Additional validations for other fields can go here

      default:
        validValue = value;
    }

    // Update form data if validation passes
    setFormData((prevData) => ({
      ...prevData,
      [name]: validValue,
    }));
  };

  // Validation for minimum length on blur
  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "entityName" && value.length > 0 && value.length < 3) {
      onMessageChange({
        type: "Warning",
        info: "Entity name should have a minimum of 3 characters.",
      });
    }
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("Text");
    if (pastedText.length > 30) {
      event.preventDefault();
      onMessageChange({
        type: "Warning",
        info: "Pasting text over 30 characters is not allowed in entity name.",
      });
    }
  };

  // Validation function to check mandatory fields and formats before submission
  const validateFormData = () => {
    const mandatoryFields = [
      "entityName",
      "category",
      "ownerFirstName",
      "email",
      "country",
      "district",
      "pincode",
    ];
    for (let field of mandatoryFields) {
      if (!formData[field]) {
        onMessageChange({
          type: "Danger",
          info: `${field} is required.`,
        });
        return false;
      }
    }

    if (!/^\d{0,15}$/.test(formData.mobile)) {
      onMessageChange({
        type: "Danger",
        info: "Mobile number must be up to 15 digits.",
      });
      return false;
    }

    if (!/^\d{6}$/.test(formData.pincode)) {
      onMessageChange({
        type: "Danger",
        info: "Pincode must be exactly 6 digits.",
      });
      return false;
    }

    return true;
  };

  // const handleBack = () => {

  //     Navigate("/dashboard/entitys/entity");
  // }

  //   const handleSubmit = (event) => {
  //     const form = event.currentTarget;
  //     if (form.checkValidity() === false) {
  //       event.preventDefault();
  //       event.stopPropagation();
  //     }
  //     setValidated(true);
  //   };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const form = event.currentTarget;

  //   // Check if the email is verified
  //   if (!isEmailVerified) {
  //     alert("Please verify your email before submitting the form.");
  //     return; // Prevent form submission if email is not verified
  //   }

  //   if (form.checkValidity() === false) {
  //     event.stopPropagation();
  //     setValidated(true);
  //   } else {
  //     const payload = {
  //       entityid: formData.entityId,
  //       entityname: formData.entityName,
  //       category: formData.entityCategory,
  //       contactfirstname: formData.ownerFirstName,
  //       contactlastname: formData.ownerLastName,
  //       email: formData.email,
  //       mobile: formData.mobile,
  //       country: formData.country,
  //       state: formData.state,
  //       district: formData.district,
  //       pincode: formData.pincode,
  //       masterentityid: formData.entityId,
  //       address_line_1: formData.addressOne,
  //       address_line_2: formData.addressTwo,
  //       GSTIN: formData.GSTIN,
  //       Region: formData.region,
  //     };

  //     axios
  //       .post("https://solar-api.antsai.in/add-entity", payload, {
  //         headers: { "Content-Type": "application/json" },
  //       })
  //       .then((response) => {
  //         console.log("Entity added successfully!");
  //         alert("Entity added successfully!");
  //         navigate("/admin");
  //         window.location.reload();
  //       })
  //       .catch((error) => {
  //         const errorMessage = error.response?.data?.message || "Unknown error";
  //         console.error("Backend error:", errorMessage);
  //         alert(`Failed to add entity: ${errorMessage}`);
  //       });
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (!isEmailVerified) {
      onMessageChange({
        type: "Danger",
        info: "Please verify your email before submitting the form.",
      });
      return;
    }

    if (
      !formData.entityName ||
      formData.entityName.length < 3 ||
      formData.entityName.length > 30
    ) {
      onMessageChange({
        type: "Danger",
        info: "Please enter a valid entity name with 3-30 characters and no special characters other than '&' and single quote.",
      });
      return;
    }

    if (!validateFormData()) return; // If validation fails, stop the submission

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      const payload = {
        entityid: formData.entityId,
        entityname: formData.entityName,
        category: formData.category,
        contactfirstname: formData.ownerFirstName,
        contactlastname: formData.ownerLastName,
        email: formData.email,
        mobile: formData.mobile,
        country: formData.country,
        state: formData.state,
        district: formData.district,
        pincode: formData.pincode,
        masterentityid: formData.entityId,
        address_line_1: formData.addressOne,
        address_line_2: formData.addressTwo,
        GSTIN: formData.GSTIN,
        Region: formData.region,
      };

      axios
        .post("https://solar-api.antsai.in/add-entity", payload, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log("Entity added successfully!");
          onMessageChange({
            type: "Success",
            info: "Entity added successfully!",
          });
          navigate("/admin");
          window.location.reload();
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || "Unknown error";
          console.error("Backend error:", errorMessage);
          onMessageChange({
            type: "Danger",
            info: `Failed to add entity: ${errorMessage}`,
          });
        });
    }
  };

  // const fileChanged = (file) => {
  //   console.log(file);
  // };

  // const updateLatLng = (latlng) => {
  //   setFormData({ ...formData, latlng: latlng });
  //   setLatLngs({ ...latlng });
  // };

  // const onVerify = () => {};
  // const oncancel = () => {};

  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(20);
  const [showResend, setShowResend] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailLocked, setIsEmailLocked] = useState(false); // State to lock email field
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  // // Send OTP
  // const handleVerifyClick = async () => {
  //   if (!formData.email) {
  //     onMessageChange({
  //       type: "Warning",
  //       info: "Please enter your email first.",
  //     });
  //     return;
  //   }
  //   try {
  //     const response = await fetch("https://solar-api.antsai.in/send-otp", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ user_email: formData.email }),
  //     });
  //     if (response.ok) {
  //       setIsOtpModalOpen(true);
  //       setTimer(20);
  //       setShowResend(false);
  //       setOtp(["", "", "", ""]);
  //       onMessageChange({
  //         type: "Success",
  //         info: "OTP sent to your email!",
  //       });
  //     } else {
  //       onMessageChange({
  //         type: "Danger",
  //         info: "Failed to send OTP. Please try again.",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error sending OTP:", error);
  //   }
  // };

  const handleVerifyClick = async () => {
    if (!formData.email) {
      onMessageChange({
        type: "Warning",
        info: "Please enter your email first.",
      });
      return;
    }

    try {
      // Step 1: Check if the email already exists
      const checkEmailResponse = await fetch(
        "https://solar-api.antsai.in/checkemail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: formData.email }),
        }
      );

      const checkEmailData = await checkEmailResponse.json();

      if (checkEmailData.exists) {
        // If email exists, show message and stop further actions
        onMessageChange({
          type: "Danger",
          info: "User already exists.",
        });
        return;
      }

      // Step 2: If email does not exist, send OTP
      const otpResponse = await fetch("https://solar-api.antsai.in/send-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_email: formData.email }),
      });

      if (otpResponse.ok) {
        setIsOtpModalOpen(true);
        setTimer(20);
        setShowResend(false);
        setOtp(["", "", "", ""]);
        onMessageChange({
          type: "Success",
          info: "OTP sent to your email!",
        });
      } else {
        onMessageChange({
          type: "Danger",
          info: "Failed to send OTP. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error in checking email or sending OTP:", error);
      onMessageChange({
        type: "Danger",
        info: "An error occurred. Please try again.",
      });
    }
  };

  // Resend OTP
  const handleResendOtp = async () => {
    setTimer(20);
    setShowResend(false);
    setOtp(["", "", "", ""]);
    otpRefs[0].current.focus();
    try {
      const response = await fetch("https://solar-api.antsai.in/send-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_email: formData.email }),
      });
      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "OTP resent successfully!",
        });
      } else {
        onMessageChange({
          type: "Danger",
          info: "Failed to resend OTP. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  // Verify OTP
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join("");
    if (otpValue.length === 4) {
      try {
        const response = await fetch("https://solar-api.antsai.in/verify-otp", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_email: formData.email, otp: otpValue }),
        });
        if (response.ok) {
          setIsOtpValid(true);
          setIsEmailVerified(true);
          setIsEmailLocked(true); // Lock email field after verification
          onMessageChange({
            type: "Success",
            info: "OTP Verified!",
          });
          setIsOtpModalOpen(false);
        } else {
          setIsOtpValid(false);
          onMessageChange({
            type: "Danger",
            info: "Invalid OTP. Please try again.",
          });
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
      }
    } else {
      setIsOtpValid(false);
      onMessageChange({
        type: "Warning",
        info: "Please enter a valid 4-digit OTP.",
      });
    }
  };

  // OTP timer
  useEffect(() => {
    if (isOtpModalOpen && timer > 0) {
      const countdown = setInterval(
        () => setTimer((prevTime) => prevTime - 1),
        1000
      );
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setShowResend(true);
    }
  }, [timer, isOtpModalOpen]);

  // OTP input handler
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== "" && index < 3) {
        otpRefs[index + 1].current.focus();
      }
    }
  };

  // Close OTP modal
  const handleCloseOtpModal = () => setIsOtpModalOpen(false);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <Container className="mw-100">
        <Styles />
        <Row className="mt-2 mb-2">
          <Col className="d-flex flex-row align-items-center justify-content-start">
            {/* <NavLink className="text-decoration-none" onClick={refreshPage}>
              <ArrowCircleLeftRoundedIcon
                sx={{ color: green[500], marginRight: "10px" }}
              />
              <span className="fw-bold text-decoration-none text-black" style={{ fontFamily: ' Lato black' }}>
                {t("entity.lefthome")}
              </span>
            </NavLink> */}
            <NavLink
              className="text-decoration-none d-flex align-items-center"
              onClick={refreshPage}
            >
              <img
                className="entity-form-left-arrow"
                src={blueSolidLeftIcon}
                alt="sort"
                style={{ cursor: "pointer" }}
              />
              <span
                className=" text-decoration-none text-black"
                style={{ fontFamily: " Lato black" }}
              >
                {" "}
                {t("entity.lefthome")}
              </span>
            </NavLink>
          </Col>

          <Col className="d-flex flex-row align-items-center justify-content-end"></Col>
        </Row>
      </Container>
      <Container className="mw-100">
        <Form
          className="w-100 p-0 m-0 entity-add-form"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <div className=" d-flex flex-column align-items-center justify-content-center w-80 p-0 mt-2">
            <Row className="gap-2 w-100 row-card p-4 m-2 d-flex flex-lg-row  flex-column">
              <Col>
                <Stack as="row" gap={2} direction="vertical">
                  <Col>
                    <Form.Group controlId="entityId">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("entity.id")}:
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            name="entityId"
                            value={formData.entityId}
                            readOnly
                            type="text"
                            placeholder="Entity ID"
                            disabled
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col span>
                    <Form.Group controlId="entityName">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("entity.name")}{" "}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "}
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            name="entityName"
                            Value={formData.entityName}
                            required
                            type="string"
                            onChange={(e) => handleChange(e)}
                            maxLength={30}
                            onBlur={handleBlur}
                            onPaste={handlePaste}
                            placeholder="Enter Entity Name"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="category">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("entity:category")}{" "}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "}
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Select
                            name="category"
                            value={formData.category}
                            onChange={handleChange}
                            required
                            size="md"
                          >
                            <option value="">
                              {t("entity:selectCategory")}
                            </option>
                            {categoryOptions.map((category) => (
                              <option key={category} value={category}>
                                {category}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="entityGstin">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("entity:gstin")}{" "}
                            {/* <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "} */}
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            name="GSTIN" // Make sure this matches the formData key if it's stored as GSTIN
                            value={formData.GSTIN} // Use value instead of defaultValue for controlled components
                            // required
                            type="text" // Use "text" instead of "string" for standard HTML
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter GSTIN"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Stack>
              </Col>
              <Col>
                <Stack gap={3} direction="vertical">
                  <Form.Group controlId="ownerFirstName">
                    <Row className="w-100">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          {t("entity:ownerFirstName")}
                          <StarOutlinedIcon
                            style={{ color: "red", fontSize: "smaller" }}
                          />
                          <spa>:</spa>
                        </Form.Label>
                      </Col>
                      <Col md={9}>
                        {/* <Form.Control
                          name="ownerFirstName"
                          defaultValue={formData.ownerFirstName}
                          required
                          type="text"
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only alphabetic characters and spaces, up to 30 characters
                            if (
                              /^[a-zA-Z\s]*$/.test(value) &&
                              value.length <= 30
                            ) {
                              handleChange(e);
                            }
                          }}
                          placeholder="Enter name"
                        /> */}

                        <Form.Control
                          name="ownerFirstName"
                          value={formData.ownerFirstName} // Use `value` instead of `defaultValue` for controlled components
                          required
                          type="text"
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only alphabetic characters and spaces, up to 30 characters
                            if (
                              /^[a-zA-Z\s]*$/.test(value) &&
                              value.length <= 30
                            ) {
                              setFormData((prevData) => ({
                                ...prevData,
                                ownerFirstName: value,
                              }));
                            } else {
                              // Show message if input is invalid
                              onMessageChange({
                                type: "Warning",
                                info: "First Name should contain only letters and spaces, up to 30 characters.",
                              });
                            }
                          }}
                          placeholder="Enter name"
                          maxLength={30}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group controlId="ownerLastName">
                    <Row gap={2} className="w-100">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          {t("entity:ownerLastName")}{" "}
                          <StarOutlinedIcon
                            style={{ color: "red", fontSize: "smaller" }}
                          />
                          <spa>:</spa>
                        </Form.Label>
                      </Col>
                      <Col md={9}>
                        <Form.Control
                          name="ownerLastName"
                          Value={formData.ownerLastName}
                          required
                          type="text" // 'string' isn't a valid type, so use 'text' here
                          maxLength={30}
                          placeholder="Enter name"
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only alphabetic characters and spaces, up to 30 characters
                            if (
                              /^[a-zA-Z\s]*$/.test(value) &&
                              value.length <= 30
                            ) {
                              setFormData((prevData) => ({
                                ...prevData,
                                ownerLastName: value,
                              }));
                            } else {
                              // Show message if input is invalid
                              onMessageChange({
                                type: "Warning",
                                info: "Last Name should contain only letters and spaces, up to 30 characters.",
                              });
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  {/* <Form.Group controlId="entityEmailid">
                    <Row className="w-100">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          {t("entity:entityEmailid")}{" "}
                          <StarOutlinedIcon
                            style={{ color: "red", fontSize: "smaller" }}
                          />{" "}
                          <spa>:</spa>
                        </Form.Label>
                      </Col>
                      <Col md={9}>
                        <Form.Control
                          name="email"
                          value={formData.email}
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter Email"
                        />
                      </Col>
                      <Col md={1}>
                        <Button
                          className="form-button px-2"
                          onClick={() => handleVerifyClick()}
                          disabled={isEmailVerified}
                        >
                          {isEmailVerified ? "Verified" : "Verify"}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group> */}
                  <Form.Group controlId="formEmail">
                    <Row>
                      <Col md={3}>
                        <Form.Label>Email</Form.Label>
                        <StarOutlinedIcon
                          style={{ color: "red", fontSize: "smaller" }}
                        />
                        :
                      </Col>
                      <Col md={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Enter email"
                          disabled={isEmailLocked} // Disable verify button after verification
                        />
                      </Col>
                      <Col md={1}>
                        <Button
                          className="form-button px-2"
                          onClick={() => handleVerifyClick()}
                          disabled={isEmailVerified}
                        >
                          {isEmailVerified ? "Verified" : "Verify"}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group controlId="entityMobile">
                    <Row className="w-100">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          {t("entity:entityMobile")} <spa>:</spa>
                        </Form.Label>
                      </Col>
                      <Col md={9}>
                        <Form.Control
                          name="mobile"
                          value={formData.mobile}
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter Mobile"
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Stack>
              </Col>
            </Row>

            <Row className="gap-2 w-100 row-card p-4 m-2 d-flex flex-lg-row  flex-column">
              <Col>
                <Stack as="row" gap={2} direction="vertical">
                  <Col>
                    <Form.Group controlId="country">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            Country{" "}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: " xx-small" }}
                            />{" "}
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Select
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            placeholder={
                              formData.country === ""
                                ? "Entity country"
                                : formData.country
                            }
                            required
                            size="md"
                          >
                            {/* <option value="">
                              {t("entity:Select Country")}
                            </option> */}
                            <option value="">{t("Select Country")}</option>
                            {country.map((country) => {
                              return (
                                <option
                                  key={country.country_id}
                                  value={country.country_name}
                                >
                                  {country.country_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col span>
                    <Form.Group controlId="region">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("entity:region")} <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Select
                            name="region"
                            value={formData.region}
                            onChange={(e) => {
                              handleChange(e);
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                ["state"]: "",
                              }));
                            }}
                            required
                            size="md"
                            disabled={regions.length === 0}
                          >
                            <option value="">{t("entity:selectRegion")}</option>
                            {regions.map((region, index) => (
                              <option key={index} value={region}>
                                {region}
                              </option>
                            ))}
                          </Form.Select>
                          {formData.country && regions.length === 0 && (
                            <small className="text-muted">
                              No regions available
                            </small>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    {/* Rendering the state dropdown only if states are available */}
                    {/* // State dropdown */}
                    <Form.Group controlId="state">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("entity:state")}{" "}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Select
                            name="state"
                            value={formData.state}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                            size="md"
                            disabled={states.length === 0}
                          >
                            {formData.state === "" ? (
                              <option value="">{t("Select State")}</option>
                            ) : (
                              ""
                            )}
                            {states.map((state, index) => (
                              <option
                                key={state.state_id || index}
                                value={state.state_name}
                              >
                                {state.state_name}
                              </option>
                            ))}
                          </Form.Select>
                          {formData.country && states.length === 0 && (
                            <small className="text-muted">
                              No states available
                            </small>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="district">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("entity:district")}{" "}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "}
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Select
                            name="district"
                            value={formData.district}
                            onChange={handleChange}
                            required
                            placeholder={
                              formData.district === ""
                                ? "Entity district"
                                : formData.district
                            }
                            size="md"
                          >
                            <option value="">{t("Select District")}</option>
                            {districts.map((district) => {
                              return (
                                <option
                                  key={district.district_id}
                                  value={district.district_name}
                                >
                                  {district.district_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="entityPincode">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("entity:pincode")}{" "}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "}
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            name="pincode" // Corrected to match formData.pincode
                            value={formData.pincode} // Controlled component for pincode
                            type="text"
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter Pincode"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Stack>
              </Col>
              <Col className="h-100">
                <Stack gap={3} direction="vertical">
                  <Form.Group controlId="addressLine1">
                    <Row className="w-100 align-items-baseline">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          {t("entity:addressLine1")} <spa>:</spa>
                        </Form.Label>
                      </Col>
                      <Col md={9}>
                        <Form.Control
                          name="addressOne" // Corrected name to match formData.addressOne
                          value={formData.addressOne} // Use value instead of defaultValue for controlled components
                          as="textarea"
                          rows={3}
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter Address Line 1"
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group controlId="addressLine2">
                    <Row className="w-100 align-items-baseline">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          {t("entity:addressLine2")} <spa>:</spa>
                        </Form.Label>
                      </Col>
                      <Col md={9}>
                        <Form.Control
                          name="addressTwo" // Corrected name to match formData.addressTwo
                          value={formData.addressTwo} // Use value instead of defaultValue for controlled components
                          as="textarea"
                          rows={3}
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter Address Line 2"
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <div className="p-2"></div>
                </Stack>
              </Col>
            </Row>
            <Row className="w-100">
              <div className="w-100 d-flex flex-row align-items-center justify-content-end">
                {" "}
                <Button
                  onClick={() => navigate(-1)}
                  className="m-1 form-button"
                >
                  Cancel
                </Button>{" "}
                <Button type="submit" className="ml-3 m-1 form-button">
                  Save Entity
                </Button>
              </div>
            </Row>
          </div>
          {/* OTP Modal */}
          {isOtpModalOpen && (
            <div>
              <div
                className="otp-backdrop"
                onClick={() => handleCloseOtpModal()}
              ></div>
              <div className="otp-modal">
                <div className="otp-modal-content">
                  <span
                    className="otp-close-icon"
                    onClick={() => handleCloseOtpModal()}
                  >
                    <FaTimes />
                  </span>
                  <h3>Enter OTP</h3>
                  <div className="otp-inputs">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        ref={otpRefs[index]}
                        className="otp-input"
                      />
                    ))}
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <div>
                      {timer > 0 ? (
                        <span className="otp-timer">Time left: {timer}s</span>
                      ) : (
                        <Button
                          className="form-button"
                          onClick={() => handleResendOtp()}
                        >
                          Resend OTP
                        </Button>
                      )}
                    </div>
                    {!isOtpValid && (
                      <p className="error-message">
                        Please enter a valid 4-digit OTP.
                      </p>
                    )}
                    <Button
                      className="form-button"
                      onClick={(e) => handleOtpSubmit(e)}
                    >
                      Submit OTP
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      </Container>
    </div>
  );
}

export default EntityAddPage;
