import React, { useState, useMemo, useEffect } from "react";
import "./Report.css"; // Importing the styles

// Importing icons from src/assets
import updownIcon from "../../assets/updown_icon.png";
import deleteIcon from "../../assets/delete_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import editIcon from "../../assets/edit-icon.png"

import { useNavigate } from "react-router-dom";
import { Col, Form, OverlayTrigger, Popover, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import Paginationcontrol from "../entity/PaginationControl";
import { ReportData } from "../../dataassets/ReportTemplateData";


const AdminReportTable = ({ stateChanger }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState({
        templateId: true, reportName: true, reportMenu: true, createdBy: true, creationDate: true, lastUpdateDate: true,
         comment: true
    });
    const [selectedFilter, setSelectedFilter] = useState(""); // No filter selected by default
    const [searchQuery, setSearchQuery] = useState(""); // Search input
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
    const [selectedRows, setSelectedRows] = useState([])
    const [update, setUpdate] = useState(false);
    const [tableProps, setTableProps] = useState({ rowsPerPage: 5, currentPage: 1, pageFirst: 1, pageLast: 5 })
    const [data, setData] = useState([]);
    useEffect(()=>{
        
    },[data])
    const { t } = useTranslation(['user']);

   const downloadLink = () => {
        alert("User list download action");
    }

    const handleFilterSelect = (filter) => {
        setSelectedFilter(filter);
        setIsFilterVisible(false); // Close the filter dropdown after selecting
    };

    const handleColumnSelect = (column) => {
        setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
    };

    const handleSort = (column) => {
        let direction = "ascending";
        if (sortConfig.key === column && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key: column, direction });
    };


    // Sorting logic based on the updown icon click
    const sortedData = useMemo(() => {
        let sortableData = [...ReportData];
        if (sortConfig.key) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig, searchQuery]);

    // Filtering logic (only filters if a filter is selected and a search query is entered)
    const filteredData = useMemo(() => {
        return sortedData.filter((row) => {
            if (!selectedFilter || !searchQuery) return true; // No filter if no filter or search query is provided
            return row[selectedFilter]
                .toLowerCase()
                .startsWith(searchQuery.toLowerCase()); // Filter matches only if it starts with the query
        })
    }, [update, sortConfig, searchQuery]);

    // Pagination logic
    const currentRows = useMemo(() => {
        if (filteredData.length > 0) {
            return filteredData.slice(((tableProps.currentPage - 1) * tableProps.rowsPerPage), (((tableProps.currentPage - 1) * tableProps.rowsPerPage) + tableProps.rowsPerPage))
        }
        return []
    }, [filteredData, selectedRows, tableProps]);

    const tablePropsChange = (tableProps) => {
        setTableProps(tableProps);
    }

    // Handling a maximum of 7 columns and hiding the rest in horizontal scrolling
    const visibleColumns = Object.keys(selectedColumns).filter(
        (key) => selectedColumns[key]
    );

    //handling filter and dropdown select toggle outside
    function handleAllToggleEvents() {
        isFilterVisible == true ? setIsFilterVisible(false) : "";
        isDropdownVisible == true ? setIsDropdownVisible(false) : ""
    }

    //disable row
    const disableRow = (e, rowData) => {
        e.stopPropagation();
        const index = filteredData.findIndex(dataItem => dataItem.id === rowData.id);
        rowData.status = "offline";
        filteredData.splice(index, 1, rowData)
        setUpdate(!update)
    }

   const emptyRows = currentRows.length !== 0 ? currentRows.length : 0;

    const editUser = (e, rowData) => {
        e.stopPropagation();
        stateChanger(rowData)
    }

    return (

        <div className=" d-flex flex-column align-items-center justify-content-center" onClick={() => handleAllToggleEvents()}>
            <div className="main-container">
                  <Row className="d-flex flex-row align-items-center justify-content-between my-4">

                    <Col><div>
                           <h1 className="header-title"> Report Templates</h1></div></Col>
                    <Col className="gap-3 d-flex flex-row align-items-center justify-content-end" >
                        {/* <span className="downloadLink" onClick={downloadLink}> {t('user:download')} </span>
                        <Divider style={{ height: 20 }} orientation="vertical" /> */}
                        <OverlayTrigger rootClose trigger="click" placement="left-start" overlay={
                            <Popover>
                                <Popover.Body className="overlay-body-menu">

                                    <ul>
                                        {Object.keys(selectedColumns).filter((key) => selectedColumns[key] == true).map(
                                            (column) => (
                                                <li key={column} onClick={() => handleFilterSelect(column)}>
                                                    {column.charAt(0).toUpperCase() + column.slice(1)}
                                                </li>
                                            )
                                        )}
                                    </ul>

                                </Popover.Body>
                            </Popover>
                        }>
                            <img
                                src={filterIcon}
                                alt="filter"
                                className="filter-icon"
                            />
                        </OverlayTrigger>

                        <Divider style={{ height: 20 }} orientation="vertical" />

                        <div style={{ position: 'relative' }} className=" w-auto">
                            <input
                                type="text"
                                style={{
                                    width: '341px',
                                    height: '39px',
                                    background: '#BBCCD9 0% 0% no-repeat padding-box',
                                    borderRadius: '5px',
                                    opacity: ' 0.51'
                                }}
                                className="search-input"
                                placeholder={`Search by ${selectedFilter || "..."}`}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                disabled={!selectedFilter} // Disable search input until a filter is selected
                            />
                            <img src={searchIcon} alt="search" className="search-icon" />
                        </div>
                        <button className="add-entity-button " onClick={() => stateChanger(11)}> New Report </button>

                    </Col>

                </Row>
              
                <div className="border rounded border-light-subtle">
                    <div className="table-container" style={{ overflowX: "auto" }}>
                        <table style={{ width: "100%", whiteSpace: "nowrap" }}>
                            <thead>
                                <tr>
                                    {/* <th></th> */}
                                    {visibleColumns.map((column) => (
                                        <th key={column}>
                                            {column.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + column.replace(/([A-Z])/g, " $1").slice(1)}
                                            <img
                                                src={updownIcon}
                                                alt="sort"
                                                onClick={() => handleSort(column)}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </th>
                                    ))}
                                    {/* <th></th> */}
                                    <th style={{ maxWidth: '100px', display: 'flex', justifyContent: 'end' }}>
                                        <OverlayTrigger rootClose trigger="click" placement="auto-start" overlay={
                                            <Popover>
                                                <Popover.Body className="overlay-body">

                                                    <div>
                                                        
                                                        {Object.keys(selectedColumns).map(
                                                            (column) =>                                                              
                                                                    <label key={column}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedColumns[column]}
                                                                            onChange={() => handleColumnSelect(column)}
                                                                        />{" "}
                                                                        {column.charAt(0).toUpperCase() + column.slice(1)}
                                                                    </label>
                                                                
                                                        )}
                                                    </div>

                                                </Popover.Body>
                                            </Popover>
                                        }>

                                            <img
                                                src={settingIcon}
                                                alt="settings"
                                                className="setting-icon"
                                            />

                                        </OverlayTrigger>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                 {currentRows.map((rowdata, index) => (
                                    <tr key={rowdata.templateId} >
        
                                        {visibleColumns.map((column) => (
                                            <td key={`${rowdata.templateId}-${column}`} style={!isNaN(Number(rowdata[column])) ? { 'text-align': 'center' } : { 'text-align': 'left' }}>
                                                { rowdata[column]}
                                                
                                            </td>
                                        ))}
                   
                                        <td className="delete-column">
                                            <Stack gap={2} direction="horizontal">
                                                <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit-icon"
                                                    onClick={(e) => editUser(e, rowdata)}
                                                />
                                                <img
                                                    src={deleteIcon}
                                                    alt="delete"
                                                    className="delete-icon"
                                                    onClick={(e) => { disableRow(e, rowdata) }}
                                                />
                                            </Stack>

                                        </td>
                                    </tr>
                                ))}

                                {/* Filling empty rows to maintain table height */}
                                {emptyRows < 0 &&
                                    Array.from({ length: emptyRows }).map((_, index) => (
                                        <tr key={`empty-${index}`}>
                                            <td colSpan="9" className="empty-row"></td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <>
                          {filteredData.length>0 ? <Paginationcontrol
                                rowsCount={filteredData.length}
                                tableProps={tableProps}
                                tablePropsChange={tablePropsChange}
                                alwaysShown={true}
                            />: <></>}
                        </>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AdminReportTable;
