import { Button, Card, Col, Image, Row, Stack } from "react-bootstrap";
import { ReactComponent as GridIcon } from "./../../../assets/grid2_icon.svg";
import { ReactComponent as InfoIcon } from "./../../../assets/information_icon.svg";
import expandAltIcon from "./../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
import { SvgIcon } from "@mui/material";
import { useState, useEffect } from "react";
import cardImage from "./../../../assets/login-bg-img.png";
import PlantsData from "./../../../dataassets/PlantsData";
import { useLocation, useNavigate } from "react-router-dom";

function Styles() {
  return (
    <style>
      {`

       .button-new-variant{
             background-color: #167db0;
             color: white;
             padding: 3px 25px 3px 25px;
          },
          
          .button-new-variant:hover{
               background-color: #0d3a51;
         
            }

      `}
    </style>
  );
}

function PlantCard(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  //plants object information
  // id: number;
  // plantName: string;
  // plantType: string;
  // capacity: string;
  // peakPower: string;
  // district: string;
  // state: string;
  // country: string;
  // installationDate: string;
  // pr: string;
  // cuf: string;
  // yield: string;
  // recCount: string;
  // alerts: string;
  // status: string;

  const [plant, setPlant] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state.plant !== undefined) {
      setPlant(location.state.plant);
    } else {
      setPlant(PlantsData.at[0])
    }
  }, [plant])

  const handleEditPlant = () => {
    navigate("/dashboard/plants/editplant", { state: { 'plant': plant } })
  }

  return (<Card className=" rounded-3 shadow-sm h-95" >
    <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center justify-content-between border-bottom border-bottom-2 shadow-sm border-secondary-subtle">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <GridIcon width={12} height={20} />
        <div className="production-title">Plant Master</div>
        <InfoIcon width={20} height={20} />
      </div>
      {/* <SvgIcon component={expandAltIcon} /> */}
      <img
        src={expandAltIcon}
        alt="Expand"
        className="hp-expand-icon"
        onClick={() => setIsModalOpen(true)} // Open modal on click
      />
    </Card.Header>
    <Card.Body>
      <Styles />
      <div className="peakpower-wrapper d-flex flex-row">
        <Stack gap={2}>
          <Row><Col>Plant ID</Col><Col>{plant?.id}</Col></Row>
          <Row><Col>Plant Name</Col><Col>{plant?.plantName}</Col></Row>
          <Row><Col>Capacity</Col><Col>{plant?.capacity}</Col></Row>
          <Row><Col>InstallationDate</Col><Col>{plant?.installationDate}</Col></Row>
          <Row><Col>Category</Col><Col>{plant?.Category}</Col></Row>
          <Row><Col>Type</Col><Col>{plant?.type}</Col></Row>
          <Row><Col>State</Col><Col>{plant?.state}</Col></Row>
          <Row><Col>District</Col><Col>{plant?.district}</Col></Row>
        </Stack>
        <Stack gap={0} className="d-flex flex-column align-items-center justify-content-around">
          <div className="w-100 d-flex flex-row align-items-center justify-content-between">
            <div></div>
            <Button variant="primary" className="button-new-variant " onClick={() => handleEditPlant()} > Edit Plant</Button>
          </div>
          <div className="w-100 d-flex flex-row align-items-center justify-content-between">
            <div></div>
            {/* <img src={`'url(${plant?.image!==undefined?plant['image']:cardImage}/171x180')'`} thumbnail /> */}
            <Image className="align-self-end" src={cardImage} width={250} height={300} thumbnail />
          </div>
        </Stack>
      </div>
      {/* Modal structure */}
      {isModalOpen && (
        <div className={`hp-modal open`}>
          <div className="hp-modal-content">
            <button
              className="hp-modal-close"
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </button>
            <div className="hp-modal-header">
              <h2 className="hp-modal-title">Plant Card</h2>
            </div>
            <div className="hp-modal-card-content">
              <div className="peakpower-wrapper d-flex flex-row">
                <Stack gap={2}>
                  <Row><Col>Plant ID</Col><Col>{plant?.id}</Col></Row>
                  <Row><Col>Plant Name</Col><Col>{plant?.plantName}</Col></Row>
                  <Row><Col>Capacity</Col><Col>{plant?.capacity}</Col></Row>
                  <Row><Col>InstallationDate</Col><Col>{plant?.installationDate}</Col></Row>
                  <Row><Col>Category</Col><Col>{plant?.Category}</Col></Row>
                  <Row><Col>Type</Col><Col>{plant?.type}</Col></Row>
                  <Row><Col>State</Col><Col>{plant?.state}</Col></Row>
                  <Row><Col>District</Col><Col>{plant?.district}</Col></Row>
                </Stack>
                <Stack gap={0} className="d-flex flex-column align-items-center justify-content-around">
                  <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                    <div></div>
                    <Button variant="primary" className="button-new-variant " onClick={() => handleEditPlant()} > Edit Plant</Button>
                  </div>
                  <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                    <div></div>
                    {/* <img src={`'url(${plant?.image!==undefined?plant['image']:cardImage}/171x180')'`} thumbnail /> */}
                    <Image className="align-self-end" src={cardImage} width={250} height={300} thumbnail />
                  </div>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      )}

    </Card.Body>
  </Card>)
}

export default PlantCard;