import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer, Label } from 'recharts';
import { Select, MenuItem, FormControl, InputLabel, IconButton, Typography, Box, Dialog, DialogContent  } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import format from 'date-fns/format';
import { FaChevronLeft, FaChevronRight, FaChevronDown } from "react-icons/fa";
import NoDataIcon from "../../../assets/no-data-icon.svg";
import dragHandleDots from "../../../assets/drag_handle_dots.svg";
import informationIcon from "../../../assets/information_icon.svg";
import expandAltIcon from "../../../assets/expand_alt_icon.svg";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloseBlue from "../../../assets/closeBtnBlue.svg";
import sampleData from "../SampleData"; // Adjust the path as necessary



// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import dayjs from 'dayjs';

import "./HistoricalProduction.css";

const dummyData = {
    total: [
      { name: '2022', value: 400 },
      { name: '2023', value: 500 },
    ],
    year: {
      2023: [
        { name: 'January', value: 150 },
        { name: 'February', value: 200 },
        { name: 'March', value: 180 },
      ],
      2022: [
        { name: 'January', value: 120 },
        { name: 'February', value: 180 },
        { name: 'March', value: 210 },
        { name: 'April', value: 120 },
        { name: 'May', value: 180 },
        { name: 'June', value: 210 },
        { name: 'July', value: 120 },
        { name: 'August', value: 180 },
        { name: 'September', value: 210 },
        { name: 'October', value: 120 },
        { name: 'November', value: 180 },
        { name: 'December', value: 210 },
      ],
    },
    month: {
      '2022-01': [
        { name: '1', value: 110 },
        { name: '2', value: 140 },
        { name: '3', value: 160 },
        { name: '4', value: 200 },
        { name: '5', value: 140 },
        { name: '6', value: 160 },
        { name: '7', value: 150 },
        { name: '8', value: 140 },
        { name: '9', value: 160 },
        { name: '10', value: 150 },
        { name: '11', value: 140 },
        { name: '12', value: 116 },
        { name: '13', value: 151 },
        { name: '14', value: 114 },
        { name: '15', value: 160 },
        { name: '16', value: 150 },
        { name: '17', value: 140 },
        { name: '18', value: 160 },
        { name: '19', value: 150 },
        { name: '20', value: 140 },
        { name: '21', value: 160 },
        { name: '22', value: 150 },
        { name: '22', value: 140 },
        { name: '24', value: 160 },
        { name: '25', value: 150 },
        { name: '26', value: 140 },
        { name: '27', value: 160 },
        { name: '29', value: 150 },
        { name: '30', value: 140 },
      ],
      '2022-02': [
        { name: 'Week 1', value: 270 },
        { name: 'Week 2', value: 260 },
        { name: 'Week 3', value: 250 },
      ],
      '2022-03': [
        { name: 'Week 1', value: 170 },
        { name: 'Week 2', value: 160 },
        { name: 'Week 3', value: 150 },
      ],
      '2023-01': [
        { name: '1', value: 110 },
        { name: '2', value: 140 },
        { name: '3', value: 160 },
        { name: '4', value: 200 },
        { name: '5', value: 140 },
        { name: '6', value: 160 },
        { name: '7', value: 150 },
        { name: '8', value: 140 },
        { name: '9', value: 160 },
        { name: '10', value: 150 },
        { name: '11', value: 140 },
        { name: '12', value: 116 },
        { name: '13', value: 151 },
        { name: '14', value: 114 },
        { name: '15', value: 160 },
        { name: '16', value: 150 },
        { name: '17', value: 140 },
        { name: '18', value: 160 },
        { name: '19', value: 150 },
        { name: '20', value: 140 },
        { name: '21', value: 160 },
        { name: '22', value: 150 },
        { name: '22', value: 140 },
        { name: '24', value: 160 },
        { name: '25', value: 150 },
        { name: '26', value: 140 },
        { name: '27', value: 160 },
        { name: '29', value: 150 },
        { name: '30', value: 140 },
      ],
      '2023-02': [
        { name: 'Week 1', value: 70 },
        { name: 'Week 2', value: 60 },
        { name: 'Week 3', value: 50 },
      ],
      '2023-03': [
        { name: 'Week 1', value: 70 },
        { name: 'Week 2', value: 60 },
        { name: 'Week 3', value: 50 },
      ],
    }
  };
  
  // ButtonField component
  function ButtonField(props) {
    const {
      setOpen,
      disabled,
      InputProps: { ref } = {},
    } = props;
  
    return (
      <IconButton
        ref={ref}
        sx={
          {
            padding: 0
          }
        }
        onClick={() => !disabled && setOpen((prev) => !prev)}
        disabled={disabled}
      >
        {/* <CalendarTodayIcon style={{ fontSize: 22 }} /> */}
        <CalendarMonthOutlinedIcon style={{ fontSize: 27 }} />
        
      </IconButton>
    );
  }
  
  // ButtonDatePicker component
  function ButtonDatePicker(props) {
    const [open, setOpen] = useState(false);
  
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          slots={{ ...props.slots, field: ButtonField }}
          slotProps={{ ...props.slotProps, field: { setOpen } }}
          {...props}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          openTo={props.openTo} // Dynamically set openTo based on timeFrame
        />
      </LocalizationProvider>
    );
  }
  
  function TimeFrameSelector({ value, onChange }) {
    return (
      <FormControl fullWidth style={{ minWidth: 100, marginRight: 16, }} size="small">
        {/* <InputLabel>Time Frame</InputLabel> */}
        <Select value={value} onChange={onChange} 
        sx={
          {
            maxHeight: 27,
          }}>
          <MenuItem value="year">Year</MenuItem>
          <MenuItem value="month">Month</MenuItem>
          <MenuItem value="total">Total</MenuItem>
        </Select>
      </FormControl>
    );
  }
  
  function NoDataAvailable() {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <ErrorOutlineIcon style={{ fontSize: 50, marginRight: 8 }} />
        <Typography variant="h6">No records available</Typography>
      </Box>
    );
  }
  
  function DataBarChart({ data }) {
    //console.log(labelValue)
    if (!data.chartData || data.chartData.length === 0) {
      return <NoDataAvailable />;
    }
  
    return (
      <BarChart width={800} height={250} data={data.chartData} margin={{ top: 0, right: 15, left: 0, bottom: 30 }}>
        <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.2} />
        <XAxis dataKey="name" strokeOpacity={0.1} tick={{ fontSize: 11 }}>
          <Label
            value={data.xLabel}
            offset={-5} // Adjust as needed
            position="insideBottom"
            style={{
              fontSize: "0.9rem",
              fill: "#333",
              textAnchor: "middle",
              fontFamily: "sans-serif",
              fontWeight: 600,
            }}
          />
        </XAxis>
        <YAxis strokeOpacity={0.01} tick={{ fontSize: 11 }}>
          <Label
            value="Energy"
            angle={-90}
            position="insideLeft"
            offset={10} // Adjust as needed
            style={{
              fontSize: "1.0rem",
              fill: "#333",
              textAnchor: "middle",
              fontFamily: "sans-serif",
              fontWeight: 600,
            }}
          />
        </YAxis>
        <Tooltip
          formatter={(value) =>
            typeof value === "number" ? value.toFixed(2) + " kWh" : "N/A"
          }
          contentStyle={{
            backgroundColor: "#eee",
            color: "#333",
            borderRadius: "5px",
            fontSize: "12px",
          }}
          labelStyle={{ color: "#333" }}
        />
        <Legend
          layout="horizontal"
          verticalAlign="top"
          align="right"
          formatter={(value, entry, index) =>
            <span
            className="text-color-class"
            style={{
              color:"#000000",
              fontFamily:'LATO',
              fontSize:18,
              fontWeight:500
            }}>
              {value}
            </span>
          }
        />
        <Bar
          dataKey="value"
          name=" Energy Data"
          wrapperStyle={{position: 'relative', marginTop: '20px'}}
          fill="#167DB0"
          barSize={30} // Increase the bar size to reduce the gap
          radius={[4, 4, 4, 4]}
        />
      </BarChart>
    );
  }
  
  const HistoricalProduction = () => {
    const currentYear = dayjs().year(); // Get current year
    const [selectedDate, setSelectedDate] = useState(dayjs().year(currentYear));
    const [timeFrame, setTimeFrame] = useState('year'); // Default to "year"
    const [chartData, setChartData] = useState(dummyData.year[currentYear]);
    const [xLabel, setxLabel] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    const handleDateChange = (newValue) => {
      setSelectedDate(newValue);
      const year = newValue.year();
      console.log('... ' + year);
  
      if (timeFrame === 'year' && newValue) {
        setxLabel('Year ' + year);
        setChartData(dummyData.year[year] || []);
      } else if (timeFrame === 'month' && newValue) {
        const monthKey = newValue.format('YYYY-MM');
        setxLabel(newValue.format('MMMM') + ' ' + year);
        setChartData(dummyData.month[monthKey] || []);
      }
    };
  
    const handleTimeFrameChange = (event) => {
      const selectedTimeFrame = event.target.value;
      setTimeFrame(selectedTimeFrame);
  
      if (selectedTimeFrame === 'total') {
        setSelectedDate(null);
        setxLabel('Total');
        setChartData(dummyData.total);
      } else if (selectedDate) {
        handleDateChange(selectedDate);
      }
    };
  
    // Determine what view to open the calendar to based on the timeFrame
    const getOpenToView = () => {
      if (timeFrame === 'year') return 'year';
      if (timeFrame === 'month') return 'month';
      return 'day'; // Default to 'day' if needed
    };

  return (
    <div className="hp-card">
      <div className="hp-title">
        <div className="hp-title-container">
          <img
            src={dragHandleDots}
            alt="Drag Handle"
            className="hp-drag-handle-icon"
          />
          <span>Historical Production</span>
          <img
            src={informationIcon}
            alt="Information"
            className="hp-information-icon"
          />
        </div>
        <div className="hp-controls">
        <TimeFrameSelector value={timeFrame} onChange={handleTimeFrameChange} />
      {timeFrame !== 'total' && (
        <ButtonDatePicker
          value={selectedDate}
          onChange={handleDateChange}
          views={timeFrame === 'year' ? ['year'] : ['year', 'month']}
          openTo={getOpenToView()} // Set the calendar's openTo view dynamically
          disabled={timeFrame === 'total'}
        />
      )}
          <div className="hp-divider"></div>
          <img
            src={expandAltIcon}
            alt="Expand"
            className="hp-expand-icon"
            // onClick={() => setIsModalOpen(true)} // Open modal on click
            onClick={handleOpenModal}
            style={{cursor:'pointer'}}
          />
        </div>
      </div>
      <div className="hp-divider-below-title" />
      <div className="hp-chart-container" >
        
      <ResponsiveContainer width="100%" height={250}>
        <DataBarChart data={{chartData, xLabel}} />
      </ResponsiveContainer>
      </div>
      

      {/* Modal Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth={'xl'} PaperProps={{
    sx: {
      width: "50%",
      minHeight: "45%"
    }
  }}
  fullWidth>
        <DialogContent>
        <div className="hp-title">
        <div className="hp-title-container">
          <img
            src={dragHandleDots}
            alt="Drag Handle"
            className="hp-drag-handle-icon"
          />
          <span>Historical Production</span>
          <img
            src={informationIcon}
            alt="Information"
            className="hp-information-icon"
          />
        </div>
        <div className="hp-controls">
        <TimeFrameSelector value={timeFrame} onChange={handleTimeFrameChange} />
      {timeFrame !== 'total' && (
        <ButtonDatePicker
          value={selectedDate}
          onChange={handleDateChange}
          views={timeFrame === 'year' ? ['year'] : ['year', 'month']}
          openTo={getOpenToView()} // Set the calendar's openTo view dynamically
          disabled={timeFrame === 'total'}
        />
      )}
          <div className="hp-divider"></div>
          <img 
            src={CloseBlue}
            alt="Close Button"
            className="hp-close-icon"
            // onClick={() => setIsModalOpen(true)} // Open modal on click
            onClick={handleCloseModal}
            style={{width:35, cursor:'pointer'}}
          />
        </div>
      </div>
      <div className="hp-divider-below-title" />
      <div className="hp-chart-container" >
        
      <ResponsiveContainer width="100%" height={350}>
        <DataBarChart data={{chartData, xLabel}} />
      </ResponsiveContainer>
      </div>
        </DialogContent>
      </Dialog>

      
    </div>
  );
};

export default HistoricalProduction;

