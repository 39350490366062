import React from "react";
import "./OverviewSection2.css";
import PowerNormalizationRanks from "./PowerNormalizationRanks";
import PlantStatus from "./PlantStatus";
import PeakHourRanking from "./PeakHourRanking";

function OverviewSection2() {
  return (
    <div className="overviewsection2">
      <div className="overviewsection2-container">
        <PowerNormalizationRanks />
        <PlantStatus />
        <PeakHourRanking />
      </div>
    </div>
  );
}

export default OverviewSection2;
