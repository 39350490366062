import { React, useState } from "react";
import styled from "styled-components";
import expandAltIcon from "../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
import infoIcon from "../../../assets/information_icon.svg"; // Adjust the path as necessary
import dragHandleDots from "../../../assets/drag_handle_dots.svg"; // Adjust the path as necessary
import { size } from "lodash";
import { Dialog, DialogContent  } from '@mui/material';
import CloseBlue from "../../../assets/closeBtnBlue.svg";

const Card = styled.div`
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #888888;
  border-radius: 8px;
  padding: 12px;
  margin: 16px;
  width: 20%;
  background: #fff;
  max-height: 350px;
  position: relative; /* Added for positioning the "more" text */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures the "more" text stays at the bottom */
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;

  .drag-handle {
    /*width: 16px; /* Set the desired width for the drag handle icon */
    /*height: 16px; /* Set the desired height if needed */
  }

  .info-icon {
    width: 20px; /* Set the desired width for the information icon */
    height: 20px; /* Set the desired height if needed */
  }

  .card-title{
    font-family: "Lato", Open Sans;
    font-weight: bold;
    font-size: 18px;
    color: #1C2E38;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const CustomDropdown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  select {
    appearance: none;
    border: none;
    background: none;
    font-family: Open Sans, bold;
    font-weight: bold;
    font-size: 18px;
    color: #818F96;
    cursor: pointer;
    padding-right: 25px;
  }

  img {
    position: absolute;
    right: 0;
    pointer-events: none;
    width: 25px;
  }
`;

const Content = styled.div`
  background-color: #61BDE6; /* Background color to match the green shade */
  border-radius: 8px;
  margin-top: 25px;
  padding: 50px;
  text-align: center; /* Align text to the left */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

const LargeText = styled.div`
  font-family: "LATO";
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  text-align: right; /* Align the large text to the right */
  margin-top: 5px;
`;

const SubText = styled.div`
  font-size: 20px;
  color: #fff;
  margin-bottom: 4px;
  text-align: center;
  font-weight: 500; /* Apply bold font weight */
  margin-top: 35px;
`;

const DateText = styled.div`
  font-size: 20px;
  color: #fff;
  margin-top: 5px;
  font-weight: 500;
  
  
`;
const DividerBelowTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  margin: 8px 0;
`;
const TitleDivider = styled.div`
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  margin-bottom: 8px;
`;

const TomorrowsForecastCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Card>
      <div>
        <Title>
          <TitleContainer>
        
          <img src={dragHandleDots} alt="Drag Handle" className="drag-handle" />
          <span className="card-title">Anomaly</span>
          <img src={infoIcon} alt="Info icon" />
          </TitleContainer>
          <Controls>
        <img src={expandAltIcon} alt="Expand icon"
          //onClick={handleOpenModal}
          style={{cursor:'pointer'}}/>
        </Controls>
      </Title>
      <TitleDivider />
      <Content>
        <LargeText>5 Anomalies</LargeText>
        <SubText>3 Plants</SubText>
        
        
      </Content>
      </div>
      {/* Modal Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth={'xl'} PaperProps={{
    sx: {
      width: "25%",
      minHeight: "25%"
    }
  }}
  fullWidth>
        <DialogContent>
          <div>
          <Title>
          <TitleContainer>
        
          <img src={dragHandleDots} alt="Drag Handle" className="drag-handle" />
          <span className="card-title">Anomaly</span>
          <img src={infoIcon} alt="Info icon" />
          </TitleContainer>
          <Controls>
        <img src={CloseBlue} alt="Close Button" onClick={handleCloseModal} style={{width:35, cursor:'pointer'}}/>
        </Controls>
      </Title>
      <TitleDivider />
      <div style={{ padding:'40px '}}>
      <Content style={{margin:0}}>
        <LargeText>5 Anomalies</LargeText>
        <SubText>3 Plants</SubText>
        
        
      </Content></div>
          </div>
        
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default TomorrowsForecastCard;
