import NTFilter from "../../assets/NTFilter.svg"; // Adjust path as necessary
import pdfIcon from "../../assets/pdf_icon.svg"; // Adjust path as necessary
import AdminExit from "../../assets/admin_exit.svg"; // Adjust path as necessary
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Navbar.css";
import { UserContext } from "../../Layout";
import { useContext } from "react";
function Styles() {
  return (
    <style>{`
            .top-search::placeholder {
                  color:white !important;
                  opacity:0.6
             }
            .overview-heading {
                  padding-left:20px;
                  font-weight:bold;
                  letter-spacing:2px;
             }
            .overview-divider {
                  padding-left:25px;
             }
        `}</style>
  );
}

function AdminBottomHeaderBar() {
  const adminRole=useContext(UserContext);
  const navigate = useNavigate(); // Initialize navigate function

  const handleExitClick = () => {
    adminRole.setAdmin(false);
    navigate("/dashboard/overview"); // Navigate to the overview page
  };

  return (
    <Container
      as={"div"}
      fluid
      className="mw-100 d-flex flex-column flex-lg-row align-items-sm-end align-items-center justify-content-sm-between bottom-bar"
    >
      <h5 className="overview-heading">Administrator</h5>
      <Styles />
      <Row className="d-flex flex-sm-row flex-column align-items-center justify-content-sm-between gap-2">
        <Col>
          {/* <div className="top-search-container">
                        <input
                            type="text"
                            placeholder="Search"
                            className="top-search"
                        />
                        <SearchOutlinedIcon sx={{color:'white' }} className="over-view-search-icon"/>
                    </div> */}
        </Col>
        <Col className="d-flex flex-row align-items-center justify-content-between">
          <img
            src={AdminExit}
            alt="Exit"
            className="overview-icon"
            onClick={handleExitClick} // Add onClick handler for navigation
            style={{ cursor: "pointer" }} // Add pointer cursor for indication
          />
          <div className="overview-divider"></div>
          <img src={NTFilter} alt="Filter Icon" className="overview-icon" />
          <div className="overview-divider"></div>
          <img src={pdfIcon} alt="PDF Icon" className="overview-icon" />
        </Col>
      </Row>
    </Container>
  );
}
export default AdminBottomHeaderBar;